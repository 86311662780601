import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core"
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import { CoinFullData } from '../../../../types/coin.type';

type NftCollectionFormInputSelect = {
    isForItems: boolean,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const PaymentToken = ({
    handleBlur,
    setFieldValue,
    isForItems
}: NftCollectionFormInputSelect) => {
    const { t } = useTranslation(['LibOfThings']);
    const availableCoins = useAppSelector<CoinFullData[]>((state) => state.coin.coinList);
    const [selectValue, setSelectValue] = useState<string>('');

    const handleSelect = (event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        const coin = event.target.value as string;
        if (coin == undefined) {
            throw new Error('Collection not found in handleSelect');
        }
        setFieldValue('tokenAcceptedAsPayment', coin, true);
        setSelectValue(coin);
    }

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id='tokenAcceptedAsPayment-label'>
                    <Typography display='inline'>
                        {t('tokenAcceptedAsPayment')}
                    </Typography>
                    <Typography
                        display="inline"
                        color="secondary"
                    >
                        {" *"}
                    </Typography>
                </InputLabel>
                <Box clone textAlign={'left'}>
                    <Select
                        fullWidth
                        name="tokenAcceptedAsPayment"
                        labelId="tokenAcceptedAsPayment-label"
                        onChange={handleSelect}
                        onBlur={handleBlur}
                        disabled={isForItems}
                        value={isForItems ? 'COS' : selectValue}
                    >
                        {
                            (availableCoins != null) && availableCoins.map(
                                (token, index) => (
                                    <MenuItem
                                        key={index}
                                        value={token.symbol}
                                    >
                                        {token.symbol}
                                    </MenuItem>
                                )
                            )
                        }
                    </Select>
                </Box>
            </FormControl>
        </Grid>
    )

}