import React, { useEffect, useState } from "react";

//Routing HOC
import { useHistory } from "react-router-dom";

//form field schema
import formSchema from "./config";

//Form controls
import { Field, Formik } from "formik";

//Material-UI components
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, List } from "@material-ui/icons";

//Custom Components
import TextInput from "../../../components/UI/Form/Input/TextInput";
import FeaturedCard from "../../../components/UI/Card/FeaturedCard/FeaturedCard";
import ZoomModal from "../../../components/UI/Modal/ZoomModal/ZoomModal";
import Loading from "../../../components/UI/Loading/Loading";

//derived from Material Kit pro component
import CustomImageInput from "../../../components/UI/Form/Upload/CustomImageInput/CustomImageInput";
import PdfFileInput from "../../../components/UI/Form/Upload/PdfFileInput/PdfFileInput";

// i18n
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import {
  collectionCreate,
  collectionCreatedGetList,
  collectionCreateResetAll,
  collectionSetSelected,
} from "../../../store/slices/nftSlice";
import AttributesSelection from "./AttributeSelection/AttributeSelection";

import CollectionValidationSchema from "./CollectionValidationSchema";
import { logger } from "../../../utilities/logger/logger";
import { collectionProps } from "../../../types/nft.types";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    justifyContent: "center",
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
  next: {
    marginLeft: "0.5vh",
    marginTop: "3vh",
  },
  collection: {
    marginLeft: "0.5vh",
    marginTop: "3vh",
    outline: "none",
  },
  bigAvatar: {
    backgroundColor: "white",
    margin: "auto",
    width: 80,
    height: 80,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderSize: "1px",
  },
  media: {
    maxWidth: 128,
    maxHeight: 128,
  },
  attribute: {
    maxHeight: 72,
  },
}));

const CollectionCreateForm = () => {
  const { t } = useTranslation(["CollectionCreateForm", "Common"]);

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const styleForFlexboxWithoutScrollbar = {
    margin: 0,
    width: "100%",
  };

  const [modalAttributesOpened, setAttributesModalOpened] = useState(false);
  const [waitForCreation, setWaitForCreation] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [attributeItem, setAttributeItem] = useState<
    JSX.Element | JSX.Element[]
  >(<></>);
  const [handler, setHandler] = useState(false);
  const collectionCreated = useAppSelector(
    (state) => state.nft.collectionCreated
  );
  const justCreatedCollectionAddress = useAppSelector(
    (state) => state.nft.justCreatedCollectionAddress
  );
  const collectionSelected: collectionProps | null = useAppSelector(
    (state) => state.nft.collectionSelected
  );
  const error = useAppSelector((state) => state.nft.error);

  let form = <Loading withLoader title={t("Common:loadingGeneric") + "..."} />;

  useEffect(() => {
    //dispatch(collectionCreateReset());
    dispatch(collectionCreateResetAll());
  }, []);

  useEffect(() => {
    if (collectionCreated && handler) {
      setModalOpened(true);
      setWaitForCreation(false);
    }
    setHandler(true);
  }, [collectionCreated, handler]);

  useEffect(() => {
    if (
      collectionSelected &&
      collectionSelected.contractAddress !== null &&
      collectionCreated
    ) {
      history.push("/nftCreate");
      logger.log("collectionSelected", collectionSelected);
    }
  }, [collectionSelected]);

  type CollectionValuesType = {
    collectionName: string;
    collectionSymbol: string;
    collectionDescription: string;
    imgFile: any;
    attributes: {
      trait_type: string;
      required: boolean;
    }[];
    contractFile: any;
  };

  const createCollection = async (values: CollectionValuesType) => {
    setModalOpened(false);
    const {
      collectionName,
      collectionSymbol,
      collectionDescription,
      imgFile,
      attributes,
      contractFile,
    } = values;
    const formattedAttributes: any = attributes.filter((attribute) => {
      return attribute.trait_type !== "";
    });
    const collectionData = {
      name: collectionName,
      symbol: collectionSymbol,
      attributes: formattedAttributes,
      description: collectionDescription,
      image: imgFile,
      file: contractFile,
    };
    setWaitForCreation(true);
    dispatch(collectionCreate(collectionData));
  };

  const handleConfirmModalOpen = () => {
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
    if (justCreatedCollectionAddress?.contractAddress !== "" || error) {
      dispatch(collectionCreateResetAll());
    }
  };

  const handleAttributesModalOpen = () => {
    setAttributesModalOpened(true);
  };
  const handleAttributesModalClose = () => {
    setAttributesModalOpened(false);
  };

  const handleCreateNft = () => {
    if (justCreatedCollectionAddress) {
      dispatch(collectionCreatedGetList());
      dispatch(
        collectionSetSelected({ selected: justCreatedCollectionAddress })
      );
      setModalOpened(false);
    }
  };

  const staticValidationSchema = CollectionValidationSchema(t);
  logger.info("modal opened", modalOpened);
  form = (
    <Formik
      validateOnMount
      initialValues={{
        collectionName: "",
        collectionSymbol: "",
        collectionDescription: "",
        imgFile: undefined,
        attributes: [
          {
            trait_type: "",
            required: false,
          },
        ],
        contractFile: undefined,
      }}
      validateOnChange
      validateOnBlur
      validationSchema={staticValidationSchema}
      onSubmit={(values, actions) => {
        logger.info(values);
        createCollection(values);
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isValid,
        validateForm,
        submitForm,
        resetForm,
      }) => {
        let modalConfirm: any;
        if (modalOpened) {
          //     if (!error && justCreatedCollectionAddress !== "") {
          //         modalConfirm = (
          //             <>
          //                 <Box mr={2}>
          //                     <Check/>
          //                 </Box>
          //                 <Typography variant="body1" display="inline">
          //                     {t("creationSuccess")}
          //                 </Typography>
          //             </>
          //         );
          //     } else if (error) {
          //         modalConfirm = (
          //             <>
          //                 <AlertAvatar big fail/>
          //                 <Typography variant="body1">{t("creationError")}</Typography>
          //             </>
          //         );
          //     } else
          if (!waitForCreation) {
            modalConfirm = formSchema.map((field, key) => {
              const { name } = field;
              let valueElement = null;
              if (name === "imgFile" && values.imgFile !== undefined) {
                let URLImage = URL.createObjectURL(values.imgFile);
                valueElement = (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs>
                      <Card className={classes.media} elevation={1}>
                        <CardMedia component="img" image={URLImage} />
                      </Card>
                    </Grid>
                  </Grid>
                );
              } else if (
                name === "collectionDescription" &&
                values.collectionDescription !== ""
              ) {
                valueElement = (
                  <Typography variant="body2">
                    {values.collectionDescription}
                  </Typography>
                );
              } else if (
                name === "collectionName" &&
                values.collectionName !== ""
              ) {
                valueElement = (
                  <Typography variant="body2">
                    {values.collectionName}
                  </Typography>
                );
              } else if (
                name === "collectionSymbol" &&
                values.collectionSymbol !== ""
              ) {
                valueElement = (
                  <Typography variant="body2">
                    {values.collectionSymbol}
                  </Typography>
                );
              } else if (
                name === "attributes" &&
                values.attributes[0].trait_type !== ""
              ) {
                let attrs = values.attributes.map((attribute, i) => (
                  <Grid item xs="auto" key={i}>
                    <Box clone p={2}>
                      <Paper variant="outlined">
                        <Typography align="center" color="primary">
                          {attribute.trait_type}
                        </Typography>
                      </Paper>
                    </Box>
                  </Grid>
                ));

                valueElement = (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    wrap="wrap"
                    style={styleForFlexboxWithoutScrollbar}
                  >
                    {attrs}
                  </Grid>
                );
              }
              if (valueElement) {
                return (
                  <Grid
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                    key={"modalConfirm." + key}
                  >
                    <Grid item xs={12} sm={4}>
                      <Typography variant="button">{t(field.name)}</Typography>:
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <strong>{valueElement}</strong>
                    </Grid>
                    <Box clone my={1}>
                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>
                    </Box>
                  </Grid>
                );
              } else {
                return null;
              }
            });
          }
          // else {
          //     modalConfirm = (
          //         <>
          //             <Typography variant="body2">{t("creationLoading")}</Typography>
          //             <CircularProgress/>
          //         </>
          //     );
          // }
        }
        const attributeSelect = (
          attributesData: { trait_type: string; required: boolean }[]
        ) => {
          if (attributesData.length != 0) {
            values.attributes = attributesData;
            setAttributeItem(
              attributesData.map((attribute, i) => (
                <Grid item xs="auto" key={i}>
                  <Box clone py={3} px={2}>
                    <Paper elevation={3}>
                      <Typography color="primary" variant="body1">
                        {attribute.trait_type || ""}
                      </Typography>
                    </Paper>
                  </Box>
                </Grid>
              ))
            );
          } else {
            values.attributes = [{ trait_type: "", required: false }];
            setAttributeItem(<></>);
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <FeaturedCard title={t("titleCollectionForm")}>
              <Box mx={4} mt={4} mb={2}>
                <Box textAlign="left">
                  <Typography
                    display="inline"
                    color="secondary"
                    variant="caption"
                  >
                    {" "}
                    *
                  </Typography>
                  <Typography display="inline" variant="caption">
                    {t("requiredExplained")}
                  </Typography>
                </Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="imgFile"
                      component={CustomImageInput}
                      title={t("collectionIcon")}
                      setFieldValue={setFieldValue}
                      touched={touched["imgFile"]}
                      setFieldTouched={setFieldTouched}
                      className={classes.fieldContainer}
                      value={values.imgFile || undefined}
                      type="nftImage"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box mt={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Field
                            name="collectionName"
                            fullWidth
                            label={
                              <>
                                <Typography display="inline">
                                  {t("collectionNameLabel")}
                                </Typography>
                                <Typography display="inline" color="secondary">
                                  {" "}
                                  *
                                </Typography>
                              </>
                            }
                            placeholder={t("collectionNamePlaceholder")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched["collectionName"]}
                            component={TextInput}
                            value={values.collectionName || ""}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="collectionSymbol"
                            fullWidth
                            label={
                              <>
                                <Typography display="inline">
                                  {t("collectionSymbolLabel")}
                                </Typography>
                                <Typography display="inline" color="secondary">
                                  {" "}
                                  *
                                </Typography>
                              </>
                            }
                            placeholder={t("collectionSymbolPlaceholder")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched["collectionSymbol"]}
                            component={TextInput}
                            value={values.collectionSymbol || ""}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="collectionDescription"
                            multiline //TextInput prop
                            fullWidth
                            label={
                              <>
                                <Typography display="inline">
                                  {t("collectionDescriptionLabel")}
                                </Typography>
                                <Typography display="inline" color="secondary">
                                  {" "}
                                  *
                                </Typography>
                              </>
                            }
                            placeholder={t("collectionDescriptionPlaceholder")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched["collectionDescription"]}
                            component={TextInput}
                            value={values.collectionDescription || ""}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box clone borderBottom={1} pb={1}>
                      <Grid container spacing={1}>
                        <Grid item xs="auto">
                          <List color="primary" />
                        </Grid>
                        <Grid item xs>
                          <Typography align="left" color="primary">
                            {t("collectionAttributeButton")}
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            align="left"
                          >
                            {t("collectionAttributeCaption")}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton
                            onClick={() => handleAttributesModalOpen()}
                          >
                            <Add color="primary"></Add>
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          spacing={1}
                          alignContent="center"
                          wrap="wrap"
                        >
                          {attributeItem}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="contractFile"
                      placeholder={
                        <>
                          <Typography display="inline">
                            {t("uploadCollectionContractPlaceholder")}
                          </Typography>
                          <Typography display="inline" color="secondary">
                            {" "}
                            *
                          </Typography>
                        </>
                      }
                      component={PdfFileInput}
                      className={classes.fieldContainer}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched["contractFile"]}
                      value={values.contractFile || undefined}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FeaturedCard>
            <div className={classes.fieldContainer}>
              <Button
                fullWidth
                className={classes.next}
                variant="contained"
                color="primary"
                onClick={() => handleConfirmModalOpen()}
                disabled={!isValid}
              >
                {t("collectionCreateButton")}
              </Button>
            </div>
            <ZoomModal
              title={t("collectionConfirmTitle")}
              open={modalOpened}
              buttons={
                justCreatedCollectionAddress &&
                justCreatedCollectionAddress.contractAddress !== "" ? (
                  <>
                    <Button
                      type="submit"
                      className={classes.buttons}
                      onClick={handleCreateNft}
                      variant="contained"
                    >
                      {t("nftCreateButton")}
                    </Button>
                    <Button
                      className={classes.buttons}
                      onClick={() => {
                        resetForm();
                        handleModalClose();
                      }}
                      variant="contained"
                    >
                      {t("Common:close")}
                    </Button>
                  </>
                ) : waitForCreation ? (
                  <></>
                ) : (
                  <>
                    <Button
                      type="submit"
                      className={classes.buttons}
                      onClick={submitForm}
                      variant="contained"
                    >
                      {t("collectionCreateButton")}
                    </Button>
                    <Button
                      className={classes.buttons}
                      onClick={() => handleModalClose()}
                      variant="contained"
                    >
                      {t("Common:close")}
                    </Button>
                  </>
                )
              }
            >
              {modalConfirm}
            </ZoomModal>
            <ZoomModal
              title={t("attributesTitle")}
              open={modalAttributesOpened}
            >
              <AttributesSelection
                create={attributeSelect}
                attributes={values.attributes}
                handleAttributesModalClose={handleAttributesModalClose}
              />
            </ZoomModal>
            {waitForCreation ? (
              <Grid container item justifyContent="center">
                <CircularProgress color="primary" />
              </Grid>
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
  return form;
};

export default CollectionCreateForm;
