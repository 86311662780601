import { Grid, InputBase, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';


const searchStyle = makeStyles({
    root: {
        border: '0.5px solid #000000',
        borderRadius: '5px',
    }
})

const searchIconStyle = makeStyles({
    root: {
        position: 'relative',
        top: '13%'
    }
})

export const SearchBar = () => {
    const useSearchStyle = searchStyle();
    const useSearchIconStyle = searchIconStyle();
    const { t } = useTranslation(['LibOfThings']);

    return (
        <div className={useSearchStyle.root}>
            <Grid container>
                <Grid item xs={2} md={1} style={{ textAlign: 'center' }}>
                    <SearchIcon className={useSearchIconStyle.root} />
                </Grid>
                <Grid item xs={10} md={11}>
                    <InputBase
                        value=""
                        placeholder={t('searchSpecificItem')}
                    />
                </Grid>
            </Grid>

        </div>
    )
}