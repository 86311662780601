const formSchema = [
  {
    name: "imgFile",
    text: "file",
    type: "file",
    // component: 'components/UI/Form/Upload/CustomImageInput/CustomImageInput',
    // props: {}
  },
  {
    name: "collection",
    text: "Collection",
    type: "text",
    // component: 'components/UI/Form/Upload/CustomFileInput/CustomFileInput',
  },
  {
    name: "nftName",
    text: "Nft Name",
    type: "text",
    // component: 'components/UI/Form/Input/TextInput',
    // props: {}
  },
  {
    name: "nftDescription",
    text: "Nft Description",
    type: "multiline",
    // component: 'components/UI/Form/Input/TextInput',
    // props: {}
  },
  {
    name: "numbersNft",
    text: "Number of Nfts",
    type: "text",
    // component: 'components/UI/Form/Upload/CustomFileInput/CustomFileInput',
  },
  {
    name: "attributes",
    text: "Attributes",
    type: "object[]",
    // component: 'components/UI/Form/Upload/CustomFileInput/CustomFileInput',
  }
];

export default formSchema;
