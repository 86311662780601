import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { getPreordersUnderReview } from "../../../../../store/slices/calendarSlice";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { PreorderActiveInList } from "../../LendedItems/PreorderActiveInList";
import { Skeleton } from "@material-ui/lab";
import { PaginationContainer } from "../../../../../components/UI/Pagination/PaginationContainer";

export const ListOfPreordersUnderReview = () => {
    const preordersUnderReview = useAppSelector(state => state.calendar.preordersUnderReview);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const numberOfPreorders = useAppSelector(state => state.calendar.numberOfPreordersUnderReview);
    const loading = useAppSelector(state => state.calendar.loading);
    const [currentPage, setCurrentPage] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        (() => dispatch(getPreordersUnderReview(true, currentPage)))()
    }, []);

    const handleClickOnPagination = async (selectedPage: number) => {
        setCurrentPage(selectedPage);
        dispatch(getPreordersUnderReview(true, selectedPage));
    }

    return (
        <Grid container style={{ position: 'relative', top: '3em' }} spacing={3}>
            {
                (!loading) ? (
                    preordersUnderReview.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <PreorderActiveInList
                                preorder={p}
                            />
                        </Grid>
                    })
                ) : (
                    <Skeleton
                        style={{
                            position: 'relative',
                            top: '1em',
                        }}
                        variant="rect"
                        width={'100%'}
                        height={'400px'}
                    />
                )
            }
            <Grid item xs={12} >
                {
                    numberOfPreorders > 0 &&
                    <PaginationContainer pageCount={numberOfPreorders / preordersPerPage} onChange={handleClickOnPagination} />
                }
            </Grid>
        </Grid>
    );
}