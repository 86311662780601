import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { logger } from '../../utilities/logger/logger'
import {
    assetDecimalRepresentationToInteger,
    assetIntegerToDecimalRepresentation,
} from '../../utilities/decimalsHandler/decimalsHandler'
import { uploadFileIpfs } from '../../api/resourceAPI'
import {
    approveTransfer,
    createCrowdsale,
    getAllCrowdsales,
    getCrowdsaleInfo,
    getCrowdsales,
    getCrowdsaleTotalReservation,
    getCrowdsaleWalletBalanceOfTokenToGive,
    joinCrowdsale,
    refundFromCrowdsale,
    Status,
    stopCrowdsale,
    unlockCrowdsale,
} from '../../api/crowdsaleAPI'
import config from '../../config'

//types
import { CoinBalance, CoinFullData } from '../../types/coin.type'
import { CrowdsaleStatus } from '../../types/crowdsale.types'
import {
    DAOCreateCrowdsale,
    DAOgetRefundFromCrowdsale,
    DAOJoinCrowdsale,
    DAOStopCrowdsale,
    DAOUnlockCrowdsale,
} from '../../api/daoAPI'
import { getIpfsUrl } from '../../utilities/ipfs/ipfs'
import {
    onGoingTransactionPop,
    onGoingTransactionPush,
} from './onGoingTransactionsSlice'
import { PlainPlaceType } from '../../components/Map/CrowdsaleMapUtils'
import { createNewPlace } from '../../api/placeAPI'
import { getTokenByAddress } from '../../api/coinAPI'
import { get } from 'lodash'

type CrowdsaleInitialState = {
    error: string | null
    loading: boolean
    crowdSaleCreated: boolean
    crowdSaleUnlocked: boolean
    crowdsales: any[] //TODO crowdsale type needed
    participantCoinToJoinBalance: CoinBalance //TODO check that the 'number' type is correct
    participantCoinToJoinLoaded: boolean
    participantReservationValue: number //TODO check that the 'number' type is correct
    participantReservationLoaded: boolean
    approvalPending: boolean
    pledgePending: boolean
    refundPending: boolean
    joined: boolean //TODO fix this
    refunded: boolean //TODO fix this
    crowdsaleStatus: any | undefined //TODO fix this
    partialReservation: any | undefined //TODO fix this
    totalReservation: any | undefined //TODO fix this
    unlockLoading: boolean
    unlocked: boolean
    stopLoading: boolean
    stopped: boolean
    onGoingOperations: any[]
}

const initialState: CrowdsaleInitialState = {
    error: null,
    loading: false,
    crowdSaleCreated: false,
    crowdSaleUnlocked: false,
    crowdsales: [],
    participantCoinToJoinBalance: { balance: 0, decimals: 0 },
    participantCoinToJoinLoaded: false,
    participantReservationValue: 0,
    participantReservationLoaded: false,
    approvalPending: false, //describe if the user is currently waiting for a request of "transfer approval" to complete
    pledgePending: false, //describe if the user is currently waiting for a join or a refund to complete
    joined: false,
    refunded: false,
    crowdsaleStatus: undefined,
    partialReservation: undefined,
    totalReservation: undefined,
    unlockLoading: false,
    unlocked: false,
    stopLoading: false,
    refundPending: false,
    stopped: false,
    onGoingOperations: [],
}

export const crowdsaleSlice = createSlice({
    name: 'crowdsale',
    initialState,
    reducers: {
        crowdsaleCreateStart(state) {
            state.loading = true
            state.crowdSaleCreated = false
            state.crowdSaleUnlocked = false
            state.error = null
        },

        crowdsaleCreateReset(state) {
            state.loading = false
            state.crowdSaleCreated = false
        },

        crowdsaleCreateFail(state, action: PayloadAction<{ error: string }>) {
            //TODO fixme
            state.loading = false
            state.crowdSaleCreated = false
            state.error = action.payload.error
        },

        crowdsaleCreateSuccess(state) {
            state.loading = false
            state.crowdSaleCreated = true
        },

        crowdsaleGetAllReset(state) {
            state.loading = false
            state.crowdsales = []
        },

        crowdsaleGetAllStart(state) {
            state.loading = true
        },

        crowdsaleGetAllSuccess(
            state,
            action: PayloadAction<{ crowdsalesArray: any[] }>
        ) {
            //TODO fixme
            state.loading = false
            state.crowdsales = action.payload.crowdsalesArray
        },

        crowdsaleGetAllFail(state, action: PayloadAction<{ error: string }>) {
            //TODO fixme
            state.loading = false
            state.error = action.payload.error
            state.crowdsales = []
        },
        crowdsalePagingReset(state) {
            state.loading = false
            state.crowdsales = []
        },

        crowdsalePagingStart(state) {
            state.loading = true
        },

        crowdsalePagingSuccess(
            state,
            action: PayloadAction<{ crowdsale: any | null }>
        ) {
            state.loading = false
            state.crowdsales = state.crowdsales.concat(action.payload.crowdsale)
        },

        crowdsalePagingFail(state, action: PayloadAction<{ error: string }>) {
            state.loading = false
            state.error = action.payload.error
        },

        crowdsaleLoadAndUnlockStart(
            state,
            action: PayloadAction<{ crowdsaleAddress: string }>
        ) {
            state.onGoingOperations.push(action.payload.crowdsaleAddress)
            state.unlockLoading = true
            state.unlocked = false
        },
        crowdsaleLoadAndUnlockSuccess(
            state,
            action: PayloadAction<{ crowdsaleAddress: string }>
        ) {
            state.unlockLoading = false
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.crowdsaleAddress
            )
            state.unlocked = true
        },
        crowdsaleLoadAndUnlockFail(
            state,
            action: PayloadAction<{ crowdsaleAddress: string }>
        ) {
            state.unlockLoading = false
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.crowdsaleAddress
            )
            state.unlocked = false
        },

        crowdsaleStopStart(state) {
            state.stopLoading = true
            state.stopped = false
        },
        crowdsaleStopSuccess(state) {
            state.stopLoading = false
            state.stopped = true
        },
        crowdsaleStopReset(state) {
            state.stopLoading = false
            state.stopped = false
        },

        crowdsaleGetParticipantCoinBalanceStart(state) {
            state.participantCoinToJoinBalance = { balance: 0, decimals: 0 }
            state.participantCoinToJoinLoaded = false
        },

        crowdsaleGetParticipantCoinBalanceDone(
            state,
            action: PayloadAction<{ balance: CoinBalance }>
        ) {
            state.participantCoinToJoinBalance = action.payload.balance
            state.participantCoinToJoinLoaded = true
        },

        crowdsaleGetParticipantReservationStart(state) {
            state.participantReservationValue = 0
            state.participantReservationLoaded = false
        },

        crowdsaleGetParticipantReservationDone(
            state,
            action: PayloadAction<{ reservationValue: number }>
        ) {
            //TODO fixme
            state.participantReservationValue = action.payload.reservationValue
            state.participantReservationLoaded = true
        },

        crowdsaleApprovalStarted(state) {
            state.approvalPending = true
        },

        crowdsaleApprovalDone(state) {
            state.approvalPending = false
        },

        crowdsaleJoinReset(state) {
            state.joined = false
            state.pledgePending = false
            state.approvalPending = false
        },
        crowdsaleJoinStart(state) {
            state.joined = false
            state.pledgePending = true
            state.approvalPending = false
        },

        crowdsaleJoinDone(
            state,
            action: PayloadAction<{ joinedSuccessfully: boolean }>
        ) {
            state.joined = action.payload.joinedSuccessfully
            state.pledgePending = false
            state.approvalPending = false
        },

        crowdsaleRefundReset(state) {
            state.refunded = false
            state.refundPending = false
            state.approvalPending = false
        },
        crowdsaleRefundStart(state) {
            state.refunded = false
            state.refundPending = true
            state.approvalPending = false
        },
        crowdsaleRefundDone(
            state,
            action: PayloadAction<{ refundedSuccessfully: boolean }>
        ) {
            //TODO fixme
            state.refunded = action.payload.refundedSuccessfully
            state.refundPending = false
            state.approvalPending = false
        },
        crowdsaleGetStateReset(state) {
            state.crowdsaleStatus = undefined
        },

        crowdsaleGetStateFail(state, action: PayloadAction<{ error: string }>) {
            //TODO fixme
            state.error = action.payload.error
        },

        crowdsaleGetStateDone(
            state,
            action: PayloadAction<{ status: Status }>
        ) {
            state.crowdsaleStatus = action.payload.status
        },

        crowdsaleGetCompleteReservationsReset(state) {
            state.totalReservation = undefined
        },

        crowdsaleGetCompleteReservationsDone(
            state,
            action: PayloadAction<{ totalReservations: number }>
        ) {
            //TODO fixme
            state.totalReservation = action.payload.totalReservations
        },
    },
})

export const {
    crowdsaleCreateStart,
    crowdsaleCreateReset,
    crowdsaleCreateFail,
    crowdsaleCreateSuccess,
    crowdsaleLoadAndUnlockSuccess,
    crowdsaleLoadAndUnlockFail,
    crowdsaleLoadAndUnlockStart,
    crowdsaleApprovalStarted,
    crowdsaleApprovalDone,
    crowdsaleJoinDone,
    crowdsaleJoinReset,
    crowdsaleRefundDone,
    crowdsaleRefundReset,
    crowdsaleJoinStart,
    crowdsaleRefundStart,
    crowdsaleStopStart,
    crowdsaleStopSuccess,
    crowdsaleStopReset,
    crowdsalePagingFail,
    crowdsalePagingReset,
    crowdsalePagingSuccess,
    crowdsalePagingStart,
} = crowdsaleSlice.actions

export type CrowdsaleData = {
    contract: File
    mainImage: File
    emittedCoin: {
        address: string
        addressOfOwner: string
        decimals: number
        symbol: string
    }
    acceptedCoin: {
        address: string
        addressOfOwner: string
        decimals: number
        symbol: string
    }
    startDate: Date
    endDate: Date
    bigTitle: string
    details: string
    acceptedCoinRatio: string
    forEachEmittedCoin: number
    totalAcceptedCoin: number
    totalEmittedCoin: string
    emittedCoinDisposability: number
    firstlifePlace: PlainPlaceType
}

export const crowdsaleCreate = (crowdsaleData: CrowdsaleData) => {
    logger.info('[CROWDSALE CREATE] called', crowdsaleData)

    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(crowdsaleCreateStart())
        dispatch(onGoingTransactionPush({ transactionCode: 4 }))

        let contractHash, iconHash
        try {
            if (
                crowdsaleData.contract == null ||
                crowdsaleData.mainImage == null ||
                crowdsaleData.firstlifePlace == null
            ) {
                return
            }
            const contractResponse = await uploadFileIpfs(
                crowdsaleData.contract
            )
            contractHash = config.network.ipfs.default_url + contractResponse
            const iconResponse = await uploadFileIpfs(crowdsaleData.mainImage)
            iconHash = config.network.ipfs.default_url + iconResponse
            const firstlifePlace = crowdsaleData.firstlifePlace
            let firstlifePlaceID: string = ''
            if (firstlifePlace !== null) {
                if (
                    firstlifePlace.firstLifePlaceID !== null &&
                    firstlifePlace.firstLifePlaceID !== ''
                ) {
                    firstlifePlaceID = firstlifePlace.firstLifePlaceID
                } else {
                    logger.info('bisogna creare il luogo')
                    firstlifePlaceID = await createNewPlace(
                        firstlifePlace.coords!,
                        firstlifePlace.name,
                        firstlifePlace.description,
                        firstlifePlace.address
                    )
                }
            }

            logger.info('firstlifePlaceID: ', firstlifePlaceID)

            const ethers = getState().ethers.ethersInstance
            const currentProfile = getState().user.currentProfile
            if (currentProfile == null || ethers == null) {
                dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                dispatch(
                    crowdsaleCreateFail({
                        error: `currentProfile is not defined in CoinCreate`,
                    })
                )
                return
            }
            const realm = currentProfile.realm

            try {
                let ccdaoAddress: string | undefined
                let accountAddress: string | undefined
                //checking if transaction is made by a user or a dao
                if (realm === 'dao') {
                    //we need both the address of the dao and the address of the user which has to call its contract's methods
                    ccdaoAddress =
                        currentProfile.additional_properties?.commonshoodWallet
                    if (ccdaoAddress == null) {
                        dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                        dispatch(
                            crowdsaleCreateFail({
                                error: `Something went wrong, the dao trying to start the transaction has no address ${currentProfile}`,
                            })
                        )
                        return
                    }
                    //getting the wallet also of the user who is "logged" as dao
                    accountAddress =
                        getState().user.userProfile?.additional_properties
                            ?.commonshoodWallet
                } else if (realm === 'user') {
                    accountAddress =
                        currentProfile.additional_properties?.commonshoodWallet
                }
                if (accountAddress == null) {
                    dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                    dispatch(
                        crowdsaleCreateFail({
                            error: 'account address is undefined',
                        })
                    )
                    return
                }
                try {
                    const crowdsaleParameters = {
                        tokenToGiveAddr: crowdsaleData.emittedCoin.address,
                        tokenToAccept: crowdsaleData.acceptedCoin.address,
                        start: Math.floor(
                            new Date(crowdsaleData.startDate).getTime() / 1000
                        ),
                        end: Math.floor(
                            new Date(crowdsaleData.endDate).getTime() / 1000
                        ),
                        acceptRatio: parseInt(
                            assetDecimalRepresentationToInteger(
                                crowdsaleData.acceptedCoinRatio,
                                crowdsaleData.acceptedCoin.decimals
                            )
                        ),
                        giveRatio: parseInt(
                            assetDecimalRepresentationToInteger(
                                crowdsaleData.forEachEmittedCoin,
                                crowdsaleData.emittedCoin.decimals
                            )
                        ),
                        maxCap: parseInt(
                            assetDecimalRepresentationToInteger(
                                crowdsaleData.totalAcceptedCoin,
                                crowdsaleData.acceptedCoin.decimals
                            )
                        ),
                        metadata: [
                            crowdsaleData.bigTitle,
                            crowdsaleData.details,
                            iconHash,
                            contractHash,
                            firstlifePlaceID,
                        ],
                    }
                    let creationResponse
                    if (realm === 'user') {
                        creationResponse = await createCrowdsale(
                            ethers,
                            accountAddress,
                            crowdsaleParameters,
                            crowdsaleData.firstlifePlace!.coords
                        )
                    } else {
                        creationResponse = await DAOCreateCrowdsale(
                            ethers,
                            accountAddress,
                            ccdaoAddress!,
                            crowdsaleParameters,
                            crowdsaleData.firstlifePlace!.coords
                        )
                    }
                    if (creationResponse) {
                        logger.info(
                            'succesfully created res: ',
                            creationResponse
                        )
                        dispatch(crowdsaleCreateSuccess())
                        dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                    } else {
                        dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                        dispatch(
                            crowdsaleCreateFail({
                                error: 'something went wrong while crowdsale creation',
                            })
                        )
                    }
                } catch (error: any) {
                    console.log('ERROR: ', error)
                    dispatch(crowdsaleCreateFail({ error }))
                    dispatch(onGoingTransactionPop({ transactionCode: 4 }))
                }
            } catch (error: any) {
                console.log('ERROR: ', error)
                dispatch(crowdsaleCreateFail({ error }))
                dispatch(onGoingTransactionPop({ transactionCode: 4 }))
            }
        } catch (error: any) {
            console.log('ERROR: ', error)
            dispatch(crowdsaleCreateFail({ error }))
            dispatch(onGoingTransactionPop({ transactionCode: 4 }))
        }
    }
}

export type Crowdsale = {
    crowdsaleAddress: string
    ownerAddress: string
    title: string
    description: string
    logoHash: string
    TOS: File
    contractHash: string
    startDate: Date
    endDate: Date
    acceptRatio: number
    giveRatio: number
    maxCap: number
    tokenToAcceptAddr: string
    tokenToAccept: CoinFullData
    tokenToGiveAddr: string
    tokenToGive: CoinFullData
    status: CrowdsaleStatus
    totalReservations: number
    tokenToGiveBalance: CoinBalance
}

export const crowdsaleLoadAndUnlock = (
    crowdsaleAddress: string,
    tokenToGiveAddr: string,
    amount: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(
            crowdsaleLoadAndUnlockStart({ crowdsaleAddress: crowdsaleAddress })
        )
        dispatch(onGoingTransactionPush({ transactionCode: 5 }))

        const currentProfile = getState().user.currentProfile
        const ethersInstance = getState().ethers.ethersInstance

        if (currentProfile == null || ethersInstance == null) {
            logger.debug('account address is undefined')
            dispatch(onGoingTransactionPop({ transactionCode: 5 }))
            dispatch(
                crowdsaleLoadAndUnlockFail({
                    crowdsaleAddress: crowdsaleAddress,
                })
            )
            return
        }
        const realm = currentProfile.realm
        try {
            let ccdaoAddress: string | undefined
            let accountAddress: string | undefined
            //checking if transaction is made by a user or a dao
            if (realm === 'dao') {
                //we need both the address of the dao and the address of the user which has to call its contract's methods
                ccdaoAddress =
                    currentProfile.additional_properties?.commonshoodWallet
                if (ccdaoAddress == null) {
                    dispatch(onGoingTransactionPop({ transactionCode: 5 }))
                    dispatch(
                        crowdsaleLoadAndUnlockFail({
                            crowdsaleAddress: crowdsaleAddress,
                        })
                    )
                    return
                }
                //getting the wallet also of the user who is "logged" as dao
                accountAddress =
                    getState().user.userProfile?.additional_properties
                        ?.commonshoodWallet
            } else if (realm === 'user') {
                accountAddress =
                    currentProfile.additional_properties?.commonshoodWallet
            }
            if (accountAddress == null) {
                dispatch(onGoingTransactionPop({ transactionCode: 5 }))
                dispatch(
                    crowdsaleLoadAndUnlockFail({
                        crowdsaleAddress: crowdsaleAddress,
                    })
                )
                return
            }
            try {
                let unlockCompleted
                if (realm === 'user') {
                    dispatch(crowdsaleApprovalStarted())
                    const approveCompleted = await approveTransfer(
                        ethersInstance,
                        accountAddress,
                        crowdsaleAddress,
                        tokenToGiveAddr,
                        amount
                    )
                    dispatch(crowdsaleApprovalDone())
                    if (!approveCompleted) {
                        logger.info('CROWDSALE approve fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 5 }))
                        dispatch(
                            crowdsaleLoadAndUnlockFail({
                                crowdsaleAddress: crowdsaleAddress,
                            })
                        )
                    } else {
                        //we are now authorized to join
                        unlockCompleted = await unlockCrowdsale(
                            ethersInstance,
                            crowdsaleAddress
                        )
                        if (unlockCompleted) {
                            logger.info('CROWDSALE unlock success')
                            dispatch(
                                crowdsaleLoadAndUnlockSuccess({
                                    crowdsaleAddress: crowdsaleAddress,
                                })
                            )
                            dispatch(
                                onGoingTransactionPop({ transactionCode: 5 })
                            )
                        } else {
                            logger.info('CROWDSALE unlock fail')
                            dispatch(
                                crowdsaleLoadAndUnlockFail({
                                    crowdsaleAddress: crowdsaleAddress,
                                })
                            )
                            dispatch(
                                onGoingTransactionPop({ transactionCode: 5 })
                            )
                        }
                    }
                } else {
                    // dao
                    unlockCompleted = await DAOUnlockCrowdsale(
                        ethersInstance,
                        ccdaoAddress!,
                        crowdsaleAddress,
                        accountAddress,
                        tokenToGiveAddr,
                        amount
                    )
                    if (unlockCompleted) {
                        logger.info('CROWDSALE unlock success')
                        dispatch(
                            crowdsaleLoadAndUnlockSuccess({
                                crowdsaleAddress: crowdsaleAddress,
                            })
                        )
                        dispatch(onGoingTransactionPop({ transactionCode: 5 }))
                    } else {
                        logger.info('CROWDSALE unlock fail')
                        dispatch(
                            crowdsaleLoadAndUnlockFail({
                                crowdsaleAddress: crowdsaleAddress,
                            })
                        )
                        dispatch(onGoingTransactionPop({ transactionCode: 5 }))
                    }
                }
            } catch (error) {
                dispatch(
                    crowdsaleLoadAndUnlockFail({
                        crowdsaleAddress: crowdsaleAddress,
                    })
                )
                dispatch(onGoingTransactionPop({ transactionCode: 5 }))
            }
        } catch (error) {
            dispatch(
                crowdsaleLoadAndUnlockFail({
                    crowdsaleAddress: crowdsaleAddress,
                })
            )
            dispatch(onGoingTransactionPop({ transactionCode: 5 }))
        }
    }
}

export const crowdsaleJoin = (
    crowdsaleAddress: string,
    amount: number,
    decimals: number,
    tokenToAccept: string
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(crowdsaleJoinStart())
        dispatch(onGoingTransactionPush({ transactionCode: 6 }))

        const correctAmount = parseInt(
            assetDecimalRepresentationToInteger(amount, decimals)
        )

        const currentProfile = getState().user.currentProfile
        const ethers = getState().ethers.ethersInstance

        if (currentProfile == null || ethers == null) {
            logger.debug('account address is undefined')
            dispatch(onGoingTransactionPop({ transactionCode: 6 }))
            dispatch(crowdsaleJoinDone({ joinedSuccessfully: false }))
            return
        }
        const realm = currentProfile.realm
        try {
            let ccdaoAddress: string | undefined
            let accountAddress: string | undefined
            //checking if transaction is made by a user or a dao
            if (realm === 'dao') {
                //we need both the address of the dao and the address of the user which has to call its contract's methods
                ccdaoAddress =
                    currentProfile.additional_properties?.commonshoodWallet
                if (ccdaoAddress == null) {
                    dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                    dispatch(crowdsaleJoinDone({ joinedSuccessfully: false }))
                    return
                }
                //getting the wallet also of the user who is "logged" as dao
                accountAddress =
                    getState().user.userProfile?.additional_properties
                        ?.commonshoodWallet
            } else if (realm === 'user') {
                accountAddress =
                    currentProfile.additional_properties?.commonshoodWallet
            }
            if (accountAddress == null) {
                dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                dispatch(crowdsaleJoinDone({ joinedSuccessfully: false }))
                return
            }
            const tokenToAcceptAddress = tokenToAccept

            try {
                let joinCompleted
                if (realm === 'user') {
                    dispatch(crowdsaleApprovalStarted())
                    const approveCompleted = await approveTransfer(
                        ethers,
                        accountAddress,
                        crowdsaleAddress,
                        tokenToAcceptAddress,
                        correctAmount
                    )
                    logger.info('approveCompleted: ', approveCompleted)
                    dispatch(crowdsaleApprovalDone())
                    if (!approveCompleted) {
                        logger.error('CROWDSALE join fail')
                        dispatch(
                            crowdsaleJoinDone({ joinedSuccessfully: false })
                        )
                        dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                    } else {
                        //we are now authorized to join
                        joinCompleted = await joinCrowdsale(
                            ethers,
                            accountAddress,
                            crowdsaleAddress,
                            correctAmount
                        )
                        logger.info('joinCompleted: ', joinCompleted)
                        if (joinCompleted) {
                            logger.debug('CROWDSALE getStatus success')
                            dispatch(
                                onGoingTransactionPop({ transactionCode: 6 })
                            )
                            dispatch(
                                dispatch(
                                    crowdsaleJoinDone({
                                        joinedSuccessfully: true,
                                    })
                                )
                            )
                        } else {
                            logger.error('CROWDSALE join fail')
                            dispatch(
                                onGoingTransactionPop({ transactionCode: 6 })
                            )
                            dispatch(
                                crowdsaleJoinDone({ joinedSuccessfully: false })
                            )
                        }
                    }
                } else {
                    // dao
                    joinCompleted = await DAOJoinCrowdsale(
                        ethers,
                        ccdaoAddress!,
                        crowdsaleAddress,
                        correctAmount,
                        tokenToAcceptAddress,
                        accountAddress
                    )
                    if (joinCompleted) {
                        logger.debug('CROWDSALE getStatus success')
                        dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                        dispatch(
                            dispatch(
                                crowdsaleJoinDone({ joinedSuccessfully: true })
                            )
                        )
                    } else {
                        logger.error('CROWDSALE join fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                        dispatch(
                            crowdsaleJoinDone({ joinedSuccessfully: false })
                        )
                    }
                }
            } catch (e) {
                logger.error('CROWDSALE join fail')
                dispatch(onGoingTransactionPop({ transactionCode: 6 }))
                dispatch(crowdsaleJoinDone({ joinedSuccessfully: false }))
            }
        } catch (e) {
            logger.error('CROWDSALE join fail')
            dispatch(onGoingTransactionPop({ transactionCode: 6 }))
            dispatch(crowdsaleJoinDone({ joinedSuccessfully: false }))
        }
    }
}

export const crowdsaleRefund = (
    crowdsaleAddress: string,
    amount: number,
    decimals: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(crowdsaleRefundStart())
        dispatch(onGoingTransactionPush({ transactionCode: 7 }))
        const correctAmount = parseInt(
            assetDecimalRepresentationToInteger(amount, decimals)
        )
        const ethersInstance = getState().ethers.ethersInstance
        const currentProfile = getState().user.currentProfile
        if (currentProfile == null || ethersInstance == null) {
            logger.debug('account address is undefined')
            dispatch(onGoingTransactionPop({ transactionCode: 7 }))
            dispatch(crowdsaleRefundDone({ refundedSuccessfully: false }))
            return
        }
        const realm = currentProfile.realm
        try {
            let ccdaoAddress: string | undefined
            let accountAddress: string | undefined
            //checking if transaction is made by a user or a dao
            if (realm === 'dao') {
                //we need both the address of the dao and the address of the user which has to call its contract's methods
                ccdaoAddress =
                    currentProfile.additional_properties?.commonshoodWallet
                if (ccdaoAddress == null) {
                    dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                    dispatch(
                        crowdsaleRefundDone({ refundedSuccessfully: false })
                    )
                    return
                }
                //getting the wallet also of the user who is "logged" as dao
                accountAddress =
                    getState().user.userProfile?.additional_properties
                        ?.commonshoodWallet
            } else if (realm === 'user') {
                accountAddress =
                    currentProfile.additional_properties?.commonshoodWallet
            }
            if (accountAddress == null) {
                dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                dispatch(crowdsaleRefundDone({ refundedSuccessfully: false }))
                return
            }
            try {
                let refundCompleted
                if (realm === 'user') {
                    refundCompleted = await refundFromCrowdsale(
                        ethersInstance,
                        accountAddress,
                        crowdsaleAddress,
                        correctAmount
                    )
                    if (refundCompleted) {
                        logger.debug('CROWDSALE refund success')
                        dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                        dispatch(
                            crowdsaleRefundDone({ refundedSuccessfully: true })
                        )
                    } else {
                        logger.error('CROWDSALE refund fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                        dispatch(
                            crowdsaleRefundDone({ refundedSuccessfully: false })
                        )
                    }
                } else {
                    // dao
                    refundCompleted = await DAOgetRefundFromCrowdsale(
                        ethersInstance,
                        ccdaoAddress!,
                        crowdsaleAddress,
                        accountAddress,
                        correctAmount
                    )
                    if (refundCompleted) {
                        logger.debug('CROWDSALE refund success')
                        dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                        dispatch(
                            crowdsaleRefundDone({ refundedSuccessfully: true })
                        )
                    } else {
                        logger.error('CROWDSALE refund fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                        dispatch(
                            crowdsaleRefundDone({ refundedSuccessfully: false })
                        )
                    }
                }
            } catch (e) {
                logger.error('CROWDSALE refund fail')
                dispatch(onGoingTransactionPop({ transactionCode: 7 }))
                dispatch(crowdsaleRefundDone({ refundedSuccessfully: false }))
            }
        } catch (e) {
            logger.error('CROWDSALE refund fail')
            dispatch(onGoingTransactionPop({ transactionCode: 7 }))
            dispatch(crowdsaleRefundDone({ refundedSuccessfully: false }))
        }
    }
}

export const crowdsaleStop = (crowdsaleAddress: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(crowdsaleStopStart())
        dispatch(onGoingTransactionPush({ transactionCode: 8 }))
        const ethers = getState().ethers.ethersInstance
        const currentProfile = getState().user.currentProfile
        if (currentProfile == null || ethers == null) {
            logger.debug('account address is undefined')
            dispatch(onGoingTransactionPop({ transactionCode: 8 }))
            dispatch(crowdsaleStopReset())
            return
        }
        const realm = currentProfile.realm
        try {
            let ccdaoAddress: string | undefined
            let accountAddress: string | undefined
            //checking if transaction is made by a user or a dao
            if (realm === 'dao') {
                //we need both the address of the dao and the address of the user which has to call its contract's methods
                ccdaoAddress =
                    currentProfile.additional_properties?.commonshoodWallet
                if (ccdaoAddress == null) {
                    dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                    dispatch(crowdsaleStopReset())
                    return
                }
                //getting the wallet also of the user who is "logged" as dao
                accountAddress =
                    getState().user.userProfile?.additional_properties
                        ?.commonshoodWallet
            } else if (realm === 'user') {
                accountAddress =
                    currentProfile.additional_properties?.commonshoodWallet
            }
            if (accountAddress == null) {
                dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                dispatch(crowdsaleStopReset())
                return
            }
            try {
                let closeCompleted = true
                if (realm === 'user') {
                    closeCompleted = await stopCrowdsale(
                        ethers,
                        accountAddress,
                        crowdsaleAddress
                    )
                    if (closeCompleted) {
                        logger.debug('CROWDSALE close success')
                        dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                        dispatch(crowdsaleStopSuccess())
                    } else {
                        logger.error('CROWDSALE close fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                        dispatch(crowdsaleStopReset())
                    }
                } else {
                    // dao
                    closeCompleted = await DAOStopCrowdsale(
                        ethers,
                        ccdaoAddress!,
                        crowdsaleAddress,
                        accountAddress
                    )
                    if (closeCompleted) {
                        logger.debug('CROWDSALE close success')
                        dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                        dispatch(crowdsaleStopSuccess())
                    } else {
                        logger.error('CROWDSALE close fail')
                        dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                        dispatch(crowdsaleStopReset())
                    }
                }
            } catch (e) {
                logger.error('CROWDSALE close fail')
                dispatch(onGoingTransactionPop({ transactionCode: 8 }))
                dispatch(crowdsaleStopReset())
            }
        } catch (e) {
            logger.error('CROWDSALE close fail')
            dispatch(onGoingTransactionPop({ transactionCode: 8 }))
            dispatch(crowdsaleStopReset())
        }
    }
}

export const getCrowdsaleList = (index: number, amount: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(crowdsalePagingStart())
        const currentProfile = getState().user.currentProfile
        const ethersInstance = getState().ethers.ethersInstance

        if (currentProfile == null || ethersInstance == null) {
            dispatch(
                crowdsalePagingFail({
                    error: `Something went wrong, current profile is undefined! ${currentProfile}`,
                })
            )
            return
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet
        if (accountAddress == null) {
            dispatch(
                crowdsalePagingFail({
                    error: `Something went wrong, wallet address of current profile is undefined! ${currentProfile}`,
                })
            )
            return
        }
        try {
            let crowdsaleToAddList1: any[] = await getCrowdsales(
                accountAddress,
                index
            )

            let crowdsaleToAddList: any[] = await getAllCrowdsales(
                ethersInstance
            )

            crowdsaleToAddList = await Promise.all(
                crowdsaleToAddList.map(async (crowdsaleAddress: any) => {
                    let statuEnum = config.crowdsaleStatus
                    let crowdsaleData = await getCrowdsaleInfo(
                        ethersInstance,
                        crowdsaleAddress
                    )
                    logger.info('crowdsaleData: ', crowdsaleData)
                    if (crowdsaleData == null) {
                        return
                    }
                    let tokenToGive = await getTokenByAddress(
                        ethersInstance,
                        accountAddress,
                        crowdsaleData.tokenToGiveAddr
                    )
                    let tokenToAccept = await getTokenByAddress(
                        ethersInstance,
                        accountAddress,
                        crowdsaleData.tokenToAcceptAddr
                    )
                    logger.info('tokenToGive: ', tokenToGive)
                    logger.info('tokenToAccept: ', tokenToAccept)
                    if (tokenToGive == null || tokenToAccept == null) {
                        return
                    }

                    const tokenToGiveBalance =
                        await getCrowdsaleWalletBalanceOfTokenToGive(
                            ethersInstance,
                            accountAddress,
                            crowdsaleAddress,
                            crowdsaleData.tokenToGiveAddr
                        )
                    logger.info("GIVERATIO",Number(crowdsaleData.giveRatio))    
                    return {
                        _id: crowdsaleAddress,
                        crowdsaleAddress: crowdsaleAddress,
                        ownerAddress: crowdsaleData.owner,
                        description: crowdsaleData.description,
                        title: crowdsaleData.title,
                        giveRatio: Number(crowdsaleData.giveRatio),
                        status:crowdsaleData.status,
                        logoHash: getIpfsUrl(crowdsaleData.logoHash),
                        TOS: getIpfsUrl(crowdsaleData.TOSHash),
                        contractHash: getIpfsUrl(crowdsaleData.TOSHash),
                        //startDate: new Date(crowdsale.startDate * 1000),
                        startDate: Number(crowdsaleData.start),
                        //endDate: new Date(crowdsale.endDate * 1000),
                        endDate: Number(crowdsaleData.end),
                        acceptRatio: parseFloat(
                            assetIntegerToDecimalRepresentation(
                                Number(crowdsaleData.acceptRatio),
                                2
                            )
                        ),

                        maxCap: parseFloat(
                            assetIntegerToDecimalRepresentation(
                                Number(crowdsaleData.maxCap),
                                2
                            )
                        ), //2 decimals (cap refers amount of coins)
                        totalReservations: parseFloat(
                            assetIntegerToDecimalRepresentation(
                                Number(await getCrowdsaleTotalReservation(ethersInstance, crowdsaleAddress)),
                                2
                            )
                        ),

                        tokenToAccept: {
                            ...tokenToAccept,
                            logoHash: tokenToAccept.logoUrl,
                            logoUrl: getIpfsUrl(tokenToAccept.logoUrl),
                        },
                        tokenToGive: {
                            ...tokenToGive,
                            logoHash: tokenToGive.logoUrl,
                            logoUrl: getIpfsUrl(tokenToGive.logoUrl),
                        },
                        tokenToGiveAddr: crowdsaleData.tokenToGiveAddr,
                        tokenToAcceptAddr: crowdsaleData.tokenToAcceptAddr,
                        tokenToGiveBalance: tokenToGiveBalance,
                    }
                })
                
            )
            console.log('crowdsaleToAddList', crowdsaleToAddList)
            dispatch(crowdsalePagingSuccess({ crowdsale: crowdsaleToAddList }))
        } catch (error: any) {
            logger.debug(
                '[crowdsale - getCrowdsaleList] something went wrong:',
                error
            )
            dispatch(crowdsalePagingFail({ error }))
            return
        }
    }
}
export default crowdsaleSlice.reducer
