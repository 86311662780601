import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { Title } from '../BasicComponents/Title';
import { ListOfActions } from "./ListOfActions";

export const MyAccountContainer = () => {
    const { t } = useTranslation(["LibOfThings"]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Title title={t('myAccount')} />
            </Grid>
            <Grid item xs={12}>
                <ListOfActions />
            </Grid>
        </Grid>
    )
}