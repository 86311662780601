import React from 'react';
import ReactDOM from 'react-dom';

// Redux
import {Provider} from 'react-redux';
import store from './store/store';

//React router
import {BrowserRouter} from 'react-router-dom';


//i18n - note this is all that's needed to use i18n in the whole app, no HOC, just magic
import './i18n';

//Fonts and icons
import WebFont from 'webfontloader';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

// Main App
// import './index.css';
import App from './App';

// Main Config
import config from './config';

//Main MuiTheme
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import theme from './theme/theme'

//Date utilities for datepickers
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//Snackbar Provider
import {SnackbarProvider} from 'notistack';
import './index.css';
import "./styles/react-paginate.css"

// Load Material Fonts and FA icons
WebFont.load({
    google: {
        families: [
            'Roboto:300,500,700',
            'Roboto+Slab:400,700',
            'Material Icons'
         ]
    }
});

library.add(fab, far, fas);

//Main App MuiTheme

const app=(
    <Provider store={store}>
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <SnackbarProvider
                        maxSnack={config.interface.snackbar.maxSnack}
                        transitionDuration={config.interface.snackbar.transitionDuration}
                    >
                        {/* <Suspense fallback={<Loading withLoader />}> */}
                            <App/>
                        {/* </Suspense> */}
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
//registerServiceWorker();
