import { Button, Grid } from "@material-ui/core"
import { Item } from "../../../types/libofthings.type"
import { ItemCard } from "../Cards/ItemCard/ItemCard"
import { useAppSelector } from "../../../store/hooks"
import { useEffect, useState } from "react"
import { getNewArrivalsAPI } from "../../../api/libOfThingsAPI"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

export const ListOfCards = () => {
    const collections = useAppSelector((state) => state.nft.collectionList);
    const [items, setItems] = useState<Item[]>([]);
    const {t} = useTranslation(['LibOfThings'])

    useEffect(() => {
        (async () => {
            const items = await getNewArrivalsAPI(0, 6);
            setItems(items);
        })();
    }, []);

    const getCollectionInfoFromSymbol = (symbol: string) => {
        let collection;
        collection = collections
            .filter((collection) => {
                if (collection.symbol === symbol) {
                    return collection;
                }
            })[0];
        if (collection == null) {
            collection = { contractAddress: '' };
        }
        return collection.contractAddress;
    }

    return (
        <Grid container spacing={1}>
            {items.length === 0 && <Skeleton variant="rect" width="100%" height={"600px"} />}
            {
                items.map((item) => {
                    return <Grid key={item.itemID} item xs={12} md={6} lg={4}>
                        <ItemCard item={item} address={getCollectionInfoFromSymbol(item.collectionSymbol)} />
                    </Grid>
                })
            }
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={() => { }} style={{ marginTop: '3em' }}>{t('exploreNewArrivals')}</Button>
            </Grid>
        </Grid>
    )
}