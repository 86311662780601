import React, {useEffect, useState} from "react";
//Redux Connection for files
import {useAppDispatch} from "../../../../store/hooks";
import {coinGetBalance} from "../../../../store/slices/coinSlice";

import CoinListDetails from "./CoinListDetails";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    maxWidth: "100%",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  balanceText: {
    textAlign: "right",
    marginRight: theme.spacing(5),
  },
  balanceTextWithPay: {
    textAlign: "right",
    marginRight: theme.spacing(10),
  },
  withRadioBalanceText: {
    textAlign: "right",
    marginRight: theme.spacing(6),
  },
}));

type CoinListItemProps = {
  coin: any; //fixme
  selected: boolean;
  handleSelect: (coinSymbol: string) => void;
  withRadio: boolean;
  showPayButton: boolean;
  coinLoading: boolean;
};

const CoinListItem = (props: CoinListItemProps) => {
  const [coinBalance, setCoinBalance] = useState(undefined);

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();

  const {
    coin,
    coinLoading,
    handleSelect,
    selected,
    showPayButton,
    withRadio,
  } = props;

    useEffect(() => {
        if (!coinBalance && !coinLoading) {
            dispatch(coinGetBalance(coin.symbol, coin.address, false));
        }
    }, []);

  useEffect(() => {
    if (coin.balance != null && coinBalance == null) {
      setCoinBalance(coin.balance);
    }
  }, [coin]);
  return (
    <CoinListDetails
      coin={coin}
      balance={coinBalance}
      iconData={coin.logo}
      selected={selected}
      handleSelect={() => handleSelect(coin.symbol)}
      showPayButton={showPayButton}
      withRadio={withRadio}
    />
  );
};
export default CoinListItem;
