import config from "../config";
import {assetIntegerToDecimalRepresentation} from '../utilities/decimalsHandler/decimalsHandler';
import {logger} from "../utilities/logger/logger";

import {CoinBalance} from "../types/coin.type";

import axios from "../utilities/backend/axios-metadata";
import {ethers, utils, Wallet} from "ethers";

import axios_places from "../utilities/backend/axios-firstlife-api";

const getTokenInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_TMPLT_ABI)
    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}

const getCrowdsaleInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_CRWDSL_ABI)

    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}

const getCrowdsaleFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.CRWDSL_FCTRY_ABI)

    return new ethers.Contract(
        config.smartContracts.CRWDSL_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    )
}


/**
 *
 * @param web3
 * @param accountAddress
 * @param crowdsaleAddress
 * @param tokenAddress
 */
export const crowdsaleGetReservationsOfAccount = async (ethersInstance: Wallet, accountAddress: string, crowdsaleAddress: string): Promise<number> => {
    const crowdsaleContractInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)

    let accountReservations = await crowdsaleContractInstance.getMyReservation()
    accountReservations = parseFloat(assetIntegerToDecimalRepresentation(Number(accountReservations), 2)); //decimals always 2 for coins

    return accountReservations;
}


export const getCrowdsaleWalletBalanceOfTokenToGive = async (ethersInstance: Wallet, accountAddress: string, crowdsaleAddress: string, tokenToGiveAddress: string): Promise<CoinBalance> => {
    const coinContractInstance = getTokenInstance(ethersInstance, tokenToGiveAddress)
    const tickerBalance = await coinContractInstance.balanceOf(crowdsaleAddress)
    const decimals = Number(await coinContractInstance.decimals())

    let balance: number = parseFloat(assetIntegerToDecimalRepresentation(Number(tickerBalance), decimals));
    if (decimals == 0) {
        balance = parseInt(balance.toString())
    }

    return {
        balance,
        decimals,
    }

}

export const unlockCrowdsale = async (ethersInstance: Wallet, crowdsaleAddress: string): Promise<boolean> => {
    logger.info(ethersInstance)
    const crowdsaleContractInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)
    logger.info('crowdsaleContractInstance: ', crowdsaleContractInstance)
    try {
        let res = await crowdsaleContractInstance.unlockCrowdsale({gasPrice: '0', gasLimit: 10000000})
        res = await res.wait()
        return true;
    } catch (error) {
        logger.info(error)
        return false
    }
}

export const approveTransfer = async (ethersInstance: Wallet, accountAddress: string, crowdsaleAddress: string, tokenAddress: string, amount: number): Promise<boolean> => {
    try {
        const tokenContractInstance = getTokenInstance(ethersInstance, tokenAddress)
        let approve = await tokenContractInstance.approve(
            crowdsaleAddress,
            amount
        )
        approve = await approve.wait()
        logger.info(approve)
        return true;
    } catch (error) {
        logger.info(error)
        return false
    }
};

/**
 * NOTE: Careful, this method will always fail if it's not called after an approveTransfer
 *          with correct amount and tokenAddress equal to that expected as tokenToAccept by the
 *          crowdsale contract
 */
export const joinCrowdsale = async (ethersInstance: Wallet, accountAddress: string, crowdsaleAddress: string, amount: number): Promise<boolean> => {

    const crowdsaleContractInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)

    try {
        const res = await crowdsaleContractInstance.joinCrowdsale(amount)
        await res.wait()
        logger.info(res)
        return true;
    } catch (error) {
        logger.info(error)
        return false;
    }
}

export type Status = keyof typeof config.crowdsaleStatus;

export const refundFromCrowdsale = async (ethersInstance: Wallet, accountAddress: string, crowdsaleAddress: string, amount: number): Promise<boolean> => {
    const crowdsaleContractInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)

    try {
        let refund = await crowdsaleContractInstance.refundMe(amount)
        refund = await refund.wait()
        logger.info(refund)
        return true;
    } catch (error) {
        return false
    }
}

export type CrowdsaleData = {
    tokenToGiveAddr: string
    tokenToAccept: string
    start: number
    end: number
    acceptRatio: number
    giveRatio: number
    maxCap: number
    metadata: any
}


export const createCrowdsale = async (
    ethersInstance: Wallet,
    accountAddress: string,
    crowdsaleData: CrowdsaleData,
    coords: { lat: number; lng: number }
): Promise<boolean> => {
    const {
        tokenToGiveAddr,
        tokenToAccept,
        start,
        end,
        acceptRatio,
        giveRatio,
        maxCap,
        metadata,
    } = crowdsaleData;

    try {
        const crowdsaleFactoryInstance = getCrowdsaleFactoryInstance(ethersInstance)
        let result = await crowdsaleFactoryInstance.createCrowdsale(
            tokenToGiveAddr,
            tokenToAccept,
            start,
            end,
            acceptRatio,
            giveRatio,
            maxCap,
            metadata,
        )

        result = await result.wait()
        logger.info(result)
        const event= result.events.filter((ev: any) => ev.event === "CrowdsaleAdded")
        logger.info("event: ", event)
        const crowdsaleAddress = event[0].args[1]
        if (crowdsaleAddress) {
            return true
        }
        return false
    } catch (error) {
        logger.info(error)
        return false
    }
}


export const getNumberOfCrowdsale = async (ethersInstance: Wallet, accountAddress: string): Promise<number> => {
    try {
        const CrowdsaleFactoryInstance = getCrowdsaleFactoryInstance(ethersInstance)
        let res = await CrowdsaleFactoryInstance.numberOfCrowdsales()
        return Number(res);
    } catch (error) {
        logger.debug("Could not load all crowdsales: ", error);
        return -1;
    }
}

export const stopCrowdsale = async (ethersInstance: Wallet, senderAddress: string, crowdsaleAddress: string): Promise<boolean> => {
    try {
        const crowdsaleInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)

        let stop = await crowdsaleInstance.stopCrowdsale()
        stop = await stop.wait()
        logger.info(stop)
        return true;
    } catch (error) {
        logger.info(error);
        return false;
    }
}

export const getCrowdsales = async (owner: string, page: number) => {
    const url = '/crowdsale/getCrowdsales'
    const params = new URLSearchParams({
        user: owner,
        amount: '0', //TODO fix me
        page: page + '',
    })
    const temp = await axios.get(url, { params })
    //TODO check ok
    console.log('crowdsales trovati', temp.data.crowdsales)
    return temp.data.crowdsales
}



export const getCrowdsaleInfo = async (
    ethersInstance: Wallet,
    crowdsaleAddress: string
) => {
    try {
        const crowdsaleInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)
        logger.info('crowdsaleInstance', crowdsaleInstance)
        const info = await crowdsaleInstance.getCrowdsaleInfo()

        return {
            owner: info[0],
            title: info[1],
            description: info[2],
            logoHash: info[3],
            TOSHash: info[4],
            start: info[5],
            end: info[6],
            acceptRatio: info[7],
            giveRatio: info[8],
            tokenToGiveAddr: info[9],
            tokenToAcceptAddr: info[10],
            status: config.crowdsaleStatus[info[11] as keyof typeof config.crowdsaleStatus],
            maxCap: info[12],
        }
    } catch (error) {
        logger.info(error)
        return null
    }
}

export const getAllCrowdsales = async (ethersInstance: Wallet): Promise<string[]> => {
    try {
        const CrowdsaleFactoryInstance = getCrowdsaleFactoryInstance(ethersInstance)
        let crowdsaleAddresses = [];
        crowdsaleAddresses = await CrowdsaleFactoryInstance.getAllCrowdsalesAddresses()
        logger.info("Crowdsales Addresses: ",crowdsaleAddresses)
        return crowdsaleAddresses;
    }
    catch (error){
        logger.debug("Could not load all crowdsales: ", error);
        return [];
    }
}

export const getCrowdsaleTotalReservation = async (ethersInstance: Wallet, crowdsaleAddress: string): Promise<number> => {
    const crowdsaleInstance = getCrowdsaleInstance(ethersInstance, crowdsaleAddress)
    const totalReservations = await crowdsaleInstance.raised()
    return Number(totalReservations);
}
