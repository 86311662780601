import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    DAOFactoryCreateDAO,
    DAOFactoryGetDaoAddressJoinedByUser,
    DAOFactoryIsAddressDAO,
    getDAOAddress,
    getDaoUri
} from "../../api/daoAPI";
import {getFirstLifePlaceIdsOfDaos} from "../../api/placeAPI";
import {getWallet} from "../../api/walletAPI";
import {logger} from "../../utilities/logger/logger";

import type {DaoData} from '../../types/dao.types';
import {getFromIpfs, uploadJsonIpfs} from "../../api/resourceAPI";
import {getUserNameByAddress} from "../../api/userAPI";
import config from "../../config";
import {onGoingTransactionPop, onGoingTransactionPush} from "./onGoingTransactionsSlice";


type DaoInitialState = {
    error: string | null;
    loading: boolean;
    currentDao: DaoData | null;
    currentDaoIsFound: boolean | null;

    daoList: DaoData[];
    loadingDaoList: boolean;

    daoListBelongingToLoggedUser: DaoData[] | null;
    loadingDaoListBelongingToLoggedUser: boolean;

    creationLoading: boolean;
    daoCreated: boolean;
    daoCreateError: string | null;

}

const initialState: DaoInitialState = {
    error: null,
    loading: false,
    currentDao: null,
    currentDaoIsFound: null,

    daoList: [],
    loadingDaoList: false,

    daoListBelongingToLoggedUser: null,
    loadingDaoListBelongingToLoggedUser: false,

    creationLoading: false,
    daoCreated: false,
    daoCreateError: null,
};

export const daoSlice = createSlice({
    name: 'DAO',
    initialState,
    reducers: {
        daoGetListBelongingToLoggedUserStart(state) {
            state.daoListBelongingToLoggedUser = [];
            state.error = null;
            state.loadingDaoListBelongingToLoggedUser = true;
        },
        daoGetListBelongingToLoggedUserSuccess(state, action: PayloadAction<{ daoList: DaoData[] }>) {
            state.daoListBelongingToLoggedUser = action.payload.daoList;
            state.loadingDaoListBelongingToLoggedUser = false;
        },
        daoGetListBelongingToLoggedUserFail(state, action: PayloadAction<{ error: string }>) {
            state.daoListBelongingToLoggedUser = [];
            state.error = action.payload.error;
            state.loadingDaoListBelongingToLoggedUser = false;
        },


        daoGetListStart(state) {
            state.daoList = [];
            state.error = null;
            state.loadingDaoList = true;
        },
        daoGetListSuccess(state, action: PayloadAction<{ daoList: DaoData[] }>) {
            state.daoList = action.payload.daoList;
            state.loadingDaoList = false;
        },
        daoGetListFail(state, action: PayloadAction<{ error: string }>) {
            state.error = action.payload.error;
            state.loadingDaoList = false;
        },

        daoStart(state) {
            state.error = null;
            state.loading = true;
        },
        daoReset(state) {
            state.error = null;
            state.loading = false;
            state.daoList = [];
        },

        daoGetDelete(state) {
            state.currentDao = null;
            state.currentDaoIsFound = null;
        },

        daoGetStart(state) {
            state.error = null;
            state.loading = true;
        },

        daoGetSuccess(state, action: PayloadAction<{ dao: any }>) {
            state.loading = false;
            state.currentDao = action.payload.dao;
            state.currentDaoIsFound = true;
        },

        daoGetFail(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
            state.currentDaoIsFound = false;
        },
        daoCreateStart(state) {
            state.daoCreated = false;
            state.creationLoading = true;
        },
        daoCreateSuccess(state) {
            state.daoCreated = true;
            state.creationLoading = false;
        },
        daoCreateFail(state, action: PayloadAction<{ error: string }>) {
            state.creationLoading = false;
            state.daoCreated = false;
            state.daoCreateError = action.payload.error;
        },
        daoCreateReset(state) {
            state.daoCreated = false;
            state.creationLoading = false;
            state.daoCreateError = null;
        },


    }
});

export const {
    daoGetListBelongingToLoggedUserStart,
    daoGetListBelongingToLoggedUserSuccess,
    daoGetListBelongingToLoggedUserFail,
    daoGetListStart,
    daoGetListSuccess,
    daoGetListFail,
    daoStart,
    daoReset,
    daoGetDelete,
    daoGetStart,
    daoGetSuccess,
    daoGetFail,
    daoCreateFail,
    daoCreateStart,
    daoCreateSuccess,
    daoCreateReset,
} = daoSlice.actions;

export const createDao = (daoName: string, firstlifePlaceID: string, description: string, daoType: string, coordinates:any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(daoCreateStart())
        dispatch(onGoingTransactionPush({transactionCode:3}))

        const accountAddress = getState().ethers.currentAccount;
        const ethersInstance = getState().ethers.ethersInstance;
        try {
            if(accountAddress && ethersInstance){
                const creator = await getUserNameByAddress(accountAddress)
                const daoUri =
                    config.network.ipfs.default_url +
                    (await uploadJsonIpfs({
                            name: daoName,
                            description: description,
                            firstlifePlaceID: firstlifePlaceID,
                            type: daoType,
                            creator: creator,
                            coordinates: coordinates
                        }
                    ));
                const result = await DAOFactoryCreateDAO(ethersInstance, daoName, firstlifePlaceID, daoUri, accountAddress, daoType, coordinates);
                if (result) {
                    dispatch(daoCreateSuccess())
                    daoGetListBelongingToLoggedUser()(dispatch, getState);
                    dispatch(onGoingTransactionPop({transactionCode:3}))

                } else {
                    dispatch(onGoingTransactionPop({transactionCode:3}))
                    dispatch(daoCreateFail({error: "error in dao creation"}))
                }
            }

        } catch (error: any) {
            dispatch(onGoingTransactionPop({transactionCode:3}))

            dispatch(daoCreateFail({error: "error in dao creation"}))
        }
    }
}


/**
 *
 * @param flPlaceIDs an OPTIONAL list of flPlaceIDs. If not passed this action will get all of them from the related api.
 * @param searchSubstring
 */
export const daoGetList = (flPlaceIDs: string[] | null, searchSubstring?: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const ethersInstance = getState().ethers.ethersInstance;
            if(!ethersInstance){
                dispatch(daoGetListFail({error: "ethersInstance not found"}));
                return
            } 

            dispatch(daoGetListStart());
            const firstLifePlaceIds: string[] = flPlaceIDs ? flPlaceIDs : await getFirstLifePlaceIdsOfDaos();
            const daos: DaoData[] = [];
            for (const id of firstLifePlaceIds) {
                const daoAddr = await getDAOAddress(ethersInstance, id);
                if (daoAddr != null) {
                    const daoCid = await getDaoUri(ethersInstance, daoAddr);
                    if(!daoCid) continue;
                    const daoData = await getFromIpfs(daoCid);
                    const daoName = daoData.name;
                    const daoType = daoData.type;
                    if (!searchSubstring || daoName.toLocaleLowerCase().includes(searchSubstring.toLocaleLowerCase())) {
                        daos.push({
                            additional_properties: {
                                commonshoodWallet: daoAddr,
                            },
                            name: daoName,
                            flPlaceId: id,
                            realm: "dao",
                            type: daoType,
                        });
                    }
                }
            }

            logger.log(`DAOS found ${daos.length} ---> `, daos);
            dispatch(daoGetListSuccess({daoList: daos}));

        } catch (error: any) {
            logger.error("Could not get the list of daos, ", error);
            dispatch(daoGetListFail({error}));
        }
    }
};


export const daoGetListBelongingToLoggedUser = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const ethersInstance = getState().ethers.ethersInstance;
            if(!ethersInstance){
                dispatch(daoGetListBelongingToLoggedUserFail({error: "ethersInstance not found"}));
                return
            }
            dispatch(daoGetListBelongingToLoggedUserStart());

            //first we get the wallet address of the currently logged user
            const loggedUserWallet = await getWallet(true);
            //the we get a list of daos in which he is at least a member
            const daosAddresses = await DAOFactoryGetDaoAddressJoinedByUser(ethersInstance, loggedUserWallet.address);

            let daos: DaoData[] = [];
            for (const address of daosAddresses) {
                const daoCid = await getDaoUri(ethersInstance, address);
                if(!daoCid) continue;
                const daoData = await getFromIpfs(daoCid);
                const daoName = daoData.name;
                const daoFirstLifePlaceId = daoData.firstlifePlaceID;
                const daoType = daoData.type;
                daos.push({
                    additional_properties: {
                        commonshoodWallet: address,
                    },
                    name: daoName,
                    flPlaceId: daoFirstLifePlaceId,
                    realm: "dao",
                    type: daoType,
                });
            }
            dispatch(daoGetListBelongingToLoggedUserSuccess({daoList: daos}));
        } catch (error: any) {
            logger.error(`Could not get the list of daos of currently logged user`, error);
            dispatch(daoGetListBelongingToLoggedUserFail({error}));

        }
    }
}
export default daoSlice.reducer;
