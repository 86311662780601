import React from "react";

import {TextField} from '@material-ui/core'

const TextInput = ({ field, ...props }) => {

    const { errMessage, touched, ...otherProps } = props;
    const { name, onChange, onBlur } = field;
    return (
        <TextField
            name={name}
            error={touched && errMessage ? true : false}
            helperText={touched && errMessage ? errMessage : undefined}
            onChange={onChange}
            onBlur={onBlur}
            {...otherProps}
        />
    );
};
export default TextInput;