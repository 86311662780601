import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {logger} from "../../utilities/logger/logger";

import {
    acceptMarketplace,
    cancelMarketplace,
    createMarketplace,
    getMarketplaceNumberFromMetadata,
    getMarketplacesFromMetadata,
    Marketplace,
    MarketplaceProps,
} from "../../api/marketplaceAPI";
import {getTokenFromMetadata} from "../../api/coinAPI";
import {assetDecimalRepresentationToInteger} from "../../utilities/decimalsHandler/decimalsHandler";
import {onGoingTransactionPop, onGoingTransactionPush} from "./onGoingTransactionsSlice";

type MarketplaceInitialState = {
    error: string | null;
    loading: boolean;
    marketplaceCreated: boolean;
    marketplaceList: Marketplace[];
    marketplaceNumber: number;
    acceptLoading: boolean;
    marketplaceAccepted: boolean;
    cancelLoading: boolean;
    marketplaceCancelled: boolean;
    onGoingOperations: any[];
    page: number;
};
const initialState: MarketplaceInitialState = {
    error: null,
    loading: false,
    marketplaceCreated: false,
    marketplaceList: [],
    marketplaceNumber: 0,
    acceptLoading: false,
    marketplaceAccepted: false,
    cancelLoading: false,
    marketplaceCancelled: false,
    onGoingOperations: [],
    page: 0,
};
export const marketplaceSlice = createSlice({
    name: "marketplace",
    initialState,
    reducers: {
        // creation methods
        marketplaceCreateReset(state) {
            state.marketplaceCreated = false;
            state.loading = false;
            state.error = null;
        },
        marketplaceIsCreatedReset(state) {
            state.marketplaceCreated = false;
        },
        marketplaceCreateStart(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations.push(action.payload.nftAddress);
            state.marketplaceCreated = false;
            state.loading = true;
        },
        marketplaceCreateFail(
            state,
            action: PayloadAction<{ error: string; nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.loading = false;
            state.error = action.payload.error;
        },
        marketplaceCreateSuccess(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.marketplaceCreated = true;
            state.loading = false;
        },
        marketplaceGetListStart(state) {
            state.loading = true;
            state.page = 0;
        },
        marketplaceGetListSuccess(
            state,
            action: PayloadAction<{ marketplaceList: Marketplace[]; page: number }>
        ) {
            state.loading = false;
            state.marketplaceList = state.marketplaceList.concat(
                action.payload.marketplaceList
            );
            state.page = action.payload.page;
        },
        marketplaceGetListFail(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        marketplaceGetListReset(state) {
            state.loading = false;
            state.marketplaceList = [];
            state.page = 0;
        },
        // Accept methods
        marketplaceAcceptStart(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations.push(action.payload.nftAddress);
            state.acceptLoading = true;
            state.marketplaceAccepted = false;
        },
        marketplaceAcceptSuccess(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.acceptLoading = false;
            state.marketplaceAccepted = true;
        },
        marketplaceAcceptFail(
            state,
            action: PayloadAction<{ error: string; nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.acceptLoading = false;
            state.error = action.payload.error;
            state.marketplaceAccepted = false;
        },
        marketplaceAcceptReset(state) {
            state.acceptLoading = false;
            state.marketplaceAccepted = false;
            state.error = null;
        },
        marketplaceCancelStart(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations.push(action.payload.nftAddress);
            state.cancelLoading = true;
            state.marketplaceCancelled = false;
        },
        marketplaceCancelSuccess(
            state,
            action: PayloadAction<{ nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.cancelLoading = false;
            state.marketplaceCancelled = true;
        },
        marketplaceCancelFail(
            state,
            action: PayloadAction<{ error: string; nftAddress: string }>
        ) {
            state.onGoingOperations = state.onGoingOperations.filter(
                (item) => item != action.payload.nftAddress
            );
            state.cancelLoading = false;
            state.error = action.payload.error;
            state.marketplaceCancelled = false;
        },
        marketplaceCancelReset(state) {
            state.cancelLoading = false;
            state.marketplaceCancelled = false;
            state.error = null;
        },
        marketplaceGetNumberStart(state) {
            state.marketplaceNumber = 0;
        },
        marketplaceGetNumberFail(state, action: PayloadAction<{ error: string }>) {
            state.error = action.payload.error;
        },
        marketplaceGetNumberSuccess(
            state,
            action: PayloadAction<{ marketplaceNumber: number }>
        ) {
            state.marketplaceNumber = action.payload.marketplaceNumber;
        },
        marketplaceGetNumberReset(state) {
            state.marketplaceNumber = 0;
        },
    },
});

export const {
    marketplaceCreateReset,
    marketplaceCreateStart,
    marketplaceCreateFail,
    marketplaceCreateSuccess,
    marketplaceGetListStart,
    marketplaceGetListFail,
    marketplaceGetListSuccess,
    marketplaceGetListReset,
    marketplaceAcceptStart,
    marketplaceAcceptFail,
    marketplaceAcceptSuccess,
    marketplaceAcceptReset,
    marketplaceCancelStart,
    marketplaceCancelFail,
    marketplaceCancelSuccess,
    marketplaceCancelReset,
    marketplaceIsCreatedReset,
    marketplaceGetNumberStart,
    marketplaceGetNumberFail,
    marketplaceGetNumberSuccess,
    marketplaceGetNumberReset,
} = marketplaceSlice.actions;

export const marketplaceCreate = (props: MarketplaceProps) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(marketplaceCreateReset());
        dispatch(marketplaceCreateStart({nftAddress: props.tokenIdOffered}));
        dispatch(onGoingTransactionPush({transactionCode: 17}))

        const currentProfile = getState().user.currentProfile;
        const ethersInstance = getState().ethers.ethersInstance;

        if (currentProfile == null || ethersInstance == null) {
            dispatch(onGoingTransactionPop({transactionCode: 17}))
            dispatch(
                marketplaceCreateFail({
                    error: `currentProfile is not defined in marketplaceCreate`,
                    nftAddress: props.tokenIdOffered,
                })
            );
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(onGoingTransactionPop({transactionCode: 17}))
            dispatch(
                marketplaceCreateFail({
                    error: "account address is undefined",
                    nftAddress: props.tokenIdOffered,
                })
            );
            return;
        }

        try {

            const coinData = await getTokenFromMetadata(props.coinRequired, "");
            const decimals = coinData.decimals;
            props.amountRequired = parseInt(
                assetDecimalRepresentationToInteger(props.amountRequired, decimals)
            );
            try {
                await createMarketplace(ethersInstance, accountAddress, props);
                dispatch(onGoingTransactionPop({transactionCode: 17}))
                dispatch(
                    marketplaceCreateSuccess({nftAddress: props.tokenIdOffered})
                );
            } catch (error: any) {
                logger.debug("Something went bad while creating marketplace:", error);
                dispatch(onGoingTransactionPop({transactionCode: 17}))
                dispatch(
                    marketplaceCreateFail({error, nftAddress: props.tokenIdOffered})
                );
            }
        } catch (error: any) {
            dispatch(onGoingTransactionPop({transactionCode: 17}))
            dispatch(
                marketplaceCreateFail({error, nftAddress: props.tokenIdOffered})
            );
        }
    };
};

export const marketplaceAccept = (
    marketplaceAddress: string,
    coinRequired: string,
    amountRequired: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(marketplaceAcceptReset());
        dispatch(marketplaceAcceptStart({nftAddress: marketplaceAddress}));
        dispatch(onGoingTransactionPush({transactionCode: 18}))
        const currentProfile = getState().user.currentProfile;
        const ethersInstance = getState().ethers.ethersInstance;

        if (currentProfile == null || ethersInstance == null) {
            dispatch(onGoingTransactionPop({transactionCode: 18}))
            dispatch(
                marketplaceAcceptFail({
                    error: `currentProfile is not defined in marketplace`,
                    nftAddress: marketplaceAddress,
                })
            );
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(onGoingTransactionPop({transactionCode: 18}))
            dispatch(
                marketplaceAcceptFail({
                    error: "account address is undefined",
                    nftAddress: marketplaceAddress,
                })
            );
            return;
        }
        try {

            const coinData = await getTokenFromMetadata(coinRequired, "");
            const decimals = coinData.decimals;
            amountRequired = parseInt(
                assetDecimalRepresentationToInteger(amountRequired, decimals)
            );
            try {
                await acceptMarketplace(
                    ethersInstance,
                    accountAddress,
                    marketplaceAddress,
                    coinRequired,
                    amountRequired
                );
                dispatch(onGoingTransactionPop({transactionCode: 18}))
                dispatch(marketplaceAcceptSuccess({nftAddress: marketplaceAddress}));
            } catch (error: any) {
                logger.debug("Something went bad while get all marketplace:", error);
                dispatch(onGoingTransactionPop({transactionCode: 18}))
                dispatch(
                    marketplaceAcceptFail({error, nftAddress: marketplaceAddress})
                );
            }
        } catch (error: any) {
            dispatch(onGoingTransactionPop({transactionCode: 18}))
            dispatch(
                marketplaceAcceptFail({error, nftAddress: marketplaceAddress})
            );
        }
    };
};

export const marketplaceCancel = (marketplaceAddress: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(marketplaceCancelReset());
        dispatch(marketplaceCancelStart({nftAddress: marketplaceAddress}));
        dispatch(onGoingTransactionPush({transactionCode: 19}))
        const currentProfile = getState().user.currentProfile;
        const ethersInstance = getState().ethers.ethersInstance;

        if (currentProfile == null || ethersInstance == null) {
            dispatch(onGoingTransactionPop({transactionCode: 19}))
            dispatch(
                marketplaceCancelFail({
                    error: `currentProfile is not defined in marketplace`,
                    nftAddress: marketplaceAddress,
                })
            );
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(onGoingTransactionPop({transactionCode: 19}))
            dispatch(
                marketplaceCancelFail({
                    error: "account address is undefined",
                    nftAddress: marketplaceAddress,
                })
            );
            return;
        }
        try {
            try {
                await cancelMarketplace(ethersInstance, accountAddress, marketplaceAddress);
                dispatch(onGoingTransactionPop({transactionCode: 19}))
                dispatch(marketplaceCancelSuccess({nftAddress: marketplaceAddress}));
            } catch (error: any) {
                logger.debug("Something went bad while get all marketplace:", error);
                dispatch(onGoingTransactionPop({transactionCode: 19}))
                dispatch(
                    marketplaceCancelFail({error, nftAddress: marketplaceAddress})
                );
            }
        } catch (error: any) {
            dispatch(onGoingTransactionPop({transactionCode: 19}))
            dispatch(
                marketplaceCancelFail({error, nftAddress: marketplaceAddress})
            );
        }
    };
};

export const marketplaceGetListFM = (
    filter: string,
    status: string,
    sortBy: string,
    page: number
) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(marketplaceGetListStart());

        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                marketplaceGetListFail({
                    error: `currentProfile is not defined in marketplace`,
                })
            );
            return;
        }
        const accountAddress =
            currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(
                marketplaceGetListFail({error: "account address is undefined"})
            );
            return;
        }
        try {
            try {
                let marketplaceList: any[];
                marketplaceList = await getMarketplacesFromMetadata(
                    accountAddress,
                    filter,
                    status,
                    sortBy,
                    page
                );

                dispatch(
                    marketplaceGetListSuccess({marketplaceList: marketplaceList, page})
                );
            } catch (error: any) {
                logger.debug("Something went bad while get all marketplace:", error);
                dispatch(marketplaceGetListFail({error}));
            }
        } catch (error: any) {
            dispatch(marketplaceGetListFail({error}));
        }
    };
};

export const marketplaceGetNumberFM = (status: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(marketplaceGetNumberReset());
        dispatch(marketplaceGetNumberStart());

        try {
            try {
                let marketplaceNumber: number;
                marketplaceNumber = await getMarketplaceNumberFromMetadata(status);

                dispatch(
                    marketplaceGetNumberSuccess({marketplaceNumber: marketplaceNumber})
                );
            } catch (error: any) {
                logger.debug("Something went bad while get all marketplace:", error);
                dispatch(marketplaceGetListFail({error}));
            }
        } catch (error: any) {
            dispatch(marketplaceGetListFail({error}));
        }
    };
};

export default marketplaceSlice.reducer;
