import React, {MouseEvent} from "react";

import {Avatar, Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography} from '@material-ui/core';
import amber from "@material-ui/core/colors/yellow";
import {Room, SwapHoriz} from '@material-ui/icons';

//Style injections
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";

import {useTranslation} from "react-i18next";
import {DaoTypes} from "../../../../types/dao.types";
import StoreIcon from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/Group";


const useStyles =  makeStyles((theme: Theme) => ({
    mainName: {
        paddingRight: theme.spacing(1),
    },
    favorite: {
        color: amber[800]
    },
    favoritePosition: {
        left: '-11px'
    },
    locationText: {
        paddingRight: '15px'
    }
}));

type ContractListItemProps= {
    name: string;
    icon: string | undefined
    near?: boolean;
    type: string | null;
    category: string | null;
    handleSelect: () => void;
    email: string | null;
    mostContacted?: any;//TODO check type
    locatePLace: (coordinates: any) => void;//TODO check type
    index: string; //it's a firstlife id
};

const ContactListItem = (props: ContractListItemProps) => {

    const {
        name,
        icon,
        category,
        handleSelect,
        email,
        mostContacted,
        type
    } = props;

    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation(['ContactListItem','DaoCreateForm']);

    let nearTooltip = null;
    let distanceText: string = "";
    let mostContactedIcon = null;

    if (mostContacted) {
        mostContactedIcon = (
            <SwapHoriz />
        );
    }

    const mainName = (
        <Typography variant="subtitle2" className={classes.mainName}>
            <span>{name}</span>
        </Typography>
    );

    const avatar = () => {
        if(type) {
            return (
                type === DaoTypes[0] ?
                    <Avatar alt={name} style={{marginRight: '10px', backgroundColor:"white"}}><StoreIcon style={{color: theme.palette.primary.main}}/></Avatar>
                    :
                    type === DaoTypes[1] ?
                        <Avatar alt={name} style={{marginRight: '10px', backgroundColor:"white"}}><GroupIcon style={{color:theme.palette.primary.main}}/></Avatar>
                        :
                        <Avatar src={icon} alt={name} style={{marginRight: '10px'}}/>
            )
        }
        return (<Avatar src={icon} alt={name} style={{marginRight: '10px'}}/>)
    }

    let listItemText = null;
    if(category === 'FL_PLACES' ){ //DAO
        listItemText = <ListItemText primary={mainName} secondary={t('DaoCreateForm:'+type)}/>;
    }else{ //PERSON
        //listItemText = <ListItemText primary={mainName} secondary={t('userCategory')} />
        listItemText = <ListItemText primary={mainName} secondary={email ? `${email}` : null}/>
    }

    return (
        <ListItem button onClick={handleSelect} style={{paddingLeft: 0, paddingRight: 0}}>
            <Grid container alignContent="space-between" direction="row" alignItems="center">
                <Grid item container xs={2} md={1} alignItems="center">
                    {avatar()}
                </Grid>
                <Grid item xs={8} md={10}>
                    {listItemText}
                </Grid>
                <Grid item container xs={2} md={1} alignItems="center">
                    {nearTooltip}{mostContactedIcon}
                </Grid>
            </Grid>
        </ListItem>
    );
};

export default (ContactListItem);