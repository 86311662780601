import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "notistack";
import { NftImageInList } from "./NftImageInList";


export const ItemCard = ({ title, subheader, uriImage, buttonStyle, handleClick }: {
    title: string,
    subheader: string,
    uriImage: string,
    buttonStyle: ClassNameMap<"root">,
    handleClick: (nft: any) => void
}) => {

    return (
        <Card style={{padding: '10px', margin: '1em', marginBottom: '4em', maxHeight: '500px'}}>
            <CardActionArea>
                <NftImageInList
                    uri={uriImage}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography component="p">
                        {subheader}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    style={{ width: '100%' }}
                    className={buttonStyle.root}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClick}
                >Upload</Button>
            </CardActions>
        </Card>
    )
}