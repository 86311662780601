import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTokensFM } from "../../store/slices/coinSlice";
import { getCategories, getItemsOfLoggedUserSlice } from "../../store/slices/libOfThingsSlice";
import { collectionGetList } from "../../store/slices/nftSlice";
import { MyToaster } from "./BasicComponents/MyToaster";
import { MyAccountContainer } from "./MyAccount/MyAccountContainer";
import { NewArrivalsContainer } from "./NewArrivals/NewArrivalsContainer";
import { QrFabForItems } from "./QrComponents/QrFabForItems";


export const LibOfThings = () => {
    const dispatch = useAppDispatch();
    const currentProfile = useAppSelector(state => state.user.currentProfile);
    useEffect(() => {
        dispatch(getCategories());
        dispatch(collectionGetList());
        dispatch(getTokensFM("", false, false, -1));
    }, [currentProfile])

    useEffect(() => {
        console.log('Use effect of get items of logged user');
        (async () => {
            await dispatch(getItemsOfLoggedUserSlice())
        })()
    }, [])

    return (
        <>
            <MyToaster />
            <MyAccountContainer />
            <NewArrivalsContainer />
            <QrFabForItems />
        </>
    )
}