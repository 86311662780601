import { Divider, Grid, Typography } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { FormInitialValuesForNfts, FormInitialValuesForItems } from "../../../../types/nft.types";
import formSchema from "../Schemas/formSchema"
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { useEffect, useState } from "react";
import itemFormSchema from "../Schemas/itemFormSchema";

export const ConfirmTitleModalContent = ({ isForItems, values, attributes, classes }: {
    isForItems: boolean,
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    attributes: any,
    classes: ClassNameMap<"collection" | "media" | "fieldContainer" | "buttons" | "next" | "mediaConfirm">
}) => {
    const [schemaUsed, setSchemaUsed] = useState<{
        text: string,
        name: string,
        type: string
    }[]>([]);

    useEffect(() => {
        setSchemaUsed(isForItems ? itemFormSchema : formSchema);
    }, [])

    return (
        <>
            {
                schemaUsed.map((field, key) => {
                    const { name } = field;
                    let valueElement = <ConfirmationDialog
                        isForItems={isForItems}
                        name={name}
                        values={values}
                        classes={classes}
                        attributes={attributes}
                        key={key}
                    />;
                    if (valueElement && (
                        name !== 'attributes' || (
                            name === 'attributes' && attributes && attributes.length > 0
                        )
                    )) {
                        return (
                            <Grid
                                container
                                justifyContent="space-evenly"
                                alignItems="center"
                                key={"modalConfirm." + key}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="button">
                                        {field.text}
                                    </Typography>
                                    :
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    {valueElement}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider variant="middle" />
                                </Grid>
                            </Grid>
                        );
                    } else {
                        return null;
                    }
                })
            }
        </>
    )
}