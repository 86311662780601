import { itemMarketplaceOptions } from "../utilities";


export const getOptionValueFromHex = (hex: string): string => {
    let value = 'sell';
    if (hex === itemMarketplaceOptions.lend) {
        value = 'lend';
    }
    return value;
}


