import { makeStyles, useTheme } from "@material-ui/core";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react"
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { uploadItemInMarketplace } from "../../../store/slices/libOfThingsSlice";
import { collectionCreatedGetList, nftCreate, nftCreateReset } from "../../../store/slices/nftSlice";
import { UploadItemInformation } from "../../../types/libofthings.type";
import { FormInitialValuesForItems, FormInitialValuesForNfts, NftData, typesAllowed } from "../../../types/nft.types";
import { duration } from "../../../utilities/costants.notifications";
import { logger } from "../../../utilities/logger/logger";
import { itemMarketplaceOptions } from "../../../utilities/utilities";
import { FormContent } from "./FormContent";
import { initialFormValues, initialFormValuesForItems } from "./initialFormValues";
import ItemValidationSchema from "./Schemas/ItemValidationSchema";
import NftValidationSchema from "./Schemas/NftValidationSchema";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        display: "flex",
        justifyContent: "center",
    },
    buttons: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.grey[200],
        margin: theme.spacing(1),
    },
    next: {
        marginLeft: "0.5vh",
        marginTop: "3vh",
    },
    collection: {
        marginLeft: "0.5vh",
        marginTop: "3vh",
        outline: "none",
    },
    media: {
        maxWidth: 528,
        maxHeight: 528,
    },
    mediaConfirm: { maxWidth: 128, maxHeight: 128 },
}));
export const FormContainer = ({ isForItems, existingNftToUse }: {
    isForItems?: boolean,
    existingNftToUse?: NftData
}) => {
    //language
    const { t } = useTranslation(['NftCreateForm', 'Common', 'LibOfThings']);

    //redux
    const dispatch = useAppDispatch();
    const nftCreated = useAppSelector((state) => state.nft.nftCreated);
    const ethersInstance = useAppSelector((state) => state.ethers.ethersInstance);
    //state
    const [modalOpened, setModalOpened] = useState(false);
    const [safeIsForItems, setSafeIsForItems] = useState(false);
    const [initialValues, setInitialValues] = useState<FormInitialValuesForNfts | FormInitialValuesForItems>();
    const [isFirstRender, setIsFirstRender] = useState(true);
   
    //refs
    const formRef = useRef<any>();

    //styles
    const theme = useTheme();
    const classes = useStyles(theme);

    useEffect(() => {
        if (existingNftToUse != null && isFirstRender) {
            /**
             * I've set the field  "nftDescription" to the same value of "nftName" 
             * because the nft does not have a default description.
             */
            setInitialValues({
                nftName: existingNftToUse.name,
                nftDescription: existingNftToUse.name,
                imgFile: undefined,
                usingExistingNft: true,
                getFromCollection: false,
                numbersNft: "1",
                attributes: [],
                collection: {
                    contractAddress: existingNftToUse.contractAddress,
                    collectionURI: existingNftToUse.tokenUri,
                    name: existingNftToUse.name,
                    symbol: existingNftToUse.symbol,
                    numberNfts: "1"
                },
                isTransferableOnlyOnce: false,
                caution: "0",
                dailyPrice: "1",
                tokenAcceptedAsPayment: "COS",
                category: ""
            });
            setIsFirstRender(false);
        } else {
            setInitialValues(safeIsForItems ? { ...initialFormValuesForItems } : { ...initialFormValues });
        }
    }, [existingNftToUse])

    useEffect(() => {
        dispatch(collectionCreatedGetList());
        handleIsForItems();
    }, [])

    useEffect(() => {
    }, [safeIsForItems, isFirstRender])

    const handleIsForItems = () => {
        if (isForItems != null) {
            setSafeIsForItems(isForItems);;
        } else {
            setSafeIsForItems(false);
        }
    }
    useEffect(() => {
        if (nftCreated) {
            dispatch(nftCreateReset());
        }
    }, [nftCreated]);

    const onSubmitHandler = async (values: FormInitialValuesForItems | FormInitialValuesForNfts) => {
        let res;

        if (existingNftToUse == null)
            res = await createNftOnSubmit(values);

        if (safeIsForItems && res) {
            let event = res.events.filter((ev: any) => ev.event === 'NftAdded');
            let evValues = event.args;
            const tokenId = existingNftToUse != null ? existingNftToUse.tokenId : evValues.tokenId;
            const collectionAddress = existingNftToUse != null ? existingNftToUse.contractAddress : evValues.contractAddress;
            await uploadItemInLibraryOfThings(
                values as FormInitialValuesForItems, tokenId, String(collectionAddress)
            );

        }
        setModalOpened(false);
    }

    const createNftOnSubmit = async (values: FormInitialValuesForNfts) => {
        const formattedAttributes = values
            .attributes
            .filter((attribute) => {
                return attribute.trait_type !== '' && attribute.value !== '';
            })
            .map(({required, ...keepAttrs}) => keepAttrs)
        const nftData = {
            name: values.nftName,
            image: values.imgFile,
            description: values.nftDescription,
            getFromCollection: values.getFromCollection,
            numbersNft: parseInt(values.numbersNft),
            attributes: formattedAttributes,
            collectionAddress: values.collection.contractAddress,
            isTransferable: !values.isTransferableOnlyOnce,
            type: typesAllowed.artistic
        };
        logger.info('nftData', nftData);
        return (await dispatch(nftCreate(nftData)));
    }

    const uploadItemInLibraryOfThings = async (
        values: FormInitialValuesForItems,
        tokenId: any,
        collectionAddress: string
    ) => {
        const itemData: UploadItemInformation = {
            name: values.nftName,
            caution: parseInt(values.caution) * (10 ** 2),
            price: parseInt(values.dailyPrice) * (10 ** 2),
            idInCollection: tokenId,
            option: itemMarketplaceOptions.lend,
            symbolOfTokenAcceptedAsPayment: values.tokenAcceptedAsPayment,
            category: values.category,
            symbol: values.collection.symbol
        }
        const trx = await dispatch(
            uploadItemInMarketplace(itemData, collectionAddress)
        )
        if (trx) {
            toast.success(t('LibOfThings:uploadSuccessful'), {
                duration: duration
            });
        } else {
            toast.error(t('LibOfThings:errorUpload'), {
                duration: duration
            });
        }
    }

    const handleConfirmModalOpen = () => {
        setModalOpened(true);
    };

    return <>
        {
            (initialValues != null) && (
                <Formik
                    initialValues={initialValues}
                    innerRef={formRef}
                    validateOnChange
                    validateOnBlur
                    validationSchema={safeIsForItems ? ItemValidationSchema(t) : NftValidationSchema(t)}
                    onSubmit={(values, actions) => {
                        logger.info(values);
                        onSubmitHandler(values);
                        actions.resetForm({});
                    }}
                >
                    {(props) => {
                        return (
                            <FormContent
                                isForItems={safeIsForItems}
                                modalOpened={modalOpened}
                                formikProps={props}
                                formRef={formRef}
                                classes={classes}
                                setModalOpened={setModalOpened}
                                handleConfirmModalOpen={handleConfirmModalOpen}
                                existingNftToUse={existingNftToUse}
                            />
                        )
                    }}
                </Formik>
            )
        }
    </>
}