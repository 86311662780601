import QrReader from "react-qr-reader";
import { QrCodeContent, QrContentForEndLendingPeriod, QrContentForStartLendingPeriod } from "../../../types/calendar.type";

export const QrReaderForItems = ({ show, openConfirmation }: { show: boolean, openConfirmation: (qrContent: QrCodeContent | QrContentForEndLendingPeriod | QrContentForStartLendingPeriod) => void }) => {

    return (
        <>
            {
                show && <QrReader
                    onScan={async (data: string | null) => {
                        if (data) {
                            const preorderInfo: QrCodeContent | QrContentForEndLendingPeriod = JSON.parse(data);
                            if (preorderInfo.handle === 'preorderItem') {
                                openConfirmation(preorderInfo as QrCodeContent);
                            } else if (preorderInfo.handle === 'endLendingPeriod') {
                                openConfirmation(preorderInfo as QrContentForEndLendingPeriod);
                            } else {
                                openConfirmation(preorderInfo as QrContentForStartLendingPeriod);
                            }
                        }
                    }}
                    onError={(err: any) => {
                        console.log(err);
                    }}
                    style={{ width: '100%' }} />
            }
        </>
    )
}