import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";

export const ItemCategories = ({
    handleBlur, setFieldValue
}: {
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}) => {
    const { t } = useTranslation(['LibOfThings']);
    const categories = useAppSelector((state) => state.libOfThings.categories);
    const [selectValue, setSelectValue] = useState<string>('');

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id='category-label'>
                    <Typography display='inline'>
                        {t('selectCategory')}
                    </Typography>
                    <Typography
                        display="inline"
                        color="secondary"
                    >
                        {" *"}
                    </Typography>
                </InputLabel>
                <Box clone textAlign={'left'}>
                    <Select
                        fullWidth
                        name="category"
                        labelId="category-label"
                        onChange={(e) => {
                            setFieldValue("category", e.target.value,);
                            setSelectValue(e.target.value as string);
                        }}
                        onBlur={handleBlur}
                        value={selectValue}
                    >
                        {
                            (categories != null) && categories.map(
                                (category, index) => (
                                    <MenuItem
                                        key={index}
                                        value={category}
                                    >
                                        {category}
                                    </MenuItem>
                                )
                            )
                        }
                    </Select>
                </Box>
            </FormControl>
        </Grid>
    );
}