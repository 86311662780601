import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    TextField,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { logger } from '../../../../utilities/logger/logger'

import { useTranslation } from 'react-i18next'
import { PlainPlaceType } from '../../../../components/Map/CrowdsaleMapUtils'
import { getPlaceFromAddressInput } from '../../../../api/placeAPI'

type PlaceCreationFormType = {
    setPlace: (place: PlainPlaceType) => void
    handleClose: () => void
    partialPlace: PlainPlaceType | null
}

const useStyles = makeStyles((theme) => {
    return createStyles({
        menuList: {
            marginTop: '50px',
        },
        typographyCreationModal: {
            marginTop: '10px',
            marginBottom: '10px',
        },
    })
})

export type AddressPlace = {
    title: string
    position: {
        lat: number
        lng: number
    }
}

const PlaceCreationForm = (props: PlaceCreationFormType) => {
    const classes = useStyles()
    const { setPlace, handleClose, partialPlace } = props
    const { t } = useTranslation('Map')
    const [suggestedAddresses, setSuggestedAddresses] = useState<
        AddressPlace[]
    >([])

    const [selectedPlace, setSelectedPlace] = useState<AddressPlace | null>()
    const [handlePartialPlace, setHandlePartialPlace] =
        useState<PlainPlaceType | null>(partialPlace)
    const [addressValue, setAddressValue] = useState<string>(
        handlePartialPlace ? handlePartialPlace.address : ''
    )
    const [description, setDescription] = useState<string>(' ')
    const [name, setName] = useState<string>(' ')

    const handleSave = () => {
        if (handlePartialPlace && handlePartialPlace.coords) {
            let place: PlainPlaceType = {
                address: addressValue,
                description: description,
                name: name,
                coords: {
                    lat: handlePartialPlace.coords.lat,
                    lng: handlePartialPlace.coords.lng,
                },
                icon: null,
                firstLifePlaceID: '',
                placeOwner: '',
                realm: 'plainPlace',
            }
            setPlace(place)
        } else if (selectedPlace) {
            let place: PlainPlaceType = {
                address: selectedPlace.title,
                description: description,
                name: name,
                coords: {
                    lat: selectedPlace.position.lat,
                    lng: selectedPlace.position.lng,
                },
                icon: null,
                firstLifePlaceID: '',
                placeOwner: '',
                realm: 'plainPlace',
            }
            setPlace(place)
        }
    }

    const updateSuggestedAddresses = (address: string) => {
        logger.info('PlaceCreationForm.updateSuggestedAddress', address)
        setHandlePartialPlace(null)
        setSelectedPlace(null)
        setAddressValue(address)
        const getPlaces = async () => {
            if (address.length < 1) {
                setSuggestedAddresses([])
                return
            }
            const items = await getPlaceFromAddressInput(address)
            setSuggestedAddresses(items)
        }
        getPlaces()
    }

    return (
        <>
            <Grid justifyContent='center' alignItems='center' container xs={12}>
                <Grid
                    item
                    xs={8}
                    container
                    justifyContent='center'
                    style={{ marginBottom: '15px' }}
                    className={classes.typographyCreationModal}
                >
                    <TextField
                        required
                        fullWidth
                        type='text'
                        label={'Indica un indirizzo'}
                        value={addressValue}
                        onChange={(event) => {
                            updateSuggestedAddresses(event.target.value)
                        }}
                    />
                </Grid>

                {suggestedAddresses.length !== 0 && selectedPlace === null ? (
                    <Grid
                        item
                        xs={12}
                        className={classes.typographyCreationModal}
                    >
                        <List style={{ maxHeight: 200 }}>
                            {suggestedAddresses.map(
                                (place: AddressPlace, index: number) => {
                                    return (
                                        <>
                                            <ListItem key={index}>
                                                <Button
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedPlace(place)
                                                        setAddressValue(
                                                            place.title
                                                        )
                                                        setSuggestedAddresses(
                                                            []
                                                        )
                                                    }}
                                                >
                                                    {place.title}
                                                </Button>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )
                                }
                            )}
                        </List>
                    </Grid>
                ) : null}
                {suggestedAddresses.length === 0 ? (
                    <>
                        <Grid
                            item
                            xs={8}
                            container
                            justifyContent='center'
                            style={{ marginBottom: '15px', marginTop: '15px' }}
                            className={classes.typographyCreationModal}
                        >
                            <TextField
                                fullWidth
                                type='text'
                                label={'Dai un nome al luogo'}
                                onChange={(event) => {
                                    setName(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            justifyContent='center'
                            style={{ marginBottom: '15px', marginTop: '15px' }}
                            className={classes.typographyCreationModal}
                        >
                            <TextField
                                fullWidth
                                type='text'
                                label={'Descrivi il luogo'}
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            justifyContent='center'
                            style={{ marginBottom: '15px', marginTop: '15px' }}
                            className={classes.typographyCreationModal}
                        >
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                    handleSave()
                                    handleClose()
                                }}
                            >
                                {t('Map:save')}
                            </Button>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    )
}

export default PlaceCreationForm
