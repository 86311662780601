import { Grid, Switch, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormInitialValuesForItems, FormInitialValuesForNfts, NftData } from "../../../../types/nft.types";

export const NftCollectionFormInputSwitch = ({
    values,
    existingNft,
    setFormValuesWhenCollectionIsSelected
}: {
    existingNft: NftData | undefined,
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    setFormValuesWhenCollectionIsSelected: (check: boolean) => void

}) => {
    const { t } = useTranslation(['NftCreateForm']);

    return (
        <>
            {
                !existingNft && (
                    <Grid container item xs>
                        <Grid item xs>
                            <Typography align="left">
                                {t('getFromCollection')}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Switch
                                name={"getFromCollection"}
                                checked={values.getFromCollection}
                                onChange={(_, checked) => {
                                    setFormValuesWhenCollectionIsSelected(checked);
                                }}
                            />
                        </Grid>
                    </Grid>

                )
            }
        </>
    )
}