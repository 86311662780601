import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core"
import { useTranslation } from "react-i18next";

export const MyItemsDashboardMenu = ({ activeMenu, handleMenuChange }: {
    activeMenu: number,
    handleMenuChange: (newMenu: number) => void
}) => {
    const { t } = useTranslation(['LibOfThings']);

    return (
        <FormControl fullWidth style={{
            marginTop: '2em'
        }}>
            <InputLabel id="preorders-to-show-label">{t('handleInventory')}</InputLabel>
            <Select
                labelId="selectPreordersToShow"
                id="preorders-to-show"
                value={activeMenu}
                label="tab"
                onChange={(e) => handleMenuChange(e.target.value as number)}
            >
                <MenuItem value={0}>{t('myItems')}</MenuItem>
                <MenuItem value={1}>{t('pendingLendings')} </MenuItem>
                <MenuItem value={2}>{t('acceptedLendings')}</MenuItem>
                <MenuItem value={3}>{t('startedLendings')}</MenuItem>
                <MenuItem value={4}>{t('underReviewLendings')}</MenuItem>
                <MenuItem value={5}>{t('endedLendings')}</MenuItem>
            </Select>
        </FormControl>
    )
}