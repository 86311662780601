import { makeStyles } from "@material-ui/core";

export const imageOptionIndicator = makeStyles({
    root: {
        textAlign: 'center',
        position: 'absolute',
        top: '0px',
        background: '#FF8A00',
        color: '#FFFFFF',
        width: '100px',
        height: '23px',
        borderRadius: '5px 0px 0px 0px',
        '@media (max-width: 400px)': {
            left: window.screen.width  - (window.screen.width * 90 / 100) - 30,
        }
    }
})

export const itemImage = makeStyles({
    root: {
        borderRadius: '5px',
        width: '100%',
        maxWidth: '100%',
        '@media (max-width: 400px)': {
            maxWidth: '90%',
        }
    }
})