import { CircularProgress, Dialog, DialogContentText, Typography } from "@material-ui/core"
import { Preorder, QrContentForEndLendingPeriod } from "../../../../../types/calendar.type"
import { useTranslation } from "react-i18next"
import { qrContainerTitleStyle } from "../../../Styles/Qr.style"
import QRCode from "qrcode.react";
import { Item } from "../../../../../types/libofthings.type";
import { useAppSelector } from "../../../../../store/hooks";

export type QrScannerEndLendingPeriodDialogProps = {
    open: boolean,
    handleClose: () => void,
    preorder: Preorder,
    item: Item | undefined
}


export const QrScannerEndLendingPeriodDialog = ({ open, handleClose, preorder, item }
    : QrScannerEndLendingPeriodDialogProps
) => {
    const { t } = useTranslation(['LibOfThings']);
    const useQrContainerTitleStyle = qrContainerTitleStyle();
    const loading = useAppSelector(state => state.calendar.loading);

    return (
        <Dialog open={open} onClose={handleClose}>
            <Typography className={useQrContainerTitleStyle.root} variant={'h5'} style={{ paddingTop: '1em' }}>
                {t('qrCodeContainerToEndLendingPeriodTitle')}
            </Typography>
            <DialogContentText style={{ textAlign: 'center' }}>
                {
                    (item == null || loading) ? <CircularProgress color="secondary" /> : <QRCode
                        size={300}
                        style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '2em', maxWidth: '350px', maxHeight: '500px' }}
                        value={JSON.stringify({
                            handle: 'endLendingPeriod',
                            preorder: preorder,
                            item: item
                        } as QrContentForEndLendingPeriod)}
                    />
                }

            </DialogContentText>
        </Dialog>
    )
}