import React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {logoutFromBackend} from "../../../store/slices/authSlice";

import config from '../../../config'

//Material Components
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles =  makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    title: {
        margin: 'auto',
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'center'
    },
    spinner: {
        margin: 'auto',
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2),
    }
}));

const Logout = () => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const dispatch = useAppDispatch();
    const history= useHistory();
    const loading = useAppSelector(state => state.auth.loading);
    const isAuthenticated = useAppSelector(state => state.auth.idToken);


    const authRedirecthandler = () => {
        dispatch(logoutFromBackend());
        const netConfig = config.network.authserver['firstlife_auth'];
        let urlString = netConfig.base_url + "v1/auth/login?";
        urlString += 'responseType=code';
        urlString += '&clientId=' + netConfig.client_id;
        urlString += '&redirectUri=' + netConfig.redirect_uri + 'login';
        //Redirect to Firstlife auth
        window.location.replace(encodeURI(urlString));
    }

    let loggingOut = (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.mainContainer}>
                <Typography variant="subtitle1" className={classes.title}>Logging Out...</Typography>
                <CircularProgress/>
            </Grid>
        </Grid>

    );

    if (!loading) {
        authRedirecthandler();
    }

    return loggingOut;
}

export default (Logout);
