import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core"
import { TFunction } from "i18next"
import { ChangeEvent, Dispatch } from "react";
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types";

type NftCollectionFormInputSelect = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    collectionSelected: any,
    collectionListFiltered: any[],
    t: TFunction,
    setCollectionSelected: Dispatch<any>,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFormValuesWhenCollectionIsSelected: (check: boolean) => void
}

export const NftCollectionFormInputSelect = ({
    setFormValuesWhenCollectionIsSelected,
    setCollectionSelected,
    handleBlur,
    t,
    values,
    collectionListFiltered
}: NftCollectionFormInputSelect) => {
    
    const handleSelect = (event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        let collection = collectionListFiltered.find((collection) => {
            return event.target.value == collection.contractAddress
        });

        if (collection == undefined) {
            throw new Error('Collection not found in handleSelect');
        }

        values.collection.contractAddress = collection.contractAddress;
        values.collection.collectionURI = collection.collectionURI;
        values.collection.name = collection.name;
        values.collection.symbol = collection.symbol;
        values.collection.numberNfts = collection.numberNft

        setCollectionSelected(collection);
        setFormValuesWhenCollectionIsSelected(false);
    }

    return (
        <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id='collection-label'>
                    <Typography display='inline'>
                        {t('collection')}
                    </Typography>
                    <Typography
                        display="inline"
                        color="secondary"
                    >
                        {" *"}
                    </Typography>
                </InputLabel>
                <Box clone textAlign={'left'}>
                    <Select
                        fullWidth
                        name="collection"
                        labelId="collection-label"
                        onChange={handleSelect}
                        onBlur={handleBlur}
                        value={
                            values.collection.contractAddress || ""
                        }
                    >
                        {
                            (collectionListFiltered != null) && collectionListFiltered.map(
                                (collection, index) => (
                                    <MenuItem
                                        key={index}
                                        value={collection.contractAddress}
                                    >
                                        {collection.name}
                                    </MenuItem>
                                )
                            )
                        }
                    </Select>
                </Box>
            </FormControl>
        </Grid>
    )

}