import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { cancelPreorder, endRevisionPeriod, getActivePreorders, startPreorder } from "../../../../store/slices/calendarSlice"
import { getItemByID } from "../../../../store/slices/libOfThingsSlice"
import { Preorder } from "../../../../types/calendar.type"
import { Item } from "../../../../types/libofthings.type"
import { possibleStatusItemInCalendar } from "../../../../utilities/costants.calendar"
import { duration } from "../../../../utilities/costants.notifications"
import { DialogWithInfo } from "../../BasicComponents/DialogWithInfo"
import { PreorderInformationList } from "../../BasicComponents/PreorderInformationList"
import { acceptStyle, denyStyle, goToItemPageButtonStyle } from "../../Styles/Buttons.style"
import { itemNameStyle } from "../../Styles/Preorders.style"
import { ItemImage } from "../MyItems/ItemImage"
import { EndReviewDialogBox } from "./EndReviewDialogBox"
import { PreorderStepper } from "./PreorderStepper"
import { QrScannerEndLendingPeriodDialog } from "./Dialogs/QrScannerEndLendingPeriodDialog"

const contentStyle = makeStyles({
    root: {
        position: 'relative',
        '@media (max-width: 450px)': {
            marginBottom: '1em',
            marginLeft: '1em',
            textAlign: 'center'
        }
    }
})

const contentWrapperStyle = makeStyles({
    root: {
        '@media (min-width: 450px)': {
            marginLeft: '1em', position: 'relative',
        }
    }
})

export const PreorderBody = ({ preorder, removeFromList }: {
    preorder: Preorder & { status: string },
    removeFromList?: (id: string) => void
}) => {
    const dispatch = useAppDispatch();
    const useContentWrapperStyle = contentWrapperStyle();
    const collections = useAppSelector((state) => state.nft.collectionList);
    const useContentStyle = contentStyle();
    const useItemNameStyle = itemNameStyle();
    const useAcceptButtonStyle = acceptStyle();
    const useGoToItemPageStyle = goToItemPageButtonStyle();
    const useDenyButtonStyle = denyStyle();
    const navigate = useHistory();
    const loading = useAppSelector(state => state.calendar.loading);
    const [openDialogToEndReviewWithQrCode, setOpenDialogToEndReviewWithQrCode] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [item, setItem] = useState<Item>();
    const [askForEndReview, setAskForEndReview] = useState<boolean>(false);

    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        (async () => {
            setItem(await dispatch(getItemByID(preorder.itemID)));
        })()
    }, [])


    const handleStartPreorder = async () => {
        const errors = await dispatch(startPreorder(preorder.preorderID));
        handleResultOfRequest('preorderStartedSuccess', errors);
    }

    const handleCancelPreorder = async () => {
        setOpenDialog(false);
        const errors = await dispatch(cancelPreorder(preorder.preorderID, true));
        handleResultOfRequest('preorderCancelled', errors);
        if (removeFromList != null) {
            removeFromList(preorder.preorderID);
        }
    }

    const handleEndReviewPeriod = async (isItemFine: boolean, comment: string) => {
        setOpenDialog(false);
        if (preorder != null) {
            const errors = await dispatch(endRevisionPeriod(preorder.preorderID, comment, isItemFine));
            handleResultOfRequest('endRevisionPeriodToast', errors);
            if (removeFromList != null) {
                removeFromList(preorder.preorderID);
            }
        } else {
            console.log(`Was trying to end the revision period of preorder but the preorder was null or undefined`);
        }
    }

    const handleResultOfRequest = (messageKey: string, errors: string[] | undefined) => {
        if (errors != null && errors.length > 0) {
            toast.error(errors[0], {
                duration: duration
            });
        } else {
            toast.success(t(messageKey), {
                duration: duration
            });
            ((): any => dispatch(getActivePreorders(true, 0)))()
        }
    }

    const getCollectionAddressFromSymbol = (symbol: string) => {
        let collection = collections
            .filter((c) => {
                return c.symbol === symbol;
            })[0];
        return collection.contractAddress;
    }

    const goToItemPage = () => {
        if (item != null) {
            const collectionAddress = getCollectionAddressFromSymbol(item.collectionSymbol);
            if (collectionAddress != null) {
                navigate.push(`/items/${collectionAddress}/${item.itemID}`);
            } else {
                toast.error('Something went wrong while trying to get the address of the collection', {
                    duration: duration
                });
            }
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1" className={useItemNameStyle.root}>
                        {
                            item != null ? (
                                <Typography variant="h5">{item.name}</Typography>
                            ) : (
                                <Skeleton />
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    {
                        <>
                            {
                                item != null ? (
                                    <ItemImage
                                        id={item.idInCollection}
                                        collectionSymbol={item.collectionSymbol}
                                        option={undefined}
                                    />
                                ) : (
                                    <Skeleton />
                                )
                            }
                        </>
                    }
                </Grid>
                <Grid item xs={12} md={8} className={useContentStyle.root} >
                    <div className={useContentWrapperStyle.root}>
                        <Typography variant="h1">
                            {
                                preorder.status === possibleStatusItemInCalendar.accepted && (
                                    <>
                                        {
                                            loading ? (
                                                <Skeleton variant="rect" width={'100%'} />
                                            ) : (
                                                <>
                                                    <Button disabled={loading} className={useAcceptButtonStyle.root} onClick={() => handleStartPreorder()}>{t('startPreorder')}</Button>
                                                    <Button className={useDenyButtonStyle.root} onClick={() => setOpenDialog(true)}>{t('cancel')}</Button>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                preorder.status === possibleStatusItemInCalendar.ended && (
                                    <Button className={useDenyButtonStyle.root} onClick={() => setAskForEndReview(true)}>
                                        {t('endReview')}
                                    </Button>
                                )
                            }
                            {
                                loading ? (
                                    <Skeleton variant="rect" width={'100%'} />
                                ) : (
                                    <>
                                        <Button disabled={loading} className={useGoToItemPageStyle.root} onClick={() => goToItemPage()}>{t('itemPage')}</Button>
                                        {preorder.status === possibleStatusItemInCalendar.started && <Button disabled={loading} className={useGoToItemPageStyle.root} onClick={() => setOpenDialogToEndReviewWithQrCode(true)}>{t('showQR')}</Button>}
                                    </>
                                )
                            }
                        </Typography>
                        <PreorderInformationList preorder={preorder} lender={true} />
                        {
                            item != null ? (
                                <DialogWithInfo
                                    item={item}
                                />
                            ) : (
                                <Skeleton variant="rect" width={'100%'} />
                            )
                        }
                        <PreorderStepper status={preorder.status} />
                    </div>

                </Grid>
            </Grid>
            <Dialog
                style={{ textAlign: 'center' }}
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('cancelPreorder')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('endReviewDialogContent')}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => handleCancelPreorder()} color="primary" autoFocus>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <QrScannerEndLendingPeriodDialog item={item} open={openDialogToEndReviewWithQrCode} handleClose={() => setOpenDialogToEndReviewWithQrCode(false)} preorder={preorder} />
            <EndReviewDialogBox handleEndReviewPeriod={handleEndReviewPeriod} openDialog={askForEndReview} setOpenDialog={setAskForEndReview} />
        </>
    )
}