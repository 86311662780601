import { Grid } from "@material-ui/core"
import { useAppSelector } from "../../../../../store/hooks"
import { Skeleton } from "@material-ui/lab"
import { PreorderPendingInList } from "./PreorderPendingInList";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPendingPreorders } from "../../../../../store/slices/calendarSlice";
import { Preorder } from "../../../../../types/calendar.type";
import { PaginationContainer } from "../../../../../components/UI/Pagination/PaginationContainer";

export const ListOfPreordersPending = () => {
    const preordersPending = useAppSelector(state => state.calendar.pendingPreorders);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const numberOfPreorders = useAppSelector(state => state.calendar.numberOfPendingPreorders);
    const loading = useAppSelector(state => state.calendar.loading);
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setPreordersToShow(preordersPending);
    }, [preordersPending])


    useEffect(() => {
        (() => dispatch(getPendingPreorders(true, currentPage)))()
    }, []);

    const removePendingPreorderFromList = (preorderID: string, accepted: boolean) => {
        setPreordersToShow(
            preordersPending.filter(
                (p) => {
                    return p.preorderID !== preorderID
                })
        );
    }
    const handleClickOnPagination = async (selectedPage: number) => {
        setCurrentPage(selectedPage);
        dispatch(getPendingPreorders(true, selectedPage));
    }

    return (
        <Grid container style={{ position: 'relative', top: '3em' }} spacing={3}>
            {
                (!loading) ? (
                    preordersToShow.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <PreorderPendingInList
                                preorder={p}
                                removeFromList={removePendingPreorderFromList}
                            />
                        </Grid>
                    })
                ) : (
                    <Skeleton
                        style={{
                            position: 'relative',
                            top: '1em',
                        }}
                        variant="rect"
                        width={'100%'}
                        height={'400px'}
                    />
                )
            }
            <Grid item xs={12} >
                {
                    numberOfPreorders > 0 &&
                    <PaginationContainer pageCount={numberOfPreorders / preordersPerPage} onChange={handleClickOnPagination} />
                }
            </Grid>
        </Grid>
    )
}