import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit"
import { getAvailableCategories, getItemInfoByID, getItemsOfLoggedUser, getNewArrivalsAPI, uploadItem } from "../../api/libOfThingsAPI";
import { Item, NftItemParams, UploadItemInformation } from "../../types/libofthings.type";
import { RootState } from "../store";

type InitialStateLibOfThingsSlice = {
    loading: boolean,
    itemUploaded: boolean,
    error: string | null,
    nftCreated: boolean,
    uploadingItem: boolean,
    itemFetched: boolean,
    categories: string[],
    item: Item | null,
    items: Item[],
    fetchingItem: boolean,
    newArrivals: Item[],
}

const initialState: InitialStateLibOfThingsSlice = {
    error: null,
    loading: false,
    uploadingItem: false,
    itemUploaded: false,
    nftCreated: false,
    itemFetched: false,
    fetchingItem: false,
    categories: [''],
    item: null,
    items: [],
    newArrivals: [],
}

export const libOfThingsSlice = createSlice({
    name: 'libOfThings',
    initialState,
    reducers: {
        itemUploadStart(state) {
            state.loading = true;
            state.uploadingItem = true;
        },
        itemUploadFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.uploadingItem = false;
            state.itemUploaded = false;
            state.error = action.payload.error;
        },
        itemUploadSuccess(state) {
            state.loading = false;
            state.itemUploaded = true;
            state.uploadingItem = false;
        },
        itemNftCreationStarted(state) {
            state.loading = true;
        },
        itemNftCreationSuccess(state) {
            state.loading = false;
            state.nftCreated = true;
        },
        itemNftCreationFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.nftCreated = false;
        },
        getCategoriesStart(state) {
            state.loading = true;
        },
        getCategoriesSuccess(state, action: PayloadAction<{ categories: string[] }>) {
            state.loading = false;
            state.categories = action.payload.categories;
        },
        getCategoriesFailed(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        getItemByIDStarted(state) {
            state.itemFetched = false;
            state.fetchingItem = true;
            state.loading = true;
        },
        getItemByIDSuccess(state, action: PayloadAction<{ item: Item }>) {
            state.itemFetched = true;
            state.item = action.payload.item;
            state.fetchingItem = false;
            state.loading = false;
        },
        getItemByIDFailed(state, action: PayloadAction<{ error: string }>) {
            state.itemFetched = false;
            state.fetchingItem = false;
            state.loading = false;
            state.error = action.payload.error;
        },
        getItemsOfUserSucceed(state, action: PayloadAction<{ items: Item[] }>) {
            state.items = action.payload.items;
            state.loading = false;
        },
        getItemsOfUserFailed(state, action: PayloadAction<{ error: string }>) {
            state.error = action.payload.error;
            state.loading = false;
        },
        getItemsOfUserStarted(state) {
            state.loading = true;
        },
        getNewArrivalsStarted(state) {
            state.loading = true;
        },
        getNewArrivalsFailed(state, action: PayloadAction<{ error: string }>) {
            state.error = action.payload.error;
            state.loading = false;
        },
        getNewArrivalsSuccess(state, action: PayloadAction<{ newArrivals: Item[] }>) {
            state.newArrivals = action.payload.newArrivals;
            state.fetchingItem = false;
        }
    }
})

export const {
    getCategoriesFailed,
    getCategoriesSuccess,
    getCategoriesStart,
    itemNftCreationFailed,
    itemNftCreationSuccess,
    itemNftCreationStarted,
    itemUploadFailed,
    itemUploadStart,
    itemUploadSuccess,
    getItemByIDFailed,
    getItemByIDStarted,
    getItemByIDSuccess,
    getItemsOfUserFailed,
    getItemsOfUserStarted,
    getItemsOfUserSucceed,
    getNewArrivalsFailed,
    getNewArrivalsStarted,
    getNewArrivalsSuccess,
} = libOfThingsSlice.actions;

export const getCategories = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(getCategoriesStart());
        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            dispatch(
                getCategoriesFailed({
                    error: 'currentProfile is not defined in getCategories'
                })
            );
            return;
        }
        const categories = await getAvailableCategories(ethers);
        dispatch(getCategoriesSuccess({ categories: categories }));
    }
}
export const getItemByID = (id: number) => {
    return async (dispatch: Dispatch, getState: () => RootState): Promise<Item | undefined> => {
        dispatch(getItemByIDStarted());
        const currentProfile = getState().user.currentProfile;
        if (currentProfile == null) {
            dispatch(
                getItemByIDFailed({
                    error: 'currentProfile is not defined in getCategories'
                })
            );
            return;
        }
        const accountAddress = currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(
                getItemByIDFailed({ error: "account address is undefined" })
            );
            return;
        }
        const item = await getItemInfoByID(id);
        if (item != null) {
            dispatch(getItemByIDSuccess({ item: item }));
        } else {
            dispatch(
                getItemByIDFailed({ error: "something went wrong while retrieving item information" })
            );
        }
        return item;
    }
}
export const uploadItemInMarketplace = (itemData: UploadItemInformation, collectionAddress: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(itemUploadStart());
        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            dispatch(
                itemUploadFailed({
                    error: 'currentProfile is not defined in uploadItemInMarketplace'
                })
            );
            return;
        }
        const accountAddress = currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(
                itemUploadFailed({ error: "account address is undefined" })
            );
            return;
        }

        const trxOfUpload = await uploadItem(ethers, itemData, collectionAddress, accountAddress);
        dispatch(itemUploadSuccess());
        return trxOfUpload;
    }
}
export const getItemsOfLoggedUserSlice = () => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(getItemsOfUserStarted());
        const currentProfile = getState().user.currentProfile;
        const ethers = getState().ethers.ethersInstance;

        if (currentProfile == null || ethers == null) {
            dispatch(
                getItemsOfUserFailed({
                    error: 'currentProfile is not defined in uploadItemInMarketplace'
                })
            );
            return;
        }
        const accountAddress = currentProfile.additional_properties?.commonshoodWallet;
        if (accountAddress == null) {
            dispatch(
                getItemsOfUserFailed({ error: "account address is undefined" })
            );
            return;
        }

        const items = await getItemsOfLoggedUser(ethers, accountAddress);
        dispatch(getItemsOfUserSucceed({ items: items }));
        return items;
    }
}

export const getNewArrivals = () => {
    return async (dispatch: Dispatch) => {
        dispatch(getNewArrivalsStarted());
        let items: Item[] = [];        
        try {
            items = await getNewArrivalsAPI(0, 6);
            dispatch(getNewArrivalsSuccess({ newArrivals: items }));
        } catch (error) {
            dispatch(getNewArrivalsFailed({ error: (error as any).message }));
            throw new Error((error as any).message);
        }
        return items;
    }
}


export default libOfThingsSlice.reducer;