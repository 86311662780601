import React, { useEffect, useState } from "react";
import {
  Avatar,
  Backdrop,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import SlideModal from "../../../../components/UI/Modal/SlideModal/SlideModal";
import NftSelected from "../NftSelected/NftSelected";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import config from "../../../../config";
import { useAppSelector } from "../../../../store/hooks";
import { nftProps } from "../../../../types/nft.types";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    maxHeight: 437,
  },
  media: {
    height: 0,
    paddingTop: "100%",
  },
  avatar: {
    marginRight: 1,
  },
  backdrop: {
    position: "absolute",
    zIndex: 5,
  },
});

export default function NftCard(props: nftProps) {
  const classes = useStyles();

  let {
    name,
    symbol,
    tokenUri,
    tokenId,
    contractAddress,
    isTransferable,
    isCreatedByMe,
  } = props;

  const [nftSelected, setNftSelected] = useState(false);
  const [metadata, setMetadata] = useState<any>(null);
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

  const nftOperations = useAppSelector((state) => state.nft.onGoingOperations);
  const marketplaceOperations = useAppSelector(
    (state) => state.marketplace.onGoingOperations
  );

  useEffect(() => {
    const fun = async () => {
      if (tokenUri.includes(config.network.ipfs.default_url))
        setMetadata(await getFromIpfs(tokenUri));
      else setMetadata(await getJsonFromResource(tokenUri));
    };
    fun();
  }, []);

  useEffect(() => {
    if (metadata !== null) {
      if (metadata.image.includes(config.network.ipfs.default_url)) {
        setMetadata({
          ...metadata,
          image: metadata.image.replace(
            config.network.ipfs.default_url,
            config.network.ipfs.gateway_url
          ),
        });
      }
      if (!metadata.image.includes(config.network.ipfs.default_url)) {
        let tempImage = new Image();
        tempImage.onload = () => {
          setIsImageLoad(true);
        };
        tempImage.src = metadata.image;
      }
    }
  }, [metadata]);

  const handleModalOpen = () => {
    setNftSelected(true);
  };
  const handleModalClose = () => {
    setNftSelected(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea onClick={() => handleModalOpen()}>
          <Backdrop
            className={classes.backdrop}
            open={
              nftOperations.includes(tokenId) ||
              marketplaceOperations.includes(tokenId)
            }
          >
            <CircularProgress color="primary" />
          </Backdrop>
          {isImageLoad ? (
            <CardMedia
              className={classes.media}
              image={metadata ? metadata.image : ""}
              title={metadata.name}
            />
          ) : (
            <Skeleton variant="rect" className={classes.media} />
          )}
          <CardContent>
            {metadata ? (
              <>
                <Typography gutterBottom variant="h5" component="h2">
                  {metadata.name + " #" + tokenId}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {metadata.description}
                </Typography>
              </>
            ) : (
              <>
                <Typography gutterBottom variant="body1" component="h5">
                  <Skeleton />
                </Typography>
                <Skeleton width="60%" />
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      {metadata && (
        <>
          <SlideModal
            open={nftSelected}
            handleClose={handleModalClose}
            title={
              <>
                <Grid container spacing={1}>
                  <Grid item xs="auto">
                    <Avatar
                      alt={symbol}
                      className={classes.avatar}
                      src={metadata ? metadata.image : ""}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography>{metadata.name}</Typography>
                  </Grid>
                </Grid>
              </>
            }
          >
            <NftSelected
              name={name || ""}
              symbol={symbol || ""}
              tokenUri={metadata || ""}
              tokenId={tokenId + "" || ""}
              isTransferable={isTransferable || false}
              isCreatedByMe={isCreatedByMe || false}
              contractAddress={contractAddress || ""}
              handleModalClose={handleModalClose || ""}
            ></NftSelected>
          </SlideModal>
        </>
      )}
    </>
  );
}
