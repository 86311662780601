import { Grid, makeStyles, Typography } from "@material-ui/core"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Preorder } from "../../../types/calendar.type";

const idTextStyle = makeStyles({
    root: {
        whiteSpace: 'nowrap'
    }
})

export const PreorderHeader = ({ preorder }: {
    preorder: Preorder
}) => {
    const useIdTextStyle = idTextStyle();
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        console.log(preorder);
    }, [])

    return (
        <Grid container style={{ padding: '1em', background: 'rgba(217, 217, 217, 0.6)' }}>
            <Grid item xs={9}>
                <Typography variant="body1" >
                    {t('preorder')} ID: {preorder.preorderID.slice(-7)}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography variant="body1" className={useIdTextStyle.root}>
                    {t('item')} ID # {preorder.itemID}
                </Typography>
            </Grid>
        </Grid>
    )
}