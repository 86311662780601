import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";


//qr reader
import ReactQrReader from 'react-qr-reader';
//qr generator
import QrGenerator from 'qrcode.react';

import {Box, Button, Grid, Typography} from '@material-ui/core';

import {logger} from '../../utilities/logger/logger';

import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {userDelete, userGetDataFromId, userPreselectContact} from '../../store/slices/userSlice';
import {handleBottomMenuIndexChange} from '../../store/slices/uiSlice';
import {getProfileId} from "../../utilities/utilities";
import {coinSendReset} from "../../store/slices/coinSlice";
import {DAOgetName, getDAOAddress} from "../../api/daoAPI";

type QrProps = {
    closeParentModal:  () => void;
};

const Qr = (props: QrProps) => {
    const {
        closeParentModal,
    } = props;

    const {t} = useTranslation("qr");
    const [qrResult, changeQrResult] = useState<string | null>(null);
    const [newQrRead, setNewQrRead] = useState(false);
    const [isShowScan, setShowScan] = useState(true); //true shows the scan, false shows the personal qr code
    const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
    const [daoAddress, setCurrentDao]= useState<string|null>(null)
    const [daoName, setDaoName]=useState<string|null>(null)

    const dispatch = useAppDispatch();
    const profile = useAppSelector(state => state.user.currentProfile);
    const realm = profile?.realm;
    const profileId = getProfileId(realm, profile);
    const bottomMenuActiveIndex = useAppSelector(state => state.ui.bottomMenuActiveIndex);
    const user = useAppSelector(state => state.user.user);
    const userIsFound = useAppSelector(state => state.user.userIsFound);
    const ethers= useAppSelector(state => state.ethers.ethersInstance);

    let buttonText = '';

    useEffect(() => {
        dispatch(coinSendReset())
        dispatch(userDelete());
    },[]);

    useEffect(() => {
        if (user != null) {
            const contact = {
                additional_properties: user.additional_properties,
                id: user.id,
                name: user.name,
                realm: 'user',
                icon: user.avatar
            };
            logger.info('user contact read => ', contact);
            dispatch(userPreselectContact({contact}));
            if (bottomMenuActiveIndex !== 0) {
                dispatch(handleBottomMenuIndexChange({index: 0}));
            }
            closeParentModal();
        }
    }, [user, closeParentModal, bottomMenuActiveIndex]); //user changed

    useEffect(() => {
        if (daoAddress != null) {
            const additional_properties = {
                commonshoodWallet: daoAddress
            }
            const contact = {
                additional_properties,
                name: daoName
            };
            logger.info('dao contact read => ', contact);
            dispatch(userPreselectContact({contact}));
            if (bottomMenuActiveIndex !== 0) {
                dispatch(handleBottomMenuIndexChange({index: 0}));
            }
            closeParentModal();
        }
    }, [daoAddress]);

    if (userIsFound === false && newQrRead) {
        setErrorMessage(
            <Typography variant="caption" color="error">
                {t('userNotFound')}
            </Typography>
        );
        setNewQrRead(false);
    }

    const handleScan = async (data: string | null) => { 
        if (data &&
            (qrResult !== data) && ethers) { //we got a new qr code scan with data different from the last one
            logger.info("got new qr code");
            setErrorMessage(null);
            setNewQrRead(true);
            dispatch(userDelete());
            changeQrResult(data);

            if (data.includes('&&&')) {
                const [id, realm] = data.split('&&&');
                //prepare object to be passed to coinSend, note the difference on which is the id
                logger.info("id -> ", id);
                logger.info("realm -> ", realm);

                if (id !== profileId) { // avoid to open a form to send coin to himself
                    if (realm === "dao") {
                        const daoAddress= await getDAOAddress(ethers, id);
                        if(daoAddress) {
                            const name = await DAOgetName(ethers, daoAddress)
                            setDaoName(name)
                            setCurrentDao(daoAddress)
                        }
                    } else { //user
                        dispatch(userGetDataFromId(id));
                    }
                } else { // if we are here the QR code read is the one === to the current profile of the logged user
                    logger.info("qr code of logged user");
                    setErrorMessage(
                        <Typography variant="caption" color="error">
                            {t('cantSendCoinToYourself')}
                        </Typography>
                    );
                }
            }
        }
    };

    const handleScanError = (err: string | Error) => {
        logger.error('Qr Reader Failure', err);
        setErrorMessage(
            <Typography variant="caption" color="error">
                {t('hwNotSupported')}
            </Typography>
        );
    };

    let content = null;
    if (isShowScan) {
        buttonText = t("showMyQrCode");
        content = (
            <>
                <Grid item xs={12}>
                    <Typography variant="overline">
                        {t("scanInstructions")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {errorMessage}
                </Grid>
                <Grid item xs={12}>
                    <ReactQrReader
                        delay={300}
                        onError={handleScanError}
                        onScan={handleScan}
                        style={{
                            maxWidth: "500px",
                            maxHeight: "500px",
                            margin:"0px auto"
                        }}
                    />
                </Grid>
            </>
        );

    }else{


        if(profile != null) {

            buttonText = t("showQrCodeReader");
            const {
                name,
                realm
            } = profile;

            let yourQrCodeText: string | JSX.Element = '';
            let stringToQRify = '';

            yourQrCodeText = (
                <p>
                    {`${t("userQrCodeText")}`} <strong>{name}</strong>
                </p>
            );
            stringToQRify = `${profileId}&&&${realm}`;

            content = (
                <>
                    <Grid item container xs={12} alignItems="center">
                        <QrGenerator
                            value={stringToQRify}
                            size={250}
                            includeMargin={true}
                        />
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                        <Typography variant="overline">
                            {yourQrCodeText}
                        </Typography>
                    </Grid>
                </>

            );
        }
    }


    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{
                    marginTop: "80px",
                    textAlign: 'center'
                }}
            >
                {content}
                <Grid item xs={12}>
                    <Button
                        onClick={() => setShowScan(!isShowScan)}
                        variant="contained"
                        color="primary"
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Qr;
