import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Switch } from "@material-ui/core"
import { Field, Formik, FormikProps, FormikTouched } from "formik"
import { TFunction } from "i18next"
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from "react"
import { FormInitialValuesForItems, FormInitialValuesForNfts, NftData } from "../../../../types/nft.types"
import { ItemCategories } from "./ItemCategories"
import { NftCollectionFormCaution } from "./NftCollectionFormCaution"
import { NftCollectionFormDescriptiion } from "./NftCollectionFormDescription"
import { NftCollectionFormInputSelect } from "./NftCollectionFormInputSelect"
import { NftCollectionFormInputSwitch } from "./NftCollectionFormInputSwitch"
import { NftCollectionFormName } from "./NftCollectionFormName"
import { NftCollectionFormNumber } from "./NftCollectionFormNumber"
import { NftCollectionFormPrice } from "./NftCollectionFormPrice"
import { NftCollectionFormTransfereable } from "./NftCollectionFormTransfereable"
import { PaymentToken } from "./PaymentToken"
import { useTranslation } from "react-i18next"

type NftCollectionFormInput = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    collectionSelected: any,
    collectionListFiltered: any[],
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    isForItems: boolean,
    existingNft: NftData | undefined,
    setCollectionSelected: Dispatch<any>,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFormValuesWhenCollectionIsSelected: (check: boolean) => void,
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setPaymentToken: Dispatch<SetStateAction<string>>
}

export const NftCollectionFormInput = ({
    handleChange,
    setFormValuesWhenCollectionIsSelected,
    setCollectionSelected,
    handleBlur,
    setFieldValue,
    existingNft,
    values,
    isForItems,
    collectionSelected,
    collectionListFiltered,
    touched
}:
    NftCollectionFormInput
) => {
    const { t } = useTranslation(['NftCreateForm', 'Common', 'LibOfThings'])
    return (
        <Box mt={6}>
            <Grid container spacing={1}>
                <NftCollectionFormInputSelect
                    t={t}
                    values={values}
                    collectionSelected={collectionSelected}
                    collectionListFiltered={collectionListFiltered}
                    setCollectionSelected={setCollectionSelected}
                    handleBlur={handleBlur}
                    setFormValuesWhenCollectionIsSelected={setFormValuesWhenCollectionIsSelected}
                />
                <NftCollectionFormInputSwitch
                    values={values}
                    existingNft={existingNft}
                    setFormValuesWhenCollectionIsSelected={setFormValuesWhenCollectionIsSelected}
                />
                <NftCollectionFormName
                    touched={touched}
                    t={t}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
                <NftCollectionFormDescriptiion
                    values={values}
                    touched={touched}
                    t={t}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
                {
                    isForItems && (
                        <>
                            <PaymentToken
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                isForItems={isForItems}
                            />
                            <NftCollectionFormCaution
                                values={values}
                                touched={touched}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                            />
                            <NftCollectionFormPrice
                                values={values}
                                touched={touched}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                isForItems={isForItems}
                            />
                            <ItemCategories
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                            />
                        </>
                    )
                }
                <NftCollectionFormNumber
                    values={values}
                    touched={touched}
                    t={t}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
                <NftCollectionFormTransfereable
                    isForItems={isForItems}
                    values={values}
                    t={t}
                    setFieldValue={setFieldValue}
                />
            </Grid>
        </Box>
    )
}