

import { Divider, Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QrCodeContent } from "../../../../types/calendar.type";

export const QrConfirmationInfo = ({ preorder }: { preorder: QrCodeContent }) => {
    const { t } = useTranslation(['LibOfThings']);

    const daysBetween = (startDate: Date, endDate: Date) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / oneDay));
    }

    const calculateTotal = (startDateInSeconds: number, endDateInSeconds: number, price: number) => {
        let days = daysBetween(new Date(startDateInSeconds), new Date(endDateInSeconds));
        if (days === 0) {
            days = 1;
        }
        return days * price;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('name')} value={preorder.item.name} variant="outlined" id="item-name" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('price')} value={preorder.item.price / 100} variant="outlined" id="item-price" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('caution')} value={preorder.item.caution / 100} variant="outlined" id="item-caution" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('symbol')} value={preorder.item.collectionSymbol} variant="outlined" id="item-symbol" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('tokenAcceptedAsPayment')} value={preorder.item.paymentToken} variant="outlined" id="item-token-symbol" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('category')} value={preorder.item.category} variant="outlined" id="item-category" />
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('startDate')} value={new Date(preorder.startDate * 1000).toLocaleDateString().slice(0,11)} variant="outlined" id="preorder-start-date" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField inputProps={{ readOnly: true }} label={t('endDate')} value={new Date(preorder.endDate * 1000).toLocaleDateString().slice(0,11)} variant="outlined" id="preorder-end-date" />
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12} md={12}>
                <TextField inputProps={{ readOnly: true }} label={t('total')} value={(calculateTotal(preorder.startDate, preorder.endDate, preorder.item.price) + preorder.item.caution) / 100} variant="outlined" id="preorder-end-date" />
            </Grid>
        </Grid>

    );
}