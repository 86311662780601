import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {useTranslation} from 'react-i18next';

import {Step, StepLabel, Stepper} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
}));
 
type ExchangeStepperProps = {
  step: number
}

const ExchangeStepper = (props: ExchangeStepperProps) => {
    const classes = useStyles();
    const { t } = useTranslation('ExchangeCreateForm');
    const steps = [t('step1'), t('step2'), t('step3')];
  
    return (
      <div className={classes.root}>
        <Stepper activeStep={props.step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
}

export default ExchangeStepper;