import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide } from "@material-ui/core"
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Item } from "../../../types/libofthings.type";
import { duration } from "../../../utilities/costants.notifications";
import { getOptionValueFromHex } from "../../../utilities/libofthings/libOfThings";
import { itemMarketplaceOptions } from "../../../utilities/utilities";
import { buttonStyle } from "../Styles/Buttons.style";

const Transition = (props: any) => {
    return <Slide direction="up" {...props} />;
}

export const DialogWithInfo = ({ item, reason }: {
    item: Item,
    reason?: string
}) => {
    const useButtonStyle = buttonStyle();
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation(['LibOfThings']);
    const possibleInfoToCopy = {
        owner: 'owner',
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }


    const handleClickAction = (textToCopy: string, info: string) => {
        navigator.clipboard.writeText(textToCopy);
        let messageToShow = '';
        switch (info) {
            case possibleInfoToCopy.owner:
                messageToShow = t('addressCopiedClipboard');
                break;
            default:
        }
        toast.success(messageToShow, {
            duration: duration
        });
    }

    return (
        <>
            <Grid container style={{ marginBottom: '1em' }}>
                <Grid item xs={12}>
                    <Button className={useButtonStyle.root} style={{ height: '40px' }} onClick={handleOpen}>
                        {t('itemInformation')}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>{t('itemInformation')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('name')}: <b>{item.name}</b><br />
                        {t('itemID')}: <b>{item.itemID}</b> <br />
                        {t('idInOriginalCollection')}: <b>{item.idInCollection}</b><br />
                        {
                            getOptionValueFromHex(item.option) === itemMarketplaceOptions.sell ? (
                                <>{t('price')}: <b>{item.price}</b></>
                            ) : (
                                <>{t('price')}: <b>{item.price} {t('perDay')}</b></>
                            )
                        }<br />
                        {t('category')}: <b>{item.category}</b> <br />
                        {t('option')}: <b>{getOptionValueFromHex(item.option)}</b><br />
                        {t('caution')}: <b>{item.caution}</b><br />
                        {t('tokenAcceptedAsPayment')}: <b>{item.paymentToken}</b> <br />
                        {t('owner')}:
                        <b
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClickAction(item.owner, possibleInfoToCopy.owner)}>
                            {item.owner.slice(0, 8)}...{item.owner.slice(item.owner.length - 8, item.owner.length - 1)}
                        </b>
                        {
                            reason != null && (
                                <>{t('reason')}: : {reason}</>
                            )
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button className={useButtonStyle.root} onClick={handleClose} color="primary">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}