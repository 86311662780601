import React from "react";

import {Grid, List} from "@material-ui/core";
// Custom Component
import CoinListItem from "./CoinListItem/CoinListItem";
import { logger } from "../../../utilities/logger/logger";

type CoinListProps = {
  coinList: any[]; //fixme
  handleSelect: (symbol: string) => void;
  showPayButton: boolean;
  selected: number | undefined;
  withRadio: boolean;
  coinLoading: boolean;
};

const CoinList = (props: CoinListProps) => {
  const {
    coinList,
    handleSelect,
    showPayButton,
    selected,
    withRadio,
    coinLoading,
  } = props;

  const orderedList = coinList
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12}>
        <List dense style={{ marginBottom: "50px" }}>
          {orderedList.map((coin, index) => {
            logger.info("COIN", coin)
            return (
              <CoinListItem
                handleSelect={handleSelect}
                showPayButton={showPayButton}
                coin={coin}
                key={index}
                selected={selected === index}
                withRadio={withRadio}
                coinLoading={coinLoading}
              />
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default CoinList;
