import { Grid, Typography } from "@material-ui/core"
import { Field, FormikTouched } from "formik"
import { TFunction } from "i18next"
import { ChangeEvent, Dispatch } from "react";
import TextInput from "../../../../components/UI/Form/Input/TextInput";
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types";

type NftCollectionFormDescriptiion = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    t: TFunction,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    handleChange: {
        (e: ChangeEvent<any>): void;
        <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void;
    }
}

export const NftCollectionFormDescriptiion = ({
    handleChange,
    handleBlur,
    t,
    values,
    touched
}: NftCollectionFormDescriptiion
) => {
    return (
        <Grid item xs={12}>
            <Field
                name="nftDescription"
                multiline
                fullWidth
                label={
                    <>
                        <Typography display="inline">
                            {t("nftDescriptionLabel")}
                        </Typography>
                        <Typography
                            display="inline"
                            color="secondary"
                        >
                            {" *"}
                        </Typography>
                    </>
                }
                placeholder={t("nftDescriptionPlaceholder")}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched["nftDescription"]}
                component={TextInput}
                disabled={values.getFromCollection}
                value={values.nftDescription || ""}
            />
        </Grid>
    )
}