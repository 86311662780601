import { CircularProgress, Divider, Grid, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import { Preorder } from "../../../../types/calendar.type";
import { Item } from "../../../../types/libofthings.type";

export const QrStartLendingPeriodDialog = ({ preorder, item }: {
    preorder: Preorder,
    item: Item
}) => {
    const { t } = useTranslation(['LibOfThings']);
    const loading = useAppSelector(state => state.calendar.loading);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {
                    !loading ? <Typography variant="h5" id="dialog-qr-end-lending-period-title" >{t('startLendingPeriod')}</Typography> : <CircularProgress color="secondary" />
                }
            </Grid>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} md={6}>
                    <TextField inputProps={{ readOnly: true }} label={t('name')} value={item.name} variant="outlined" id="preorder-item-id" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField inputProps={{ readOnly: true }} label={t('startDate')} value={new Date(preorder.startDate * 1000).toLocaleDateString().slice(0, 11)} variant="outlined" id="preorder-start-date" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField inputProps={{ readOnly: true }} label={t('endDate')} value={new Date(preorder.endDate * 1000).toLocaleDateString().slice(0, 11)} variant="outlined" id="preorder-end-date" />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
            </Grid>
        </Grid>
    );
}