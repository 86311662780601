import { Grid, TextField } from "@material-ui/core";
import { Field, FormikTouched } from "formik";
import { TFunction } from "i18next";
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types";

type NftCollectionFormNumber = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    t: TFunction,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const NftCollectionFormNumber = ({
    handleBlur,
    t,
    values,
    touched,
    setFieldValue
}: NftCollectionFormNumber) => {
    return (
        <Grid item xs={12}>
            <Field
                name="numbersNft"
                fullWidth
                label={t("nftsNumber")}
                onChange={(v: React.ChangeEvent<any>) => {
                    if (v.target.value > 1)
                        setFieldValue(
                            "numbersNft",
                            Math.floor(v.target.value)
                        );
                    else setFieldValue("numbersNft", "1");
                }}
                onBlur={handleBlur}
                touched={touched["numbersNft"]}
                component={TextField}
                type="number"
                value={values.numbersNft}
            />
        </Grid>
    )
}