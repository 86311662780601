import { Grid, makeStyles } from "@material-ui/core"
import { PreorderBody } from "./PreorderBody";
import { PreorderHeader } from "../../BasicComponents/PreorderHeader";

const backgroundStyle = makeStyles({
    root: {
        background: '#FFFFFF',
        border: '1px solid #A9A9A9',
        borderRadius: '5px',
    }
})
export const PreorderActiveInList = ({ preorder, removeFromList }: {
    preorder: any,
    removeFromList?: (id: string) => void
}) => {
    const useBackgroundStyle = backgroundStyle();

    return (
        <div className={useBackgroundStyle.root}>
            <Grid container>
                {
                    (preorder != null) && (
                        <>
                            <Grid item xs={12}>
                                <PreorderHeader preorder={preorder} />
                            </Grid>
                            <Grid item xs={12}>
                                <PreorderBody preorder={preorder} removeFromList={removeFromList} />
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </div>
    )
}