import { Grid } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Preorder } from "../../../types/calendar.type"
import { fromUnixToDate } from "../../../utilities/timestamps/unixDateConverter"
import { Remember } from "../MyRequests/PreorderInList/Remember"

export const PreorderInformationList = ({ preorder, lender }: {
    preorder: Preorder,
    lender: boolean
}) => {
    const [requestByMessage, setRequestByMessage] = useState<string>('');
    const [ownerMessage, setOwnerMessage] = useState<string>('');

    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        if (lender) {
            setRequestByMessage((preorder.borrower.slice(0, 7)).concat('...'.concat(preorder.borrower.slice(-7))));
            setOwnerMessage(t('you'));
        } else {
            setOwnerMessage((preorder.lender.slice(0, 7)).concat('...'.concat(preorder.lender.slice(-7))));
            setRequestByMessage(t('you'));
        }
    }, [preorder]);

    return (
        <Grid container style={{
            marginTop: '1rem'
        }} spacing={1}>
            <Grid item xs={12}>
                {t('requestedBy')}: <b>{requestByMessage}</b>
            </Grid>
            <Grid item xs={12}>
                {t('lendingActiveFrom')} <b>{fromUnixToDate(preorder.startDate)}</b> {t('to')} <b>{fromUnixToDate(preorder.endDate)}</b>
            </Grid>
            <Grid item xs={12}>
                {t('owner')}: <b>{ownerMessage}</b>
            </Grid>
            <Grid item xs={12}>
                {
                    preorder.itemWasFine != null && (
                        <>
                            {t('wasTheItemOk')}: <b>{preorder.itemWasFine ? t('no') : t('yes')}</b>
                        </>
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {
                    preorder.comment && (
                        <>
                            {t('comment')}: <b>{preorder.comment}</b>
                        </>
                    )
                }
            </Grid>

            <Grid item xs={12}>
                <Remember status={preorder.status} lender={lender} />
            </Grid>
        </Grid>
    )
}