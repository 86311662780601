import React from 'react';

import {constantCase} from 'change-case';

//Menu Icons
import Payment from '@material-ui/icons/Payment';
import Place from '@material-ui/icons/Place';
import HowToVote from '@material-ui/icons/HowToVote';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import StorefrontIcon from '@material-ui/icons/Storefront';
import {TFunction} from 'i18next';

export const getMenuElements = (t: TFunction) => {

    return {
        'pay': { //payment view
            //icon: {font: 'material', name: 'place'},
            icon: <Place/>,
            text: constantCase( t('pay'), {delimiter: " "} ),
            path: '/pay',
        },
        'sales': {
            icon: <LoyaltyIcon/>,
            text: constantCase( t('exchanges'), {delimiter: " "}),
            path: '/exchanges',
        },
        'pigs': {
            icon: <HowToVote />,
            text: constantCase(t('piggies'), {delimiter: " "} ),
            path: '/pigs',
        },
        'coins': { //coin balance view
            //icon: {font: 'material', name: 'payment'},
            icon: <Payment/>,
            text: constantCase( t('myWallet'), {delimiter: " "} ),
            path: '/coins',
        },
        'marketPlace': {
            icon: <StorefrontIcon />,
            text: constantCase(t('marketPlace'), {delimiter: " "} ),
            path: '/pay',
        },
    };
};
