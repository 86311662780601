import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../store/slices/nftSlice"
import { Item, ItemStats } from "../../../types/libofthings.type"
import { backgroundCardStyle } from "../Styles/Backgrounds.style"
import { titleBar, titleStyle } from "../Styles/Titles.style"
import { ImageContainer } from "./ImageContainer"
import { InformationList } from "./InformationList"

export const ItemInformation = ({ item, itemStats }: {
    item: Item,
    itemStats: ItemStats
}) => {
    const useInformationBox = backgroundCardStyle();
    const useTitleBarStyle = titleBar();
    const useTitleStyle = titleStyle();
    const dispatch = useAppDispatch();
    const collectionsAndSymbols = useAppSelector((state) => state.nft.collectionsBySymbol);

    useEffect(() => {
        if (item != null) {
            const collectionAndAddress = collectionsAndSymbols.filter((collection) => {
                if (collection.symbol === item.collectionSymbol) {
                    return collection;
                }
            })[0];
            if (collectionAndAddress == null) {
                (async () => { await dispatch(getCollectionFromSymbol(item.collectionSymbol)) })()
            } else {
                let address = collectionAndAddress.address;
                (async () => { await dispatch(getURIByIDSlice(item.idInCollection, address)) })()
            }
        }

    }, [collectionsAndSymbols])

    return (
        <Grid container className={useInformationBox.root} >
            <Grid item xs={12}>
                <div className={useTitleBarStyle.root}>
                    {
                        (item != null) ? (
                            <Typography variant={'h3'} className={useTitleStyle.root}>
                                {item.name}
                            </Typography>
                        ) : (
                            <Skeleton />
                        )
                    }

                </div>
            </Grid>
            <Grid item xs={12} md={3} style={{ textAlign: 'center', marginLeft: '20px' }}>
                {
                    (item != null) ? (
                        <ImageContainer item={item} />
                    ) : (
                        <Skeleton />
                    )
                }

            </Grid>
            <Grid item xs={12} md={8}>
                {
                    (item != null) ? (
                        <InformationList item={item} itemStats={itemStats} />
                    ) : (
                        <Skeleton />
                    )
                }
            </Grid>
        </Grid>
    )
}