import { Button, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { backgroundCardStyle, manageItemStyle } from "../Styles/Backgrounds.style";
import { baseButton, greenButton, redButton } from "../Styles/Buttons.style";
import { titleBar, titleStyle } from "../Styles/Titles.style";
import { toolTypeBackground } from "../Styles/Tooltips.style";
import { ItemQRCode } from "./ItemQRCode";
import { Item } from "../../../types/libofthings.type";

export const ManageItem = ({ item }: {
    item: Item
}) => {
    const useInformationBoxStyle = backgroundCardStyle();
    const useTitleBarStyle = titleBar();
    const useTitleTextStyle = titleStyle()
    const useManageItemStyle = manageItemStyle();
    const useBaseButtonStyle = baseButton();
    const useRedButtonStyle = redButton();
    const useGreenButtonStyle = greenButton();
    const useTooltipStyle = toolTypeBackground();
    const profile = useAppSelector((state) => state.user.currentProfile);
    const { t } = useTranslation(['LibOfThings']);
    const [titleForDisabledButtons, setTitleForDisabledButtons] = useState('');
    const [showQR, setShowQR] = useState(false);

    useEffect(() => {
        setTitleForDisabledButtons(t('functionNotImplementedYet'))
    }, [])

    const handleShowQR = (value: boolean) => {
        setShowQR(value);
    }

    return (
        <Grid container className={`${useInformationBoxStyle.root} ${useManageItemStyle.root}`} style={{ textAlign: 'center' }}>
            <Grid item xs={12} className={`${useTitleBarStyle.root}`}>
                <Typography variant={'h3'} className={useTitleTextStyle.root}>
                    {t('manageItem')}
                </Typography>
            </Grid>
            {
                profile != null && (
                    <>
                        {
                            (profile.additional_properties?.commonshoodWallet === item.owner) ? (
                                <>
                                    <Tooltip title={titleForDisabledButtons} className={useTooltipStyle.root}>
                                        <Grid item xs={12} style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}>
                                            <Button disabled={true} className={`${useBaseButtonStyle.root} ${useRedButtonStyle.root}`}>
                                                {t('removeFromMarket')}
                                            </Button>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title={titleForDisabledButtons}>
                                        <Grid item xs={12}>
                                            <Button onClick={() => setShowQR(true)} className={`${useBaseButtonStyle.root} ${useGreenButtonStyle.root}`}>
                                                {t('showQR')}
                                            </Button>
                                        </Grid>
                                    </Tooltip>

                                </>
                            ) : (
                                <>
                                    <Tooltip title={titleForDisabledButtons}>
                                        <Grid item xs={12} style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' }}>
                                            <Button disabled={true} className={`${useBaseButtonStyle.root} ${useGreenButtonStyle.root}`}>
                                                {t('request')}
                                            </Button>
                                        </Grid>
                                    </Tooltip>
                                </>
                            )
                        }
                    </>
                )
            }
            <Tooltip title={titleForDisabledButtons}>
                <Grid item xs={12}>
                    <Button disabled={true} className={`${useBaseButtonStyle.root} ${useGreenButtonStyle.root}`}>
                        {t('preorderHistory')}
                    </Button>
                </Grid>
            </Tooltip>
            <ItemQRCode item={item} handleShowQR={handleShowQR} showQR={showQR} />
        </Grid>
    )
}