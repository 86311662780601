import React from 'react';
import ReactCountryFlag from 'react-country-flag';

type FlagButtonProps = {
    lngISO: string;
    countryAcronym: string;
    i18n: any;
}

const FlagButton = (props: FlagButtonProps) => {

    const handleClick = () =>{
        props.i18n.changeLanguage(props.lngISO);
    };

    return(
        <a onClick={handleClick}>
            <ReactCountryFlag code={props.countryAcronym} countryCode={props.countryAcronym} alt={props.countryAcronym} style={{height: "1.5em", width: "1.5em"}} svg/>
        </a>
    )
};

export default FlagButton;
