import {Avatar, Badge, Card, Chip, Grid, IconButton, Typography} from "@material-ui/core";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useTranslation} from "react-i18next";
import {logger} from "../../utilities/logger/logger";
import { coinUnsetPreselected } from "../../store/slices/coinSlice";

const PreselectedCoinComponent = () => {
    const preselectedCoin = useAppSelector(state => state.coin.preselectedCoin);
    const {t} = useTranslation(['Common'])
    const dispatch= useAppDispatch();

    return (
        <>
            {
                preselectedCoin ?
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Chip
                            style={{
                                backgroundColor: 'white',
                                height: '60px',
                                marginTop: '15px',
                                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                            }}
                            label={<Typography variant={'subtitle1'} style={{fontWeight: 'bold'}}>
                                <Grid container alignItems={'center'} justifyContent="center">
                                    <Grid>
                                        {t('preselectedCoinText')}
                                    </Grid>
                                    &nbsp;
                                    <Grid>
                                        <Avatar src={preselectedCoin.iconData} style={{display: 'inline-block'}}/>
                                    </Grid>
                                    <Grid>
                                        &nbsp;
                                        {preselectedCoin.symbol}
                                        &nbsp;
                                        {t('to')}:

                                    </Grid>
                                </Grid>
                            </Typography>}
                            onDelete={() => {dispatch(coinUnsetPreselected())}}
                        />
                    </Grid>



                    : null
            }
        </>
    )
}
export default PreselectedCoinComponent;