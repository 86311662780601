import React, { useEffect, useState } from "react";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { exchangeCreate } from "../../../store/slices/exchangeSlice";

import SelectCoins from "./SelectCoins";
import ExchangeStepper from "./Stepper";
import ResumePage from "./ResumePage";

import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  coinGetList,
  getTokensFM,
  getTokensOwnedFM,
} from "../../../store/slices/coinSlice";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
    flexGrow: 1,
    textAlign: "center",
  },
  searchbox: {
    width: "100%",
    marginTop: "3vh",
  },
  reset: {
    marginTop: "3vh",
    marginRight: "0.5vh",
  },
  next: {
    marginLeft: "0.5vh",
    marginTop: "3vh",
  },
  loading: {
    marginTop: "3vh",
  },
}));

const CreateExchangeForm = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation("ExchangeCreateForm");

  const dispatch = useAppDispatch();
  const coinListLoading = useAppSelector((state) => state.coin.getListloading);
  const coinList = useAppSelector((state) => state.coin.coinList);
  const creationLoading = useAppSelector((state) => state.exchange.loading);
  const currentProfile = useAppSelector((state) => state.user.currentProfile);

  const [coinsOffered, setCoinsOffered] = useState(new Map());
  const [coinsRequired, setCoinsRequired] = useState(new Map());

  const [errorsAmounts, setErrorsAmounts] = useState(new Set());
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(0);

  const [showingCoins, setShowingCoins] = useState<any[]>([]);

  const [repeatings, setRepeatings] = useState(1);
  const [repeatingsError, setRepeatingsError] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleReset = () => {
    setActiveStep(0);
    setCoinsOffered(new Map());
    setCoinsRequired(new Map());
    setErrorsAmounts(new Set());
    setRepeatingsError(false);
    setSelectedExpirationDate(0);
    //dispatch(coinGetList(null, true, true, false, null))
    dispatch(getTokensOwnedFM("", true, false, -1));
  };

  useEffect(() => {
    if (activeStep === 0 && !coinListLoading) {
      //dispatch(coinGetList(null, true, true, false, null))
      dispatch(getTokensOwnedFM("", true, false, -1));
    }
  }, []);

  useEffect(() => {
    if (activeStep === 0) {
      setShowingCoins(coinList);
    } else if (activeStep === 1) {
      const selectedCoins = Array.from(coinsOffered, ([coin, amount]) => ({
        coin,
        amount,
      }));
      const newCoinList = coinList.filter((coin) => {
        for (let c of selectedCoins) {
          if (c.coin.address === coin.address && parseFloat(c.amount) != 0)
            return false;
        }
        return true;
      });
      setShowingCoins(newCoinList);
    }
  }, [coinList]);

  useEffect(() => {
    if (activeStep === 0 && !coinListLoading) {
      //dispatch(coinGetList(null, true, true, false, null));
      dispatch(getTokensOwnedFM("", true, false, -1));
    }
    if (activeStep === 1 && !coinListLoading) {
      //dispatch(coinGetList(null, true, false, false, null));
      dispatch(getTokensFM("", true, false, -1));
    }
    if (activeStep === 3) exchangeCreation();
  }, [activeStep]);

  useEffect(() => {
    if (!creationLoading) handleReset();
  }, [creationLoading]);

  useEffect(() => {
    handleReset();
  }, [currentProfile]);

  const handleAmountChange = (coin: any, amount: string) => {
    const isInputEmpty = amount.length === 0;
    const amountFloat = parseFloat(amount);

    if (activeStep === 0) {
      if (isInputEmpty || amountFloat < 0) {
        const newCoinsOffered = new Map(coinsOffered);
        newCoinsOffered.delete(coin);
        setCoinsOffered(newCoinsOffered);
      } else {
        setCoinsOffered((prev) => new Map(prev.set(coin, amountFloat)));
      }

      if (amountFloat > coin.balance.balance) {
        setErrorsAmounts((prev) => new Set(prev.add(coin)));
      } else {
        const newErrorsAmounts = new Set(errorsAmounts);
        newErrorsAmounts.delete(coin);
        setErrorsAmounts(newErrorsAmounts);
      }
    } else if (activeStep === 1) {
      setCoinsRequired((prev) => new Map(prev.set(coin, amountFloat)));
      if (isInputEmpty || amountFloat < 0) {
        const newCoinsRequired = new Map(coinsRequired);
        newCoinsRequired.delete(coin);
        setCoinsRequired(newCoinsRequired);
      }
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const exchangeCreation = () => {
    const coinsOfferedArray = Array.from(coinsOffered, ([coin, amount]) => ({
      coin,
      amount,
    }));
    const coinsOfferedAddr = [];
    const amountsOffered = [];

    for (let offer of coinsOfferedArray) {
      if (parseFloat(offer.amount) !== 0) {
        coinsOfferedAddr.push(offer.coin.address);
        amountsOffered.push(offer.amount);
      }
    }

    const coinsRequiredAddr = [];
    const offer = Array.from(coinsRequired.keys());
    for (let coin of offer) coinsRequiredAddr.push(coin.address);

    const amountsRequired = Array.from(coinsRequired.values());

    dispatch(
      exchangeCreate(
        coinsOfferedAddr,
        amountsOffered,
        coinsRequiredAddr,
        amountsRequired,
        repeatings,
        selectedExpirationDate
      )
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchInput = event.target.value.toLowerCase();

    setShowingCoins(
      coinList.filter((coin) => {
        const coinName = coin.name.toLowerCase();
        const coinSymbol = coin.symbol.toLowerCase();
        if (coinName.includes(searchInput) || coinSymbol.includes(searchInput))
          return true;
        else return null;
      })
    );
  };

  const getCoinAmount = (coin: any) => {
    if (activeStep === 0) {
      if (coinsOffered.has(coin)) return coinsOffered.get(coin);
    } else if (activeStep === 1) {
      if (coinsRequired.has(coin)) return coinsRequired.get(coin);
    }
    return "";
  };

  const showCoinError = (coin: any, balance: number) => {
    if (activeStep === 0)
      if (coinsOffered.has(coin) && coinsOffered.get(coin) > balance)
        return true;
    return false;
  };

  const nextStepButtonDisabled = () => {
    let disabled =
      errorsAmounts.size !== 0 ||
      coinsOffered.size === 0 ||
      (coinsRequired.size === 0 && activeStep === 1) ||
      creationLoading;

    const toCheckAmounts = activeStep === 0 ? coinsOffered : coinsRequired;

    if (!disabled) {
      const amounts = Array.from(toCheckAmounts.values());
      let allZeroAmounts = true;
      for (let amount of amounts) {
        if (parseFloat(amount) !== 0) {
          allZeroAmounts = false;
          break;
        }
      }

      disabled = disabled || allZeroAmounts;
    }

    return disabled;
  };

  const changeRepeatingsError = (newValue: boolean) => {
    setRepeatingsError(newValue);
  };

  const changeRepeatigsValue = (newRepeatings: number) => {
    setRepeatings(newRepeatings);
  };

  const formContent = () => {
    if (creationLoading || coinListLoading) {
      return (
        <Grid
          container
          item
          justifyContent="center"
          className={classes.loading}
        >
          <CircularProgress color="primary" />
        </Grid>
      );
    }

    if (activeStep === 2)
      return (
        <ResumePage
          coinsOffered={coinsOffered}
          coinsRequired={coinsRequired}
          repeatings={repeatings}
          onRepeatingsChange={changeRepeatigsValue}
          onRepeatingsError={changeRepeatingsError}
          onDateChange={(dateTimestamp) =>
            setSelectedExpirationDate(dateTimestamp)
          }
        />
      );
    else
      return (
        <SelectCoins
          activeCoinsList={showingCoins}
          isLoadingCoinList={coinListLoading}
          getCoinAmount={getCoinAmount}
          showCoinError={showCoinError}
          onAmountChange={handleAmountChange}
          step={activeStep}
        />
      );
  };

  return (
    <>
      <ExchangeStepper step={activeStep} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.form}
      >
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            disabled={coinsOffered.size === 0 || creationLoading}
            variant="outlined"
            color="primary"
            onClick={handleReset}
            className={classes.reset}
          >
            {t("reset")}
          </Button>
          <Button
            disabled={nextStepButtonDisabled() || repeatingsError}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.next}
          >
            {activeStep === 2 ? t("create") : t("next")}
          </Button>
        </Grid>
        {activeStep !== 2 && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            className={classes.searchbox}
          >
            <TextField
              disabled={creationLoading}
              id="searchBox"
              label={t("search")}
              variant="outlined"
              onChange={handleSearch}
            />
          </Grid>
        )}
      </Grid>
      {formContent()}
    </>
  );
};

export default CreateExchangeForm;
