import config from "../../config";

type LoggingLevel = "dev" | "prod";
const loggingLevel = config.logging.level as LoggingLevel;

const noOp = function(...args: any[]){}; //placeholder function to deactivate logging methods when needed

type Logger = {
    log: typeof noOp,
    debug: typeof noOp,
    info: typeof noOp,
    warn: typeof noOp,
    error: typeof console.error,
}

let logger: Logger | typeof console;
if(loggingLevel === "prod"){
    //we deactivate all logging EXCEPT for error
    logger = {
        log: noOp,
        debug: noOp,
        info: noOp,
        warn: noOp,
        error: console.error,
    }
}else{ //whatever it has other value or is undefined, we treat it as dev environment
    logger = console;
}

export {logger};
