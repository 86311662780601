import React from 'react';

import {Card, Divider, Grid, Menu, MenuItem, Typography} from "@material-ui/core";

//style injection
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useAppSelector} from "../../../store/hooks";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.down('sm')]:
            {
                fontSize: '0.7em'
            }
    },
    emptyList: {
        alignContent: "center"
    },
    menu: {
        width: '120vw',
        margin: theme.spacing(2),
        borderRadius: theme.spacing(4),
    },
    typo: {
        textAlign: "center",
    },
    item: {
        textAlign: "center",
        margin: theme.spacing(1),
    }
}));


type NotificationMenuListProps = {
    id: string,
    anchorEl: EventTarget & Element | null,
    open: boolean,
    onClose: () => void
};

const PendingTransactionList = (props: NotificationMenuListProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation('TransactionList');
    const onGoingTransactionList = useAppSelector(state => state.onGoingTransactions.onGoingTransactions)

    return (
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transitionDuration={{enter: 750, exit: 0}}
                open={props.open}
                onClose={props.onClose}
                className={classes.menu}
            >
                {
                    onGoingTransactionList.length > 0 && props.anchorEl ?
                        onGoingTransactionList.map((item: string, index: number) => {
                            return (
                                <>
                                    <Typography key={index} className={classes.item} >{t('TransactionList:' + item)}</Typography>
                                    <Divider/>
                                </>
                            )
                        }) : null
                }
            </Menu>
    )
}

export default (PendingTransactionList);