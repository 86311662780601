import {
    Avatar,
    Chip,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    Theme,
    Typography,
} from '@material-ui/core'
import { CrwdslCardType, DaoContact } from '../MapUtils'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import img from '../../../assets/img/mapImgUtils/img.png'
import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import logo from '../../../assets/img/logo/240x240.png'
import config from '../../../config'
import BuildIcon from '@material-ui/icons/Build'
import { DaoTypes } from '../../../types/dao.types'
import StoreIcon from '@material-ui/icons/Store'
import GroupIcon from '@material-ui/icons/Group'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Place from '@material-ui/icons/Place'
import theme from '../../../theme/theme'
import { PlainPlaceType } from '../CrowdsaleMapUtils'

type ClusterListCardProps = {
    contact: DaoContact | PlainPlaceType | CrwdslCardType
    handleOpenDaoDetails?: (item: DaoContact) => void
    handleOpenDetails?: (item: PlainPlaceType) => void
    handleOpenCrowdsaleDetails?: (item: CrwdslCardType) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: 'white',
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        image: {
            width: 300,
            height: 200,
        },
        rootScroll: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            marginLeft: '1vh',
            marginBottom: '2vh',
        },
        imageList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. [source: Material UI]
            transform: 'translateZ(0)',
        },
        imageListItem: {
            marginLeft: '1.5vh',
            marginRight: '1.5vh',
            cursor: 'pointer',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
            backgroundColor: 'white',
        },
        avatar: {
            position: 'absolute',
            right: '2%',
            top: '2%',
            border: '2px solid black',
            background: 'white',
        },
    })
)

const listItemTextStyle = {}

const onFocusListItemTextStyle = {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#e5b409',
}

const ClusterListCard = (props: ClusterListCardProps) => {
    const { contact, handleOpenDetails, handleOpenDaoDetails, handleOpenCrowdsaleDetails } = props
    const classes = useStyles()
    const [imageListItemFocus, setImageListItemFocus] = useState<string>('')

    const categoryIcon = () => {
        const realm = contact.realm
        if (realm === 'crowdsale'){
            return <RecordVoiceOverIcon style={{ color: '#ffbf00' }} />
        }
        else if (realm === 'plainPlace')
            return <Place style={{ color: '#ffb9c3' }} />
        else if (realm === 'dao') {
            const category = contact.daoDetails.type
            return category === DaoTypes[0] ? (
                <StoreIcon style={{ color: theme.palette.primary.main }} />
            ) : category === DaoTypes[1] ? (
                <GroupIcon style={{ color: '#E44E3C' }} />
            ) : undefined
        }
    }

    const handleOpen = () => {
        if (contact.realm === 'crowdsale') {
            handleOpenCrowdsaleDetails && handleOpenCrowdsaleDetails(contact)
        } else if (contact.realm === 'plainPlace') {
            handleOpenDetails && handleOpenDetails(contact)
        } else if (contact.realm === 'dao') {
            handleOpenDaoDetails && handleOpenDaoDetails(contact)
        }
    }
    
    return (
        <ImageListItem
            className={classes.imageListItem}
            onMouseOver={() => setImageListItemFocus(contact.firstLifePlaceID)}
            onMouseLeave={() => setImageListItemFocus('')}
            key={contact.firstLifePlaceID}
            onClick={() => handleOpen()}
        >
            {contact.realm === 'dao' &&
            contact.resources &&
            contact.resources.length > 0 ? (
                <img
                    className={classes.image}
                    alt={contact.resources[0].name}
                    src={
                        config.network.resources.url +
                        contact.resources[0].fileId
                    }
                />
            ) : (
                <img
                    className={classes.image}
                    alt={'default'}
                    src={logo}
                />
            )}
            <Avatar className={classes.avatar}>{categoryIcon()}</Avatar>
            <ImageListItemBar
                title={
                    <Typography
                        variant={'body2'}
                        style={
                            imageListItemFocus === contact.firstLifePlaceID
                                ? onFocusListItemTextStyle
                                : listItemTextStyle
                        }
                    >
                        {contact.realm === 'dao'
                            ? contact.daoDetails.name
                            : contact.realm === 'plainPlace' ? contact.name : 
                            contact.realm ==='crowdsale' ? contact.title : undefined}
                    </Typography>
                }
                classes={{
                    root: classes.titleBar,
                    title: classes.title,
                }}
            />
        </ImageListItem>
    )
}

export default ClusterListCard
