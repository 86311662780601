import {  useState } from "react";
import { NftData } from "../../../../types/nft.types";
import { FormContainer } from "../../../Nft/NftCreateForm/FormContainer";
import { HeaderHomeRegisterNewItem } from "./HeaderHomeRegisterNewItem";
import { ScrollWithOwnedNfts } from "./ScrollWithOwnedNfts";

export const DisplayListOfNFTs = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [existingNftToUse, setExistingNftToUse] = useState<NftData | undefined>(undefined);

    const handleChangeTab = (newTab: number) => {
        setActiveTab(newTab);
    }

    const handleClickOnCreateNewNft = () => {
        handleChangeTab(1);
    }

    const handleClickOnUploadNft = (nft: NftData) => {
        openFormWithExistingNft(nft);
    }

    const openFormWithExistingNft = (nft: NftData) => {
        handleChangeTab(1);
        setExistingNftToUse(nft);
    }

    return (
        <>
            {
                activeTab === 0 && <>
                    <HeaderHomeRegisterNewItem handleClickOnCreateNewNft={handleClickOnCreateNewNft} />
                    <ScrollWithOwnedNfts handleClickOnUploadNft={handleClickOnUploadNft} />
                </>
            }
            {
                activeTab === 1 && (
                    <FormContainer isForItems={true} existingNftToUse={existingNftToUse} />
                )
            }
        </>

    )
}