import { Grid } from "@material-ui/core";
import { Preorder } from "../../../../types/calendar.type";
import { backgroundStyleForPreorderInList } from "../../Styles/Backgrounds.style";
import { PreorderHeader } from "../../BasicComponents/PreorderHeader";
import { PreorderBody } from "../PreorderInList/PreorderBody";

export const PreorderStartedInList = ({
    preorder,
    goToItemPage
}: {
    preorder: Preorder,
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const useBackgroundStyle = backgroundStyleForPreorderInList();
    
    return (
        <div className={useBackgroundStyle.root}>
            <Grid container>
                {
                    (preorder != null) && (
                        <>
                            <Grid item xs={12}>
                                <PreorderHeader preorder={preorder} />
                            </Grid>
                            <Grid item xs={12}>
                                <PreorderBody preorder={preorder} goToItemPage={goToItemPage} />
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </div>
    )
};