import { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { useAppDispatch } from "../../../../../store/hooks";
import { collectionSetSelected } from "../../../../../store/slices/nftSlice";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getFromIpfs,
  getJsonFromResource,
} from "../../../../../api/resourceAPI";
import config from "../../../../../config";
import { collectionProps } from "../../../../../types/nft.types";

const useStyle = makeStyles(({ spacing }: Theme) => ({
  item: {
    maxWidth: "100%",
  },
  avatar: {
    marginRight: spacing(1),
  },
  balanceText: {
    textAlign: "right",
    marginRight: spacing(5),
  },
  balanceTextWithPay: {
    textAlign: "right",
    marginRight: spacing(15),
  },
  balanceTextWithoutPay: {
    textAlign: "right",
    marginRight: "auto",
  },
  withRadioBalanceText: {
    textAlign: "right",
    marginRight: spacing(6),
  },
}));

const CollectionListItem = (props: collectionProps) => {
  const { name, symbol, contractAddress, nftOwned, owner } = props;
  let { collectionURI } = props;
  const { t } = useTranslation(["Common", "NftCreateForm"]);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyle(theme);
  const dispatch = useAppDispatch();

  const [metadata, setMetadata] = useState<any>(null);
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

  useEffect(() => {
    const fun = async () => {
      if (collectionURI.includes(config.network.ipfs.default_url))
        setMetadata(await getFromIpfs(collectionURI));
      else setMetadata(await getJsonFromResource(collectionURI));
    };
    fun();
  }, []);
  useEffect(() => {
    if (metadata !== null) {
      if (metadata.image.includes(config.network.ipfs.default_url)) {
        setMetadata({
          ...metadata,
          image: metadata.image.replace(
            config.network.ipfs.default_url,
            config.network.ipfs.gateway_url
          ),
        });
      }

      if (!metadata.image.includes(config.network.ipfs.default_url)) {
        let tempImage = new Image();
        tempImage.onload = () => {
          setIsImageLoad(true);
        };
        tempImage.src = metadata.image;
      }
    }
  }, [metadata]);
  const handleSelect = (contractAddress: collectionProps) => {
    dispatch(collectionSetSelected({ selected: contractAddress }));
  };
  const handleCreate = (contractAddress: collectionProps) => {
    dispatch(collectionSetSelected({ selected: contractAddress }));
    history.push("/nftCreate", { contractAddress: contractAddress });
  };
  return (
    <>
      <ListItem
        dense
        className={classes.item}
        button
        onClick={() => {
          handleSelect(props);
        }}
        disabled={!metadata}
      >
        {metadata && isImageLoad ? (
          <Avatar
            alt={symbol}
            className={classes.avatar}
            src={metadata ? metadata.image : ""}
          />
        ) : (
          <Avatar alt={symbol} className={classes.avatar}>
            <Skeleton variant="circle" />
          </Avatar>
        )}
        <ListItemText primary={name} secondary={symbol} />

        {owner ? (
          <>
            <ListItemText
              primary={nftOwned}
              className={classes.balanceTextWithPay}
            />
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCreate(props);
                }}
                disabled={!metadata}
              >
                {t("create")}
              </Button>
            </ListItemSecondaryAction>
          </>
        ) : (
          <ListItemText
            primary={nftOwned}
            className={classes.balanceTextWithoutPay}
          />
        )}
      </ListItem>
    </>
  );
};

export default CollectionListItem;
