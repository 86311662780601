import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getNftsOwned } from "../../../../api/nftAPI";
import { useAppSelector } from "../../../../store/hooks";
import { NftData } from "../../../../types/nft.types";
import { basicCommonsHoodButtonStyle } from "../../Styles/Buttons.style";
import { ItemCard } from "./ItemCard";
import Slider from "react-slick";
import { settings } from "./sliderSettings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ScrollWithOwnedNfts = ({ handleClickOnUploadNft }: {
    handleClickOnUploadNft: (nft: NftData) => void;
}) => {

    //redux
    const currentProfile = useAppSelector(state => state.user.currentProfile);

    //state
    const [nfts, setNfts] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesLoaded, setPagesLoaded] = useState<number[]>([1]);

    //languages
    const { t } = useTranslation(["LibOfThings", "Common"]);

    //styles
    const commonsHoodStyleButton = basicCommonsHoodButtonStyle();

    useEffect(() => {
        (async () => {
            const firstPage = await loadPage(1);
            const secondPage = await loadPage(2);
            setNfts([...firstPage, ...secondPage]);
        })();
    }, [currentProfile]);

    const loadPage = async (page: number) => {
        if (currentProfile?.additional_properties?.commonshoodWallet != undefined) {
            const firstPageNft = await getNftsOwned(
                currentProfile?.additional_properties?.commonshoodWallet,
                "alphaAscending",
                page.toString()
            )
            return firstPageNft;
        }
    }

    const handleClick = (nft: any) => {
        handleClickOnUploadNft({
            tokenId: nft.tokenId,
            tokenUri: nft.tokenUri,
            name: nft.name,
            contractAddress: nft.contractAddress,
            isTransferable: nft.isTransferable,
            owner: nft.owner,
            symbol: nft.symbol
        });
    }

    const handleSliderChange = async (current: number, nextSlide: number) => {
        if (nextSlide > current) {
            setCurrentPage(currentPage + 1);
        } else {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        }
        if (currentPage % 2 == 0) {
            const newPage = await loadPage(currentPage + 1);
            setPagesLoaded([...pagesLoaded, currentPage + 1]);
            if (!pagesLoaded.includes(currentPage + 1))
                setNfts([...nfts, ...newPage]);
        }
    }

    return (
        <Slider {...settings} beforeChange={handleSliderChange}>
            {
                nfts.map((nft, index) => {
                    return (
                        <ItemCard
                            buttonStyle={commonsHoodStyleButton}
                            title={nft.name}
                            uriImage={nft.tokenUri}
                            handleClick={() => handleClick(nft)}
                            subheader={`${t('Common:collection')}: ${nft.symbol}`}
                        />
                    )
                })
            }
        </Slider>
    )
}