import { useState } from "react";
import { MyToaster } from "../BasicComponents/MyToaster";
import { DisplayListOfNFTs } from "./RegisterNewItem/HomeRegisterNewItem";


export const HubItemsUpload = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <MyToaster />
            {
                activeTab === 0 && (
                    <>
                        <DisplayListOfNFTs />
                    </>

                )
            }
        </>
    )
}