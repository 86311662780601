import React, {useState} from 'react';

import {Divider, Grid, IconButton, Menu, Tooltip, Typography} from "@material-ui/core";

//style injection
import {makeStyles, useTheme} from "@material-ui/core/styles";

//Custom components
import Notification from './Notification';

import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import {RealtimeNotificationType} from "../../api/notificationAPI";
import {useTranslation} from "react-i18next";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {notificationMarkAsRead, notificationRemoveAllFromCurrentlyListed} from "../../store/slices/notificationSlice";
import {useAppDispatch} from "../../store/hooks";

const useStyles = makeStyles((theme) => ({
    icon: {
        [theme.breakpoints.down('sm')]:
            {
                fontSize: '0.7em'
            }
    },
    emptyList: {
        alignContent: "center"
    },
    menu: {
        width: '100vw'
    },
    typo: {
        textAlign: "center",
    },
    markAllButton:{

    }

}));


type NotificationMenuListProps = {
    notificationList: RealtimeNotificationType[],
    id: string,
    anchorEl: EventTarget & Element | null,
    open: boolean,
    onClose: () => void
};


const NotificationMenuList = (props: NotificationMenuListProps) => {
    const dispatch= useAppDispatch()
    const theme = useTheme();
    const classes = useStyles(theme);
    const history = useHistory();
    const {t} = useTranslation('NotificationMessages');

    const [numberOfNotf, setNumberOfNotf] = useState(5)

    const hiddenNotf = props.notificationList.length > numberOfNotf ? props.notificationList.length - numberOfNotf : 0
    let notfList= [...props.notificationList].slice(0,numberOfNotf)

    const markALLNotificationAsRead = () => {
        dispatch(notificationMarkAsRead(null)) // null means 'ALL'
        dispatch(notificationRemoveAllFromCurrentlyListed())
    }

    const markAllButton =
        <IconButton onClick={() => markALLNotificationAsRead()} className={classes.markAllButton}
                    color="primary" size='medium'>
            <Tooltip aria-label="Bold" role="presentation" title={'Mark ALL as read'}><DoneAllIcon/></Tooltip>
        </IconButton>


    return (
        <Menu
            id={props.id}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            onClose={props.onClose}
            className={classes.menu}
        >
            {
                notfList.length > 0 ?
                    notfList.map((item: RealtimeNotificationType, index: number) => {
                        return (
                            <Typography component={'span'} key={index}>
                                <Notification
                                    params={item}
                                />
                            </Typography>
                        )
                    }) : <Typography style={{marginBottom: '25px', marginTop: '10px'}}>
                        <Icon>info</Icon> {t('noNotifications')}
                    </Typography>
            }
            <Divider/>
            <Grid container alignItems="center">
                <Grid item xs={3} className={classes.typo}>
                    {notfList.length>1 ? markAllButton:null }
                </Grid>
                <Grid item xs={3} className={classes.typo}>
                    {hiddenNotf > 0 ? <Typography>{hiddenNotf} more...</Typography> : null}
                </Grid>
                <Grid item xs={3} className={classes.typo}>
                    <Button className={classes.typo} onClick={() => {
                        history.push('/messages')
                    }}> {t('goToNotificationsHistory')}</Button>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default (NotificationMenuList);
