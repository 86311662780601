import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

// Styles
import {makeStyles, useTheme} from "@material-ui/core/styles";

//Material UI Components
import {
    Backdrop,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    Typography
} from "@material-ui/core";

//custom components
import CoinAvatarLabeled from '../../../../components/UI/CoinAvatarLabeled/CoinAvatarLabeled';
import {logger} from "../../../../utilities/logger/logger";


import config from "../../../../config";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {crowdsaleLoadAndUnlock} from "../../../../store/slices/crowdsaleSlice";
import Loading from "../../../../components/UI/Loading/Loading";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
    },
    media: {
        height: 140
    },
    actions: {
        display: "flex"
    },
    acceptedCoin: {
        marginLeft: "auto"
    },
    coinToGive: {},
    favorite: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    owned: {
        position: "absolute",
        left: theme.spacing(1),
        top: theme.spacing(1)
    },
    backdrop: {
        position: 'absolute',
        zIndex: 5
    },
}));

type PiggyCardProps = {
    crowdsale: any,
    handleOpen: () => void,
    reload: (mustReload: boolean) => void
}

const PiggyCard = (props: PiggyCardProps) => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const {t} = useTranslation(["PiggyCard"])

    const unlocked = useAppSelector(state => state.crowdsale.unlocked)
    const unlockLoading = useAppSelector(state => state.crowdsale.unlockLoading)
    const crowdsaleOperation = useAppSelector(state => state.crowdsale.onGoingOperations);


    const {
        crowdsale,
        handleOpen,
        reload,
    } = props;

    // useEffect(() => {
    //    if(unlocked){
    //        reload(true)
    //    }
    // }, [unlocked]);
    //
    const {
        crowdsaleAddress,
        logoHash,
        title,
        description,
        isOwnedByCurrentUserWallet,
        startDate,
        endDate,
        maxCap,
        totalReservations,
        photo,
        status,
        acceptRatio,
        tokenToAccept,
        tokenToAcceptAddr,
        tokenToAcceptLogo,
        giveRatio,
        tokenToGive,
        tokenToGiveAddr,
        tokenToGiveLogo,
        tokenToGiveBalance //note that this is the balance of the related token for the crowdsale's wallet!
    } = crowdsale;


    const [showLoadingComponent, setShowLoadingComponent] = useState(false);

    const handleUnlock = () => {
        //setShowLoadingComponent(true)
        dispatch(crowdsaleLoadAndUnlock(crowdsaleAddress, tokenToGiveAddr, maxCap / acceptRatio))
    }

    const handleModalClose = () => {
        //setShowLoadingComponent(false)
        reload(true)
    }

    // visually shows if the logged user ownes the crowdsale
    let ownedButton = null;
    if (isOwnedByCurrentUserWallet) {
        ownedButton = (
            <Button variant="contained" component="div" color="default" className={classes.owned}>
                <Icon>vpn_key</Icon> OWNED
            </Button>
        );
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    //TODO add check on cap reach first? If reached crowdsale is over for sure
    let timeLeft = null;
    if (status === config.crowdsaleStatus[1]) {
        timeLeft = t('crowdsaleOver');
    } else if (maxCap !== totalReservations) {
        if (today < start) { //crowdsale not started yet

            timeLeft = (start.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
            if (timeLeft < 1) {
                timeLeft = t('lessThanADayStart');
            } else {
                timeLeft = t('startingIn', {params: {days: Math.round(timeLeft)}});
            }
        } else { //crowdsale already started
            if (today < end && status !== config.crowdsaleStatus[1]) { //crowdsale already started but not ended
                timeLeft = (end.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
                if (timeLeft < 1) {
                    timeLeft = t('lessThanADayEnd');
                } else {
                    timeLeft = t('endingIn', {params: {days: Math.round(timeLeft)}});
                }
            } else { //crowdsale ended
                timeLeft = t('crowdsaleOver');
            }
        }
    } else { //it doesn't matter the remaining time, crowdsale is already completed
        timeLeft = t("crowdsaleReachedCap");
    }

    //messages reserved to the owner
    let crowdsaleIsVisibleTypography = null;
    if (isOwnedByCurrentUserWallet &&
        status === config.crowdsaleStatus[0] //RUNNING aka the owner already loaded and unlocked it
    ) {
        crowdsaleIsVisibleTypography = (
            <Typography color="error" style={{marginTop: "1em"}}>
                {t("crowdsaleIsVisible")}
            </Typography>
        )
    }

    //default case
    let iconButtons = (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                {crowdsaleIsVisibleTypography}
            </Grid>
            <Grid item>
                <IconButton disabled={true} className={classes.acceptedCoin}>
                    <CoinAvatarLabeled noName={true}
                                       coin={{symbol: tokenToAccept.symbol, logoUrl: tokenToAcceptLogo, logoHash: tokenToAccept.logoHash}}/>
                    <Typography style={{color: "grey"}}
                                variant="caption">{acceptRatio + ' ' + tokenToAccept.symbol}</Typography>
                </IconButton>
                <Icon>compare_arrows</Icon>
                <IconButton disabled={true} className={classes.coinToGive}>
                    <CoinAvatarLabeled noName={true} coin={{symbol: tokenToGive.symbol, logoUrl: tokenToGiveLogo, logoHash: tokenToGive.logoHash}}/>
                    <Typography style={{color: "grey"}}
                                variant="caption">{giveRatio + ' ' + tokenToGive.symbol}</Typography>
                </IconButton>
            </Grid>
        </Grid>
    );

    logger.info(`Crowdsale ${title} has a balance of ${tokenToGiveBalance.balance}`);
    logger.info("  --> it requires: ", parseInt(String(maxCap / acceptRatio)));

    if (
        (tokenToGiveBalance.balance < parseInt(String(maxCap / acceptRatio))) && //this crowdsale has not enough coupons loaded yet
        (status === config.crowdsaleStatus[2]) //and it's in the correct state: LOCKED

    ) {
        iconButtons = (
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography color="error" variant="caption">
                        {t('couponsNotLoaded',
                            {
                                params: {
                                    total: parseInt(String(maxCap / acceptRatio)) - tokenToGiveBalance.balance,
                                    symbol: tokenToGive.symbol
                                }
                            }
                        )}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" disabled={crowdsaleOperation.includes(crowdsaleAddress)} color="primary" onClick={() => handleUnlock()
                    }>
                        {t("loadCrowdsaleButton")}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const loadingTransactionComponent = (
        <Loading title={t('crowdsaleUnlockedMessageInfo')} withLoader/>
    );

    return (
        <>
            <Card className={classes.card}>
                <CardActionArea>
                    <Backdrop className={classes.backdrop} open={crowdsaleOperation.includes(crowdsaleAddress)}>
                        <CircularProgress color="primary" />
                    </Backdrop>
                    <CardMedia
                        className={classes.media}
                        image={logoHash}
                        title={title}
                        onClick={handleOpen}/>
                    {ownedButton}
                    <CardContent onClick={handleOpen}>
                        <Typography component="p">
                            {title}
                        </Typography>
                        <Typography variant="overline" style={{textAlign: 'center', display: 'block'}}>
                            {timeLeft}
                        </Typography>
                        <Typography variant="caption" style={{textAlign: 'center', display: 'block', color: 'gray'}}>
                            {t('currentlyRaised',
                                {
                                    params: {
                                        raised: totalReservations,
                                        cap: maxCap,
                                        ticker: tokenToAccept.symbol
                                    }
                                })}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.actions}>
                    {iconButtons}
                </CardActions>
            </Card>
            {/*<ZoomModal title={t('crowdsaleUnlockedMessage')} fullscreen={"false"} open={showLoadingComponent}>*/}
            {/*    {unlockLoading ? loadingTransactionComponent :*/}
            {/*        unlocked ? <AlertAvatar big success text={t('crowdsaleUnlockedSuccess')}/> :*/}
            {/*            <AlertAvatar big fail text={t('crowdsaleUnlockedFail')}/>}*/}
            {/*    <Button*/}
            {/*        disabled={unlockLoading}*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        style={{marginTop: "1em"}}*/}
            {/*        onClick={handleModalClose}*/}
            {/*    >*/}
            {/*        {"OK"}*/}
            {/*    </Button>*/}
            {/*</ZoomModal>*/}
        </>
    );
}

export default (PiggyCard);