import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import logo_crowdbankers from "../../assets/img/logo/240x240.png";
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 120,
        height: 120,
        margin: "0 auto",
        marginTop: 15
    },
    typo: {
        flexGrow: 1,
        textAlign: "center"
    }
}));

const About = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation('About')
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <Avatar src={logo_crowdbankers} className={classes.logo}/>
            </Grid>
            <Grid item xs={12} alignContent="center">
                <Link href={t('about1')}></Link>
                <Typography variant="body2"  className={classes.typo}>
                    {t('about1')}
                    <br></br>
                    {t('about2')}<Link
                    href="https://www.uia-initiative.eu/en/uia-cities/turin"
                    target="_blank">https://www.uia-initiative.eu/en/uia-cities/turin</Link>.
                    <br></br>
                    {t('about3')}<Link
                    href="mailto:crowdbankers@di.unito.it">crowdbankers@di.unito.it</Link>.
                </Typography>
            </Grid>
        </Grid>
    )
}


export default About;