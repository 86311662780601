import { Divider, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

export const EndLendingPeriodDialog = () => {
    const { t } = useTranslation(['LibOfThings']);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" id="dialog-qr-end-lending-period-title" >{t('endingLendingPeriod')}</Typography>
            </Grid>
            <Divider />
        </Grid>
    );
}