import { Grid } from "@material-ui/core"
import { AccessAlarm, AddCircleOutline } from "@material-ui/icons"
import { WidgetsOutline } from "mdi-material-ui"
import { useTranslation } from "react-i18next"
import { AccountAction } from "./AccountActions"

export const ListOfActions = () => {
    const {t} = useTranslation(['LibOfThings']);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <AccountAction
                    link={'/myPreorders'}
                    title={t('myRequests')}
                    subtitle={t('myRequestsSub')}
                    svg={<AccessAlarm fontSize="large" />}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AccountAction
                    link={'/myitems'}
                    title={t('myInventory')}
                    subtitle={t('myInventorySub')}
                    svg={<WidgetsOutline fontSize="large" />}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <AccountAction
                    link={'/uploadItemInMarketplace'}
                    title={t('registerNewItem')}
                    subtitle={t('registerNewItemSubtitle')}
                    svg={<AddCircleOutline fontSize="large" />} />
            </Grid>
        </Grid>
    )
}