import config from "../config";
import {assetIntegerToDecimalRepresentation} from "../utilities/decimalsHandler/decimalsHandler";
import {logger} from "../utilities/logger/logger";
import axios from "../utilities/backend/axios-metadata";
import {getIpfsUrl} from "../utilities/ipfs/ipfs";
import {ethers, utils, Wallet} from "ethers";


const getMarketplaceFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.MKP_FCTRY_ABI)

    return new ethers.Contract(
        config.smartContracts.MKP_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    );
}

export const getMarketplaceTemplateInstance = (ethersInstance: Wallet, marketplaceAddress: string) => {
    const abi_template = new utils.Interface(config.smartContracts.MKP_TMPLT_ABI)

    return new ethers.Contract(
        marketplaceAddress,
        abi_template,
        ethersInstance
    );
}

const getNftTemplateInstance = (ethersInstance: Wallet, nftAddress: string) => {
    const abi_template = new utils.Interface(config.smartContracts.NFT_TMPLT_ABI)

    return new ethers.Contract(
        nftAddress,
        abi_template,
        ethersInstance
    );
}

const getTokenTemplateInstance = (ethersInstance: Wallet, tokenAddress: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_TMPLT_ABI)

    return new ethers.Contract(
        tokenAddress,
        abi_template,
        ethersInstance
    );
}


export type MarketplaceProps = {
    nftOffered: string;
    tokenIdOffered: string;
    coinRequired: string;
    amountRequired: number;
};


export const createMarketplace = async (
    ethersInstance: Wallet,
    accountAddress: string,
    props: MarketplaceProps
) => {
    try {
        const marketplaceFactoryInstance = getMarketplaceFactoryInstance(ethersInstance)
        const nftTemplateInstance = getNftTemplateInstance(ethersInstance, props.nftOffered)

        let approve = await nftTemplateInstance.approve(config.smartContracts.MKP_FCTRY_ADDR, props.tokenIdOffered)
        approve = await approve.wait()
        logger.info("approve", approve);

        let marketplaceAddrs = await marketplaceFactoryInstance.createMarketplace(
            props.nftOffered,
            props.tokenIdOffered,
            props.coinRequired,
            props.amountRequired,
            config.smartContracts.NFT_FCTRY_ADDR
        )
        marketplaceAddrs = await marketplaceAddrs.wait()
        logger.info("marketplaceAddrs", marketplaceAddrs);
        return true;
    }
    catch (error) {
        logger.error("[createMarketplace]", error);
        return false;
    }

};

export type Marketplace = {
    owner: string;
    status: string;
    nftOffered: {
        nftAddress: string;
        nameCollection: string;
        symbolCollection: string;
        tokenId: string;
        tokenUri: string;
    };
    coinRequired: {
        coinAddress: string;
        coinName: string;
        coinSymbol: string;
        coinLogoUrl: string;
        amountRequired: string;
    };
    marketplaceAddress: string;
};

export const acceptMarketplace = async (
    ethersInstance: Wallet,
    accountAddress: string,
    marketplaceAddress: string,
    coinRequired: string,
    amountRequired: number
) => {
    try {
        const coinInstance = getTokenTemplateInstance(ethersInstance, coinRequired)
        let approve = await coinInstance.approve(marketplaceAddress, amountRequired)
        approve = await approve.wait()
        logger.info("approve", approve);
        const marketplaceInstance = getMarketplaceTemplateInstance(ethersInstance, marketplaceAddress)
        let accept = await marketplaceInstance.acceptMarketplace()
        accept = await accept.wait()
        logger.info("acceptMarketplace", accept);
        return true;
    } catch (error) {
        const coinInstance = getTokenTemplateInstance(ethersInstance, coinRequired)
        let resetapprove = await coinInstance.approve(marketplaceAddress, 0)
        resetapprove = await resetapprove.wait()
        logger.info("resetapprove", resetapprove);
        logger.error("[acceptMarketplace]", error);
        return false;
    }
};

export const cancelMarketplace = async (
    ethersInstance: Wallet,
    ownerAddress: string,
    marketplaceAddress: string
) => {
    try {
        const marketplaceInstance = getMarketplaceTemplateInstance(ethersInstance, marketplaceAddress)

        let cancel = await marketplaceInstance.cancelMarketplace()
        cancel = await cancel.wait()
        logger.info("cancelMarketplace", cancel);
        return true;
    } catch (error) {
        logger.error("[cancelMarketplace]", error);
        return false;
    }
};

export const getMarketplacesFromMetadata = async (
    owner: string,
    filter: string,
    status: string,
    sortBy: string,
    page: number
) => {
    const url = "/marketplace/getMarketplaces";
    const params = new URLSearchParams({
        owner: owner,
        filter: filter,
        status: status,
        sortBy: sortBy,
        page: page + "",
    });
    const temp = await axios.get(url, {params});
    //TODO check ok
    const marketplaces = temp.data.marketplaces.map((marketplace: any) => {
        const logoUrl = marketplace.coinRequired.coinLogoUrl;
        return {
            ...marketplace,
            coinRequired: {
                ...marketplace.coinRequired,
                coinLogoUrl: getIpfsUrl(logoUrl),
            },
        };
    });
    console.log("marketplace trovati", temp.data.marketplaces);
    return marketplaces;
};

export const getMarketplaceNumberFromMetadata = async (status: string) => {
    const url = "/marketplace/getMarketplaceNumber";
    const params = new URLSearchParams({
        status: status,
    });
    const temp = await axios.get(url, {params});
    //TODO check ok
    return temp.data.marketplaces;
};
