import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core"
import { useEffect, useState } from "react";
import { Calendar } from "react-calendar"
import { useTranslation } from "react-i18next";
import { generateHashForPreorder } from "../../../api/libOfThingsAPI";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { createPreorderSlice, getDatesOfItem } from "../../../store/slices/calendarSlice";
import { backgroundCardStyle } from "../Styles/Backgrounds.style";
import { buttonStyle } from "../Styles/Buttons.style";
import { calendarTileStyle } from "../Styles/Calenda.style";
import { titleBar, titleStyle } from "../Styles/Titles.style";
import toast from 'react-hot-toast';
import { duration } from "../../../utilities/costants.notifications";
import CalendarTileProperties from "react-calendar";

const calendarContainerStyle = makeStyles({
    root: {
        position: 'relative',
        marginTop: '2em',
        textAlign: 'center',
        '@media (max-width: 600px)': {
            marginTop: '4em',
            position: 'relative'
        }
    }
})

export const ItemCalendar = ({ itemID, coin, price, caution, owner }: {
    itemID: number,
    coin: string,
    price: number,
    caution: number,
    owner: string
}) => {
    const dispatch = useAppDispatch();
    const useCalendarTileStyle = calendarTileStyle();
    const useTitleBarStyle = titleBar();
    const useTitleTextStyle = titleStyle();
    const useBackgroundStyle = backgroundCardStyle();
    const useCalendarContainerStyle = calendarContainerStyle();
    const profile = useAppSelector(state => state.user.currentProfile)
    const dates = useAppSelector(state => state.calendar.rangeOfDatesForItem);
    const loadingPreorder = useAppSelector(state => state.calendar.loading);
    const [value, setValue] = useState<Date[]>([]);
    const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false);
    const { t } = useTranslation(['LibOfThings']);
    const useButtonStyle = buttonStyle();

    useEffect(() => {
        (() => dispatch(getDatesOfItem(itemID)))()
    }, [])

    const onChange = (nextValue: Date[]) => {
        setValue(nextValue);
    }

    const disableRangeOfDates = (date: Date) => {
        return isWithinRange(date.getTime() / 1000);
    }

    const isWithinRange = (selectedDate: number): boolean => {
        let rangeFound = false;

        for (let i = 0; i < dates.length && !rangeFound; i++) {
            if (selectedDate >= dates[i].startDate && selectedDate <= dates[i].endDate) {
                rangeFound = true;
            }
        }
        return rangeFound;
    }

    const isDateSelectedValid = (dateSelected: Date): boolean => {
        let dateIsvalid = true;
        const monthSelected = dateSelected.getMonth();
        const yearSelected = dateSelected.getFullYear();
        const daySelected = dateSelected.getDate();
        const today = new Date();
        const monthToday = today.getMonth();
        const yearToday = today.getFullYear();
        if (yearSelected < yearToday) {
            dateIsvalid = false;
        } else if (yearSelected === yearToday && monthSelected < monthToday) {
            dateIsvalid = false;
        } else if (yearSelected === yearToday && monthSelected === monthToday && daySelected < today.getDate()) {
            dateIsvalid = false;
        }

        return dateIsvalid;
    }

    const handleClickPreorder = async () => {
        try {
            setAskForConfirmation(false);
            const address = profile?.additional_properties?.commonshoodWallet;
            if (owner === address) {
                toast.error(t('youAreTheOwner'), {
                    duration: duration
                });
                return;
            }
            if (value[0] > value[1]) {
                toast.error(t('invalidRange'), {
                    duration: duration
                });
                return;
            }
            if (!isDateSelectedValid(value[0])) {
                toast.error(t('noDatesInThePast'), {
                    duration: duration
                });
                return;
            }
            if (address != null) {
                const { hash, signature } = await generateHashForPreorder(
                    itemID,
                    value[0],
                    value[1],
                    address
                );
                console.log(`Hash: ${hash}, Signature: ${signature}`);
                const errors = await dispatch(
                    createPreorderSlice(
                        itemID,
                        value[0],
                        value[1],
                        hash,
                        signature,
                        coin,
                        price,
                        caution
                    )
                )
                if (errors != null && errors.length > 0) {
                    toast.error(errors[0], {
                        duration: duration
                    });
                } else {
                    toast.success(t('preorderCreated'), {
                        duration: duration
                    });
                }

            }
        } catch (error: any) {
            toast.error(error.message, {
                duration: duration
            });
            console.error(error.message);
        }
    }

    const getSelectedLanguage = () => {
        const language = localStorage.getItem('i18nextLng');
        if (language == null)
            return 'en';
        if (language.includes('en'))
            return 'en'
        else
            return 'it'
    }

    return (
        <>
            <Grid container className={`${useBackgroundStyle.root} ${useCalendarContainerStyle.root}`} >
                <Grid item xs={12} className={`${useTitleBarStyle.root}`}>
                    <Typography variant={'h3'} className={useTitleTextStyle.root}>
                        {t('availability')}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '1em' }}>
                    <Calendar
                        locale={getSelectedLanguage()}
                        onChange={(value: any) => {
                            onChange(value as Date[]);
                        }}
                        defaultView={'month'}
                        maxDetail={'month'}
                        minDetail={'year'}
                        returnValue={'range'}
                        selectRange={true}
                        tileDisabled={({date}) => disableRangeOfDates(date)}
                        tileClassName={useCalendarTileStyle.root}
                    ></Calendar>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {
                        !loadingPreorder ? (
                            <Button
                                onClick={() => {
                                    if (value[0] == null || value[1] == null) {
                                        toast.error(t('selectValidRange'), {
                                            duration: duration
                                        })
                                    } else {
                                        setAskForConfirmation(true)
                                    }
                                }}
                                className={useButtonStyle.root}
                            >{t('preorder')}</Button>
                        ) : (
                            <CircularProgress />
                        )
                    }
                </Grid>
            </Grid>
            <Dialog
                style={{ textAlign: 'center' }}
                open={askForConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('confirmPreorderDates')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('pendingPreorderDialogContent')}
                        <br />
                        <b>{t('pendingPreorderDialogContentSubtitle')}</b>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setAskForConfirmation(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleClickPreorder} color="primary" autoFocus>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}