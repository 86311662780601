import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ExchangeCard from "./ExchangeCard";
import ExchangeConfirmDialog from "./ExchangeConfirmDialog";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  exchangePaginationReset,
  getExchangeList,
} from "../../../store/slices/exchangeSlice";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { useTranslation } from "react-i18next";

import config from "../../../config";
import {
  getExchangeNumberFromMetadata
} from "../../../api/exchangeAPI";
import { logger } from "../../../utilities/logger/logger";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    flexGrow: 1,
  },
  filterGroup: {
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    height: "4.5vh",
    "&.Mui-selected, &.Mui-selected:hover": {
      background: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  filterBar: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginLeft: theme.spacing(1),
    height: "2.5vw",
  },
  exchangesStatusSelector: {
    color: theme.palette.primary.main,
    border: "0.5px solid " + theme.palette.primary.main,
    height: "4.5vh",
    minHeight: "4.5vh",
  },
  expiredMessage: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  container: {
    justifyContent: "center",
  },
  typo: {
    textAlign: "center",
  },
}));

type ExchangesStatusSelectorProps = {
  onStatusChange: (value: number) => void;
  exchangeLoading: boolean;
};

const ExchangesStatusSelector = (props: ExchangesStatusSelectorProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation("ExchangesList");

  const [status, setStatus] = useState(config.exchangeStatus.RUNNING);

  const handleChange = (event: any) => {
    setStatus(event.target.value);
    props.onStatusChange(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      disabled={props.exchangeLoading}
    >
      <Select
        value={status}
        onChange={handleChange}
        className={classes.exchangesStatusSelector}
      >
        <MenuItem value={0}>{t("onGoing")}</MenuItem>
        <MenuItem value={1}>{t("ended")}</MenuItem>
      </Select>
    </FormControl>
  );
};

const ExchangesList = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { t } = useTranslation("ExchangesList");

  const dispatch = useAppDispatch();
  const exchangesList: any[] = useAppSelector(
    (state) => state.exchange.exchanges
  );
  const expiredExchangesList = useAppSelector(
    (state) => state.exchange.expiredExchanges
  );
  const exchangeOperation = useAppSelector(
    (state) => state.exchange.onGoingOperations
  );
  const loading = useAppSelector((state) => state.exchange.loading);
  const currentProfile = useAppSelector((state) => state.user.currentProfile);

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [status, setStatus] = useState(config.exchangeStatus.RUNNING);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState("accept");
  const [exchangeConfirmDialog, setExchangeConfirmDialog] = useState(null);
  const [isShowingExpiredExchanges, setIsShowingExpiredExchanges] =
    useState(false);
  const [showingList, setShowingList] = useState<any>([]);
  const [cardExpanded, setCardExpanded] = useState("0");
  const [index, setIndex] = useState<number>(0);
  const [amount, setAmount] = useState<number>(12);
  const [maxIndex, setMaxIndex] = useState<number>(0);
  const [expectedSize, setExpectedSize] = useState<number>(0);
  const [expectedExpiredSize, setExpectedExpiredSize] = useState<number>(0);
  const [maxPossessedIndex, setMaxPossessedIndex] = useState<number>(0);
  const [allowReload, setAllowReload] = useState(false);

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (allowReload) {
      if (!loading) {
        if (isShowingExpiredExchanges) setShowingList(expiredExchangesList);
        else setShowingList(exchangesList);
      }
    }
    console.log("exchangeList", exchangesList);
  }, [exchangesList, expiredExchangesList, loading]);

  useEffect(() => {
    if (allowReload) {
      const onlyOwned = selectedFilter === 1;
      dispatch(exchangePaginationReset());
      //dispatch(exchangesPagination(status, onlyOwned, 0, amount, setIndex));
      //setIndex(amount);
      dispatch(getExchangeList(status, onlyOwned, 0, amount));
      setIndex(1);
    }
  }, [currentProfile]);

  useEffect(() => {
    if (allowReload) {
      if (!loading) {
        setShowingList([]);
        dispatch(exchangePaginationReset());
        const onlyOwned = selectedFilter === 1;
        //dispatch(exchangesPagination(status, onlyOwned, 0, amount, setIndex));
        //setIndex(amount);
        dispatch(getExchangeList(status, onlyOwned, 0, amount));
        setIndex(1);
      }
    }
  }, [exchangeOperation]);

  useEffect(() => {
    async function numberOfExchange() {
      if (currentProfile !== undefined && currentProfile !== null) {
        const currentProfileWallet =
          currentProfile.additional_properties?.commonshoodWallet;
        if (currentProfileWallet !== undefined) {
          //let nOfExc = await getNumberOfExchange(web3, currentProfileWallet);
          //setMaxIndex(nOfExc);
          //setExpectedSize(nOfExc);

          const nOfExc = await getExchangeNumberFromMetadata(
            currentProfileWallet,
            status,
            false
          );
          setExpectedSize(nOfExc);
          setMaxIndex(nOfExc);
        }
      }
    }

    async function numberOfPossessedExchange() {
      if (currentProfile !== undefined && currentProfile !== null) {
        const currentProfileWallet =
          currentProfile.additional_properties?.commonshoodWallet;
        if (currentProfileWallet !== undefined) {

          const nOfExc = await getExchangeNumberFromMetadata(
            currentProfileWallet,
            status,
            true
          );
          setExpectedExpiredSize(nOfExc);
          setMaxPossessedIndex(nOfExc);
        }
      }
    }

    if (!allowReload) {
      setShowingList([]);
      dispatch(exchangePaginationReset());
      //dispatch(exchangesPagination(status, false, 0, amount, setIndex));
      //setIndex(amount);
      dispatch(getExchangeList(status, false, 0, amount));
      setIndex(1);
      numberOfExchange();
      numberOfPossessedExchange();
      setAllowReload(true);
    }
  }, []);

  const handleChangeFilter = (event: object | null, selectedFilter: any) => {
    if (selectedFilter !== null) {
      setIndex(0);
      setSelectedFilter(selectedFilter);
      const onlyOwned = selectedFilter === 1;
      changeShowingExchanges(onlyOwned, status);
    }
  };

  const handleExchangesStatusSelection = (status: number) => {
    setStatus(status);
    const onlyOwned = selectedFilter === 1;
    changeShowingExchanges(onlyOwned, status);
  };

  const changeShowingExchanges = (onlyOwned: boolean, status: number) => {
    setShowingList([]);
    dispatch(exchangePaginationReset());
    setIsShowingExpiredExchanges(false);
    //dispatch(exchangesPagination(status, onlyOwned, 0, amount, setIndex));
    //setIndex(amount);
    dispatch(getExchangeList(status, onlyOwned, 0, amount));
    setIndex(1);
  };

  const handleOpenConfirmDialog = (exchange: any, action: string) => {
    setExchangeConfirmDialog(exchange);
    setConfirmDialogAction(action);
    setIsConfirmDialogOpen(true);
  };

  const handleShowExpiredExchanges = () => {
    setIsShowingExpiredExchanges(true);
    logger.info(isShowingExpiredExchanges);
    setSelectedFilter(-1);
    setShowingList(expiredExchangesList);
  };

  window.onscroll = () => {
    if (status !== config.exchangeStatus.COMPLETED) {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1
      ) {
        const onlyOwned = selectedFilter === 1;
        if (!loading) {
          if (!onlyOwned) {
            //if (expectedSize - expiredExchangesList.length > index * 2) {
            if (expectedSize > index * amount) {
              /*dispatch(
                exchangesPagination(status, onlyOwned, index, amount, setIndex)
              );*/
              //setIndex(index + amount);
              dispatch(getExchangeList(status, onlyOwned, index, amount));
              setIndex(index + 1);
            }
          } else {
            //if (expectedExpiredSize - expiredExchangesList.length > index * 2) {
            if (expectedExpiredSize > index * amount) {
              /*dispatch(
                exchangesPagination(status, onlyOwned, index, amount, setIndex)
              );*/
              //setIndex(index + amount);
              dispatch(getExchangeList(status, onlyOwned, index, amount));
              setIndex(index + 1);
            }
          }
        }
      }
    }
  };

  const exchanges = () => {
    if (showingList.length === 0 && !loading) {
      return (
        <Grid container item justifyContent="center">
          <Typography>{t("emptyList")}</Typography>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          xs={12}
          alignItems="flex-start"
          justifyContent={isSm ? "center" : "flex-start"}
          className={classes.grid}
          spacing={2}
        >
          {showingList.map((exchange: any) => (
            <ExchangeCard
              key={exchange.address}
              exchange={exchange}
              onOperation={handleOpenConfirmDialog}
              expanded={cardExpanded}
              onChangeExpanded={(exchange) => setCardExpanded(exchange)}
            />
          ))}
        </Grid>
      );
    }
  };
  const expiredExchangesBtn = () => {
    if (expiredExchangesList.length > 0 && !isShowingExpiredExchanges) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowExpiredExchanges}
        >
          {" "}
          {t("expiredOffersMessage")}{" "}
        </Button>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid container className={classes.filterBar} spacing={3}>
          <Grid item container xs={12} md={6} justifyContent="flex-start">
            <ToggleButtonGroup
              value={selectedFilter}
              exclusive
              onChange={handleChangeFilter}
              aria-label="text alignment"
            >
              <ToggleButton
                disabled={loading}
                value={0}
                className={classes.filterGroup}
              >
                {t("all")}
              </ToggleButton>
              <ToggleButton
                disabled={loading}
                value={1}
                className={classes.filterGroup}
              >
                {t("myExchanges")}
              </ToggleButton>
            </ToggleButtonGroup>
            <ExchangesStatusSelector
              onStatusChange={handleExchangesStatusSelection}
              exchangeLoading={loading}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            className={classes.expiredMessage}
          >
            {expiredExchangesBtn()}
          </Grid>
        </Grid>
        {exchanges()}
        {exchangeConfirmDialog && isConfirmDialogOpen && (
          <ExchangeConfirmDialog
            isOpen={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
            exchange={exchangeConfirmDialog}
            action={confirmDialogAction}
          />
        )}
      </Grid>
      {loading && (
        <Box className={classes.typo}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ExchangesList;
