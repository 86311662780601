import { Grid, List } from "@material-ui/core";
import { collectionList, collectionProps } from "../../../../types/nft.types";
import CollectionListItem from "./CollectionListItem/CollectionListItem";

const CollectionList = (props: collectionList) => {
  const collectionList = props.collectionList;
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <List dense style={{ marginBottom: "50px" }}>
            {collectionList.map((c: collectionProps, i: number) => (
              <Grid container alignItems="center" key={i}>
                <Grid item xs={12}>
                  <CollectionListItem {...c} />
                </Grid>
              </Grid>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionList;
