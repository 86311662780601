import { CardMedia } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import config from "../../../../config";

export const NftImageInList = ({ uri }: {
    uri: string
}) => {
    const [metadata, setMetadata] = useState<any>(null);
    const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (uri != null && uri.includes(config.network.ipfs.default_url))
                setMetadata(await getFromIpfs(uri));
            else setMetadata(await getJsonFromResource(uri));
        })()
    }, [uri])

    useEffect(() => {
        if (metadata !== null) {
            if (metadata.image.includes(config.network.ipfs.default_url)) {
                setMetadata({
                    ...metadata,
                    image: metadata.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!metadata.image.includes(config.network.ipfs.default_url)) {
                let tempImage = new Image();
                tempImage.onload = () => {
                    setIsImageLoad(true);
                };
                tempImage.src = metadata.image;
            }
        }

    }, [metadata]);

    return <>
        {
            (isImageLoad) ? (
                <CardMedia
                    component="img"
                    style={{ maxHeight: '280px' }}
                    height="280"                    
                    src={metadata.image}
                >
                </CardMedia>
            ) : (
                <Skeleton
                    variant="rect" width={'100%'} height={250}
                />
            )
        }

    </>
}