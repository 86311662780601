import { CardMedia, makeStyles } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../../store/slices/nftSlice";
import config from "../../../../config";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import Skeleton from "@material-ui/lab/Skeleton";

const style = makeStyles({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        margin: '0.5em',
        borderRadius: '1em'
    }
});

export const ItemCardMedia = ({ itemID, collectionSymbol }: {
    itemID: number,
    collectionSymbol: string,
}) => {
    const classes = style();
    const [uri, setUri] = useState<string | null>(null);
    const [metadata, setMetadata] = useState<any>(null);
    const [loading, setloading] = useState<boolean>(false);

    const collectionsAndSymbols = useAppSelector((state) => state.nft.collectionsBySymbol);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const collectionAndSymbol = collectionsAndSymbols.filter((collection: any) => {
            if (collection.symbol === collectionSymbol) {
                return collection;
            }
        })[0];
        if (collectionAndSymbol == null) {
            (async () => { dispatch(getCollectionFromSymbol(collectionSymbol)) })()
        } else {
            (async () => {
                const uri = await dispatch(getURIByIDSlice(itemID, collectionAndSymbol.address))
                if (uri != null)
                    setUri(uri);
            })()
        }

    }, [collectionsAndSymbols])

    useEffect(() => {
        (async () => {
            if (uri != null) {
                if (uri.includes(config.network.ipfs.default_url))
                    setMetadata(await getFromIpfs(uri));
                else setMetadata(await getJsonFromResource(uri));
            }
        })()
    }, [uri])

    useEffect(() => {
        if (metadata !== null) {
            if (metadata.image.includes(config.network.ipfs.default_url)) {
                setMetadata({
                    ...metadata,
                    image: metadata.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!metadata.image.includes(config.network.ipfs.default_url)) {
                let tempImage = new Image();
                tempImage.onload = () => {
                    setloading(true);
                };
                tempImage.src = metadata.image;
            }
        }

    }, [metadata]);

    return (
        <>
            {
                metadata == null ? <Skeleton variant="rect" width={'100%'} height={'200px'}  /> :
                    <CardMedia
                        className={classes.media}
                        title={`Item image`}
                        image={metadata.image}
                    />
            }
        </>


    )
}


