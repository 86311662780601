import { Button, Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../store/hooks"
import { getItemByID } from "../../../../store/slices/libOfThingsSlice"
import { Preorder } from "../../../../types/calendar.type"
import { Item } from "../../../../types/libofthings.type"
import { duration } from "../../../../utilities/costants.notifications"
import { DialogWithInfo } from "../../BasicComponents/DialogWithInfo"
import { ItemImage } from "../../MyInventory/MyItems/ItemImage"
import { actionButtonStyle } from "../../Styles/Buttons.style"
import { contentStyle, contentWrapperStyle, itemNameStyle } from "../../Styles/Preorders.style"

export const PreorderBody = ({ preorder, removeFromList, goToItemPage }: {
    preorder: Preorder,
    removeFromList: () => void,
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const dispatch = useAppDispatch();
    const useContentStyle = contentStyle();
    const useItemNameStyle = itemNameStyle();
    const useContentWrapperStyle = contentWrapperStyle();
    const useActionButtonStyle = actionButtonStyle();
    const [item, setItem] = useState<Item>();
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        (async () => {
            const itemFound = await dispatch(getItemByID(preorder.itemID));
            setItem(itemFound);
        })()
    }, [])

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1" className={useItemNameStyle.root}>
                        {
                            item != null ? (
                                <b>{item.name}</b>
                            ) : (
                                <Skeleton />
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    {
                        <>
                            {
                                item != null ? (
                                    <ItemImage
                                        id={item.idInCollection}
                                        collectionSymbol={item.collectionSymbol}
                                        option={undefined}
                                    />
                                ) : (
                                    <Skeleton />
                                )
                            }
                        </>
                    }
                </Grid>
                <Grid item xs={12} md={8} className={useContentStyle.root}>
                    <div className={useContentWrapperStyle.root}>
                        <Typography variant={'body1'}>
                            <Button className={useActionButtonStyle.root} onClick={() => {
                                if (item != null) {
                                    goToItemPage(item.collectionSymbol, item.itemID)
                                } else {
                                    toast.loading(t('itemNotReady'), {
                                        duration: duration
                                    });
                                }
                            }}>
                                {t('itemPage')}
                            </Button>
                        </Typography>
                        {
                            item != null ? (
                                <DialogWithInfo
                                    item={item}
                                />
                            ) : (
                                <Skeleton />
                            )
                        }
                    </div>

                </Grid>
            </Grid>
        </>
    )
}