import { Box, Card, CardMedia, Grid, Paper, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { useEffect, useState } from "react";
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types";

export const ConfirmationDialog = ({ name, values, classes, attributes, isForItems }: {
    name: string,
    isForItems: boolean,
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    classes: ClassNameMap<"fieldContainer" | "buttons" | "next" | "collection" | "media" | "mediaConfirm">,
    attributes: {
        trait_type: string;
        value: string;
        required?: boolean;
    }[] | undefined
}) => {
    const [displayImage, setDisplayImage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (
            values.getFromCollection ||
            (
                (values as FormInitialValuesForItems).usingExistingNft != null &&
                (values as FormInitialValuesForItems).usingExistingNft
            )
        ) {
            setDisplayImage(values.imgFile);
        } else {
            console.log(values.imgFile);
            setDisplayImage(values.imgFile);
        }
    }, [values]);

    return (
        <>
            {
                (name === 'imgFile' && values.imgFile != undefined) && (
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs>
                            <Card className={classes.mediaConfirm} elevation={1}>
                                <CardMedia
                                    component="img"
                                    image={displayImage}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                )
            }
            {
                (name === 'nftDescription' && values.nftDescription !== "") && (
                    <Typography variant="body2">
                        {values.nftDescription}
                    </Typography>
                )
            }
            {
                (name === 'collection') && (
                    <Typography variant="body2">
                        {values.collection.name}
                    </Typography>
                )
            }
            {
                (name === 'nftName' && values.nftName !== "") && (
                    <Typography variant="body2">
                        {values.nftName}
                    </Typography>
                )
            }
            {
                (name === 'numbersNft' && values.numbersNft !== "") && (
                    <Typography variant="body2">
                        {values.numbersNft}
                    </Typography>
                )
            }
            {
                isForItems && (
                    <>
                        {
                            name === 'caution' && (
                                <Typography variant="body2">
                                    {(values as FormInitialValuesForItems).caution}
                                </Typography>
                            )
                        }
                        {
                            name === 'dailyPrice' && (
                                <Typography variant="body2">
                                    {(values as FormInitialValuesForItems).dailyPrice}
                                </Typography>
                            )
                        }
                        {
                            name === 'tokenAcceptedAsPayment' && (
                                <Typography variant="body2">
                                    {(values as FormInitialValuesForItems).tokenAcceptedAsPayment}
                                </Typography>
                            )
                        }
                        {
                            name === 'category' && (
                                <Typography variant="body2">
                                    {(values as FormInitialValuesForItems).category}
                                </Typography>
                            )
                        }
                    </>
                )
            }


            {
                (
                    attributes &&
                    attributes.length > 0 &&
                    name === "attributes" &&
                    values.attributes[0].value !== ""
                ) && (
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        wrap="wrap"
                        style={{
                            margin: 0,
                            width: "100%",
                        }}
                    >
                        {
                            values.attributes.map((attribute, i) => {
                                if (attribute.value !== '') {
                                    return <Grid item xs="auto" key={i}>
                                        <Box clone py={2} px={3}>
                                            <Paper variant="outlined">
                                                <Typography align="center" color="primary">
                                                    {attribute.trait_type}
                                                </Typography>
                                                <Typography variant="body1" align="center">
                                                    {attribute.value}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </Grid>
                )
            }
        </>
    )
}