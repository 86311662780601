import { makeStyles } from "@material-ui/core";

export const basicCommonsHoodButtonStyle = makeStyles({
    root: {
        whiteSpace: 'nowrap',
        '&:hover': {
            background: '#179F90'
        },
        '&:click': {
            background: '#1BD1BB'
        }
    }
})

export const buttonStyle = makeStyles({
    root: {
        marginTop: '2em',
        marginBottom: '2em',
        background: '#0B6258',
        whiteSpace: 'nowrap',
        borderRadius: '100px',
        color: 'white',
        padding: '0.8em',
        paddingLeft: '5em',
        paddingRight: '5em',
        '&:hover': {
            background: '#179F90'
        },
        '&:click': {
            background: '#1BD1BB'
        }
    }
})


export const searchButton = makeStyles({
    root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '15px',
        letterSpacing: '-0.02em',
        color: 'white',
        background: 'black',
        borderRadius: '20px',
        height: '31px'
    }
})

export const actionButtonStyle = makeStyles({
    root: {
        background: '#FFD600',
        height: '28px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        marginRight: '10px',
        '&:hover': {
            background: '#FFA800'
        },
        '@media (max-width: 700px)': {
            marginTop: '10px',
            width: '90%'
        }
    }
})

export const denyStyle = makeStyles({
    root: {
        background: '#FF0606',
        marginLeft: '10px',
        height: '28px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        '&:hover': {
            background: '#F57328'
        },
    }
})

export const acceptStyle = makeStyles({
    root: {
        marginLeft: '10px',
        height: '28px',
        background: '#38E54D',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        '&:hover': {
            background: '#9CFF2E'
        },
        '&:click': {
            background: '#FDFF00'
        }
    }
})


export const baseButton = makeStyles({
    root: {
        borderRadius: '10px',
        width: '90%',
        position: 'relative',
        marginBottom: '1em',
        marginRight: '20px',
        marginLeft: '20px',
        color: 'white',
        textTransform: 'capitalize'
    }
})


export const redButton = makeStyles({
    root: {
        background: '#D2001A',
        '&:hover': {
            background: '#E14D2A'
        },
        '&:click': {
            background: '#FD841F'
        }
    }
})

export const greenButton = makeStyles({
    root: {
        background: '#0B6258',
        '&:hover': {
            background: '#179F90'
        },
        '&:click': {
            background: '#1BD1BB'
        }
    },
})

export const goToItemPageButtonStyle = makeStyles({
    root: {
        background: '#FFD600',
        marginLeft: '10px',
        height: '28px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        '&:hover': {
            background: '#F57328'
        },
    }
})

export const endRevisionButtonStyle = makeStyles({
    root: {
        background: '#00E132',
        border: '0.5px solid #000000',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
        marginRight: '1em',
        width: '100px'
    }
})
export const endRevisioneButtonCancelStyle = makeStyles({
    root: {
        background: '#FF0606',
        border: '0.5px solid #000000',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
        width: '100px'
    }
})

export const selectStyle = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.7)',
        border: '0.2px solid #000000',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: ' 3px',
        width: '100px'
    }
})