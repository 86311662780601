import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks"
import { useEffect, useState } from "react";
import { getPreordersUnderReview } from "../../../../store/slices/calendarSlice";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { PaginationContainer } from "../../../../components/UI/Pagination/PaginationContainer";
import { PreorderActiveInList } from "../PreorderInList/PreorderActiveInList";

export const ListOfPreordersUnderReview = ({ goToItemPage }: {
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const preorders = useAppSelector(state => state.calendar.preordersUnderReview);
    const numberOfUnderReviewPreorders = useAppSelector(state => state.calendar.numberOfPreordersUnderReview);
    const preordersPerPage = useAppSelector(state => state.calendar.preordersPerPage);
    const loading = useAppSelector(state => state.calendar.loading);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    
    useEffect(() => {
        dispatch(getPreordersUnderReview(false, page))
    }, [])
    
    const handleChange = async (newPage: number) => {
        setPage(newPage);
        dispatch(getPreordersUnderReview(false, newPage));
    }

    return(
        <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
            {
                (!loading) ? (
                    preorders.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <PreorderActiveInList
                                preorder={p}
                                goToItemPage={goToItemPage}
                            />
                        </Grid>
                    })
                ) : (
                    <Skeleton
                        style={{
                            position: 'relative',
                            top: '1em',
                        }}
                        variant="rect"
                        width={'100%'}
                        height={'400px'}
                    />
                )
            }
            <Grid item xs={12}>
                <PaginationContainer
                    pageCount={numberOfUnderReviewPreorders / preordersPerPage}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}