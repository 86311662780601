import React, { useEffect, useState } from "react";

import {
  Box,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";

import NftCard from "./NftCard/NftCard";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom";
import { Add, NavigateBefore, Refresh } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  collectionGetList,
  collectionGetListReset,
  collectionResetSelected,
  nftGetListFromCollection,
  nftGetListReset,
  nftIsSendReset,
} from "../../../store/slices/nftSlice";
import { collectionProps, nftList, nftProps } from "../../../types/nft.types";
import { marketplaceIsCreatedReset } from "../../../store/slices/marketplaceSlice";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    maxHeight: 437,
    textAlign: "center",
  },
  styleForFlexboxWithoutScrollbar: { margin: 0, width: "100%" },
});

const NftList = (props: nftList) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["NftCreateForm", "Common"]);
  const { nftList } = props;
  const classes = useStyles();
  const history = useHistory();

  const collectionSelected: collectionProps | null = useAppSelector(
    (state) => state.nft.collectionSelected
  );
  const page = useAppSelector((state) => state.nft.page);
  const amount = useAppSelector((state) => state.nft.amount);
  const loading = useAppSelector((state) => state.nft.paginationLoading);
  const [nftListSorted, setNftListSorted] = useState<nftProps[]>([]);
  const [sortBy, setSortBy] = useState("tokenIdAscending");

  useEffect(() => {
    setNftListSorted(nftList);
  }, [nftList]);

  useEffect(() => {
    if (collectionSelected) {
      dispatch(nftGetListReset());
      dispatch(nftGetListFromCollection(collectionSelected, page, sortBy));
    }
  }, [sortBy]);

  let renderNFTs: any;
  if (nftListSorted && nftListSorted.length > 0) {
    renderNFTs = nftListSorted.slice().map((n, i) => (
      <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
        <NftCard {...n} />
      </Grid>
    ));
  }

  const handleBack = () => {
    dispatch(collectionResetSelected());
    dispatch(collectionGetListReset());
    dispatch(collectionGetList());
  };
  const handleRefresh = () => {
    if (collectionSelected) {
      dispatch(nftGetListReset());
      dispatch(nftGetListFromCollection(collectionSelected, page, sortBy));
    }
  };

  const handleCreate = () => {
    history.push("/nftCreate");
  };

  window.onscroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 1
    ) {
      if (!loading && collectionSelected && page * 10 < amount) {
        dispatch(
          nftGetListFromCollection(collectionSelected, page + 1, sortBy)
        );
      }
    }
  };

  return (
    <>
      <Box my={2}>
        <Grid
          container
          spacing={1}
          alignContent="center"
          alignItems="center"
          wrap="wrap"
          className={classes.styleForFlexboxWithoutScrollbar}
        >
          <Grid
            container
            item
            xs={12}
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid container spacing={2} item xs>
              <Grid item xs="auto">
                <ButtonGroup variant="text">
                  <IconButton onClick={handleBack} style={{ borderRadius: 1 }}>
                    <NavigateBefore />
                  </IconButton>
                  <IconButton
                    onClick={handleRefresh}
                    style={{ borderRadius: 1 }}
                  >
                    <Refresh />
                  </IconButton>
                  {nftList &&
                    (nftList.length === 0 ||
                      (nftList.length > 0 && nftList[0].isCreatedByMe)) && (
                      <IconButton
                        onClick={handleCreate}
                        style={{ borderRadius: 1 }}
                      >
                        <Add></Add>
                      </IconButton>
                    )}
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs="auto">
              <FormControl variant="outlined">
                <InputLabel id="SortByLabel">{t("sortLabel")}</InputLabel>
                <Select
                  labelId="SortByLabel"
                  label={t("sortLabel")}
                  value={sortBy}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setSortBy(e.target.value);
                  }}
                >
                  <MenuItem value="tokenIdAscending">
                    {t("tokenIdAscending")}
                  </MenuItem>
                  <MenuItem value="tokenIdDescending">
                    {t("tokenIdDescending")}
                  </MenuItem>
                  <MenuItem value="alphaAscending">
                    {t("alphaAscending")}
                  </MenuItem>
                  <MenuItem value="alphaDescending">
                    {t("alphaDescending")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {renderNFTs}
        </Grid>
      </Box>
      {loading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default NftList;
