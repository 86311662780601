import config from "../config";
import {logger} from "../utilities/logger/logger";
import axios from "../utilities/backend/axios-metadata";
import {ethers, utils, Wallet} from "ethers";


const getTokenInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_TMPLT_ABI)

    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}

const getExchangeFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.EXCHNG_FCTRY_ABI)
    return new ethers.Contract(
        config.smartContracts.EXCHNG_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    );
}

const getExchangeTemplateInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_EXCHNG_ABI)
    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}


export const createExchange = async (
    ethersInstance: Wallet,
    ownerAddress: string,
    coinsOfferedAddr: string[],
    amountsOffered: number[],
    coinsRequiredAddr: string[],
    amountsRequired: number[],
    repeatings: number,
    expirationDate: number
) => {
    try {
        logger.info("[createExchange] start");
        for (let i = 0; i < coinsOfferedAddr.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsOfferedAddr[i])
            logger.info(coinInstance)
            let approve = await coinInstance.approve(
                config.smartContracts.EXCHNG_FCTRY_ADDR,
                amountsOffered[i] * repeatings
            )
            await approve.wait()
        }

        const ExchangeFactoryInstance = getExchangeFactoryInstance(ethersInstance)

        let creationResponse = await ExchangeFactoryInstance
            .createExchange(
                coinsOfferedAddr,
                coinsRequiredAddr,
                amountsOffered,
                amountsRequired,
                repeatings,
                expirationDate
            )

        creationResponse = await creationResponse.wait()
        console.log(creationResponse);
    } catch (error) {
        for (let i = 0; i < coinsOfferedAddr.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsOfferedAddr[i])

            let resetApprove = await coinInstance
                .approve(config.smartContracts.EXCHNG_FCTRY_ADDR, 0)
            await resetApprove.wait()
        }
        throw error;
    }
};


export const acceptExchange = async (
    ethersInstance: Wallet,
    accountAddress: string,
    exchangeAddress: string,
    coinsRequired: string[],
    amountsRequired: number[],
    repeatings: number
) => {
    try {
        for (let i = 0; i < coinsRequired.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsRequired[i])

            let approve = await coinInstance.approve(exchangeAddress, amountsRequired[i] * repeatings)
            approve = await approve.wait()
        }

        const exchangeInstance = getExchangeTemplateInstance(ethersInstance, exchangeAddress)

        let accept = await exchangeInstance.accceptExchange(repeatings)
        accept = await accept.wait()
        return true;
    } catch (error) {
        for (let i = 0; i < coinsRequired.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsRequired[i])

            let resetApprove = await coinInstance.approve(exchangeAddress, 0)
            resetApprove = await resetApprove.wait()

        }
        logger.error("[acceptExchange]", error);
        return false;
    }
};

export const cancelExchange = async (
    ethersInstance: Wallet,
    ownerAddress: string,
    exchangeAddress: string
) => {
    try {
        const exchangeInstance = getExchangeTemplateInstance(ethersInstance, exchangeAddress)

        let cancel = await exchangeInstance.cancelExchange()
        cancel = await cancel.wait()
        logger.info(cancel);
        return true;
    } catch (error) {
        logger.error("[cancelExchange]", error);
        return false;
    }
};

export const renewExchange = async (
    ethersInstance: Wallet,
    ownerAddress: string,
    exchangeAddress: string,
    expiration: number
) => {
    try {
        const exchangeInstance = getExchangeTemplateInstance(ethersInstance, exchangeAddress)

        let renew = await exchangeInstance.renewExchange(expiration)
        renew = await renew.wait()
        logger.info(renew);
        return true;
    } catch (error) {
        logger.error("[renewExchange]", error);
        return false;
    }
};

export const refillExchange = async (
    ethersInstance: Wallet,
    ownerAddress: string,
    exchangeAddress: string,
    coinsOfferedAddr: string[],
    amountsOffered: number[],
    repeatings: number
) => {
    try {
        for (let i = 0; i < coinsOfferedAddr.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsOfferedAddr[i])

            let approve = await coinInstance.approve(exchangeAddress, amountsOffered[i] * repeatings)
            approve = await approve.wait()

        }

        const exchangeInstance = getExchangeTemplateInstance(ethersInstance, exchangeAddress)

        let refill = await exchangeInstance.refillExchange(repeatings)
        refill = await refill.wait()
        logger.info(refill);
        return true;
    } catch (error) {
        for (let i = 0; i < coinsOfferedAddr.length; i++) {
            const coinInstance = getTokenInstance(ethersInstance, coinsOfferedAddr[i])
            let approve = await coinInstance.approve(exchangeAddress, 0)
            approve = await approve.wait()
        }
        logger.error("[acceptExchange]", error);
        return false;
    }
};

export const getExchangeNumberFromMetadata = async (
    user: string,
    status: number,
    onlyOwned: boolean
) => {
    const url = "/exchange/getExchangeNumber";
    const params = new URLSearchParams({
        user: user + "",
        status: status + "",
        onlyOwned: onlyOwned + "",
    });
    const temp = await axios.get(url, {params});
    //TODO check ok
    console.log("exchange trovati", temp.data.exchanges);
    return temp.data.exchanges;
};

export const getExchanges = async (
    user: string,
    status: number,
    onlyOwned: boolean,
    index: number,
    amount: number
) => {
    const url = "/exchange/getExchange";
    const params = new URLSearchParams({
        user: user,
        status: status + "",
        page: index + 1 + "",
        onlyOwned: onlyOwned + "",
        amount: amount + "",
    });
    const temp = await axios.get(url, {params});
    return temp.data.exchanges;
};
