//add my notification type (how I'm going to call it in the code of the provider)
const notificationKeys = {
    WALLET_READY: 'WALLET_READY',

    COIN_CREATED: 'COIN_CREATED',
    COIN_SENT: 'COIN_SENT',
    COIN_RECEIVED: 'COIN_RECEIVED',
    COIN_MINTED: 'COIN_MINTED',

    DAO_CREATED: 'DAO_CREATED',

    DAO_MEMBER_JOINED: 'DAO_MEMBER_JOINED',
    DAO_MEMBER_KICKED: 'DAO_MEMBER_KICKED',
    DAO_MEMBER_DEMOTED: 'DAO_MEMBER_DEMOTED',
    DAO_MEMBER_PROMOTED: 'DAO_MEMBER_PROMOTED',

    CROWDSALE_CREATED: 'CROWDSALE_CREATED',
    CROWDSALE_UNLOCKED: 'CROWDSALE_UNLOCKED',
    CROWDSALE_JOINED: 'CROWDSALE_JOINED',
    CROWDSALE_REFUNDTO: 'CROWDSALE_REFUNDTO',
    CROWDSALE_STOPPED: 'CROWDSALE_STOPPED',
    CROWDSALE_CAPREACHED: 'CROWDSALE_CAPREACHED',


    EXCHANGE_ADDED: 'EXCHANGE_ADDED',
    EXCHANGE_CANCELLED: 'EXCHANGE_CANCELLED',
    EXCHANGE_COMPLETED: 'EXCHANGE_COMPLETED',
    EXCHANGE_RENEWED: 'EXCHANGE_RENEWED',

    COLLECTION_CREATED: 'COLLECTION_CREATED',
    NFT_ADDED: 'NFT_ADDED',
    NFT_REMOVED: 'NFT_REMOVED',

    MARKETPLACE_ADDED: 'MARKETPLACE_ADDED',
    MARKETPLACE_CANCELLED: 'MARKETPLACE_CANCELLED',
    MARKETPLACE_COMPLETED: 'MARKETPLACE_COMPLETED',

    PREORDER_CREATED: 'PREORDER_CREATED',
    PREORDER_ACCEPTED: 'PREORDER_ACCEPTED',
    PREORDER_DENIED: 'PREORDER_DENIED',
    PREORDER_STARTED: 'PREORDER_STARTED',
    PREORDER_CANCELLED: 'PREORDER_CANCELLED',
    PREORDER_ENDED: 'PREORDER_ENDED',
    PREORDER_REVIEW_ENDED: 'PREORDER_REVIEW_ENDED',
};

export default notificationKeys;
