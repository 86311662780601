import React from "react";

//Custom components
//import AppIcon from '../../AppIcon/AppIcon'
//Style injections
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import slideModalStyle from "./SlideModalStyle";

//Material-UI components
import {AppBar, Avatar, CircularProgress, Dialog, IconButton, Toolbar, Typography} from "@material-ui/core";

//Materia Icon
import {Close} from "@material-ui/icons"
import {useAppSelector} from "../../../../store/hooks";
type SlideModalPropsType = {
    children: any,
    fullscreen?: boolean,
    open: boolean,
    handleClose: () => void,
    title?: string | JSX.Element,
    transition?: any,
    icon?: string,
    position?: boolean
}

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    appBarAbsolute: {
        position: "absolute"
    },
    flex: {
        flex: 1
    },
    marginTop: {
        marginTop: theme.mixins.toolbar.minHeight
    },

    mainPadding: { padding: "1em 3em" },
    avatar : {
        marginRight: theme.spacing(1)
    },
    children: {
        paddingTop: 40
    }
}));

const SlideModal = (props: SlideModalPropsType) => {
    const classes = useStyles();

    const {
        children,
        fullscreen,
        open,
        handleClose,
        title,
        transition,
        icon,
        position
    } = props;

    const mapPlacesLoading = useAppSelector(state => state.place.mapPlacesByCoordinatesLoading)

    return (
        <div>
            <Dialog
                fullScreen={ fullscreen != null ? fullscreen : true }
                open={open}
                onClose={handleClose}
                TransitionComponent={transition}
            >

                <AppBar className={position ? classes.appBarAbsolute :classes.appBar}>
                    <Toolbar>
                        {icon ? <Avatar src={icon} className={classes.avatar} /> : null}
                        <Typography variant="subtitle1" color="inherit" className={classes.flex}>
                            {title}
                        </Typography>
                        {mapPlacesLoading && <CircularProgress color={'secondary'}/>}
                        <IconButton
                            onClick={handleClose}
                            aria-label="Close"
                            color="inherit"
                        >
                            {/*<AppIcon icon={{font: 'material', name:'close'}}/>*/}
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {children}

            </Dialog>
        </div>
    );
};

export default SlideModal;
