import React from 'react';
import {Field, Formik} from 'formik';
import {Button, Grid} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {useTranslation} from "react-i18next";
import TextInput from "../../../UI/Form/Input/TextInput";

import * as Yup from "yup";


import {coinMint} from '../../../../store/slices/coinSlice';

type CoinMinterProps = {
    coinType: 'token' | 'goods';
    decimals: number;
    symbol: string;
    onMintSubmit: () => void;
    tokenAddress: string;
}

const CoinMinter = (props: CoinMinterProps) => {
    const {
        coinType,
        decimals,
        onMintSubmit,
        tokenAddress,
        symbol
    } = props;

    const mintLoading = useAppSelector(state => state.coin.mintLoading);
    const dispatch = useAppDispatch();

    const {t} = useTranslation('CoinMint')

    const validationSchema = Yup.object().shape({
        amountToMint: Yup.string()
            .required(t('Common:required'))
            .when('assetType', {
                is: 'token',
                then: Yup.string().matches(/^[0-9]+([.,][0-9][0-9]?)?$/, t('errorInToken')),
            })
            .when('assetType', {
                is: 'goods',
                then: Yup.string().matches(/^\d*$/, t('errorInCoupon')),
            })
    });

    return (
        <>
            <Formik
                initialValues={{
                    amountToMint: coinType === "token" ? "1.00" : "1",
                    assetType: coinType
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    const amount: number = +values.amountToMint;
                    dispatch(coinMint(tokenAddress, symbol, amount, decimals ))
                    onMintSubmit();
                    actions.setSubmitting(false);
                }}
            >
                {
                    (
                        {
                            values,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                        }
                    ) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction='column' justifyContent='center' alignItems='center'
                                      spacing={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="amountToMint"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched['amountToMint']}
                                            component={TextInput}
                                            errMessage={errors['amountToMint']}
                                            value={values.amountToMint}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={mintLoading} color="primary" variant="contained" type="submit">submit</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }
                }
            </Formik>
        </>
    );
};

export default CoinMinter;
