import React, { lazy, Suspense, useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  List,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  checkNftOwner,
  collectionGetList,
  collectionGetListReset,
  nftIsSendReset,
  nftSend,
} from "../../../../store/slices/nftSlice";
import {
  marketplaceCreate,
  marketplaceIsCreatedReset,
} from "../../../../store/slices/marketplaceSlice";
import { logger } from "../../../../utilities/logger/logger";
import { getTokenFromMetadata } from "../../../../api/coinAPI";

const NftSend = lazy(() => import("../NftSend/NftSend"));
const NftSell = lazy(() => import("../NftSell/NftSell"));

const useStyles = makeStyles({
  backdrop: {
    position: "absolute",
    zIndex: 5,
  },
});

type nftCardProp = {
  name: string;
  symbol: string;
  tokenUri: any;
  tokenId: string;
  isTransferable: boolean;
  isCreatedByMe: boolean;
  contractAddress: string;
  handleModalClose: any;
};

export default function NftSelected(props: nftCardProp) {
  const classes = useStyles();
  const { t } = useTranslation(["NftCreateForm", "Common"]);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [attributes, setAttributes] = useState<
    { trait_type: string; value: string }[]
  >([]);
  const [renderAttributes, setRenderAttributes] = useState<
    JSX.Element | JSX.Element[]
  >(<></>);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [modalSendOpen, setModalSendOpen] = useState<boolean>(false);
  const [modalSellOpen, setModalSellOpen] = useState<boolean>(false);
  const nftSended = useAppSelector((state) => state.nft.nftSend);
  const marketplaceCreated = useAppSelector(
    (state) => state.marketplace.marketplaceCreated
  );
  const marketplaceLoading = useAppSelector(
    (state) => state.marketplace.loading
  );
  const nftSendLoading = useAppSelector((state) => state.nft.nftSendLoading);

  let {
    name,
    tokenUri,
    tokenId,
    contractAddress,
    isTransferable,
    isCreatedByMe,
  } = props;
  const ownerCheck = useAppSelector((state) => state.nft.ownerCheck);

  useEffect(() => {
    console.log("ownerCheck: ", ownerCheck);
  }, [ownerCheck]);

  useEffect(() => {
    dispatch(checkNftOwner(contractAddress, tokenId));

    setAttributes(tokenUri.attributes);
    let tempImage = new Image();
    tempImage.onload = () => {
      setIsLoad(true);
    };
    tempImage.src = tokenUri.image;
  }, []);

  useEffect(() => {
    if (nftSended) {
      // dispatch(collectionGetListReset());
      // dispatch(collectionGetList());
      props.handleModalClose();
      dispatch(nftIsSendReset());
    }
  }, [nftSended]);

  useEffect(() => {
    if (marketplaceCreated) {
      // dispatch(collectionGetListReset());
      // dispatch(collectionGetList());
      props.handleModalClose();
      dispatch(marketplaceIsCreatedReset());
    }
  }, [marketplaceCreated]);

  useEffect(() => {
    logger.info("attributes", attributes);
    if (attributes)
      if (attributes.length > 0) {
        setRenderAttributes(
          attributes.map((attribute) => (
            <>
              <Grid item xs="auto">
                <Box clone py={2} px={3}>
                  <Paper variant="outlined">
                    <Typography align="center" color="primary">
                      {attribute.trait_type}
                    </Typography>
                    <Typography variant="body1" align="center">
                      {attribute.value}
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
            </>
          ))
        );
      }
  }, [attributes]);

  const handleModalSendOpen = () => {
    setModalSendOpen(true);
  };
  const handleModalSendClose = (isSended: boolean) => {
    if (isSended) {
      dispatch(collectionGetListReset());
      dispatch(collectionGetList());
    }
    setModalSendOpen(false);
  };

  const handleSend = (contact: any) => {
    dispatch(
      nftSend({
        to: contact.additional_properties.commonshoodWallet,
        nftContract: contractAddress,
        tokenId,
      })
    );
    setModalSendOpen(false);
  };
  const handleModalSellOpen = () => {
    setModalSellOpen(true);
  };
  const handleModalSellClose = (isListed: boolean) => {
    if (isListed) {
      dispatch(collectionGetListReset());
      dispatch(collectionGetList());
    }
    setModalSellOpen(false);
  };
  const handleSell = (coin: any, amount: number) => {
    dispatch(
      marketplaceCreate({
        nftOffered: contractAddress,
        tokenIdOffered: tokenId,
        coinRequired: coin.address,
        amountRequired: amount,
      })
    );
    setModalSellOpen(false);
  };

  return (
    <>
      <Box m={6}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Card elevation={10}>
              <CardActionArea>
                <Backdrop
                  className={classes.backdrop}
                  open={nftSendLoading || marketplaceLoading}
                >
                  <CircularProgress color="primary" />
                </Backdrop>
                {isLoad ? (
                  <CardMedia component="img" image={tokenUri.image} />
                ) : (
                  <Skeleton variant="rect" height={528} width={528} />
                )}
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {tokenUri.name + " #" + tokenId}
                </Typography>
              </Grid>
              {isTransferable ? (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start" mt={3}>
                      <ButtonGroup
                        disabled={nftSendLoading || marketplaceLoading}
                        variant="contained"
                        color="secondary"
                        fullWidth={matches}
                        size="large"
                      >
                        <Button
                          startIcon={<SendIcon />}
                          onClick={() => handleModalSendOpen()}
                          disabled={!ownerCheck}
                        >
                          {t("sendButton")}
                        </Button>
                        <Button
                          startIcon={<AttachMoneyIcon />}
                          onClick={() => handleModalSellOpen()}
                          disabled={!ownerCheck}
                        >
                          {t("sellButton")}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                </>
              ) : isCreatedByMe ? (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-start" mt={3}>
                      <Button
                        disabled={
                          !ownerCheck || nftSendLoading || marketplaceLoading
                        }
                        variant="contained"
                        color="secondary"
                        fullWidth={matches}
                        size="large"
                        startIcon={<SendIcon />}
                        onClick={() => handleModalSendOpen()}
                      >
                        {t("sendButton")}
                      </Button>
                    </Box>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {!isTransferable && isCreatedByMe ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      {t("isTransferableOnlyOnce")}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" color="primary">
                  {t("nftDescription")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{tokenUri.description}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {attributes && attributes.length > 0 && (
            <Grid item xs={12}>
              <Box clone borderBottom={1} pb={1}>
                <Grid container spacing={1}>
                  <Grid item xs="auto">
                    <List color="primary" />
                  </Grid>
                  <Grid item xs>
                    <Box display="flex" justifyContent="flex-start">
                      <Typography align="left" color="primary" variant="h6">
                        {t("nftAttributesLabel")}
                      </Typography>
                    </Box>
                    <Typography display="block" align="left">
                      {t("attributeDescription")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    alignContent="center"
                    wrap="wrap"
                  >
                    {renderAttributes}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Suspense fallback={<></>}>
        <NftSend
          modalSendOpen={modalSendOpen}
          handleModalSendClose={handleModalSendClose}
          handleSend={handleSend}
        ></NftSend>
      </Suspense>
      <Suspense fallback={<></>}>
        <NftSell
          modalSellOpen={modalSellOpen}
          handleModalSellClose={handleModalSellClose}
          handleSell={handleSell}
        ></NftSell>
      </Suspense>
    </>
  );
}
