import React from 'react';
import Avatar from "@material-ui/core/Avatar";

import imageLogo from '../../../assets/img/logo/240x240.png';

import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(1) * 20,
        height: theme.spacing(1) * 20,
        margin: theme.spacing(1),
        boxShadow: "4px 4px 5px rgba(0,0,0,0.75)"
    },
}));

const Logo = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Avatar className={classes.logo} src={imageLogo}/>
    )
};

export default Logo

