import {Circle, Fill, Icon, Stroke, Style} from "ol/style";
import Text from "ol/style/Text";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Cluster from "ol/source/Cluster";
import {Vector as VectorLayer} from "ol/layer";
import {Feature} from "ol";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from 'ol/source/VectorTile';
import CircleStyle from "ol/style/Circle";
import FL_PLACES from "../../assets/img/mapImgUtils/FL_PLACES.png";
import users from "../../assets/img/mapImgUtils/users.png";
import store from "../../assets/img/mapImgUtils/store.png";
import icon from "../../assets/img/mapImgUtils/home-green.png";
import map_marker from "../../assets/img/mapImgUtils/map-marker.webp";

export type PlainPlaceType = {
    firstLifePlaceID: string,
    icon: any,
    name: string,
    address: string,
    placeOwner: string,
    description: string
    coords: {
        lat: number,
        lng: number
    }
    realm :"plainPlace"

};

export const placesVectorSource = new VectorSource({
    format: new GeoJSON()
});

export const clusterSource = new Cluster({
    distance: 60,
    source: placesVectorSource
});

const outerCircleFill = new Fill({
    color: '#8d8d8d',
});

const innerCircleFill = new Fill({
    color: 'white',
});

const textFill = new Fill({
    color: '#f36944',
});

const innerCircle = new CircleStyle({
    radius: 17,
    fill: innerCircleFill,
});

const outerCircle = new CircleStyle({
    radius: 20,
    fill: outerCircleFill,
});


export const layer = new VectorLayer({
    source: clusterSource,
    zIndex: 2,
    style: (element) => {
        const size = element.get("features").length;
        if (size === 1) {
            return [
                new Style({
                    image: new Circle({
                        radius: 17,
                        fill: new Fill({
                            color: '#0b6258',
                        })
                    })}),
                new Style({
                    image: new Circle({
                        radius: 15,
                        fill: new Fill({
                            color: 'white'
                        })
                    })}),
                new Style({
                    image: new Icon({
                        color: '#fff',
                        src: icon,
                        scale: 0.25
                    })
                })];

        } else {
            return [
                new Style({
                    image: outerCircle,
                }),
                new Style({
                    image: innerCircle,
                    text: new Text({
                        text: size.toString(),
                        fill: textFill
                    }),
                })
            ];

        }
    }
});

export const vtLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new GeoJSON(),
        attributionsCollapsible: false,
        attributions: '© CommonsHood'
    })
})

export const positionFeature = new Feature();
positionFeature.setStyle(
    new Style({
        // add a background color to the icon
        image: new Icon({
            anchor: [0.5, 1],
            scale: 0.1,
            src: map_marker,
        })
    })
)

export const positionLayer= new VectorLayer({
    source: new VectorSource({
        features: [positionFeature],
    }),
});

export const placeSample : PlainPlaceType = {
    firstLifePlaceID: "",
    address: "",
    icon: null,
    name: "",
    description: "",
    placeOwner: "",
    coords: {
        lat: 0,
        lng: 0
    },
    realm: "plainPlace"
}