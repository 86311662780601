import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { useTranslation } from "react-i18next";
import { getOptionValueFromHex } from "../../../../utilities/libofthings/libOfThings";
import { itemMarketplaceOptions } from "../../../../utilities/utilities";
import toast from "react-hot-toast";
import { duration } from "../../../../utilities/costants.notifications";


const Transition = (props: any) => {
    return <Slide direction="up" {...props} />;
}


export const ItemInformationDialog = ({ item, open, handleClose }: { item: Item, open: boolean, handleClose: () => void }) => {
    const { t } = useTranslation(['LibOfThings']);

    const handleClickAction = (textToCopy: string, info: string) => {
        navigator.clipboard.writeText(textToCopy);
        let messageToShow = '';
        switch (info) {
            case 'owner':
                messageToShow = t('addressCopiedClipboard');
                break;
            default:
        }
        toast.success(messageToShow, {
            duration: duration
        });
    }
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>{t('itemInformation')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {t('name')}: <b>{item.name}</b><br />
                    {t('itemID')}: <b>{item.itemID}</b> <br />
                    {t('idInOriginalCollection')}: <b>{item.idInCollection}</b><br />
                    {
                        getOptionValueFromHex(item.option) === itemMarketplaceOptions.sell ? (
                            <>{t('price')}: <b>{item.price}</b></>
                        ) : (
                            <>{t('price')}: <b>{item.price} {t('perDay')}</b></>
                        )
                    }<br />
                    {t('category')}: <b>{item.category}</b> <br />
                    {t('option')}: <b>{getOptionValueFromHex(item.option)}</b><br />
                    {t('caution')}: <b>{item.caution}</b><br />
                    {t('tokenAcceptedAsPayment')}: <b>{item.paymentToken}</b> <br />
                    {t('owner')}:
                    <b
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickAction(item.owner, 'owner')}>
                        {item.owner.slice(0, 8)}...{item.owner.slice(item.owner.length - 8, item.owner.length - 1)}
                    </b>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={handleClose} color="primary">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}