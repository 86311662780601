import * as Yup from "yup";
import {TFunction} from "i18next";

export default function getValidationSchema(t: TFunction) {
  const MIN_SYMBOL = 3;
  const MAX_SYMBOL = 5;
  const FILE_SIZE = 16000000; //~16mb
  const CONTRACT_FILE_SIZE = 20971520; // ~20MBytes
  const SUPPORTED_CONTRACT_FORMATS = ["application/pdf"];
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  return Yup.object().shape({
    collectionName: Yup.string().required(),
    collectionSymbol: Yup.string().required(),
    collectionDescription: Yup.string().required(),
    imgFile: Yup.mixed()
      .required()
      .test(
        "fileSize",
        t("Common:fileSizeLarge"),
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileType",
        t("Common:formatUnsupported"),
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    contractFile: Yup.mixed()
      .required(t("contractRequired"))
      .test(
        "contractFileSize",
        t("Common:fileSizeLarge"),
        (value) => value && value.size <= CONTRACT_FILE_SIZE
      )
      .test(
        "contractFileFormat",
        t("Common:formatUnsupported"),
        (value) => value && SUPPORTED_CONTRACT_FORMATS.includes(value.type)
      ),
  });
}
