import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { getItemByID } from "../../../store/slices/libOfThingsSlice";
import { getOptionValueFromHex } from "../../../utilities/libofthings/libOfThings";
import { marketplaceOption } from "../../../utilities/utilities";
import { ItemCalendar } from "./ItemCalendar";
import { ItemInformation } from "./ItemInformation";
import { ManageItem } from "./ManageItem";
import { MyToaster } from "../BasicComponents/MyToaster";
import { getDatesOfItem } from "../../../store/slices/calendarSlice";
import { Item, ItemStats } from "../../../types/libofthings.type";
import { getItemStats } from "../../../api/libOfThingsAPI";

type ParamsOfPage = {
    collection: string,
    itemID: string
}

export const ItemPage = (props: any) => {
    const params = useParams<ParamsOfPage>();
    const dispatch = useAppDispatch();
    const [item, setItem] = useState<Item>();
    const [itemStats, setItemStats] = useState<ItemStats>();

    useEffect(() => {
        if (item != null) {
            (async () => await dispatch(getDatesOfItem(item.itemID)))()
            getItemStats(item.itemID.toString())
                .then(stats => {
                    setItemStats(stats);
                })
                .catch(err => {
                    setItemStats(undefined);
                    console.log(err);
                })
        }
    }, [item]);

    useEffect(() => {
        if (item == null || item.itemID.toString() !== params.itemID) {
            (async () => {
                const item = await dispatch(getItemByID(parseInt(params.itemID)))
                setItem(item);
            }
            )();
        }
    }, [])

    return (
        <>
            <MyToaster />
            <Grid container>
                <Grid item xs={12} md={9}>
                    {
                        (item == null || itemStats == null) ? (
                            <Skeleton variant="rect" width={'100%'} height={'100%'} />
                        ) : (
                            <ItemInformation item={item} itemStats={itemStats} />
                        )
                    }
                </Grid>
                <Grid item xs={12} md={3} >
                    {
                        (item != null) ? (
                            <ManageItem item={item} />
                        ) : (
                            <Skeleton variant="rect" width={'100%'} height={'100%'} />
                        )
                    }
                </Grid>
                {
                    (item != null) ? (
                        <Grid item xs={12} md={9}>
                            <ItemCalendar
                                owner={item.owner}
                                itemID={item.itemID}
                                coin={item.paymentToken}
                                price={item.price}
                                caution={item.caution}
                            />
                        </Grid>
                    ) : (
                        <Skeleton variant="rect" width={'100%'} height={'400px'} />
                    )
                }

            </Grid>
        </>

    )
}