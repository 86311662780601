import React from 'react';
import {logger} from '../../../utilities/logger/logger';

import {Grid, List, ListSubheader} from '@material-ui/core';

import {useTranslation} from 'react-i18next';

//Custom Components
import ContactListItem from './ContactListItem/ContactListItem'
import config from "../../../containers/Coin/Send/configSend";

import type {ExtendedUserData} from "../../../types/user.type";
import type {DaoData} from '../../../types/dao.types';
import type {Place} from "../../../types/place.type";

type ContactListProps = {
    handleSelect: (contact: Contact) => void; //TODO check type
    hasSearched: boolean;
    currentProfile: ExtendedUserData | DaoData;
    searchInput: string;
    placesList: Place[];
    contactsList: ExtendedUserData[];
    locatePLace: any;  //TODO check type
};


export type Contact = ExtendedUserData | Place;

const ContactList = (props: ContactListProps) => {
    const {
        handleSelect,
        hasSearched,
        currentProfile,
        searchInput,
        placesList,
        contactsList,
        locatePLace
    } = props;

    const { t } = useTranslation('ContactList');

    logger.debug('CONTACT LIST, profile => ', currentProfile);
    //function to filter on searchInput
    const listSearch = (inputField: string, list: any) => { //TODO: fix type
        if ( inputField.length >= config.numberOfCharsToStartSearch ) {
            return list.filter( ( listItem: any ) => { //TODO check type
                return listItem.name.toLowerCase().includes((inputField.toLowerCase()).trim());
            });
        }else{
            return list;
        }
    };
    //function to create listItems from placesList and contactsList
    const listItemMap =  (contact: Contact , index: number) => {

        if(contact != null) {
            return (
                <ContactListItem
                    handleSelect={() => handleSelect(contact)}
                    key={index}
                    icon={contact.icon}
                    name={contact.name}
                    category={contact.realm === "dao" ? "FL_PLACES" : null}
                    type={ contact.realm === "dao" ? contact.daoDetails : null}
                    index={contact.realm === "dao" ? contact.firstLifePlaceID : contact.id}
                    email={contact.realm === "dao" ? null : contact.email}
                    locatePLace={(location: any) => locatePLace(location)}//TODO: fix type
                />
            )
        }else{
            return null;
        }
    };

    //PLACES == DAOs
    let subheaderForPlaces = null;
    let filteredPlaceList: Contact[] = [];
    let placesListItems = null;
    let placesUIList = null;

    if(placesList) {
        if (currentProfile?.realm === 'dao') { //if present remove the DAOs == current profile (to avoid self payment)
            filteredPlaceList = placesList.filter((place: any) => currentProfile.flPlaceId !== place.firstLifePlaceID); //TODO: fix type
        } else {
            filteredPlaceList = placesList
        }
    }
    if(filteredPlaceList && filteredPlaceList.length !== 0){
        if(hasSearched) {
            subheaderForPlaces = <ListSubheader style={{paddingLeft: 0, paddingRight: 0}}>{t('filteredPlaces')}:</ListSubheader>;
            filteredPlaceList = listSearch(searchInput, filteredPlaceList);
        }else{ //it shows the closest places
            subheaderForPlaces = <ListSubheader style={{paddingLeft: 0, paddingRight: 0}}>{t('closePlaces')}:</ListSubheader>;
        }

        subheaderForPlaces = filteredPlaceList.length === 0 ? null : subheaderForPlaces;
        placesListItems = filteredPlaceList.map(listItemMap);

        placesUIList = (
            <>
                {subheaderForPlaces}
                {placesListItems}
            </>
        );
    }

    //CONTACTS = people
    let subheaderForPeople = null;
    let filteredPeople = [];
    let peopleListItems = null;
    let peopleUIList = null;

    if(currentProfile?.realm === 'user'){//if present remove the user == currentProfile to avoid self paying
        filteredPeople = contactsList.filter( (person: ExtendedUserData) => person?.id !== currentProfile.id);
    }else{
        filteredPeople = contactsList;
    }
    if(filteredPeople && filteredPeople.length !== 0){
        if(hasSearched) {
            subheaderForPeople = <ListSubheader style={{paddingLeft: 0, paddingRight: 0}}>{t('filteredPeople')}:</ListSubheader>;
            filteredPeople = listSearch(searchInput, filteredPeople);
        }else{ //it shows the users with which the user made the most transactions
            subheaderForPeople =  <ListSubheader style={{paddingLeft: 0, paddingRight: 0}}>{t('topUsers')}:</ListSubheader>;
        }

        subheaderForPeople = filteredPeople.length ===0 ? null : subheaderForPeople;
        peopleListItems = filteredPeople.map(listItemMap);

        peopleUIList = (
            <>
                {subheaderForPeople}
                {peopleListItems}
            </>
        );

    }

    return (
        <Grid container style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Grid item xs={12}>
                <List dense>
                    {placesUIList}
                    {peopleUIList}
                </List>
            </Grid>
        </Grid>
    );
};

export default ContactList;