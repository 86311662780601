import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logger } from '../../../utilities/logger/logger'
import { assetsType } from '../../../config/assets'
import FeaturedCard from '../../../components/UI/Card/FeaturedCard/FeaturedCard'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { constraints, formFieldsNames } from './configForm'
import Loading from '../../../components/UI/Loading/Loading.js'

import FormStep0 from './steps/FormStep0'
import FormStep1 from './steps/FormStep1'
import FormStep2 from './steps/FormStep2'
import FormStep3 from './steps/FormStep3'
import FormStep4 from './steps/FormStep4'
import CreationModal from './CreationModal'
import SplashScreen from '../../../components/UI/SplashScreen/SplashScreen'

import { coinGetList, getTokensFM } from '../../../store/slices/coinSlice'
import {
    CrowdsaleData,
    crowdsaleCreate,
    crowdsaleCreateReset,
} from '../../../store/slices/crowdsaleSlice'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { TFunction } from 'i18next'

const getValidationSchema = (t: TFunction) => {
    return Yup.object({
        [formFieldsNames.mainImage]: Yup.mixed()
            .required(t('mainImageRequired'))
            .test(
                'imageFileFormat',
                t('mainImageUnsupportedFormat', {
                    supportedFormats:
                        constraints.SUPPORTED_IMG_FORMAT_STRINGIFIED,
                }),
                (value) =>
                    value &&
                    constraints.SUPPORTED_IMG_FORMATS.includes(value.type)
            )
            .test(
                'imageFileSize',
                t('mainImageFileSize', {
                    maxSize: parseInt(
                        String(constraints.IMG_FILE_SIZE / 1000000)
                    ),
                }), //conversion to give a measure in megabytes
                (value) => value && value.size <= constraints.IMG_FILE_SIZE
            ),
        [formFieldsNames.bigTitle]: Yup.string()
            .required(t('titleRequired'))
            .min(
                constraints.TITLE_MIN_CHARS,
                t('titleMinChars', { value: constraints.TITLE_MIN_CHARS })
            )
            .max(
                constraints.TITLE_MAX_CHARS,
                t('titleMaxChars', { value: constraints.TITLE_MAX_CHARS })
            ),
        [formFieldsNames.details]: Yup.string()
            .required(t('descriptionRequired'))
            .min(
                constraints.DESCRIPTION_MIN_CHARS,
                t('descriptionMinChars', {
                    value: constraints.DESCRIPTION_MIN_CHARS,
                })
            )
            .max(
                constraints.DESCRIPTION_MAX_CHARS,
                t('descriptionMaxChars', {
                    value: constraints.DESCRIPTION_MAX_CHARS,
                })
            ),
        [formFieldsNames.totalEmittedCoin]: Yup.number()
            .required()
            .positive(t('emittedCoinMustBePositive'))
            .integer(t('emittedCoinMustBeInteger')),
        [formFieldsNames.acceptedCoinRatio]: Yup.string()
            .required()
            .matches(
                /^[0-9]+([.,][0-9][0-9]?)?$/,
                t('acceptedCoinRatioDecimal')
            )
            .test('parsedPositive', t('acceptedCoinRatioPositive'), (value) =>
                value && parseFloat(value) > 0 ? true : false
            ),
        [formFieldsNames.startDate]: Yup.date().required(
            t('startDateRequired')
        ),
        [formFieldsNames.endDate]: Yup.date()
            .required(t('endDateRequired'))
            .test('pastDate', t('endDateMustBeAfterStart'), function (value) {
                return value ? value > this.parent.startDate : false
            }),
        [formFieldsNames.contract]: Yup.mixed()
            .required(t('contractRequired'))
            .test(
                'contractFileSize',
                t('contractFileSize', {
                    maxSize: parseInt(
                        String(constraints.CONTRACT_FILE_SIZE / 1000000)
                    ),
                }),
                (value) => value && value.size <= constraints.CONTRACT_FILE_SIZE
            )
            .test(
                'contractFileFormat',
                t('contractUnsupportedFormat', {
                    supportedFormats:
                        constraints.SUPPORTED_CONTRACT_FORMAT_STRINGIFIED,
                }),
                (value) =>
                    value &&
                    constraints.SUPPORTED_CONTRACT_FORMATS.includes(value.type)
            ),
        [formFieldsNames.firstlifePlace]: Yup.mixed().required(
            t('firstlifePlaceRequired')
        ),
    })
}

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        display: 'flex',
        alignItems: 'left',
        margin: theme.spacing(1),
    },
    buttons: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.grey[200],
        margin: theme.spacing(1),
    },
    loading: {
        marginTop: '3vh',
    },
}))

const CrowdSaleCreateForm = () => {
    const coinListLoading = useAppSelector(state => state.coin.loadingCoinListForPiggies)
    const coinList = useAppSelector(state => state.coin.coinListForPiggies)
    const currentProfile= useAppSelector(state=>state.user.currentProfile)
    const crowdsaleCreationLoading= useAppSelector(state=>state.crowdsale.loading)

    const { t } = useTranslation('CrowdSaleCreateForm')
    const theme = useTheme()
    const classes = useStyles(theme)
    const history = useHistory()
    const dispatch = useAppDispatch()

    const [step, setStep] = useState(0) //this represents the form "parts"
    const [ownedCoupons, setOwnedCoupons] = useState<any[]>([])
    const [allTokens, setAllTokens] = useState<any[]>([])

    const today = new Date().toISOString().substring(0, 10);
    let tomorrow: Date = new Date();
    tomorrow.setDate((new Date()).getDate() + 1);
    const tomorrowDate = tomorrow.toISOString().substring(0, 10);

    const [modalOpen, setModalOpen] = useState(false)

    function handleCrowdsaleCreation(values: {
        bigTitle?: any;
        contract?: any;
        mainImage?: any;
        emittedCoin?: any;
        acceptedCoin?: any;
        startDate?: any;
        endDate?: any;
        details?: any;
        acceptedCoinRatio?: any;
        forEachEmittedCoin?: any;
        totalAcceptedCoin?: any;
        totalEmittedCoin?: any;
        emittedCoinDisposability?: any;
        firstlifePlace?: any;}) {

        const crowdsaleData={
            bigTitle: values.bigTitle,
            contract: values.contract,
            mainImage: values.mainImage,
            emittedCoin: values.emittedCoin,
            acceptedCoin: values.acceptedCoin,
            startDate: values.startDate,
            endDate: values.endDate,
            details: values.details,
            acceptedCoinRatio: values.acceptedCoinRatio,
            forEachEmittedCoin:values.forEachEmittedCoin ,
            totalAcceptedCoin: values.totalAcceptedCoin,
            totalEmittedCoin: values.totalEmittedCoin,
            emittedCoinDisposability:values.emittedCoinDisposability,
            firstlifePlace: values.firstlifePlace
        }
        dispatch(crowdsaleCreate(crowdsaleData))
    }

    const formik = useFormik({
        initialValues: {
            [formFieldsNames.mainImage]: null,
            [formFieldsNames.bigTitle]: "",
            [formFieldsNames.details]: "",
            [formFieldsNames.totalEmittedCoin]: 1,
            [formFieldsNames.indexEmittedCoin]: 0,
            [formFieldsNames.emittedCoin]: {},
            [formFieldsNames.forEachEmittedCoin]: 1, //fixed
            [formFieldsNames.emittedCoinDisposability]: null,
            [formFieldsNames.acceptedCoinRatio]: 1,
            [formFieldsNames.indexAcceptedCoin]: 0,
            [formFieldsNames.acceptedCoin]: {},
            [formFieldsNames.totalAcceptedCoin]: 0.01,
            [formFieldsNames.startDate]: today,
            [formFieldsNames.endDate]: tomorrowDate,
            [formFieldsNames.contract]: null,
            [formFieldsNames.firstlifePlace]: null,
        },
        validationSchema: getValidationSchema(t),
        onSubmit: (values) => {
            if(values) {
                logger.info("CrowdsaleCreateForm form values: ", values);
                handleCrowdsaleCreation(values);
                history.push('/')
            }
        }
    });

    useEffect(() => {
        if (!coinListLoading) {
            dispatch(getTokensFM('', true, true, -1))
            //dispatch(coinGetList(null, true, false,true,null));
            dispatch(crowdsaleCreateReset())
        }
    }, [])

    useEffect(() => {
        setStep(0)
        if (!coinListLoading) {
            dispatch(getTokensFM('', true, true, -1))
            //dispatch(coinGetList(null, true, false,true,null));
            dispatch(crowdsaleCreateReset())
        }
    }, [currentProfile])

    useEffect(() => {
        if (coinList != null && coinList.length !== 0 && currentProfile) {
            logger.info('Reloading tokens and coupons')
            setAllTokens(
                coinList
                    .filter((coin) => coin.type === assetsType.token.name)
                    .sort((a, b) => a.symbol.localeCompare(b.symbol))
            )
            setOwnedCoupons(
                coinList
                    .filter((coin) => coin.type === assetsType.goods.name)
                    .filter(
                        (coupon) => coupon.balance && coupon.balance.balance > 0
                    )
                    //.filter(coupon=> coupon.addressOfOwner === currentProfile.additional_properties?.commonshoodWallet)
                    .sort((a, b) => a.symbol.localeCompare(b.symbol))
            )
        }
    }, [coinList])

    logger.info('alltokens => ', allTokens)
    logger.info('ownedCoupons =>', ownedCoupons)
    logger.info('CrowdsaleCreateForm form values: ', formik.values)

    if (coinListLoading) {
        return <Loading withLoader={true} title={t('loadingCoins')} />
    } else if (allTokens.length === 0 || ownedCoupons.length === 0) {
        return (
            <SplashScreen
                title={t('noCoinsTitle')}
                avatarProps={{ big: true, warning: true }}
            >
                <div>{t('createQuestion')}</div>
                <div>
                    <Button
                        className={classes.buttons}
                        variant='contained'
                        onClick={() => history.push('/coinCreate?firstTime')}
                    >
                        {t('createNewCoin')}
                    </Button>
                </div>
            </SplashScreen>
        )
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <FeaturedCard title={t('describeCrowdSaleTitle')}>
                <Grid container justifyContent='center' alignItems='flex-end'>
                    {{
                        0: <FormStep0 formik={formik} setStep={setStep} />,
                        1: (
                            <FormStep1
                                formik={formik}
                                setStep={setStep}
                                ownedCoupons={ownedCoupons}
                                currentAccount={currentProfile}
                            />
                        ),
                        2: (
                            <FormStep2
                                formik={formik}
                                setStep={setStep}
                                allTokens={allTokens}
                            />
                        ),
                        3: (
                            <FormStep3
                                formik={formik}
                                setStep={setStep}
                                openModal={() => setModalOpen(true)}
                            />
                        ),
                        4: (
                            <FormStep4
                                formik={formik}
                                setStep={setStep}
                                openModal={() => setModalOpen(true)}
                            />
                        ),
                    }[step] || <div />}
                    <CreationModal
                        formik={formik}
                        modalOpen={modalOpen}
                        closeModal={() => {
                            setModalOpen(false)
                            dispatch(crowdsaleCreateReset())
                        }}
                    />
                </Grid>
            </FeaturedCard>
            {crowdsaleCreationLoading ? (
                <Grid
                    container
                    item
                    justifyContent='center'
                    className={classes.loading}
                >
                    <CircularProgress color='primary' />
                </Grid>
            ) : null}
        </form>
    )
}

export default CrowdSaleCreateForm
