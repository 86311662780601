import { Grid, makeStyles } from "@material-ui/core";
import { Preorder } from "../../../../types/calendar.type";
import { PreorderHeader } from "../../BasicComponents/PreorderHeader";
import { PreorderBody } from "./PreorderBody";

const backgroundStyle = makeStyles({
    root: {
        background: '#FFFFFF',
        border: '1px solid #A9A9A9',
        borderRadius: '5px',
    }
})

export const PreorderCancelledInList = ({ preorder, removeFromList, goToItemPage }: {
    preorder: Preorder,
    removeFromList: () => void,
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const useBackgroundStyle = backgroundStyle();

    return (
        <div className={useBackgroundStyle.root}>
            <Grid container>
                {
                    (preorder != null) && (
                        <>
                            <Grid item xs={12}>
                                <PreorderHeader preorder={preorder} />
                            </Grid>
                            <Grid item xs={12}>
                                <PreorderBody preorder={preorder} removeFromList={() => removeFromList()} goToItemPage={goToItemPage}/>
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </div>
    )
}