import React from 'react';
import { useTranslation } from 'react-i18next';

//for js-to-css
import getNotificationText from '../../utilities/notification/notification-messages';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NotificationParameters, RealtimeNotificationType } from "../../api/notificationAPI";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { notificationMarkAsRead, notificationRemoveFromCurrentlyListed } from "../../store/slices/notificationSlice";
import { Divider, Grid, IconButton, ListItemText, Tooltip } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { getDate } from "../../utilities/utilities";
import { getSuggestedUsers } from "../../store/slices/userSlice";
import { logger } from "../../utilities/logger/logger";

const useStyles = makeStyles((theme) => ({

    notificationText: {
        marginLeft: '10px'
    },
    buttonCheck: {
        marginLeft: '30px',
        marginRight: '20px'
    },
    notification: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    typo: {
        textAlign: "center",
    },


}));

type NotificationProps = {
    params: RealtimeNotificationType
}


const Notification = (props: NotificationProps) => {
    const {
        params
    } = props;

    const { t } = useTranslation('NotificationMessages');
    const theme = useTheme();
    const classes = useStyles(theme);
    const currentProfile = useAppSelector(state => state.user.currentProfile)
    const dispatch = useAppDispatch()

    const notification: NotificationParameters = JSON.parse(params.message)

    let messageString = '';

    try {
        if (currentProfile) {
            let chWallet = currentProfile.additional_properties?.commonshoodWallet
            if (chWallet) {
                const messageKey = translateMessageKey(notification, chWallet)
                messageString = getNotificationText(messageKey, notification, t, chWallet);
            }
        }
    } catch (error: any) {
        messageString = error.message;
    }

    const markNotificationAsRead = () => {
        dispatch(notificationMarkAsRead(params._id))
        dispatch(notificationRemoveFromCurrentlyListed({ messageId: params._id }))
    }
    //TODO TRADUZIONI
    return (
        <>
            <Grid container alignItems={'center'} className={classes.notification}>
                <Grid item xs={1} className={classes.typo}>
                    <EmailIcon />
                </Grid>
                <Grid item xs={9}>
                    {messageString}
                    <ListItemText secondary={getDate(notification.timestamp)} />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={() => markNotificationAsRead()} className={classes.buttonCheck}
                        color="primary">
                        <Tooltip aria-label="Bold" role="presentation" title={'Mark as read'}><CheckIcon /></Tooltip>
                    </IconButton>
                </Grid>


            </Grid>
            <Divider />
        </>
    );
};

export default (Notification);

function translateMessageKey(notification: NotificationParameters, chWallet: string) {
    if (notification.eventType !== 'COIN_TRANSFER') {
        // If the event type is not a coin transfer, return the event type as is
        return notification.eventType;
    } else {
        // If the event type is a coin transfer, check the sender
        if (notification.sender === chWallet) {
            // If the sender is chWallet, return 'COIN_SENT'
            return 'COIN_SENT';
        } else {
            // If the sender is not chWallet, return 'COIN_RECEIVED'
            return 'COIN_RECEIVED';
        }
    }
}

