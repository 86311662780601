import Map from 'ol/Map'
import React, { useEffect, useState } from 'react'
import TileLayer from 'ol/layer/Tile'
import { XYZ } from 'ol/source'
import { View } from 'ol'
import { transform } from 'ol/proj'
import { defaults } from 'ol/control'
import '../../theme/map.css'
import {
    placesVectorSource,
    layer,
    positionFeature,
    positionLayer,
    vtLayer,
    PlainPlaceType,
    placeSample,
} from './CrowdsaleMapUtils'
import Overlay from 'ol/Overlay'
import icon from '../../../assets/img/mapImgUtils/home-green.png'
import Point from 'ol/geom/Point'
import { useTranslation } from 'react-i18next'
import { logger } from '../../utilities/logger/logger'
import {
    Button,
    Card,
    Grid,
    IconButton,
    ImageList,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed'
import config from '../../config'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getAllMyPlaces } from '../../api/placeAPI'
import PlainPlaceCard from './Cards/PlainPlaceCard'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ClusterListCard from './Cards/ClusterListCard'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. [source: Material UI]
            transform: 'translateZ(0)',
        },
        title: {
            color: 'white',
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        root: {
            display: 'flex',
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '100%',
            borderRadius: 20,
            backgroundColor: 'rgba(255,255,255,0.5)',
            right: 0,
            top: '11vh',
        },
        image: {
            width: 300,
            height: 200,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        iconButton: {
            color: 'white',
        },
        centerControl: {
            position: 'absolute',
            top: '1.4vh',
            right: '2vh',
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        centerControlIcon: {
            color: 'rgb(228,78,60)',
            '&:hover': {
                color: 'white',
            },
            fontSize: '2.5rem',
        },
        infoControl: {
            position: 'absolute',
            top: '9vh',
            right: '2vh',
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        infoControlIcon: {
            color: 'rgb(228,78,60)',
            '&:hover': {
                color: 'white',
            },
            fontSize: '2.5rem',
        },
        info: {
            animation: `$myEffect 1000ms`,
            position: 'absolute',
            top: '8vh',
            left: '2vh',
            width: '80%',
        },
        rootScrollContainer: {
            position: 'absolute',
            bottom: 0,
            borderRadius: 20,
            backgroundColor: 'rgba(0,0,0,0.2)',
            marginBottom: '2vh',
            display: 'flex',
        },
        rootScroll: {
            padding: '2vh',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            marginLeft: '1vh',
        }
    })
)

type MapPropsType = {
    handleClose: () => void
    setPlace: (flPlace: PlainPlaceType) => void
}

const CrowdsalesMap = (props: MapPropsType) => {
    const { handleClose, setPlace } = props
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['Common'])

    const userProfile = useAppSelector((state) => state.user.userProfile)
    const ethersInstance = useAppSelector((state) => state.ethers.ethersInstance)

    const [currentPosition, setCurrentPosition] = useState<any>([
        config.defaultGeoCoordinates.latitude,
        config.defaultGeoCoordinates.longitude,
    ])
    const [map, setMap] = useState<Map | null>(null)
    const [openDetails, setOpenDetails] = useState(false)
    const [openClusterDetails, setOpenClusterDetails] = useState(false)
    const [mapPlaces, setMapPlaces] = useState<any[] | null>(null)
    const [selectedPlace, setSelectedPlace] =
        useState<PlainPlaceType>(placeSample)
    const [clusterContacts, setClusterContacts] = useState<PlainPlaceType[]>([])
    const [openInfo, setOpenInfo] = useState(false)

    const getMyPlaces = () => {
        async function getPlaces() {
            if (userProfile && userProfile.id != null && ethersInstance) {
                const ownedPlaceList = await getAllMyPlaces(
                    userProfile.id,
                    ethersInstance
                )
                setMapPlaces(ownedPlaceList)
            }
        }

        getPlaces()
    }

    useEffect(() => {
        if (!map) {
            loadMap()
        }
    }, [])

    useEffect(() => {
        if (map) {
            setOpenInfo(true)
            centerControl()
        }
    }, [map])

    useEffect(() => {
        if (map && mapPlaces) {
            //createFeatures()
            map.un('click', clickHandler)
            map.un('movestart', resetView)
            createOverlays()
            createListeners()
        }
    }, [mapPlaces])

    const createFeatures = async () => {
        let allFeatures: any[] = []
        if (mapPlaces) {
            for (const flPlace of mapPlaces) {
                if (flPlace._id !== null) {
                    const format = placesVectorSource.getFormat()
                    if (format) {
                        const feat = format.readFeature(flPlace, {
                            featureProjection: 'EPSG:3857',
                        })
                        allFeatures.push(feat)
                    }
                }

                placesVectorSource.addFeatures(allFeatures)
                placesVectorSource.getFeatureById(flPlace.id)?.set('coords', flPlace.geometry.coordinates)
            }
        }
    }

    const createOverlays = () => {
        if (map) {
            const cardDOMElement = document.getElementById('overlay-card')
            const cardElement =
                cardDOMElement === null ? undefined : cardDOMElement
            let overlay: Overlay = new Overlay({
                element: cardElement,
                id: 'card-overlay',
            })
            map.addOverlay(overlay)

            const clusterDOMElement = document.getElementById('cluster-card')
            const clusterElement =
                clusterDOMElement === null ? undefined : clusterDOMElement
            let clusterOverlay: Overlay = new Overlay({
                element: clusterElement,
                id: 'cluster-overlay',
            })
            map.addOverlay(clusterOverlay)
        }
    }

    const createListeners = () => {
        if (map) {
            const listener = map.getListeners('click')
            if (listener === undefined) {
                map.on('click', clickHandler)
                map.on('movestart', resetView)
            }
        }
    }

    const resetView = () => {
        if (map) {
            setOpenDetails(false)
            setOpenClusterDetails(false)
            setSelectedPlace(placeSample)
            setOpenInfo(false)
        }
    }

    const centerControl = () => {
        if (map) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((location) => {
                    let position = transform(
                        [location.coords.longitude, location.coords.latitude],
                        'EPSG:4326',
                        'EPSG:3857'
                    )
                    positionFeature.setGeometry(new Point(position))
                    map.getView().animate({
                        center: position,
                        zoom: 15,
                        duration: 1500,
                    })
                    setCurrentPosition(position)
                })
            } else {
                logger.info('Geolocation is not supported by this browser.')
                setCurrentPosition(currentPosition)
            }
        }
    }

    const calculateAddress = (coords: number[]) => {
        const getPlaces = async () => {
            try {
                const response = await fetch(
                    'https://autosuggest.search.hereapi.com/v1/autosuggest?q=' +
                        coords[1] +
                        ',' +
                        coords[0] +
                        `&language=IT&apiKey=${config.autosuggestApiKey}&limit5&at=45.065520,7.664315`
                )
                const data = await response.json()
                return data.items.length > 0 ? data.items[0].title : ''
            } catch (error) {
                logger.error('CrowdsalesMap.calculateAddress', error)
                return ''
            }
        }
        return getPlaces()
    }

    const clickHandler = async (event: any) => {
        if (map) {
            resetView()

            let coords = transform(
                [event.coordinate[0], event.coordinate[1]],
                'EPSG:3857',
                'EPSG:4326'
            )
            const address = await calculateAddress(coords)
            if (address !== '') {
                const selectedPlace: PlainPlaceType = {
                    firstLifePlaceID: '',
                    name: '',
                    description: '',
                    icon: [],
                    address: address,
                    placeOwner: '',
                    coords: {
                        lat: coords[1],
                        lng: coords[0],
                    },
                    realm: 'plainPlace',
                }

                setSelectedPlace(selectedPlace)
                setOpenDetails(true)
            }

            map.forEachFeatureAtPixel(event.pixel, async (feature, layer) => {
                if (feature.getProperties().features) {
                    if (feature.getProperties().features.length > 1) {
                        resetView()
                        let cluster: PlainPlaceType[] = []
                        for (const feat of feature.getProperties().features) {
                            logger.info('feat', feat)
                            let place: PlainPlaceType = {
                                firstLifePlaceID: feat.id_,
                                name: feat.values_.name,
                                description: feat.values_.description,
                                icon: feat.values_.images,
                                address: feat.values_.address,
                                placeOwner: feat.values_.owner.display_name,
                                coords: {
                                    lat: feat.values_.coords[1],
                                    lng: feat.values_.coords[0],
                                },
                                realm: 'plainPlace',
                            }
                            cluster.push(place)
                        }
                        setClusterContacts(cluster)
                        setOpenClusterDetails(true)
                    } else {
                        let feat = feature.getProperties().features[0]

                        let place: PlainPlaceType = {
                            firstLifePlaceID: feat.id_,
                            name: feat.values_.name,
                            description: feat.values_.description,
                            icon: feat.values_.images,
                            address: feat.values_.address,
                            placeOwner: feat.values_.owner.display_name,
                            coords: {
                                lat: feat.values_.coords[1],
                                lng: feat.values_.coords[0],
                            },
                            realm: 'plainPlace',
                        }
                        setSelectedPlace(place)
                        setOpenDetails(true)
                    }
                }
            })
        }
    }

    const loadMap = () => {
        const map = new Map({
            controls: defaults().extend([]),
            target: 'commonshood-crowdsale-map',

            layers: [
                new TileLayer({
                    source: new XYZ({
                        url:
                            config.network.firstLifeApi.tile_server +
                            config.network.firstLifeApi.mapbox_apiKey,
                    }),
                }),
                layer,
                vtLayer,
                positionLayer,
            ],
            view: new View({
                center: currentPosition,
                zoom: 14,
                enableRotation: false,
            }),
        })

        getMyPlaces()
        setMap(map)
    }

    const  handleOpenCrowdsaleDetails= (item: PlainPlaceType)=> {
        setSelectedPlace(item)
        setOpenClusterDetails(false)
        setOpenDetails(true)
    }

    return (
        <>
            <Grid
                id='commonshood-crowdsale-map'
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    overflowY: 'hidden',
                }}
            >
                {map && (
                    <>
                        <IconButton
                            size={'small'}
                            className={classes.centerControl}
                            onClick={() => centerControl()}
                        >
                            <GpsNotFixedIcon
                                className={classes.centerControlIcon}
                            />
                        </IconButton>
                        <IconButton
                            size={'small'}
                            className={classes.infoControl}
                            onClick={() => {
                                resetView()
                                setOpenInfo(!openInfo)
                            }}
                        >
                            <InfoOutlinedIcon
                                className={classes.infoControlIcon}
                            />
                        </IconButton>
                        {openInfo && (
                            <Grid
                                container
                                sm={5}
                                xs={12}
                                direction='row'
                                justifyContent='center'
                                alignItems='flex-end'
                                className={classes.info}
                                spacing={1}
                                onClick={() => {
                                    setOpenInfo(false)
                                }}
                            >
                                <Grid item>
                                    <Paper className={classes.paper}>
                                        <Grid item xs>
                                            <Typography
                                                gutterBottom
                                                variant='subtitle1'
                                                style={{
                                                    marginTop: '10px',
                                                }}
                                            >
                                                Seleziona un luogo che hai già
                                                creato oppure clicca in un punto
                                                per crearne uno nuovo
                                            </Typography>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
                {openDetails && (
                    <PlainPlaceCard
                        closeCard={() => setOpenDetails(false)}
                        place={selectedPlace}
                        setPlace={(flplace: PlainPlaceType) => {
                            setPlace(flplace)
                        }}
                    />
                )}
                {openClusterDetails &&
                    <Grid container className={classes.rootScrollContainer}>
                        <Grid className={classes.rootScroll}>
                            <ImageList className={classes.imageList}>
                                {clusterContacts.map((item) => (
                                    <ClusterListCard contact={item} handleOpenDetails={handleOpenCrowdsaleDetails}/>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default CrowdsalesMap
