import { Grid } from "@material-ui/core";
import { Title } from "../BasicComponents/Title";
import { ListOfCards } from "./ListOfCards";
import { useTranslation } from "react-i18next";

export const NewArrivalsContainer = () => {
    const { t } = useTranslation(['LibOfThings']);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Title title={t('newArrivals')} />
            </Grid>
            <Grid item xs={12}>
                <ListOfCards />
            </Grid>
        </Grid>
    )
}