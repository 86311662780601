import React, { useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import { Divider, Grid, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction,Avatar, Switch, TextField, Typography, Button, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import addDays from "date-fns/addDays";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-US";
import config from "../../../config";

const INPUT_HEIGHT = "56px"

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        flexGrow: 1,
        textAlign: "center",
        marginTop: "10px"
    },
    listCoins:  {
        overflow: 'auto',
        width: '100%',
        maxWidth: 720,
        margin: "2%",
        backgroundColor: theme.palette.background.paper,
    },
    datePicker: {
        marginLeft: "18px",
    },
    repeatingsSelection: {
        marginLeft: "5px",
        marginRight: "5px",
        width: "100px",
        height: INPUT_HEIGHT
    },
    redText: {
        color: "#f44336"
    },
    invisible: {
        color: "white",
        userSelect: "none"
    },
    normalText: {
        color: "black"
    }
}));

type CoinListProps = {
  coins: any[];
  repeatings: number;
  coinError: Set<string>;
};

const CoinList = (props: CoinListProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const coins = props.coins;
  const repeatings = props.repeatings;
  const coinsError = props.coinError;

  return (
    <>
      {coins.map((coin) => (
        <ListItem key={coin}>
          <ListItemAvatar>
            <Avatar src={coin.coin.logo} alt={coin.coin.symbol} />
          </ListItemAvatar>
          <ListItemText
            primary={`${coin.coin.symbol}`}
            className={
              coinsError.has(coin.coin.address)
                ? classes.redText
                : classes.normalText
            }
          />
          <ListItemSecondaryAction>
            <ListItemText primary={`${coin.amount * repeatings}`} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  );
};

interface LocaleMapType {
  [key: string]: Locale;
}

const localeMap: LocaleMapType = {
  en: enLocale,
  it: itLocale,
};

type ResumePageProps = {
  coinsOffered: any;
  coinsRequired: any;
  repeatings: number;
  onRepeatingsChange: (newValue: number) => void;
  onRepeatingsError: (newValue: boolean) => void;
  onDateChange: (dateTimestamp: number) => void;
};

const ResumePage = (props: ResumePageProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t, i18n } = useTranslation("ExchangeCreateForm");

  const repeatingsSelection = props.repeatings;
  const setRepeatingsSelection = props.onRepeatingsChange;

  const setRepeatingsError = props.onRepeatingsError;

  let coinsOffered = Array.from(props.coinsOffered, ([coin, amount]) => ({
    coin,
    amount,
  }));
  coinsOffered = coinsOffered.filter((offer) => parseFloat(offer.amount) !== 0);
  const coinsRequired = Array.from(props.coinsRequired, ([coin, amount]) => ({
    coin,
    amount,
  }));

  const minDate = addDays(new Date(), 1);
  const [enableDatePicker, setEnableDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(minDate);
  const [coinOfferError, setCoinOfferError] = useState(new Set<string>());

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);

        if (date !== null) {
            const dateTimestamp = parseInt(format(date, "t"));
            props.onDateChange(dateTimestamp);
        }
    };

    const handleEnableDatePicker = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const status = event.target.checked;
        setEnableDatePicker(status);
        if (!status) props.onDateChange(0);
    };

  const DatePicker = () => {
    return (
      <>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[i18n.languages[1]]}
        >
          <DateTimePicker
            autoOk
            ampm={false}
            value={selectedDate}
            onChange={handleDateChange}
            label={t("expiration")}
            className={classes.datePicker}
            disabled={!enableDatePicker}
            inputVariant="outlined"
            minDate={minDate}
            inputProps={{ style: { textAlign: "center"} }}
            minDateMessage={t("dateError")}
          />
        </MuiPickersUtilsProvider>
        <Switch
          checked={enableDatePicker}
          onChange={handleEnableDatePicker}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </>
    );
  };

  const handleRepeatingsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = Number(event.target.value);

    if (isNaN(newValue) || newValue < 1) setRepeatingsSelection(1);
    else changeRepeatings(newValue);
  };

  const changeRepeatings = (newRepeatings: number) => {
    const coinOfferErrorSet = new Set<string>();

    for (let coinOffer of coinsOffered) {
      if (coinOffer.amount * newRepeatings > coinOffer.coin.balance.balance)
        coinOfferErrorSet.add(coinOffer.coin.address);
    }

    setRepeatingsError(coinOfferErrorSet.size !== 0);
    setCoinOfferError(coinOfferErrorSet);
    setRepeatingsSelection(newRepeatings);
  };

    return(
        <>
        <Grid container justifyContent="center" className={classes.grid} spacing={2}>
            <Grid item container xs={12} justifyContent="center">
                <Typography variant="body1" className={coinOfferError.size > 0 ? classes.redText : classes.invisible}>
                    {t("repeatingsError")}
                </Typography>
            </Grid>

            <Grid item container xs={12} lg={6} spacing={2} justifyContent="center">
                <Grid item container xs={12} lg={6} alignItems="center" justifyContent={isMobile ? "center" : "flex-start"}>
                    <DatePicker/>
                </Grid>
                
                <Grid item container xs={12} lg={6} justifyContent={isMobile ? "center" : "flex-end"}>
                    <Button variant="outlined" color="primary" onClick={() => changeRepeatings(repeatingsSelection - 1)} disabled={repeatingsSelection <= 1} style={{height: INPUT_HEIGHT}}>
                        <RemoveIcon />
                    </Button>
                        
                    <TextField 
                        onChange={handleRepeatingsChange} 
                        value={repeatingsSelection} 
                        label={t("numberOfRepeatings")} 
                        variant="outlined"
                        error={coinOfferError.size > 0}
                        className={classes.repeatingsSelection}
                        inputProps={{style: {textAlign: "center"}}}
                    />
                    <Button variant="outlined" color="primary" onClick={() => changeRepeatings(repeatingsSelection + 1)} style={{height: INPUT_HEIGHT}}>
                        <AddIcon />
                    </Button>
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="center" spacing={3}>
                <Grid item xs={6} md={3}>
                    <List className={classes.listCoins}>
                        <ListItem key="coinsOfferedTitle">
                            <ListItemText primary={t('coinsOffered')} />
                        </ListItem>
                        <Divider />
                        <CoinList coins={coinsOffered} repeatings={repeatingsSelection} coinError={coinOfferError}/>
                    </List>
                </Grid>
                <Grid item xs={6} md={3}>
                    <List className={classes.listCoins}>
                        <ListItem key="coinsRequiredTitle">
                            <ListItemText primary={t('coinsRequired')} />
                        </ListItem>
                        <Divider />
                        <CoinList coins={coinsRequired} repeatings={repeatingsSelection} coinError={coinOfferError} />
                    </List>
                </Grid>
            </Grid>
      </Grid>
    </>
  );
};

export default ResumePage;
