import React from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";

import {useTranslation} from "react-i18next";

import {
    Avatar,
    Badge,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from "@material-ui/core";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import TollIcon from "@material-ui/icons/Toll";

import { assetsType } from "../../../config/assets";

const useStyles = makeStyles((theme: Theme) => ({
  listCoins: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  inputLenght: {
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
  message: {
    marginTop: "3vh",
  },
}));

type SelectCoinsProps = {
  activeCoinsList: any[];
  isLoadingCoinList: boolean;
  getCoinAmount: (coin: any) => string;
  showCoinError: (coin: any, balance: number) => boolean;
  onAmountChange: (coin: any, value: string) => void;
  step: number;
};

const SelectCoins = (props: SelectCoinsProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation("ExchangeCreateForm");

  const activeCoinsList = props.activeCoinsList;
  const step = props.step;

  const coinItem = (value: string, showError: boolean, coin: any) => {
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onAmountChange(coin, event.target.value);
    };

    return (
      <>
        {showError ? (
          <TextField
            value={value}
            id="outlined-basic"
            label={t("amount")}
            type="number"
            variant="outlined"
            size="small"
            error={true}
            helperText={t("amountError")}
            onChange={handleAmountChange}
          />
        ) : (
          <TextField
            value={value}
            id="outlined-basic"
            label={t("amount")}
            type="number"
            variant="outlined"
            size="small"
            onChange={handleAmountChange}
          />
        )}
      </>
    );
  };

  const emptyListMessage = () => {
    if (step === 0) return t("noPossesedCoins");
    else return t("noCoins");
  };

  const getCoinBadge = (coin: any) => {
    if (coin.type === assetsType.goods.name)
      return <LocalOfferIcon style={{ fontSize: 20 }} />;
    else return <TollIcon style={{ fontSize: 20 }} />;
  };

  return !activeCoinsList || activeCoinsList?.length === 0 ? (
    <Grid container item justifyContent="center" className={classes.message}>
      <Typography>{emptyListMessage()}</Typography>
    </Grid>
  ) : (
    <List className={classes.listCoins}>
      {activeCoinsList.map((coin) => (
        <ListItem key={coin.address}>
          <ListItemAvatar>
            <Badge
              badgeContent={getCoinBadge(coin)}
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Avatar src={coin.logo} alt={coin.symbol} />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={`${coin.name} - ${coin.symbol}`}
            secondary={t("balance") + " " + coin.balance.balance}
          />
          <ListItemSecondaryAction className={classes.inputLenght}>
            {coinItem(
              props.getCoinAmount(coin),
              props.showCoinError(coin, coin.balance.balance),
              coin
            )}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default SelectCoins;
