import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogWithInfo } from '../../BasicComponents/DialogWithInfo';
import { Item } from '../../../../types/libofthings.type';
import { ItemInformationDialog } from './ItemInformationDialog';

const styles = makeStyles({
    dialogWithItemInfo: {
        position: 'relative',
        left: '1em',
        '@media(max-width: 401px)': {
            display: 'none'
        }
    }
})

export const ItemMenu = ({ item }: { item: Item }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogWithItemInfo, setOpenDialogWithItemInfo] = useState(false);
    const { t } = useTranslation(['LibOfThings']);
    const classes = styles();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleOpenDialogWithItemInfo = () => {
        handleClose();
        setOpenDialogWithItemInfo(true);
    }

    return (
        <>
            <MoreVertIcon onClick={handleClick} />
            <Menu id="item-card-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleOpenDialogWithItemInfo}>{t('itemInformation')}</MenuItem>
            </Menu>
            <div className={classes.dialogWithItemInfo}>
                <ItemInformationDialog item={item} open={openDialogWithItemInfo} handleClose={() => { setOpenDialogWithItemInfo(false) }} />
            </div>
        </>


    )
}