import React from 'react';

import {Avatar, Typography} from '@material-ui/core';

import AccountCircleIconOutlined from '@material-ui/icons/AccountCircleOutlined';
import HomeWorkIconOutlined from '@material-ui/icons/HomeWorkOutlined';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import StoreIcon from '@material-ui/icons/Store';
import GroupIcon from '@material-ui/icons/Group';

//Place placeholder
import {ExtendedUserData} from "../../../../types/user.type";
import {DaoData, DaoTypes} from "../../../../types/dao.types";
import {useTranslation} from "react-i18next";

type ContactSelectDetailsProps = {
    contact: ExtendedUserData | DaoData;
    showEmail: boolean;
    style: any
};


const ContactSelectDetails = (props: ContactSelectDetailsProps) => {
    const {contact, showEmail} = props;
    const {t} = useTranslation(['DaoCreateForm', 'Common']);
    let details = (<div>{t('loadingGeneric')}...</div>);

    if (contact) {
        const {
            name,
        } = contact;

        let emailField = null;
        if (showEmail && contact.realm === "user") {
            emailField = <Typography variant="caption" display="block">{contact.email}</Typography>;
        } else if (showEmail && contact.realm === "dao") {
            emailField = <Typography variant="caption" display="block">[{t(contact.type)}]</Typography>;
        }

        const avatar = (
            <Avatar alt={name} style={{"backgroundColor": "transparent"}}>
                {
                    contact.realm === "dao" ?
                        contact.type === DaoTypes[1] ?
                            <GroupIcon color="primary"/> :
                            contact.type === DaoTypes[0] ?
                                <StoreIcon color="primary"/> :
                                    <HomeWorkIconOutlined color="primary"/> :
                        <AccountCircleIconOutlined color="primary"/>
                }
            </Avatar>
        );

        details = (
            <>
                {avatar}
                {<span>&nbsp;</span>}
                <Typography variant="inherit">{name}</Typography>
                {<span>&nbsp;</span>}
                {emailField}
            </>
        )
    }
    return details;
};

export default ContactSelectDetails;
