import React from 'react';

import ContactSelectDetails from './ContactSelectDetails';

import {useTranslation} from "react-i18next";
import {ExtendedUserData} from "../../../../types/user.type";
import {DaoData} from "../../../../types/dao.types";


type ContactSelectItemProps = {
    showEmail: boolean;
    contact: ExtendedUserData | DaoData | null;
}

const ContactSelectItem = (props: ContactSelectItemProps) => {
    const {
        showEmail,
        contact,
    } = props;
    const {t} = useTranslation("Common");

    if (contact) {
        return (
            <ContactSelectDetails
                style={{ overflow: 'hidden', textOverflow: 'ellipsis'}}
                contact={contact}
                showEmail={showEmail}
            />
        )
    } else {
        return (
            <>{t("loadingGeneric")}...</>
        )
    }
};

export default ContactSelectItem;
