import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {logger} from "../../utilities/logger/logger";
import {
    CrwdslPlacesDetails,
    getAllPlacesByCoordinates,
    getNearPlacesWithDao,
    PlacesDetails,
    PlacesDetailsType
} from "../../api/placeAPI";
import {DaoData} from "../../types/dao.types";
import {RootState} from "../store";
import { et } from "date-fns/locale";


type PlaceInitialState = {
    mapPlaces: PlacesDetails[]| null;
    mapPlacesLoading: boolean;
    mapPlacesError: string | null;

    mapDaoPlacesByCoordinates: PlacesDetails[];
    mapCrwdslPlacesByCoordinates: CrwdslPlacesDetails[];
    mapPlacesByCoordinatesError: string | null;
    mapPlacesByCoordinatesLoading: boolean

    nearPlacesContact: DaoData[],
    nearPlacesContactError: string | null,
    nearPlacesContactLoading: boolean
}

const initialState: PlaceInitialState = {
    mapPlaces: null,
    mapPlacesLoading: false,
    mapPlacesError: null,

    mapDaoPlacesByCoordinates: [],
    mapCrwdslPlacesByCoordinates: [],
    mapPlacesByCoordinatesError: null,
    mapPlacesByCoordinatesLoading: false,

    nearPlacesContact: [],
    nearPlacesContactError: null,
    nearPlacesContactLoading: false
};

export const placeSlice = createSlice({
    name: 'Place',
    initialState,
    reducers: {

        getMapPlaces(state, action: PayloadAction<{places: PlacesDetails[]}>){
            state.mapPlaces = action.payload.places;
            state.mapPlacesError = null;
            state.mapPlacesLoading = false;
        },
        getMapPlacesReset(state){
            state.mapPlaces = null;
            state.mapPlacesError = null;
            state.mapPlacesLoading = false;
        },
        getMapPlacesFail(state, action: PayloadAction<{error: string}>){
            state.mapPlacesError = action.payload.error;
            state.mapPlacesLoading = false;
        },
        getMapPlacesStart(state){
            state.mapPlacesError = null;
            state.mapPlacesLoading = true;
        },

        getMapPlacesByCoordinates(state, action: PayloadAction<{daoPlaces: PlacesDetails[], crwdslPlaces: CrwdslPlacesDetails[]}>){
            state.mapDaoPlacesByCoordinates = action.payload.daoPlaces;
            state.mapCrwdslPlacesByCoordinates = action.payload.crwdslPlaces;
            state.mapPlacesByCoordinatesError = null;
            state.mapPlacesByCoordinatesLoading = false;
        },
        getMapPlacesByCoordinatesReset(state){
            state.mapDaoPlacesByCoordinates = [];
            state.mapCrwdslPlacesByCoordinates = [];
            state.mapPlacesByCoordinatesError = null;
            state.mapPlacesByCoordinatesLoading = false;
        },
        getMapPlacesByCoordinatesFail(state, action: PayloadAction<{error: string}>){
            state.mapPlacesByCoordinatesError = action.payload.error;
            state.mapPlacesByCoordinatesLoading = false;
        },
        getMapPlacesByCoordinatesStart(state){
            state.mapPlacesByCoordinatesError = null;
            state.mapPlacesByCoordinatesLoading = true;
        },

        getNearPlacesSuccess(state, action: PayloadAction<{places: DaoData[]}>){
            state.nearPlacesContact = action.payload.places;
            state.nearPlacesContactError = null;
            state.nearPlacesContactLoading = false;
        },
        getNearPlacesReset(state){
            state.nearPlacesContact = [];
            state.nearPlacesContactError = null;
            state.nearPlacesContactLoading = false;
        },
        getNearPlacesFail(state, action: PayloadAction<{error: string}>){
            state.nearPlacesContactError = action.payload.error;
            state.nearPlacesContactLoading = false;
        },
        getNearPlacesStart(state){
            state.nearPlacesContactError = null;
            state.nearPlacesContactLoading = true;
        }
    }
});

export const {
    getMapPlaces,
    getMapPlacesReset,
    getMapPlacesFail,
    getMapPlacesStart,
    getMapPlacesByCoordinates,
    getMapPlacesByCoordinatesReset,
    getMapPlacesByCoordinatesFail,
    getMapPlacesByCoordinatesStart,
    getNearPlacesSuccess,
    getNearPlacesReset,
    getNearPlacesFail,
    getNearPlacesStart,
} = placeSlice.actions;


export const getPlacesDetailsByCoordinates = (ne_lat: number, ne_lon: number, sw_lat: number, sw_lon: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const ethersInstance = getState().ethers.ethersInstance;
            dispatch(getMapPlacesByCoordinatesStart());
            const currentProfile = getState().user.currentProfile;
            if(currentProfile && currentProfile.additional_properties && ethersInstance) {
                const commonshoodWallet = currentProfile.additional_properties.commonshoodWallet;
                if(commonshoodWallet){
                    const placesDetails = await getAllPlacesByCoordinates(ethersInstance, commonshoodWallet, ne_lat, ne_lon,sw_lat, sw_lon)
                    dispatch(getMapPlacesByCoordinates({daoPlaces: placesDetails.placesWithDAO, crwdslPlaces: placesDetails.placesWithCrowdsale}));
                }
                else{
                    dispatch(getMapPlacesByCoordinatesFail({error: "No commonshood wallet"}));
                }
            }
            else{
                dispatch(getMapPlacesByCoordinatesFail({error: "No current profile"}));
            }
        }catch(error: any){
            logger.error("Could not get the list of places details, ", error);
            dispatch(getMapPlacesByCoordinatesFail({error}));
        }
    }
}

export const getNearPlaces = (lat: number, lon: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const ethersInstance = getState().ethers.ethersInstance;
            const currentProfile = getState().user.currentProfile;
            if(currentProfile && currentProfile.additional_properties && ethersInstance) {
                const commonshoodWallet = currentProfile.additional_properties.commonshoodWallet;
                if(commonshoodWallet){
                    dispatch(getNearPlacesStart());
                    const nearPlaces = await getNearPlacesWithDao(ethersInstance, lat, lon, commonshoodWallet)
                    console.log("nearPlaces", nearPlaces)
                    dispatch(getNearPlacesSuccess({places: nearPlaces}));
                }
            }
        }catch(error: any){
            logger.error("Could not get the list of places details, ", error);
            dispatch(getNearPlacesFail({error}));
        }
    }
}




export default placeSlice.reducer;
