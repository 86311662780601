import config from "../config";
import {assetIntegerToDecimalRepresentation} from "../utilities/decimalsHandler/decimalsHandler";
import {logger} from "../utilities/logger/logger";
import axiosNotifications from "../utilities/backend/axios-notifications";
import axiosMetadata from "../utilities/backend/axios-metadata";

import {CoinBalance, } from "../types/coin.type";
import {NotificationType} from "./notificationAPI";

import {ethers, utils, Wallet} from "ethers";



const getTokenInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.TKN_TMPLT_ABI)
    return new ethers.Contract(address, abi_template, ethersInstance)
}

export const getTokenFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.TKN_FCTRY_ABI)
    return new ethers.Contract(config.smartContracts.TKN_FCTRY_ADDR, abi_factory, ethersInstance)
}

export type CoinCreateData = {
    name: string;
    symbol: string;
    decimals: number;
    iconUrl: string;
    iconHash: string;
    cap: number;
    contractHash: string;
};
export const createCoin = async (
    ethersInstance: Wallet,
    accountAddress: string,
    coinData: CoinCreateData
) => {
    const {name, symbol, decimals, iconUrl, iconHash, cap, contractHash} =
        coinData;
    const tokenFactoryInstance = getTokenFactoryInstance(ethersInstance);
    let creationResponse = await tokenFactoryInstance.createToken(name, symbol, decimals, iconUrl, iconHash, cap, contractHash)
    creationResponse = await creationResponse.wait()
    logger.info("succesfully created res: ", creationResponse);
    // logger.info("creating coin with data: ", coinData);
    // const tokenFactoryInstance = getTokenFactoryInstance(web3);
    // const creationResponse = await tokenFactoryInstance.methods
    //   .createToken(name, symbol, decimals, iconUrl, iconHash, cap, contractHash)
    //   .send({ from: accountAddress, gasPrice: "0" });
    // logger.info("succesfully created res: ", creationResponse);

    return creationResponse;
};

export const mintCoin = async (
    ethersInstance: Wallet,
    accountAddress: string,
    coinAddress: string,
    amount: number
) => {
    logger.info("minting coin with address: ", coinAddress);
    const justCreatedTokenInstance = getTokenInstance(ethersInstance, coinAddress)

    //TODO fixme here there should be some response state management in case of failure
    let result = await justCreatedTokenInstance.mint(accountAddress, amount)
    result = await result.wait()
    logger.info(result)
    return result
};

export const coinGetBalance = async (
    ethersInstance: Wallet,
    accountAddress: string,
    tokenAddress: string
): Promise<CoinBalance> => {
    const coinContractInstance = getTokenInstance(ethersInstance, tokenAddress)
    const tickerBalance = Number(await coinContractInstance['balanceOf(address)'](accountAddress))
    const decimals = Number(await coinContractInstance.decimals());

    let balance: number = parseFloat(assetIntegerToDecimalRepresentation(tickerBalance, decimals));

    if (decimals == 0) {
        balance = parseInt(balance.toString());
    }

    return {
        balance,
        decimals,
    };
};


export const getPossessedTokens = async (
    ethersInstance: Wallet,
    accountAddress: string
): Promise<string[]> => {
    try {
        const TokenFactoryInstance = getTokenFactoryInstance(ethersInstance)
        return await TokenFactoryInstance.getPossessedTokens(accountAddress);
    } catch (error) {
        logger.error(
            "Something went wrong while trying to get all possessed tokens"
        );
        return [];
    }
};

export const getAllTokenAddresses = async (
    ethersInstance: Wallet,
    accountAddress: string
): Promise<string[]> => {
    try {
        const TokenFactoryInstance = getTokenFactoryInstance(ethersInstance)
        return await TokenFactoryInstance.getAllTokenAddresses();
    } catch (error) {
        logger.error(
            "Something went wrong while trying to get all token addresses"
        );
        return [];
    }
};

type TokenByAddressReturn = {
    //derived by TokenFactory Contract
    0: string; //contractAddress
    1: string; //name
    2: string; //symbol
    3: string; //decimals
    4: string; //logoUrl
    5: string; //owner
    6: boolean; //mintable
};
export const getTokenByAddress = async (
    ethersInstance: Wallet,
    accountAddress: string,
    tokenAddress: string
) => {
    try {
        const TokenFactoryInstance = getTokenFactoryInstance(ethersInstance)
        const tokenExtendedData: TokenByAddressReturn = await TokenFactoryInstance.getTokenByAddress(tokenAddress)

        return {
            contractAddress: tokenExtendedData[0],
            name: tokenExtendedData[1],
            symbol: tokenExtendedData[2],
            decimals: parseInt(tokenExtendedData[3]),
            logoUrl: tokenExtendedData[4],
            owner: tokenExtendedData[5],
            mintable: tokenExtendedData[6],
        };
    } catch (error) {
        logger.error(
            "[getTokenByAddress] Could not find the request token for " + tokenAddress
        );
        return null;
    }
};

export const getTokenBySymbol = async (
    ethersInstance: Wallet,
    accountAddress: string,
    tokenSymbol: string
) => {
    try {
        const TokenFactoryInstance = getTokenFactoryInstance(ethersInstance)
        const tokenExtendedData: TokenByAddressReturn =
            await TokenFactoryInstance.getToken(tokenSymbol)

        logger.info("tokenExtendedData: ", tokenExtendedData);
        return {
            contractAddress: tokenExtendedData[0],
            name: tokenExtendedData[1],
            symbol: tokenExtendedData[2],
            decimals: parseInt(tokenExtendedData[3]),
            logoUrl: tokenExtendedData[4],
            owner: tokenExtendedData[5],
            mintable: tokenExtendedData[6],
        };
    } catch (error) {
        logger.error(
            "[getTokenBySymbol] Could not find the request token for " + tokenSymbol
        );
        return null;
    }
};

export const getLogoHash = async (
    ethersInstance: Wallet,
    accountAddress: string,
    tokenAddress: string
): Promise<string | null> => {
    try {
        const coinContractInstance = getTokenInstance(ethersInstance, tokenAddress)
        return await coinContractInstance.getLogoHash()
    } catch (error) {
        logger.error("Could not find the request logohash for " + tokenAddress);
        return null;
    }
};

export const getContractHash = async (
    ethersInstance: Wallet,
    accountAddress: string,
    tokenAddress: string
): Promise<string | null> => {
    try {
        const coinContractInstance = getTokenInstance(ethersInstance, tokenAddress)
        return await coinContractInstance.getContractHash();
    } catch (error) {
        logger.error("Could not find the request contractHash for " + tokenAddress);
        return null;
    }
};

export const coinSend = async (
    ethersInstance: Wallet,
    tokenAddress: string,
    senderAddress: string,
    receiverAddress: string,
    amount: number
) => {
    const coinContractInstance = getTokenInstance(ethersInstance, tokenAddress)
    try {
        let transfer = await coinContractInstance.transfer(receiverAddress, amount)
        transfer = await transfer.wait()
        logger.info("transfer: ", transfer);
    } catch (error) {
        logger.error("Something went wrong while trying to complete a transfer");
        throw error; //retrowing
    }
};

export const getCoinTransactions = async (
    page: number,
    amount: number,
    tokenAddress: string,
    daoAddress: string | null
): Promise<any[]> => {
    try {
        const url = "/notifications";
        let params;
        if (daoAddress) {
            params = new URLSearchParams({
                page: page.toString(),
                limit: amount.toString(),
                token_address: tokenAddress,
                dao_address: daoAddress,
            });
        } else {
            params = new URLSearchParams({
                page: page.toString(),
                limit: amount.toString(),
                token_address: tokenAddress,
            });
        }
        const response = await axiosNotifications.get(url, {params});
        const data: NotificationType[] = response.data;
        logger.info(response.data);
        return data;
    } catch (error) {
        logger.error("Could not find the request contractHash for " + tokenAddress);
        return [];
    }
};

export const getTokensFromMetadata = async (type: string, page: number) => {
    const url = "/token/getTokens";
    const params = new URLSearchParams({
        type: type,
        page: page + "",
    });
    const tokensList = await axiosMetadata.get(url, {params});
    return tokensList.data.tokens;
};

export const getTokensOwnedFromMetadata = async (
    owner: string,
    type: string,
    page: number,
    withBalance: boolean
) => {
    const url = "/token/getTokensOwned";
    const params = new URLSearchParams({
        owner: owner,
        type: type,
        page: page + "",
        withBalance: withBalance.toString(),
    });
    const tokensOwnedList = await axiosMetadata.get(url, {params});
    return tokensOwnedList.data.tokens;
};

export const getSymbolListFromMetadata = async (type: string, page: number) => {
    const url = "/token/getSymbolList";
    const params = new URLSearchParams({
        type: type,
        page: page + "",
    });
    const symbolOwnedList = await axiosMetadata.get(url, {params});
    console.log("symbolOwnedList: ", symbolOwnedList);
};

export const getTokenFromMetadata = async (
    contractAddress: string,
    symbol: string
) => {
    const url = "/token/getToken";
    const params = new URLSearchParams();
    if (contractAddress !== "") {
        params.set("contractAddress", contractAddress);
    }
    if (symbol !== "") {
        params.set("symbol", symbol);
    }
    const token = await axiosMetadata.get(url, {params});
    console.log("token: ", token);
    return token.data.token;
};

export const getTokenWithBalanceFromMetadata = async (
    owner: string,
    contractAddress?: string,
    symbol?: string
) => {
    const url = "/token/getTokenWithBalance";
    const params = new URLSearchParams();
    params.append("owner", owner);
    if (contractAddress) {
        params.append("contractAddress", contractAddress);
    }
    if (symbol) {
        params.append("symbol", symbol);
    }
    const token = await axiosMetadata.get(url, {params});
    return token.data.tokens;
};