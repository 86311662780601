import config from '../config'
import { logger } from '../utilities/logger/logger'
import { CrowdsaleData } from './crowdsaleAPI'
import axios from '../utilities/backend/axios-firstlife-api'
import { ethers, utils, Wallet } from 'ethers'

import axios_places from '../utilities/backend/axios-firstlife-api'
import { filter } from 'lodash'

const getDaoFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.DAO_FCTRY_ABI)
    return new ethers.Contract(
        config.smartContracts.DAO_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    )
}

export const getDaoTemplateInstance = (
    ethersInstance: Wallet,
    daoAddress: string
) => {
    const abi_template = new utils.Interface(config.smartContracts.CCDAO_ABI)

    return new ethers.Contract(daoAddress, abi_template, ethersInstance)
}

const getTokenInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(
        config.smartContracts.TKN_TMPLT_ABI
    )

    return new ethers.Contract(address, abi_template, ethersInstance)
}

const getCrowdsaleFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(
        config.smartContracts.CRWDSL_FCTRY_ABI
    )

    return new ethers.Contract(
        config.smartContracts.CRWDSL_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    )
}

/**
 *
 * @param ethersInstance
 * @param firstLifePlaceId
 *
 * @returns the corresponding ccdao ADDRESS given the related firstLifePlaceId
 */
export const getDAOAddress = async (
    ethersInstance: Wallet,
    firstLifePlaceId: string
): Promise<string | null> => {
    const DAOFactoryContractInstance = getDaoFactoryInstance(ethersInstance)
    try {
        let dao = await DAOFactoryContractInstance.getDAO(firstLifePlaceId)
        if (dao === '0x0000000000000000000000000000000000000000') {
            return null
        } else {
            return dao
        }
    } catch {
        logger.error(
            `could not find the DAO related to the FLplaceid ${firstLifePlaceId} on the contract`
        )
        return null
    }
}

export const DAOFactoryCreateDAO = async (
    ethersInstance: Wallet,
    name: string,
    firstlifePlaceID: string,
    daoUri: string,
    accountAddress: string,
    daoType: string,
    coordinates: any
): Promise<boolean> => {
    const DAOFactoryContractInstance = getDaoFactoryInstance(ethersInstance)
    try {
        const result = await DAOFactoryContractInstance.createDAO(
            name,
            firstlifePlaceID,
            daoUri,
            config.smartContracts.TKN_FCTRY_ADDR,
            config.smartContracts.CRWDSL_FCTRY_ADDR,
            config.smartContracts.EXCHNG_FCTRY_ADDR
        )
        const receipt = await result.wait()
        logger.info(receipt)
        const event = receipt.events.filter(
            (ev: any) => ev.event === 'DAOAdded'
        )
        logger.info('event: ', event)
        const daoAddress = event[0].args[1]
        if (daoAddress) {
            logger.info('daoAddress: ', daoAddress)
            const response = await axios.post('web3locations', {
                thingId: firstlifePlaceID,
                coords: coordinates,
                domain_id: 17,
                client_id: config.network.authserver.firstlife_auth.client_id,
                web3_address: daoAddress,
                web3_type: daoType,
                factory_address: config.smartContracts.DAO_FCTRY_ADDR,
            })
            logger.info('response: ', response)
            return true
        } else {
            return false
        }
    } catch (error) {
        logger.info(error)
        return false
    }
}

export const DAOFactoryIsAddressDAO = async (
    ethersInstance: Wallet,
    userAddress: string,
    toCheckAddress: string
): Promise<boolean> => {
    const DAOFactoryContractInstance = getDaoFactoryInstance(ethersInstance)
    try {
        return await DAOFactoryContractInstance.isAddressDAO(toCheckAddress)
    } catch (e) {
        return false
    }
}

export const DAOFactoryGetDaoAddressJoinedByUser = async (
    ethersInstance: Wallet,
    userAddress: string
): Promise<string[]> => {
    const DAOFactoryContractInstance = getDaoFactoryInstance(ethersInstance)

    const joinedDAOs =
        await DAOFactoryContractInstance.getDaoAddressJoinedByUser(userAddress)
    let filtered = []
    //we only take DAO where the user has a role greater than not member (0)
    for (const daoAddress of joinedDAOs) {
        if ((await DAOgetRole(ethersInstance, daoAddress, userAddress)) > 0) {
            filtered.push(daoAddress)
        }
    }
    return filtered
}

export const DAOTransferToken = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    symbol: string,
    to: string,
    amount: number
): Promise<boolean> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        let transfer = await CcDAOContractInstance.transferToken(
            symbol,
            amount,
            to
        )
        transfer = await transfer.wait()
        logger.info(transfer)
        return true
    } catch (error) {
        logger.error(
            `Transaction did not complete, Error in DAOTransferToken => ${error}`
        )
        return false
    }
}

export const DAOCreateToken = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    name: string,
    symbol: string,
    decimals: number,
    logoURL: string,
    logoHash: string,
    hardCap: number,
    contractHash: string
): Promise<any> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        let res = await CcDAOContractInstance.createToken(
            name,
            symbol,
            decimals,
            logoURL,
            logoHash,
            hardCap,
            contractHash
        )
        res = await res.wait()
        logger.log(`DAOCreateToken successful ----> `, res)
        return res
    } catch (error: any) {
        logger.error('error in DAOCreateToken', error)
        return null
    }
}

export const DAOMintToken = async (
    ethersInstance: any,
    senderAddress: string,
    DAOAddress: string,
    ticker: string,
    amount: number
): Promise<any> => {
    //fixme return value with correct type
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        await CcDAOContractInstance.mintToken(ticker, amount)
        return true
    } catch (error: any) {
        logger.log('Something went wrong in DAOMintToken ', error)
        return false
    }
}

export const DAOCreateCrowdsale = async (
    ethersInstance: Wallet,
    senderAccount: string,
    DAOAddress: string,
    crowdsaleData: CrowdsaleData,
    coords: { lat: number; lng: number }
): Promise<boolean> => {
    const {
        tokenToGiveAddr,
        tokenToAccept,
        start,
        end,
        acceptRatio,
        giveRatio,
        maxCap,
        metadata,
    } = crowdsaleData
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    const crowdsaleFactoryInstance = getCrowdsaleFactoryInstance(ethersInstance)
    try {
        let creation = await CcDAOContractInstance.createCrowdsale(
            tokenToGiveAddr,
            tokenToAccept,
            start,
            end,
            acceptRatio,
            giveRatio,
            maxCap,
            metadata[0],
            metadata[1],
            metadata[2],
            metadata[3],
            metadata[4]
        )
        creation = await creation.wait()
        logger.info(creation)
        let filterFrom =
            crowdsaleFactoryInstance.filters.CrowdsaleAdded(DAOAddress)

        let event = await crowdsaleFactoryInstance.queryFilter(
            filterFrom,
            creation.blockNumber,
            creation.blockNumber
        )
        logger.info('event: ', event)
        if (event.length > 0) {
            if (event[0].args) {
                const crowdsaleAddress = event[0].args[1]
                if (crowdsaleAddress) {
                    if (coords) {
                        const response = await axios_places.post(
                            'web3locations',
                            {
                                thingId: metadata[4],
                                coords: [coords.lng, coords.lat],
                                domain_id: 17,
                                client_id:
                                    config.network.authserver.firstlife_auth
                                        .client_id,
                                web3_address: crowdsaleAddress,
                                web3_type: 'crowdsale',
                                factory_address:
                                    config.smartContracts.CRWDSL_FCTRY_ADDR,
                            }
                        )
                        logger.info('response: ', response)
                    }
                    return true
                }
            }
        }
        return false
    } catch (error: any) {
        logger.info(error)
        return false
    }
}

export const DAOUnlockCrowdsale = async (
    ethersInstance: Wallet,
    DAOAddress: string,
    crowdsaleAddress: string,
    senderAccount: string,
    tokenToGiveAddr: string,
    amount: number
): Promise<boolean> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        let unlock = await CcDAOContractInstance.unlockCrowdsale(
            crowdsaleAddress,
            tokenToGiveAddr,
            amount
        )
        unlock = await unlock.wait()
        logger.info(unlock)
        return true
    } catch (error) {
        logger.info(error)
        return false
    }
}

export const DAOStopCrowdsale = async (
    ethersInstance: Wallet,
    DAOAddress: string,
    crowdsaleAddress: string,
    accountAddress: string
): Promise<boolean> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        let stop = await CcDAOContractInstance.stopCrowdsale(crowdsaleAddress)
        stop = await stop.wait()
        logger.info(stop)
        return true
    } catch {
        return false
    }
}

export const DAOJoinCrowdsale = async (
    ethersInstance: Wallet,
    DAOAddress: string,
    crowdsaleAddress: string,
    amount: number,
    tokenAddress: string,
    accountAddress: string
): Promise<boolean> => {
    try {
        const CcDAOContractInstance = getDaoTemplateInstance(
            ethersInstance,
            DAOAddress
        )
        const tokenContractInstance = getTokenInstance(
            ethersInstance,
            tokenAddress
        )
        const symbol = await tokenContractInstance.symbol()
        let join = await CcDAOContractInstance.joinCrowdsale(
            crowdsaleAddress,
            amount,
            symbol
        )
        join = await join.wait()
        logger.info(join)
        return true
    } catch (error) {
        logger.info(error)
        return false
    }
}

export const DAOCreateExchange = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    coinsOfferedAddr: string[],
    amountsOffered: number[],
    coinsRequiredAddr: string[],
    amountsRequired: number[],
    repeatings: number,
    expirationDate: number
) => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        console.log('in DAOCreateExchange function', CcDAOContractInstance)
        let create = await CcDAOContractInstance.createExchange(
            coinsOfferedAddr,
            coinsRequiredAddr,
            amountsOffered,
            amountsRequired,
            repeatings,
            expirationDate
        )
        create = await create.wait()
        logger.info(create)
    } catch (error) {
        logger.error('[DAOCreateExchange]', error)
    }
}

export const DAOAcceptExchange = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    exchangeAddress: string,
    coinsRequired: string[],
    amountsRequired: number[],
    repeatings: number
) => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        let accept = await CcDAOContractInstance.acceptExchange(
            exchangeAddress,
            coinsRequired,
            amountsRequired,
            repeatings
        )
        accept = await accept.wait()
        logger.info(accept)
    } catch (error) {
        logger.error('[DAOAcceptExchange]', error)
    }
}

export const DAOCancelExchange = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    exchangeAddress: string
) => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        let cancel = await CcDAOContractInstance.cancelExchange(exchangeAddress)
        cancel = await cancel.wait()
        logger.info(cancel)
    } catch (error) {
        logger.error('[DAOCancelExchange]', error)
    }
}

export const DAORenewExchange = async (
    ethersInstance: any,
    senderAddress: string,
    DAOAddress: string,
    exchangeAddress: string,
    expiration: number
) => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        let renew = await CcDAOContractInstance.renewExchange(
            exchangeAddress,
            expiration
        )
        renew = await renew.wait()
        logger.info(renew)
    } catch (error) {
        logger.error('[DAORenewExchange]', error)
    }
}

export const DAORefillExchange = async (
    ethersInstance: Wallet,
    senderAddress: string,
    DAOAddress: string,
    exchangeAddress: string,
    coinsOfferedAddr: string[],
    amountsOffered: number[],
    repeatings: number
) => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        let refill = await CcDAOContractInstance.refillExchange(
            exchangeAddress,
            coinsOfferedAddr,
            amountsOffered,
            repeatings
        )
        refill = await refill.wait()
        logger.info(refill)
    } catch (error) {
        logger.error('[DAORefillExchange]', error)
    }
}

export const DAOgetName = async (
    ethersInstace: any,
    DAOAddress: string
): Promise<string> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstace,
        DAOAddress
    )
    return await CcDAOContractInstance.getDaoName()
}

export const DAOgetRole = async (
    ethersInstance: Wallet,
    DAOAddress: string,
    userAddress: string
): Promise<number> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    const role = await CcDAOContractInstance.getRole(userAddress)

    logger.info('role: ', role.toNumber())
    return role.toNumber()
}

export const DAOgetRefundFromCrowdsale = async (
    ethersInstance: Wallet,
    DAOAddress: string,
    crowdsaleAddress: string,
    accountAddress: string,
    amount: number
): Promise<boolean> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )

    try {
        let refund = await CcDAOContractInstance.refundMeCrowdsale(
            crowdsaleAddress,
            amount
        )
        refund = await refund.wait()
        logger.info(refund)
        return true
    } catch (error) {
        logger.info(error)
        return false
    }
}

export const getDaoUri = async (
    ethersInstance: Wallet,
    DAOAddress: string
): Promise<string | null> => {
    const CcDAOContractInstance = getDaoTemplateInstance(
        ethersInstance,
        DAOAddress
    )
    try {
        return await CcDAOContractInstance.getDaoUri()
    } catch (error) {
        logger.info('error', error)
        return null
    }
}
