import { Grid, Switch, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types";


type NftCollectionFormTransfereable = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    isForItems: boolean,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    t: TFunction
}

export const NftCollectionFormTransfereable = ({
    isForItems,
    values,
    setFieldValue,
    t
}: NftCollectionFormTransfereable) => {

    const handleCheckIsTransferable = (checked: boolean) => {
        setFieldValue("isTransferableOnlyOnce", checked);
    }

    return (
        <Grid container item xs>
            <Grid item xs>
                <Typography align="left">
                    {t("isTransferable")}
                </Typography>
            </Grid>
            <Grid item xs="auto">
                <Switch
                    name={"isTransferableOnlyOnce"}
                    checked={values.isTransferableOnlyOnce}
                    disabled={isForItems}
                    onChange={(event, checked) => {
                        handleCheckIsTransferable(checked);
                    }}
                />
            </Grid>
        </Grid>
    )
}