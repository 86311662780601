import {Button, CircularProgress, Grid, ListItemText, MenuItem, TextField, Typography} from "@material-ui/core";

import React, {useEffect, useState} from "react";

import FeaturedCard from "../../../components/UI/Card/FeaturedCard/FeaturedCard";

import {createStyles, makeStyles, useTheme} from "@material-ui/core/styles";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";

import {getOwnedPlaces, PlacesDetailsType} from "../../../api/placeAPI";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createDao} from "../../../store/slices/daoSlice";

import PlaceIcon from '@material-ui/icons/Place';
import Loading from "../../../components/UI/Loading/Loading";
import SplashScreen from "../../../components/UI/SplashScreen/SplashScreen";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {logger} from "../../../utilities/logger/logger";
import {DaoTypes} from "../../../types/dao.types";

const useStyles = makeStyles((theme) => {
    return createStyles({
        select: {
            marginTop: theme.spacing(4),
            minWidth: "250px",
            maxWidth: "400px",
        },
        buttons: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.grey[200],
            margin: theme.spacing(1)
        },
        loading: {
            marginTop: '3vh'
        },
        descriptionLabel: {
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            padding: "13px",
        },
    });
});



const DaoCreationForm = () => {
    const {t} = useTranslation(['DaoCreateForm'])
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [ownedPlacesList, setOwnedPlacesList] = useState<any[]>([]);
    const [loadingPlacesList, setLoadingPlacesList] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState<any>(null);

    const userProfile = useAppSelector(state => state.user.userProfile)
    const loading = useAppSelector(state => state.dao.creationLoading)
    const ethersInstance = useAppSelector(state => state.ethers.ethersInstance)
    useEffect(() => {
        async function getPlaces() {
            setLoadingPlacesList(true)
            if (userProfile && userProfile.id != null && ethersInstance != null) {
                const ownedPlaceList = await getOwnedPlaces(ethersInstance, userProfile.id);
                setOwnedPlacesList(ownedPlaceList)
            }
            setLoadingPlacesList(false)
        }

        getPlaces()
    }, []);

    const handlePlaceSelect = (selectedPlaceIndex: number) => {
        const selectedPlace = ownedPlacesList[selectedPlaceIndex];
        setSelectedPlace(selectedPlace);
        formik.setFieldValue('placeID',selectedPlace.id);
    }

    const handleTypeSelect = (selectedType: string) => {
        logger.info(selectedType)
        formik.setFieldValue('daoType', selectedType);
    }

    const handleSubmit = () => {
        formik.handleSubmit();
    }

    const getValidationSchema = () => {
        return Yup.object().shape({
            'daoName': Yup.string().required(t('nameRequired')),
            'placeID': Yup.mixed().required(t('placeRequired')),
            'daoDescription': Yup.mixed().required(t('descriptionRequired')),
            'daoType': Yup.string().required(t('typeRequired'))
        })
    }

    const formik = useFormik({
        initialValues: {
            'daoName': '',
            'placeID': '',
            'daoDescription': '',
            'daoType': ''
        },
        validationSchema: getValidationSchema(),
        onSubmit: (values) => {
            if(selectedPlace) {
                dispatch(createDao(values.daoName, values.placeID, values.daoDescription, values.daoType, selectedPlace.geometry.coordinates));
                formik.resetForm();
                history.push('/')
            }
        }
    });

    if (loadingPlacesList) {
        return (
            <Loading withLoader={true} title={t('loadingPlaces')}/>
        )
    }
    if (ownedPlacesList.length === 0) {
        return (
            <SplashScreen title={t('noPlacesWarningMessage')} avatarProps={{'big': true, 'warning': true}}>
                <Typography variant="h5">{t('noPlaces')}</Typography>
            </SplashScreen>
        )
    }

    return (

        <>
            <Grid container direction='column' justifyContent='center' alignItems='center' spacing={3}
                  style={{marginTop: 10}}>
                <FeaturedCard title={t('mainTitle')}>
                    <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>

                        <Grid item xs={12}>
                            <TextField
                                style={{alignItems: "center"}}
                                id={'daoName'}
                                name={'daoName'}
                                label={t('nameInput')}
                                fullWidth
                                type="text"
                                value={formik.values['daoName']}
                                onChange={(event) => {
                                    formik.setFieldValue('daoName', event.target.value);
                                }}
                                error={(formik.errors['daoName'] != null) && formik.touched['daoName']}
                                helperText={formik.touched['daoName'] ? formik.errors['daoName'] : null}/>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                select
                                id={'placeID'}
                                name={'placeID'}
                                multiline
                                className={classes.select}
                                onChange={(event) => handlePlaceSelect(parseInt(event.target.value))}
                                label={t('placeInput')}
                                error={(formik.errors['placeID'] != null) && formik.touched['placeID']}
                                helperText={formik.touched['placeID'] ? formik.errors['placeID'] : null}
                            >
                                {ownedPlacesList.map((place: any, index) => {
                                    return (
                                        <MenuItem key={place.address} value={index}>
                                            <ListItemText>
                                                <Typography variant={"subtitle1"}> <PlaceIcon style={{marginRight: "5px"}}/>
                                                    {place.properties.name}, {place.properties.address}</Typography>
                                            </ListItemText>
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                select
                                id={'type'}
                                name={'type'}
                                className={classes.select}
                                onChange={(event) => handleTypeSelect(event.target.value)}
                                label={t('daoType')}
                                error={(formik.errors['daoType'] != null) && formik.touched['daoType']}
                                helperText={formik.touched['daoType'] ? formik.errors['daoType'] : null}
                            >
                                {DaoTypes.map((type) => {
                                    return (
                                        <MenuItem key={type} value={type}>
                                            <Grid container justifyContent="flex-start" alignItems="flex-start">
                                                <Grid item xs={12}>
                                                    {t(type)}
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1"
                                        style={{marginTop: 50}}>{t('descriptionInputHelper')}</Typography>
                            <TextField
                                id={'daoDescription'}
                                name={'daoDescription'}
                                label={t('descriptionLabel')}
                                fullWidth
                                multiline
                                type="text"
                                value={formik.values['daoDescription']}
                                onChange={(event) => {
                                    formik.setFieldValue('daoDescription', event.target.value);
                                }}
                                error={(formik.errors['daoDescription'] != null) && formik.touched['daoDescription']}
                                helperText={formik.touched['daoDescription'] ? formik.errors['daoDescription'] : null}/>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: 50}}>
                            <Button
                                disabled={loading}
                                variant='contained'
                                color='primary'
                                style={{marginTop: "10px"}}
                                onClick={handleSubmit}
                            >
                                {t('Common:create')}
                            </Button>
                        </Grid>
                        {loading ? <Grid container item justifyContent='center' className={classes.loading}>
                            <CircularProgress color="primary"/>
                        </Grid> : null}

                    </form>
                </FeaturedCard>
            </Grid>
        </>

    );
}

export default DaoCreationForm;
