import * as Yup from "yup";
import {TFunction} from "i18next";

export default function getValidationSchema(t: TFunction) {
  const FILE_SIZE = 16000000; //~16mb
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  return Yup.object().shape({
    getFromCollection: Yup.boolean(),
    nftName: Yup.string().required(),
    nftDescription: Yup.string().required(),
    imgFile: Yup.mixed().when("getFromCollection", {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.mixed()
        .required()
        .test(
          "fileSize",
          t("Common:fileSizeLarge"),
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          "fileType",
          t("Common:formatUnsupported"),
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
    collection: Yup.object({
      name: Yup.string().required(),
      symbol: Yup.string().required(),
      contractAddress: Yup.string().required(),
    }),
    attributes: Yup.array(
      Yup.object({
        trait_type: Yup.string(),
        required: Yup.boolean(),
        value: Yup.string().when("required", {
          is: true,
          then: Yup.string().required(),
        }),
      })
    ),
  });
}
