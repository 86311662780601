import config from '../config'
import {
    CollectionCreateDataApi,
    collectionProps,
    NftCreateDataApi,
    typesAllowed,
} from '../types/nft.types'
import axios from '../utilities/backend/axios-metadata'
import { logger } from '../utilities/logger/logger'
import {ethers, utils, Wallet} from "ethers";


const getNftTemplateInstance = (ethersInstance: Wallet, nftAddress: string) => {
    const abi_template = new utils.Interface(config.smartContracts.NFT_TMPLT_ABI)
    return new ethers.Contract(nftAddress, abi_template, ethersInstance)
}

export const getNftFactoryInstance = (ethersInstance: Wallet) => {
    const abi_factory = new utils.Interface(config.smartContracts.NFT_FCTRY_ABI)
    return new ethers.Contract(
        config.smartContracts.NFT_FCTRY_ADDR,
        abi_factory,
        ethersInstance
    )
}
export const createNftCollection = async (
    ethersInstance: Wallet,
    accountAddress: string,
    collectionData: CollectionCreateDataApi
) => {
    const { name, symbol, collectionURI } = collectionData

    const nftFactoryInstance = getNftFactoryInstance(ethersInstance)
    logger.info('symbol: ', nftFactoryInstance)

    let response = await nftFactoryInstance.createNftCollection(
        name,
        symbol,
        collectionURI,
        typesAllowed.artistic
    )
    response = await response.wait()
    logger.info('symbol: ' + symbol)
    logger.info(response)
    return response
}

export const createNft = async (
    ethersInstance: Wallet,
    accountAddress: string,
    nftData: NftCreateDataApi
) => {
    console.log('in createNft function')
    const { tokenUri, collectionAddress, numbersNft, isTransferable } = nftData
    const nftFactoryInstance = getNftFactoryInstance(ethersInstance)
    let response
    let nftCreated = 0
    let isOkay = true
    let gasAmount = 0
    let nftxTransaction = 0
    while (nftCreated < numbersNft) {
        const block = await ethersInstance.provider.getBlock('latest')
        const nftLeft = numbersNft - nftCreated
        if (isOkay) {
            gasAmount = await nftFactoryInstance.estimateGas
                .createNftForCollection(
                    collectionAddress,
                    tokenUri,
                    1,
                    isTransferable,
                    typesAllowed.artistic
                )
                .then((gasAmount: any) => {
                    logger.info('gasAmount for 1: ', gasAmount)
                    return gasAmount
                })
                .catch((error: any) => {
                    logger.info('error: ', error)
                })
            nftxTransaction = Math.trunc(Number(block.gasLimit) / gasAmount)
        } else {
            nftxTransaction--
        }

        gasAmount = await nftFactoryInstance.estimateGas
            .createNftForCollection(
                collectionAddress,
                tokenUri,
                nftxTransaction < nftLeft ? nftxTransaction : nftLeft,
                isTransferable,
                typesAllowed.artistic
            )
            .then((gasAmount: any) => {
                logger.info(
                    'gasAmount for ' +
                        (nftxTransaction < nftLeft
                            ? nftxTransaction
                            : nftLeft) +
                        ': ' +
                        gasAmount
                )
                isOkay = true
                return gasAmount
            })
            .catch((error: any) => {
                logger.info('error: ', error)
                isOkay = false
            })
        if (nftxTransaction < nftLeft) {
            response = await nftFactoryInstance.createNftForCollection(
                collectionAddress,
                tokenUri,
                nftxTransaction,
                isTransferable,
                nftData.type
            )
            response = await response.wait()
            logger.info(response)
            nftCreated += nftxTransaction
        } else {
            response = await nftFactoryInstance.createNftForCollection(
                collectionAddress,
                tokenUri,
                nftLeft,
                isTransferable,
                nftData.type
            )
            response = await response.wait()
            logger.info(response)
            nftCreated += nftLeft
        }
    }
    return response
}

export const isCollection = async (
    ethersInstance: Wallet,
    accountAddress: string,
    collectionAddress: string
) => {
    const nftFactoryInstance = getNftFactoryInstance(ethersInstance)
    return await nftFactoryInstance.isCollection(collectionAddress)
}

export const isCreatedByMe = async (
    ethersInstance: Wallet,
    accountAddress: string,
    collectionAddress: string
) => {
    const nftFactoryInstance = getNftFactoryInstance(ethersInstance)
    return await nftFactoryInstance.isCreatedByMe(collectionAddress)
}

export const getCollectionOwned = async (accountAddress: string) => {
    const url = '/nft/getCollections'
    const params = new URLSearchParams({
        owner: accountAddress,
        page: '0',
    })
    const collectionList = await axios.get(url, { params })
    console.log(collectionList.data)
    return collectionList.data.nfts
}

export const getCollectionCreated = async (accountAddress: string) => {
    const url = '/nft/getCollectionsCreated'
    const params = new URLSearchParams({
        owner: accountAddress,
    })
    const collectionList = await axios.get(url, { params })
    return collectionList.data.nfts
}

export const getCollection = async (
    accountAddress: string,
    contractAddress: string
): Promise<collectionProps> => {
    const url = '/nft/getCollection'
    logger.info('getCollection', accountAddress, contractAddress)
    const params = new URLSearchParams({
        contractAddress: contractAddress,
        owner: accountAddress,
    })
    const collection = await axios.get(url, { params })
    return collection.data.collection
}

export const getNftsOwned = async (
    owner: string,
    sortBy: string,
    page: string
) => {
    console.log('getNftsOwned', owner, sortBy, page)
    const nfts = await getNfts(owner, undefined, sortBy, page + '')
    return nfts
}

export const getCollectionBySymbol = async (symbol: string) => {
    const url = '/nft/getCollectionFromSymbol'
    const params = new URLSearchParams({
        symbol: symbol,
    })
    const collection = await axios.get(url, { params })
    console.log(collection.data)
    return collection.data.collection
}

export const getNftsFromCollection = async (
    accountAddress: string,
    contractAddress: string,
    page: number,
    sortBy: string
) => {
    const nfts = await getNfts(
        accountAddress,
        contractAddress,
        sortBy,
        page + ''
    )
    return nfts
}

export const getNfts = async (
    accountAddress: string,
    contractAddress: string | undefined,
    sortBy: string,
    page: string
) => {
    let nfts = []
    try {
        let url = `/nft/getNfts?owner=${accountAddress}&sortBy=${sortBy}&page=${page}`
        if (contractAddress) {
            url += `&contractAddress=${contractAddress}`
        }
        const nftList = await axios.get(url)
        nfts = nftList.data.nfts
    } catch (error) {
        console.log(error)
    }
    return nfts
}

export const sendNFT = async (
    ethersInstance: Wallet,
    accountAddress: string,
    to: string,
    nftContract: string,
    tokenId: string
) => {
    const nftTemplateInstance = getNftTemplateInstance(
        ethersInstance,
        nftContract
    )
    let send = await nftTemplateInstance[
        'safeTransferFrom(address,address,uint256)'
    ](accountAddress, to, tokenId)
    send = await send.wait()
}

export const isOwner = async (
    ethersInstance: Wallet,
    accountAddress: string,
    nftContract: string,
    tokenId: string
) => {
    const nftTemplateInstance = getNftTemplateInstance(
        ethersInstance,
        nftContract
    )
    const owner = await nftTemplateInstance.ownerOf(tokenId)
    return owner === accountAddress
}

export const getAddressFromSymbolAPI = async (symbol: string) => {
    const url = `nft/getCollectionFromSymbol?symbol=${symbol}`
    const collection = await axios.get(url)
    if (collection == null)
        throw new Error(`Collection information null in getAddressFromSymbol`)
    console.log(collection.data)
    return collection.data.collection.contractAddress
}

export const getTokenByID = async (
    collectionAddress: string,
    tokenID: string
) => {
    console.log('getTokenByID', collectionAddress, tokenID)
    const url = `nft/${collectionAddress}/${tokenID}`
    const token = await axios.get(url)
    return token.data
}
