import { Button, Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"
import { getItemByID } from "../../../../../store/slices/libOfThingsSlice"
import { ItemImage } from "../../MyItems/ItemImage"
import { Item } from "../../../../../types/libofthings.type";
import { acceptPreorderRequest, denyPreorderRequest } from "../../../../../store/slices/calendarSlice"
import { toast } from "react-hot-toast"
import { contentStyle, contentWrapperStyle, itemNameStyle } from "../../../Styles/Preorders.style"
import { DialogWithInfo } from "../../../BasicComponents/DialogWithInfo"
import { useTranslation } from "react-i18next"
import { acceptStyle, denyStyle } from "../../../Styles/Buttons.style"
import { duration } from "../../../../../utilities/costants.notifications"
import { Preorder } from "../../../../../types/calendar.type"

export const PreorderBody = ({ preorder, removeFromList }: {
    preorder: Preorder,
    removeFromList?: (id: string, accepted: boolean) => void
}) => {
    const dispatch = useAppDispatch();

    //styles
    const useAcceptStyle = acceptStyle();
    const useDenyStyle = denyStyle();
    const useContentStyle = contentStyle();
    const useContentWrapperStyle = contentWrapperStyle();
    const useItemNameStyle = itemNameStyle();

    //selectors
    const loadingPreorder = useAppSelector(state => state.calendar.loading);

    //state
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [item, setItem] = useState<Item>();

    //translations
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        (async () => {
            setItem(await dispatch(getItemByID(preorder.itemID)));
        })()
    }, [])

    useEffect(() => {
        setStartDate(new Date(preorder.startDate * 1000));
        setEndDate(new Date(preorder.endDate * 1000));
    }, [preorder])

    const handleConfirmRequest = async () => {
        const errors = await dispatch(acceptPreorderRequest(preorder.preorderID));
        handleResultOfRequest('preorderConfirmed', errors);
        if (removeFromList)
            removeFromList(preorder.preorderID, true);
    }

    const handleDenyRequest = async () => {
        const errors = await dispatch(denyPreorderRequest(preorder.preorderID));
        handleResultOfRequest('preorderDenied', errors);
        if (removeFromList)
            removeFromList(preorder.preorderID, false);
    }

    const handleResultOfRequest = (messageKey: string, errors: string[] | undefined) => {
        if (errors && errors?.length > 0) {
            toast.error(errors[0], {
                duration: duration
            });
        } else {
            toast.success(t(messageKey), {
                duration: duration
            });
        }
    }

    useEffect(() => {
        console.log(`Loading value changed: ${loadingPreorder}`);
        if (loadingPreorder) {
            toast.loading(t('loading'), {
                duration: duration
            });
        } else {
            toast.dismiss();
        }
    }, [loadingPreorder]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h1" className={useItemNameStyle.root}>
                    {
                        item != null && !loadingPreorder ? (
                            <b>{item.name}</b>
                        ) : (
                            <Skeleton />
                        )
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                {
                    <>
                        {
                            item != null && !loadingPreorder ? (
                                <ItemImage
                                    id={item.idInCollection}
                                    collectionSymbol={item.collectionSymbol}
                                    option={undefined}
                                />
                            ) : (
                                <Skeleton />
                            )
                        }
                    </>
                }
            </Grid>
            <Grid item xs={12} md={8} className={useContentStyle.root}>
                <div className={useContentWrapperStyle.root}>
                    <Typography>
                        <b>{t('acceptRequest?')}?</b>
                        <Button disabled={loadingPreorder} className={useAcceptStyle.root} onClick={() => handleConfirmRequest()}>{t('accept')}</Button>
                        <Button disabled={loadingPreorder} className={useDenyStyle.root} onClick={() => handleDenyRequest()}>{t('deny')}</Button>
                    </Typography>
                    <div style={{ position: 'relative', top: '1em', marginBottom: '1em' }}>
                        <Typography variant={'body1'} >
                            {t('requestedBy')}: <b>{preorder.borrower.slice(0, 5).concat(`...` + preorder.borrower.slice(-5))}</b>
                        </Typography>
                        <Typography variant={'body1'}>
                            {
                                startDate != null && (
                                    <>{t('startDate')}: <b>{startDate.getDate()}/{startDate.getMonth()}/{startDate.getFullYear()}</b></>
                                )
                            }
                        </Typography>
                        <Typography variant={'body1'}>
                            {
                                endDate != null && (
                                    <>{t('endDate')}: <b>{endDate.getDate()}/{endDate.getMonth()}/{endDate.getFullYear()}</b></>
                                )
                            }
                        </Typography>
                    </div>

                    {
                        item != null ? (
                            <DialogWithInfo
                                item={item}
                            />
                        ) : (
                            <Skeleton />
                        )
                    }
                </div>
            </Grid>
        </Grid>
    )
}