import React, {useEffect, useState} from "react";

//i18n
import {useTranslation} from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import maialino from "../../../../../assets/img/pig-actionable.png";
import {
    crowdsaleJoin,
    crowdsaleJoinReset,
    crowdsaleRefund,
    crowdsaleRefundReset
} from "../../../../../store/slices/crowdsaleSlice";

import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {CircularProgress, Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    center: {
        textAlign: "center"
    },
    positivePledgeValue: {
        color: theme.palette.success.main,
    },
    negativePledgeValue: {
        color: theme.palette.secondary.main,
    },
    unmodifiedPledgeValue: {
        color: "black"
    },
    centeredPledgeValue: {
        position: "absolute",
        top: "57%", //harcoded to center on the real body of the maialino
        left: "49%", //harcoded to center on the real body of the maialino
        transform: "translate(-50%, -50%)",
    }
}));

type PiggyBankProps = {
    reload: ()=> void,
    crowdsale: any, // TODO: type crowdsale
    crowdsaleEnded: boolean,
    maxJoinLeft: number,
    piggyBankClose: () => void,
    startingReservation: number,
    tokenToAcceptUserBalance: number,
    userReservations: number
}

const PiggyBank = (props: PiggyBankProps) => {
    const {
        reload,
        crowdsale,
        crowdsaleEnded,
        maxJoinLeft,
        piggyBankClose,
        startingReservation,
        tokenToAcceptUserBalance,
        userReservations
    } = props;
    const {crowdsaleAddress, acceptRatio, giveRatio, tokenToAccept, tokenToAcceptAddr, tokenToGive} = crowdsale;

    const pledgePending = useAppSelector( state =>  state.crowdsale.pledgePending);
    const refundPending = useAppSelector(state => state.crowdsale.refundPending)
    const refunded = useAppSelector(state => state.crowdsale.refunded);
    const joined = useAppSelector(state => state.crowdsale.joined);

    const [currentReservation, setCurrentReservation] = useState(parseFloat(String(userReservations)));
    const [pledgeDifference, setpledgeDifference] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [mount, setMount]= useState(false)


    const {t} = useTranslation("PiggyBank");
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(crowdsaleJoinReset())
        dispatch(crowdsaleRefundReset())
    }, [])

    useEffect(() => {
        if(mount) {
            if (joined || refunded) {
                reload()
                setpledgeDifference(0)
            }
        }
        setMount(true)
    }, [refunded, joined, mount])

    const handleSubmitPledge = async () => {
        if (pledgeDifference < 0) { //asking for a refund
            dispatch(crowdsaleRefund(crowdsaleAddress, pledgeDifference * -1, tokenToAccept.decimals));
        } else if (pledgeDifference > 0) {
            dispatch(crowdsaleJoin(crowdsaleAddress, pledgeDifference, tokenToAccept.decimals, tokenToAcceptAddr))
        }
        setOpenModal(true);
    };


    const changePledgeClicked = (type: string) => {
        if (type === "add") {
            setCurrentReservation(parseFloat((currentReservation + acceptRatio).toFixed(2)));
            setpledgeDifference(parseFloat((currentReservation + acceptRatio - userReservations).toFixed(2)));
        } else {
            setCurrentReservation(parseFloat((currentReservation - acceptRatio).toFixed(2)));
            setpledgeDifference(parseFloat((currentReservation - acceptRatio - userReservations).toFixed(2)));
        }
    };

    //current pledge message:
    let pledgeText = t('currentPledge');
    let pledgeValueClass = [classes.centeredPledgeValue, classes.unmodifiedPledgeValue].join(' ');

    if (pledgeDifference > 0) {
        pledgeText = t('addingPledge', {
            params: {
                pledgeDifference,
                acceptedCoin: crowdsale.tokenToAccept.symbol
            }
        });
        pledgeValueClass = [classes.centeredPledgeValue, classes.positivePledgeValue].join(' ');
    } else if (pledgeDifference < 0) {
        pledgeText = t('refundingPledge', {
            params: {
                pledgeDifference,
                acceptedCoin: crowdsale.tokenToAccept.symbol,
            }
        });
        pledgeValueClass = [classes.centeredPledgeValue, classes.negativePledgeValue].join(' ');
    }

    //some quick state tests
    //logger.info("1->",pledgeDifference + parseFloat(acceptRatio) > tokenToAcceptUserBalance) //cannot join anymore
    //logger.info("2->",crowdsaleEnded) //if crowdsale is over joining is not allowed anymore
    //logger.info("3->",(parseFloat(String(maxJoinLeft)) === parseFloat(String(pledgeDifference))))

    return (
        <div className={classes.center} style={{marginTop: '20px'}}>
            <Typography align="center" variant="subtitle2">
                {t('acceptingFor')}: <strong
                style={{display: 'inline'}}>{acceptRatio + ' ' + tokenToAccept.symbol}</strong>
            </Typography>
            <Typography align="center" variant="subtitle2">
                {t('returningFor')}: <strong style={{display: 'inline'}}>{giveRatio + ' ' + tokenToGive.symbol}</strong>
            </Typography>
            <Typography align="center" variant="body2">
                {t('acceptedCoinInWallet')}: <strong
                style={{display: 'inline'}}>{tokenToAcceptUserBalance + ' ' + tokenToAccept.symbol}</strong>
            </Typography>
            {/*<Typography align="left" variant="h6">*/}
            {/*    {t('minPledge')}: 10 {crowdsale.coinToGive}*/}
            {/*</Typography>*/}
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <IconButton
                    onClick={() => changePledgeClicked("sub")}
                    disabled={
                        (currentReservation === 0) //can't refund below 0 investment
                    }
                >
                    <Icon fontSize="large">remove_circle</Icon>
                </IconButton>
                <div
                    style={{position: "relative", textAlign: "center"}}
                >
                    <img
                        width="260"
                        src={maialino}
                        alt="Piggy Bank"
                    />
                    <Typography
                        variant="h3"
                        display="inline"
                        className={pledgeValueClass}>
                        {currentReservation.toFixed(2)}
                    </Typography>
                </div>
                <IconButton
                    onClick={() => changePledgeClicked("add")}
                    disabled={
                        pledgeDifference + parseFloat(acceptRatio) > tokenToAcceptUserBalance || //cannot join anymore
                        crowdsaleEnded || //if crowdsale is over joining is not allowed anymore
                        (parseFloat(String(maxJoinLeft)) === parseFloat(String(pledgeDifference)))
                    }
                >
                    <Icon fontSize="large">add_circle</Icon>
                </IconButton>
            </div>
            <Typography paragraph={true} align="center" variant="subtitle1">
                {pledgeText}
            </Typography>
            <Button
                variant="contained"
                color= {pledgeDifference>0? "primary": "secondary"}
                fullWidth
                disabled={pledgeDifference === 0 || pledgePending || refundPending}
                onClick={handleSubmitPledge}
            >
                {pledgeDifference>0? t('pledge'): 'Preleva'}

            </Button>

                {
                    pledgePending || refundPending ?  <Grid container item justifyContent='center'>
                        <CircularProgress color="primary"/>
                    </Grid> : null
                }

            {/*<PiggyBankModal*/}
            {/*    closePiggyBank={successfully => handlePledgeDone(successfully)}*/}
            {/*    openModal={openModal}*/}
            {/*    ticker={tokenToAccept.symbol}*/}
            {/*    pledgeDifference={pledgeDifference < 0 ? (pledgeDifference * -1) : pledgeDifference} //give absolute value for formatting purposes*/}
            {/*/>*/}
        </div>
    );
}

export default (PiggyBank);