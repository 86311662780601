import React, {MouseEvent, useEffect, useState} from 'react';
//routing
import {RouteComponentProps, withRouter} from 'react-router-dom';
//i18n
import {useTranslation} from 'react-i18next';

//Redux connector
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
    getSuggestedUsers, getSuggetstedUsersReset,
    userGetDataFromId,
    userSetAllProfiles,
    userSetCurrentProfile,
    userSetUserProfile
} from "../../store/slices/userSlice";
import {daoGetListBelongingToLoggedUser} from "../../store/slices/daoSlice";

import type {ExtendedUserData} from "../../types/user.type";

//Material-UI Components
import {Divider, Grid, MenuItem, Select} from '@material-ui/core';

//Snackbar provider
import {useSnackbar} from 'notistack';

//Custom Components
import MainAppBar from '../../components/UI/NavBar/MainAppBar/MainAppBar';
import LanguageFlags from '../../components/UI/LanguageFlags/LanguageFlags';
import BottomMenuBar from "../../components/BottomMenuBar/BottomMenuBar";
import ListMenu from "../../components/UI/Menu/ListMenu/ListMenu";
import Loading from "../../components/UI/Loading/Loading";
import ResponsiveDrawer from '../../components/UI/Drawer/ResponsiveDrawer';
import ContactSelectItem from '../../components/Contacts/ContactsSelect/ContactSelectItem/ContactSelectItem';

//Style injections
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';

//Menu Config
import menuConfig from '../../config/menuConfig';
import HelpModal from "../../views/Help/HelpModal/HelpModal";


import {DaoData} from "../../types/dao.types";
import {RealtimeNotificationType} from "../../api/notificationAPI";
import getNotificationText from "../../utilities/notification/notification-messages";
import {newNotfConsumed} from "../../store/slices/notificationSlice";
import {logger} from "../../utilities/logger/logger";
import CustomCircularProgress from "../../components/UI/LoadingFab/CustomCircularProgress";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        height: "100%",
        //zIndex: 100,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        //position: "relative",

        [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        }
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
        [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        }
    },
    navIconHide: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },

    gridItem: {
        width: '100%',
        marginBottom: '50px',
    },

    content: {
        //display: 'flex',
        //position: 'relative',
        flexGrow: 1,
        backgroundColor: "#FFF",
        marginTop: theme.mixins.toolbar.minHeight as number - theme.spacing(1),
        // marginLeft: 'auto',
        // marginRight: 'auto',
        padding: theme.spacing(1),
        justify: 'center',
        [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth - theme.spacing(1), //adjust for basic padding
            width: `calc(100% - ${drawerWidth}px)`
        }

    },

    logo: {
        width: 80,
        height: 80,
        margin: "0 auto",
        marginTop: 15
    }
}));

type LayoutProps = RouteComponentProps & {
    title: any,
    children: React.ReactNode,
};

const Layout = (props: LayoutProps) => {
    const {t, i18n} = useTranslation(['NotificationMessages', 'Menu', 'Common']);
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const {
        title,
        children,
    } = props

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [helpOpen, setHelpOpen] = useState<boolean>(false);
    const [profileIndex, setProfileIndex] = useState<number>(0);

    const userLoading = useAppSelector(state => state.user.loading);
    const userData = useAppSelector(state => state.user.user);
    const loadingDaoListBelongingToLoggedUser = useAppSelector(state => state.dao.loadingDaoListBelongingToLoggedUser);
    const daoListBelongingToLoggedUser = useAppSelector(state => state.dao.daoListBelongingToLoggedUser);
    const currentProfile = useAppSelector(state => state.user.currentProfile);
    const userProfile = useAppSelector(state => state.user.userProfile);
    const profilesList = useAppSelector(state => state.user.profilesList);
    const ethersInstance = useAppSelector(state => state.ethers.ethersInstance);
    const notificationSocketAuthenticated = useAppSelector(state => state.notification.notificationSocketAuthenticated)
    const newNotfID = useAppSelector(state => state.notification.newNotfID)
    const notfOfCurrentSession = useAppSelector(state => state.notification.notificationsOfCurrentSession)
    const notificationList = [...notfOfCurrentSession]
        .sort((notf1, notf2) => { //order with last created in 0 position
            const notf1_message = JSON.parse(notf1.message);
            const notf2_message = JSON.parse(notf2.message);
            return notf2_message.timestamp - notf1_message.timestamp;
        })
    const onGoingTransactions= useAppSelector(state => state.onGoingTransactions.onGoingTransactions);

    const [anchorTransactionEl, setAnchorTransactionEl] = useState<EventTarget & Element | null>(null);

    const handleTransactionList = (event:MouseEvent) => {
        setAnchorTransactionEl( event.currentTarget );
    };
    const handleCloseTransactionList = () => {
        setAnchorTransactionEl( null );
    };

    const navHandler = (path: string) => {
        drawerCloseHandler();
        setTimeout(() => props.history.push(path), 200);
    };

    // Drawer Handlers
    const drawerOpenHandler = () => setDrawerOpen(true);

    const drawerCloseHandler = () => setDrawerOpen(false);

    const helpCloseHandler = () => setHelpOpen(false);

    // homeClickedHandler = () => {
    //     this.props.history.replace('/')
    // };
    useEffect(() => {
        // Get user information from backend
        // When layout is mounting user is already authenticated, so userId should be instantiated
        const userId = localStorage.getItem('userId');
        if (!userLoading && userId != null) {
            dispatch(userGetDataFromId(userId));
        }

    }, []); //onMount


    //setting main profile (loaded in userData via the useEffect above)
    useEffect(() => {
        if (
            userData != null && //user profile loaded and ready to be stored
            userProfile == null //we didn't already store it
        ) {
            logger.info("Setting user profile");
            dispatch(userSetCurrentProfile({profile: userData}));
            dispatch(userSetUserProfile({profile: userData}));
        }
    }, [userData, userProfile]);

    useEffect(() => {
        handleCloseTransactionList()
    },[onGoingTransactions])


    //load user belonging daos into profiles
    useEffect(() => {
        //getting them ino the daoListBelongingToLoggedUser store array
        if (
            ethersInstance != null &&
            !loadingDaoListBelongingToLoggedUser &&
            daoListBelongingToLoggedUser == null //we haven't already loaded it
        ) {
            dispatch(daoGetListBelongingToLoggedUser());
        }

        //putting them into the profiles
        const profiles: (DaoData | ExtendedUserData)[] = [];
        if (userProfile != null) {
            profiles.push(userProfile);
        }
        if (
            daoListBelongingToLoggedUser != null &&
            daoListBelongingToLoggedUser?.length !== 0
        ) {
            for (const dao of daoListBelongingToLoggedUser) {
                profiles.push(dao);
            }
        }
        dispatch(userSetAllProfiles({profilesList: profiles}));

    }, [ethersInstance, loadingDaoListBelongingToLoggedUser, daoListBelongingToLoggedUser]);

    useEffect(() => {
        if (newNotfID !== null) {
            let lastNotification: RealtimeNotificationType | undefined= notificationList.find(notf => JSON.parse(String(notf._id === newNotfID)));
            if(lastNotification) {
                const notfMessage = JSON.parse(lastNotification.message)
                let messageString = '';
                try {
                    if (currentProfile) {
                        let chWallet = currentProfile.additional_properties?.commonshoodWallet
                        if (chWallet) {
                            const messageKey = (notfMessage.eventType !== 'COIN_TRANSFER' ? notfMessage.eventType : (notfMessage.sender === chWallet ? 'COIN_SENT' : 'COIN_RECEIVED'))
                            messageString = getNotificationText(messageKey, notfMessage, t, chWallet);
                            if(messageKey === 'COIN_SENT' || messageKey === 'COIN_RECEIVED'){
                                dispatch(getSuggestedUsers(1))
                            }
                        }
                    }
                } catch (error: any) {
                    messageString = error.message;
                }
                enqueueSnackbar(messageString, {variant: "success"})
                dispatch(newNotfConsumed({notificationID: newNotfID}))
            }
        }
    }, [newNotfID, notfOfCurrentSession]);


    const selectOnChange = (newlySelectedProfileIndex: number) => {
        if (profilesList && profilesList.length > 0) {
            const newlySelectedProfile = profilesList[newlySelectedProfileIndex];
            dispatch(userSetCurrentProfile({profile: newlySelectedProfile}));
            dispatch(getSuggetstedUsersReset())
            setProfileIndex(newlySelectedProfileIndex);
        }
    }

    let mainLayout: JSX.Element | null = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {/*<Loading withLoader title={t("reachingBlockchain")}/>*/}
                {/*NOTE: here we do not use a translated string via i18n because at the poin in which this message
                        is shown, i18n is not yet instantiated */}
                <Loading withLoader title="Loading..."/>
            </Grid>
        </Grid>
    );
    if (profilesList?.length !== 0 && ethersInstance != null) {
        const mainMenu = (
            <>
                <ListMenu
                    navHandler={navHandler}
                    itemList={menuConfig(t).menu.items}
                />

                <LanguageFlags i18n={i18n}/>
                <Divider/>

            </>
        );

        mainLayout = (

            <div className={classes.root}>
                <MainAppBar
                    drawerHandler={drawerOpenHandler}
                    title={title}
                    profile={currentProfile}
                />
                <ResponsiveDrawer
                    mobileOpen={drawerOpen}
                    direction={theme.direction}
                    handleDrawerToggle={drawerCloseHandler}
                    userDataComponent={(
                        <Select
                            style={{width: "100%"}}
                            onChange={(event: any) => selectOnChange(event.target.value)}
                            value={profileIndex}
                            renderValue={() => (
                                <MenuItem key={profileIndex} value={profileIndex}>
                                    <ContactSelectItem
                                        showEmail={false}
                                        contact={currentProfile}
                                    />
                                </MenuItem>
                            )}
                        >
                            {
                                profilesList?.map((contact: ExtendedUserData | DaoData, key: number) => {
                                    if (contact != null) {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                <ContactSelectItem
                                                    showEmail={true}
                                                    contact={contact}
                                                />
                                            </MenuItem>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </Select>
                    )}
                >
                    {mainMenu}
                </ResponsiveDrawer>

                {/*Main Content*/}

                <Grid container spacing={2} className={classes.content}>
                    <Grid item className={classes.gridItem} xs={12}>
                        {children}
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item>
                            <BottomMenuBar/>
                        </Grid>
                    </Grid>
                </Grid>

                {/* HELP GUIDE - currently empty*/}
                <HelpModal
                    open={helpOpen}
                    handleClose={helpCloseHandler}
                    tutorialSteps={[]}
                />
                {
                    onGoingTransactions.length >0?
                        <CustomCircularProgress anchorEl={anchorTransactionEl} handleMenu={handleTransactionList} onClose={handleCloseTransactionList}/>
                        :
                        null
                }
            </div>
        );
    }

    return (mainLayout);
}

export default withRouter(Layout);
