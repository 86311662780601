import Paper from '@material-ui/core/Paper'
import { Box, Chip, Grid, Theme, Typography } from '@material-ui/core'
import img from '../../../assets/img/mapImgUtils/img.png'
import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import { DaoContact } from '../MapUtils'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import './CardDetails.css'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import StoreIcon from '@material-ui/icons/Store'
import GroupIcon from '@material-ui/icons/Group'
import logo from '../../../assets/img/logo/240x240.png'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { useAppSelector } from '../../../store/hooks'
import { DaoTypes } from '../../../types/dao.types'
import { logger } from '../../../utilities/logger/logger'
import theme from '../../../theme/theme'

type CardDetailsPropsType = {
    setSendFormOpen: (mode: boolean) => void
    contact: DaoContact
    closeCard: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '100%',
            borderRadius: 20,
            marginBottom: '0.5vh',
            marginLeft: '0.5vh',
        },
        detailPaper: {
            padding: theme.spacing(2),
            margin: 'auto',
            borderRadius: 20,
        },
        image: {
            width: 280,
            height: 200,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        cardButtonPay: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
        },
        cardButtonClose: {
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
        cardDownloadManifest: {
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: 'primary',
                color: 'white',
            },
        },
    })
)

const bigImgStyle = {
    width: 280,
    height: 200,
}

const smallImgStyle = {
    height: 110,
}

const CardDetails = (props: CardDetailsPropsType) => {
    const { setSendFormOpen, contact, closeCard } = props
    const classes = useStyles()
    const { t } = useTranslation(['DaoCreateForm', 'Map', 'Common'])
    const [imgStyle, setImgStyle] = useState<any>(null)
    const currentProfile = useAppSelector(
        (state) =>
            state.user.currentProfile?.additional_properties?.commonshoodWallet
    )

    useEffect(() => {
        if (window.innerHeight < 760) {
            setImgStyle(smallImgStyle)
        } else {
            setImgStyle(bigImgStyle)
        }
    }, [window.innerHeight])

    const categoryIcon = () => {
        const category = contact.daoDetails.type
        return category === DaoTypes[0] ? (
            <StoreIcon style={{ color: theme.palette.primary.main }} />
        ) : category === DaoTypes[1] ? (
            <GroupIcon style={{ color: '#E44E3C' }} />
        ) : undefined
    }

    return (
        <Grid
            container
            xs={12}
            sm={5}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            style={{ position: 'absolute', bottom: 0, width: '90%' }}
            spacing={1}
        >
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        alignItems='flex-start'
                    >
                        <Grid item>
                            <Grid style={imgStyle}>
                                {contact.resources &&
                                contact.resources.length > 0 ? (
                                    <img
                                        className={classes.img}
                                        alt={contact.resources[0].name}
                                        src={
                                            config.network.resources.url +
                                            contact.resources[0].fileId
                                        }
                                    />
                                ) : (
                                    <img
                                        className={classes.img}
                                        alt={'default image'}
                                        src={logo}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                xs
                                container
                                direction='column'
                                spacing={2}
                            >
                                <Grid item xs>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        style={{ marginTop: '10px' }}
                                    >
                                        {contact.daoDetails.name}
                                    </Typography>
                                    <Chip
                                        icon={categoryIcon()}
                                        label={
                                            <Typography variant='caption'>
                                                {t(contact.daoDetails.type)}
                                            </Typography>
                                        }
                                    />
                                    <Typography
                                        variant='body2'
                                        color='textPrimary'
                                        style={{ marginTop: '15px' }}
                                    >
                                        {contact.daoDetails.creator ? (
                                            <>
                                                {t('Map:creator')}:{' '}
                                                {
                                                    contact.daoDetails.creator
                                                        .first_name
                                                }{' '}
                                                {
                                                    contact.daoDetails.creator
                                                        .last_name
                                                }
                                            </>
                                        ) : null}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='textSecondary'
                                    >
                                        {contact.daoDetails.creator ? (
                                            <>
                                                email:{' '}
                                                {
                                                    contact.daoDetails.creator
                                                        .email
                                                }
                                            </>
                                        ) : null}
                                    </Typography>
                                    {contact.daoDetails.description &&
                                    contact.daoDetails.description !== '' ? (
                                        <Box
                                            style={{
                                                height: 120,
                                                maxWidth: 400,
                                                overflowY: 'scroll',
                                                marginTop: '15px',
                                            }}
                                        >
                                            {contact.daoDetails.description &&
                                            contact.daoDetails.description !==
                                                '' ? (
                                                <>
                                                    <Typography
                                                        gutterBottom
                                                        variant='h6'
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {t(
                                                            'Map:daoDescription'
                                                        )}
                                                        :
                                                    </Typography>
                                                    <Typography
                                                        variant='body1'
                                                        gutterBottom
                                                    >
                                                        {
                                                            contact.daoDetails
                                                                .description
                                                        }
                                                    </Typography>
                                                </>
                                            ) : null}
                                        </Box>
                                    ) : null}
                                </Grid>
                                <Grid item>
                                    <Paper
                                        elevation={4}
                                        style={{
                                            marginTop: '15px',
                                            backgroundColor: '#f1f0ea',
                                            boxShadow: '10 10 10 10 #000',
                                            padding: 2,
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            color='textPrimary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:findus')}: {contact.name}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:address')}:{' '}
                                            {contact.daoPlaceAddress}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:owner')}:{' '}
                                            {contact.placeOwner}
                                        </Typography>
                                    </Paper>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid item>
                                            <Button
                                                className={
                                                    classes.cardButtonPay
                                                }
                                                style={{ marginTop: '15px' }}
                                                size='small'
                                                variant={'outlined'}
                                                onClick={() => {
                                                    setSendFormOpen(true)
                                                }}
                                                color={'primary'}
                                                disabled={
                                                    currentProfile ===
                                                    contact
                                                        .additional_properties
                                                        .commonshoodWallet
                                                }
                                            >
                                                <Typography variant='subtitle1'>
                                                    {t('Common:pay')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={
                                                    classes.cardButtonClose
                                                }
                                                style={{
                                                    marginTop: '15px',
                                                    marginLeft: '15px',
                                                }}
                                                size='small'
                                                variant={'outlined'}
                                                onClick={() => {
                                                    closeCard()
                                                }}
                                                color={'secondary'}
                                            >
                                                <Typography variant='subtitle1'>
                                                    {t('Common:close')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CardDetails
