import Paper from '@material-ui/core/Paper'
import { Grid, Theme, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import '../Cards/CardDetails.css'
import logo from '../../../assets/img/logo/240x240.png'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { PlainPlaceType } from '../CrowdsaleMapUtils'
import { logger } from '../../../utilities/logger/logger'


type CardDetailsPropsType = {
    place: PlainPlaceType
    closeCard: () => void
    setPlace: (place: PlainPlaceType) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '100%',
            borderRadius: 20,
            marginBottom: '2vh',
            marginLeft: '2vh',
        },
        detailPaper: {
            padding: theme.spacing(2),
            margin: 'auto',
            borderRadius: 20,
        },
        image: {
            width: 280,
            height: 200,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        cardButtonPay: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
        },
        cardButtonClose: {
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
        cardDownloadManifest: {
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: 'primary',
                color: 'white',
            },
        },
    })
)

const bigImgStyle = {
    width: 280,
    height: 200,
}

const smallImgStyle = {
    height: 110,
}

const PlainPlaceCard = (props: CardDetailsPropsType) => {
    const { place, closeCard, setPlace } = props
    const classes = useStyles()
    const { t } = useTranslation(['DaoCreateForm', 'Map', 'Common'])
    const [imgStyle, setImgStyle] = useState<any>(null)

    const existingPlace =
        place.firstLifePlaceID !== null &&
        place.firstLifePlaceID !== undefined &&
        place.firstLifePlaceID !== ''

    useEffect(() => {
        if (window.innerHeight < 760) {
            setImgStyle(smallImgStyle)
        } else {
            setImgStyle(bigImgStyle)
        }
    }, [window.innerHeight])

    return (
        <Grid
            container
            sm={5}
            xs={12}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            style={{ position: 'absolute', bottom: 0, width: '90%' }}
            spacing={1}
        >
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        alignItems='flex-start'
                    >
                        <Grid item>
                            <Grid style={imgStyle}>
                                {place.icon && place.icon.length > 0 ? (
                                    <img
                                        className={classes.img}
                                        alt={place.icon[0].name}
                                        src={
                                            config.network.resources.url +
                                            place.icon[0].filename
                                        }
                                    />
                                ) : (
                                    <img
                                        className={classes.img}
                                        alt={'default'}
                                        src={logo}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                xs
                                container
                                direction='column'
                                spacing={2}
                            >
                                <>
                                    <Grid item xs>
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            style={{ marginTop: '10px' }}
                                        >
                                            {existingPlace ? place.name : 'Vuoi creare un luogo qui?'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Paper
                                            elevation={4}
                                            style={{
                                                marginTop: '15px',
                                                backgroundColor: '#f1f0ea',
                                                boxShadow: '10 10 10 10 #000',
                                                padding: 2,
                                            }}
                                        >
                                            {existingPlace ? (
                                                <>
                                                    <Typography
                                                        variant='body2'
                                                        color='textSecondary'
                                                        style={{
                                                            marginTop: '5px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {t('Map:owner')}:{' '}
                                                        {place.placeOwner}
                                                    </Typography>
                                                </>
                                            ) : null}
                                            <Typography
                                                    variant='body1'
                                                    color='textPrimary'
                                                    style={{
                                                        marginTop: '5px',
                                                        fontWeight: 'bold',
                                                    }}
                                            >
                                                    {t('Map:address')}:{' '}
                                                    {place.address}
                                            </Typography>
                                            {place.coords ? <Typography
                                                    variant='body1'
                                                    color='textPrimary'
                                                    style={{
                                                        marginTop: '5px',
                                                        fontWeight: 'bold',
                                                    }}
                                            >
                                                        {t('Map:coords')}:{' '}
                                                        {place.coords.lat.toFixed(5) + ', ' +
                                                            place.coords.lng.toFixed(5)}
                                            </Typography>: null}
                                            
                                            
                                        </Paper>
                                        <Grid
                                            container
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Grid item>
                                                <Button
                                                    className={
                                                        classes.cardButtonPay
                                                    }
                                                    style={{
                                                        marginTop: '15px',
                                                    }}
                                                    size='small'
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        setPlace(place)
                                                    }}
                                                    color={'primary'}
                                                >
                                                    <Typography variant='subtitle1'>
                                                        Seleziona
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className={
                                                        classes.cardButtonClose
                                                    }
                                                    style={{
                                                        marginTop: '15px',
                                                        marginLeft: '15px',
                                                    }}
                                                    size='small'
                                                    variant={'outlined'}
                                                    onClick={() => {
                                                        closeCard()
                                                    }}
                                                    color={'secondary'}
                                                >
                                                    <Typography variant='subtitle1'>
                                                        {t('Common:close')}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default PlainPlaceCard
