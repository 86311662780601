import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getFromIpfs, getJsonFromResource } from "../../../api/resourceAPI"
import FeaturedCard from "../../../components/UI/Card/FeaturedCard/FeaturedCard"
import config from "../../../config"
import { useAppSelector } from "../../../store/hooks"
import { FormInitialValuesForItems, NftCreationFormInput } from "../../../types/nft.types"
import { logger } from "../../../utilities/logger/logger"
import { NftCollectionFormAttributes } from "./Inputs/NftCollectionFormAttributes"
import { NftCollectionFormInput } from "./Inputs/NftCollectionFormInput"
import { NftImage } from "./Inputs/NftImage"
import { AttributesTitleModal } from "./Modals/AttributesTitleModal"
import { ConfirmTitleModal } from "./Modals/ConfirmTitleModal"

export const FormContent = ({
    formikProps,
    formRef,
    modalOpened,
    classes,
    isForItems,
    existingNftToUse,
    setModalOpened,
    handleConfirmModalOpen,
}: NftCreationFormInput
) => {

    //redux
    const nftCreated = useAppSelector((state) => state.nft.nftCreated);
    const collectionList = useAppSelector((state) => state.nft.collectionList);

    //state
    const [isImageLoad, setIsImageLoad] = useState<boolean>(false);
    const [modalAttributesOpened, setAttributesModalOpened] = useState(false);
    const [tokenAcceptedAsPayment, setPaymentToken] = useState('');
    const [collectionSelected, setCollectionSelected] = useState<any>(useAppSelector((state) => state.nft.collectionSelected));
    const [metadataSelected, setMetadataSelected] = useState<any>(null);
    const [title, setTitle] = useState('');
    const [collectionListFiltered, setCollectionListFiltered] = useState<any[]>([]);
    const [attributeItem, setAttributeItem] = useState<any>(<></>);
    const [attributes, setAttributes] = useState<{
        trait_type: string;
        value: string;
        required?: boolean;
    }[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const libOfThingsLoading = useAppSelector((state) => state.libOfThings.loading);
    const nftLoading = useAppSelector((state) => state.nft.loading);

    //language
    const { t } = useTranslation(['NftCreateForm', 'Common', 'LibOfThings']);

    useEffect(() => {
        setLoading(nftLoading || libOfThingsLoading);
    }, [nftLoading, libOfThingsLoading]);

    useEffect(() => {
        if (collectionList)
            setCollectionListFiltered(collectionList);
    }, [collectionList]);

    useEffect(() => {
        setTitle(isForItems ? t("createItem") : t("title"));
    }, [])

    useEffect(() => {
        if (collectionSelected && !existingNftToUse) {
            resetFormValues();
            setInheritedMetadata(collectionSelected.collectionURI);
        } else {
            if (existingNftToUse) {
                setInheritedMetadata(existingNftToUse?.tokenUri);
            }
        }
    }, [collectionSelected]);

    const setInheritedMetadata = async (imageURI: string) => {
        let metadata = null;
        if (isIpfsURI(imageURI)) {
            metadata = await getFromIpfs(imageURI);
        } else {
            metadata = await getJsonFromResource(imageURI);
        }
        setMetadataSelected(metadata);
    }

    useEffect(() => {
        if (collectionListFiltered.length > 0) {
            setFormValues();
        }
    }, [collectionListFiltered])

    const setFormValues = () => {
        if (collectionListFiltered.length > 0) {
            let defaultCollectionToShowInSelect = filterCollectionToShowInSelect();

            if (defaultCollectionToShowInSelect) {
                formRef.current.values.collection.contractAddress = defaultCollectionToShowInSelect.contractAddress;
                formRef.current.values.collection.collectionURI = defaultCollectionToShowInSelect.collectionURI;
                formRef.current.values.collection.name = defaultCollectionToShowInSelect.name;
                formRef.current.values.collection.symbol = defaultCollectionToShowInSelect.symbol;
                formRef.current.values.collection.numberNfts = defaultCollectionToShowInSelect.numberNfts;
                // The tokenAcceptAsPayment is set to COS because it was the token chosen for the first test to do with the university
                if (isForItems) {
                    formRef.current.values.dailyPrice = 1;
                    formRef.current.values.caution = 0;
                    formRef.current.values.tokenAcceptedAsPayment = 'COS';
                }
                setCollectionSelected(defaultCollectionToShowInSelect);
            }
        } else {
            logger.log("No collection found in user account");
        }
    }

    const filterCollectionToShowInSelect = () => {
        let defaultCollectionToShowInSelect = [];
        if (existingNftToUse) {
            defaultCollectionToShowInSelect = collectionListFiltered.find((collection) => {
                return existingNftToUse.contractAddress == collection.contractAddress;
            })
        } else {
            if (collectionSelected) {
                defaultCollectionToShowInSelect = collectionListFiltered.find((collection) => {
                    return collectionSelected.contractAddress == collection.contractAddress;
                })
            } else {
                defaultCollectionToShowInSelect = collectionListFiltered[0];
            }
        }

        return defaultCollectionToShowInSelect;
    }

    const setFormValuesWhenCollectionIsSelected = (shouldInheritFromCollection: boolean) => {
        formikProps.setFieldValue("getFromCollection", shouldInheritFromCollection);
        formikProps.setFieldValue("numbersNft", "1");

        if (shouldInheritFromCollection) {
            formikProps.setFieldValue("imgFile", metadataSelected.image);
            formikProps.setFieldValue("nftName", collectionSelected.name);
            formikProps.setFieldValue("nftDescription", metadataSelected.description);
        } else {
            formikProps.setFieldValue("imgFile", undefined);
            formikProps.setFieldValue("nftName", "");
            formikProps.setFieldValue("nftDescription", "");
        }
    }

    useEffect(() => {
        if (existingNftToUse)
            formikProps.setFieldValue("usingExistingNft", true);
        else
            formikProps.setFieldValue("usingExistingNft", false);
    }, [existingNftToUse]);

    useEffect(() => {
        handleMetadataSelection();
        if (metadataSelected && metadataSelected.image) {
            formikProps.setFieldValue('getFromCollection', false);
            formikProps.setFieldError('nftDescription', metadataSelected.description);
            formikProps.setFieldValue("imgFile", metadataSelected.image);
        }
    }, [metadataSelected]);

    const handleMetadataSelection = () => {
        if (metadataSelected) {
            if (metadataSelected.hasOwnProperty("attributes")) {
                let tempAttributes = metadataSelected.attributes;
                tempAttributes = tempAttributes.map((attribute: any) => ({
                    ...attribute,
                    value: "",
                }));
                setAttributes(tempAttributes);
            } else {
                setAttributes([]);
            }
            if (isIpfsURI(metadataSelected.image)) {
                setMetadataSelected({
                    ...metadataSelected,
                    image: metadataSelected.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!isIpfsURI(metadataSelected.image)) {
                let tempImage = new Image();
                tempImage.onload = () => {
                    setIsImageLoad(true);
                };
                tempImage.src = metadataSelected.image;
            }
        }
    }

    useEffect(() => {
        if (attributes) {
            formRef.current.values.attributes = attributes;
        }
    }, [attributes]);


    const isIpfsURI = (stringToCheck: string): boolean => {
        return stringToCheck.includes(config.network.ipfs.default_url);
    }

    const resetFormValues = () => {
        setAttributes([]);
        setAttributeItem(<></>);
        setMetadataSelected(null);
        setPaymentToken("");
        formRef.current.values.attributes = [
            {
                trait_type: "",
                value: "",
                required: false,
            },
        ];
    }

    return (
        <form onSubmit={formikProps.handleSubmit}>
            <FeaturedCard title={title}>
                <Box mx={4} mt={4} mb={2}>
                    <Box textAlign={'left'}>
                        <Typography
                            display="inline"
                            color="secondary"
                            variant="caption"
                        >
                            {" "}
                            *
                        </Typography>
                        <Typography display="inline" variant="caption">
                            {t("requiredExplained")}
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <NftImage
                            existingNft={existingNftToUse}
                            isImageLoad={isImageLoad}
                            touched={formikProps.touched}
                            setFieldTouched={formikProps.setFieldTouched}
                            setFieldValue={formikProps.setFieldValue}
                            values={formikProps.values}
                            classes={classes}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NftCollectionFormInput
                            existingNft={existingNftToUse}
                            isForItems={isForItems}
                            setFormValuesWhenCollectionIsSelected={setFormValuesWhenCollectionIsSelected}
                            collectionSelected={collectionSelected}
                            values={formikProps.values}
                            collectionListFiltered={collectionListFiltered}
                            setPaymentToken={setPaymentToken}
                            setCollectionSelected={setCollectionSelected}
                            handleBlur={formikProps.handleBlur}
                            setFieldValue={formikProps.setFieldValue}
                            handleChange={formikProps.handleChange}
                            touched={formikProps.touched}
                        />
                    </Grid>
                    {
                        attributes && (
                            <NftCollectionFormAttributes
                                attributes={attributes}
                                attributeItem={attributeItem}
                                t={t}
                                setAttributesModalOpened={setAttributesModalOpened}
                            />
                        )
                    }
                </Grid>
            </FeaturedCard>
            {
                (modalOpened && !loading) && (
                    <ConfirmTitleModal
                        values={formikProps.values}
                        attributes={attributes}
                        isForItems={isForItems}
                        modalOpened={modalOpened}
                        nftCreated={nftCreated}
                        setModalOpened={setModalOpened}
                        classes={classes}
                        submitForm={formikProps.submitForm}
                        loading={loading}
                    />
                )
            }
            {
                (attributes) && (
                    <AttributesTitleModal
                        t={t}
                        modalAttributesOpened={modalAttributesOpened}
                        attributes={attributes}
                        setAttributesModalOpened={setAttributesModalOpened}
                        values={formikProps.values}
                        setAttributeItem={setAttributeItem}
                        setFieldValue={formikProps.setFieldValue}
                    />
                )
            }
            {
                loading ? (
                    <Grid container item justifyContent="center">
                        <CircularProgress color="primary" />
                    </Grid>
                ) : (
                    <>
                        <div className={classes.fieldContainer}>
                            <Button
                                fullWidth
                                className={classes.next}
                                variant="contained"
                                color="primary"
                                onClick={() => handleConfirmModalOpen()}
                                disabled={!formikProps.isValid || !formikProps.dirty}
                            >
                                {t("nftCreateButton")}
                            </Button>
                        </div>
                    </>
                )
            }
        </form>
    )
}