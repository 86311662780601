import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { MyToaster } from "../BasicComponents/MyToaster";
import { SearchBar } from "../MyInventory/SearchBar";
import { searchButton } from "../Styles/Buttons.style";
import { MyPreordersDashboardMenu } from "./MyPreordersDashboardMenu";
import { ListOfPendingPreorders } from "./Pending/ListOfPendingPreorders";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getAddressFromSymbolAPI } from "../../../api/nftAPI";
import { duration } from "../../../utilities/costants.notifications";
import { ListOfAcceptedPreorders } from "./Accepted/ListOfAcceptedPreorders";
import { ListOfStartedPreorders } from "./Started/ListOfStartedPreorders";
import { ListOfEndedPreorders } from "./Ended/ListOfEndedPreorders";
import { ListOfPreordersCancelled } from "./Cancelled/ListOfCancelledPreorders";
import { ListOfPreordersUnderReview } from "./UnderReview/ListOfPreordersUnderReview";
import { ListOfDeniedPreorders } from "./Denied/ListOfDeniedPreorders";

const mainContainerStyle = makeStyles({
  root: {
    position: "relative",
    top: "2em",
    "@media (min-width: 837px)": {
      left: "0.2em",
    },
  },
});

const titleStyle = makeStyles({
  root: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "32.81px",
    letter: "-2%",
    whiteSpace: "nowrap",
    "@media (max-width: 837px)": {
      fontSize: "17px",
    },
  },
});

export const MyPreordersContainer = () => {
  const useTitleStyle = titleStyle();
  const useSearchButtonStyle = searchButton();
  const useMainContainerStyle = mainContainerStyle();
  const pendingPreorders = useAppSelector(
    (state) => state.calendar.pendingPreorders
  );
  const cancelledPreorders = useAppSelector(
    (state) => state.calendar.cancelledPreorders
  );
  const activePreordersOfBorrower = useAppSelector(
    (state) => state.calendar.activePreordersOfBorrower
  );
  const preordersUnderReview = useAppSelector(
    (state) => state.calendar.preordersUnderReview
  );
  const [activeMenu, setActiveMenu] = useState(0);
  const { t } = useTranslation(["LibOfThings"]);
  const history = useHistory();
  const handleMenuChange = (menuToActivate: number) => {
    setActiveMenu(menuToActivate);
  };

  const goToItemPage = async (symbol: string, itemID: number) => {
    const collection = await getAddressFromSymbolAPI(symbol);
    if (collection != null && itemID != null) {
      const q = `/items/${collection}/${itemID}`;
      history.push(q);
    } else {
      toast.loading(`Wait until I finish to load item information`, {
        duration: duration
      });
    }
  };

  useEffect(() => {
    console.log(activePreordersOfBorrower);
  }, [activePreordersOfBorrower])

  return (
    <>
      <MyToaster />
      <Grid container className={useMainContainerStyle.root}>
        <Grid item xs={6} md={6}>
          <Typography variant={"h4"} className={useTitleStyle.root}>
            {t("myRequests")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <SearchBar />
            </Grid>
            <Grid item xs={3}>
              <Button className={useSearchButtonStyle.root}>
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {pendingPreorders != null &&
          cancelledPreorders != null &&
          activePreordersOfBorrower != null && (
            <Grid container>
              <Grid item xs={8} md={2}>
                <MyPreordersDashboardMenu
                  activeMenu={activeMenu}
                  handleMenuChange={handleMenuChange}
                />
              </Grid>
            </Grid>
          )}
        {activeMenu === 0 && (
          <ListOfPendingPreorders
            goToItemPage={goToItemPage}
          />
        )}
        {activeMenu === 1 && (
          <ListOfAcceptedPreorders
            goToItemPage={goToItemPage}
          />
        )}
        {activeMenu === 2 && (
          <ListOfStartedPreorders
            goToItemPage={goToItemPage}
          />
        )}
        {activeMenu === 3 && (
          <ListOfPreordersUnderReview
            goToItemPage={goToItemPage}
          />
        )}
        {
          activeMenu === 4 && (
            <ListOfEndedPreorders
              goToItemPage={goToItemPage}
            />
          )
        }
        {activeMenu === 5 && (
          <ListOfPreordersCancelled
            goToItemPage={goToItemPage}
            cancelledPreorders={cancelledPreorders}
          />
        )}
        {activeMenu === 6 && (
          <ListOfDeniedPreorders
            goToItemPage={goToItemPage}
          />
        )}
      </Grid>
    </>
  );
};
