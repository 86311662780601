import { makeStyles } from "@material-ui/core";

export const titleBar = makeStyles({
    root: {
        background: '#FFD600',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '1em',
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '2em',
        textAlign: 'center'
    }
})

export const titleStyle = makeStyles({
    root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: '-0.02em',
    }
})