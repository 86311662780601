// This is the main view after login
import React from 'react';
//import PropTypes from 'prop-types';
import Piggies from '../../containers/CrowdSale/Piggies/Piggies';
import MyWallet from '../../containers/Coin/MyWallet/MyWallet';
import ExchangesList from '../../containers/Exchange/ExchangeList/ExchangesList';
import Marketplace from '../../containers/Marketplace/Marketplace'

import {connect} from "react-redux";
import SearchBar from "../../containers/SearchBar/SearchBar";

const Main = (props) => {

    const subpagesComponents = [<SearchBar/>, <ExchangesList/>, <Piggies/>, <MyWallet/>, <Marketplace/>];
    const {subpageIndex} = props;

    return (
        <>
            {subpagesComponents[subpageIndex]}
        </>
    );
};

const mapStateToProps = state => {
    return {
        subpageIndex: state.ui.bottomMenuActiveIndex,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps,mapDispatchToProps) (Main);
