import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        background: 'white',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        height: '77px',
        paddingRight: '2em',
        cursor: 'pointer',
        width: 'fit-content',
        '&:hover': {
            background: '#EEEEEE'
        }
    },
    title: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '16.41px',
        letterSpacing: '-0.02em',
        fontStyle: 'normal',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: '0.2em',
        whiteSpace: 'nowrap'
    },
    subtitle: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16.41px',
        letterSpacing: '-0.02em',
        fontStyle: 'normal',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: '0.4em',
    }
});

export const AccountAction = ({
    title,
    subtitle,
    svg,
    link
}: { link: string, title: string, subtitle: string, svg: React.ReactNode }) => {
    const classes = useStyles();

    return (
        <Link to={link} style={{ textDecoration: 'none', color: 'black' }}>
            <Grid container className={classes.root}
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={3} style={{ textAlign: 'center' }}>
                    {svg}
                </Grid>
                <Grid item xs={9}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant="h1" className={classes.title}>{title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <label className={classes.subtitle}>{subtitle}</label>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Link>

    );
}