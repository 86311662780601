import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Chip, Grid, IconButton, Typography, makeStyles } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { useEffect, useState } from "react";
import { ItemCardMedia } from "./ItemCardMedia";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ItemMenu } from "./ItemMenu";
import { getCollectionBySymbol } from "../../../../api/nftAPI";

const style = makeStyles({
    card: {
        maxWidth: 350,
        background: '#f5f5f5',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
})

export const ItemCard = ({ item, address }: {
    item: Item,
    address: string
}) => {
    const classes = style()
    const [uplaodTime, setUploadTime] = useState<Date>(new Date(item.uploadTime))
    const navigate = useHistory();
    const { t } = useTranslation(['LibOfThings'])

    useEffect(() => {
        setUploadTime(new Date(item.uploadTime * 1000))
    }, [item]);

    const handleClikGoToItemPage = async () => {
        if (item != null && address != null && item.itemID != null) {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            navigate.push(`/items/${collection.contractAddress}/${item.itemID}`);
        }
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton>
                        <ItemMenu item={item} />
                    </IconButton>
                }
                title={item.name}
                subheader={`Simbolo collezione: ${item.collectionSymbol}`}
            >
            </CardHeader>
            <ItemCardMedia
                itemID={item.itemID}
                collectionSymbol={item.collectionSymbol}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" onClick={handleClikGoToItemPage}>{t('itemPage')}</Button>
                    </Grid>
                    <Grid item xs={6} style={{alignItems: 'center', display: 'flex', justifyContent: 'right'}}>
                        <Chip color="primary" avatar={<Avatar>{item.paymentToken}</Avatar>} variant="outlined" label={`${item.price / 100} ${t('perDay')}`} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}