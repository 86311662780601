import React, {Component} from 'react';

import {logger} from './utilities/logger/logger';

//Redux connector
import {connect} from 'react-redux';
import {authFail, checkAuthOnClient, checkAuthOnServer} from './store/slices/authSlice';

//Routing system
import {Redirect, Route, Switch, withRouter} from "react-router-dom";

// import routes
import indexRoutes from './routes/index'

//Custom components
import Layout from './hoc/Layout/Layout';

//Global app config
import mainConfig from './config/mainConfig'


//Auth flow components
import Auth from './containers/Auth/Auth'
import Logout from './containers/Auth/Logout/Logout';
import {ethersCheck} from "./store/slices/ethersSlice";

class App extends Component {
    state = {
        hasChecked: false,
    };

    componentDidMount() {
        const {
            isAuthenticated,
            onEthersCheck
        } = this.props;

        if (
            isAuthenticated
        ) {
            onEthersCheck();
            this.setState({
                hasChecked: true,
            });
        }
    }


    componentDidUpdate() {
        const {
            isAuthenticated,
            onEthersCheck,
            ethersInstance,
        } = this.props;

        if (isAuthenticated) {
            logger.log('APPjs - componentDidUpdate, isAuthenticated ', isAuthenticated);
            if (this.state.hasChecked === false &&  ethersInstance == null) {
                onEthersCheck();
                this.setState({
                    hasChecked: true,
                });
            }

        }
    }


    render() {
        const {
            isAuthenticated,
            loading,
        } = this.props;

        let routes = null;

        if (isAuthenticated) {

            let routesArray = indexRoutes.map((route, key) => {
                const Component = route.component;
                return (<Route
                    key={key}
                    exact={route.exact}
                    path={route.path}
                    render={
                        () => {
                            if (route.path !== '/logout') {
                                return (
                                    <Layout title={mainConfig.basic.appName}>
                                        <Component/>
                                    </Layout>

                                )
                            } else {
                                return (<Component/>)
                            }
                        }
                    }
                />)
            });
            //routesArray.push(<Redirect key={routesArray.length} to='/?help=true' />);
            routesArray.push(<Redirect key={routesArray.length} to='/'/>);
            //history.replace('/');

            routes = (
                <Switch>
                    {routesArray}
                </Switch>);


        } else { // NOT AUTHENTICATED
            routes = (
                <Switch>
                    <Route path='/login' component={Auth}/>
                    <Route path='/logout' component={Logout}/>
                    {!loading ? <Redirect from='/' to='/login'/> : null}
                    {/* {!loading ? history.replace('/login') : null}*/}
                </Switch>
            )
        }

        return (
            <Switch>
                {routes}
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        isAuthenticated: state.auth.idToken !== null,
        ethersInstance: state.ethers.ethersInstance,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckAuth: () => dispatch(checkAuthOnClient()),
        onAuthFromServer: (type, data) => dispatch(checkAuthOnServer(type, data)),
        onAuthError: (error) => dispatch(authFail(error)),
        onEthersCheck: () => dispatch(ethersCheck()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
