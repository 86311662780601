import { useState } from "react";

import { Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface ConditionalAvatarProps {
    alt: string
    src: string
    className: string
}

const ConditionalAvatar = (props: ConditionalAvatarProps) => {
    const [loading, setLoading] = useState(true);

    const {alt, src, className} = props;

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {
                loading && <Skeleton variant="circle" className={className} />
            }
            <Avatar alt={alt} src={src} style={{display: loading ? 'none' : 'block'}} className={className} imgProps={{onLoad: () => handleImageLoad()}}/>
        </>
    )
}

export default ConditionalAvatar;