export const possibleStatusItemInCalendar = {
    accepted: 'Accepted',
    started: 'Started',
    ended: 'Ended',
    review: 'Review'
}
export const steps = [
    possibleStatusItemInCalendar.accepted,
    possibleStatusItemInCalendar.started,
    possibleStatusItemInCalendar.ended,
    possibleStatusItemInCalendar.review,
];
