/**
 * This file is created as suggested here: https://redux-toolkit.js.org/tutorials/typescript
 * to have typed useSelector and useDispatch inside the application
 */

import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
