import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/UI/Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { coinGetList, getTokensOwnedFM } from "../../store/slices/coinSlice";
import {
  marketplaceGetListFM,
  marketplaceGetListReset,
  marketplaceGetNumberFM,
} from "../../store/slices/marketplaceSlice";
import { logger } from "../../utilities/logger/logger";

const MarketplaceCard = lazy(() => import("./MarketplaceCard/MarketplaceCard"));

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    maxHeight: 437,
    textAlign: "center",
  },
  styleForFlexboxWithoutScrollbar: { margin: 0, width: "100%" },
});

const status = {
  running: "0",
  completed: "1",
  cancelled: "2",
};

const Marketplace = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation(["Marketplace", "Common"]);

  const marketplaceList = useAppSelector(
    (state) => state.marketplace.marketplaceList
  );
  const marketplaceNumber = useAppSelector(
    (state) => state.marketplace.marketplaceNumber
  );
  const loading = useAppSelector((state) => state.marketplace.loading);

  const [sortBy, setSortBy] = useState<string>("alphaAscending");
  const [searchInput, setSearchInput] = useState<string>("");
  const page = useAppSelector((state) => state.marketplace.page);

  const loadCoinsAndMarketPlace = () => {
    dispatch(marketplaceGetNumberFM(status.running));
    dispatch(marketplaceGetListFM(searchInput, status.running, sortBy, page));
  };

  useEffect(() => {
    dispatch(marketplaceGetListReset());
    loadCoinsAndMarketPlace();
  }, []);

  const handleSort = (event: any) => {
    setSortBy(event.target.value);
    dispatch(marketplaceGetListReset());
    dispatch(marketplaceGetNumberFM(status.running));
    dispatch(
      marketplaceGetListFM(
        searchInput,
        status.running,
        event.target.value,
        page
      )
    );
  };
  const handleSearch = (event: any) => {
    setSearchInput(event.target.value);
    dispatch(marketplaceGetListReset());
    dispatch(marketplaceGetNumberFM(status.running));
    dispatch(
      marketplaceGetListFM(event.target.value, status.running, sortBy, page)
    );
  };
  const handleReset = () => {
    setSearchInput("");
    setSortBy("alphaAscending");
    dispatch(marketplaceGetListReset());
    dispatch(marketplaceGetNumberFM(status.running));
    dispatch(marketplaceGetListFM("", status.running, "alphaAscending", 0));
  };
  let renderMarketplace = marketplaceList.map((m, i) => (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      key={m.nftOffered.nftAddress + " " + m.coinRequired.coinAddress + " " + i}
    >
      <Suspense fallback={<></>}>
        <MarketplaceCard
          owner={m.owner}
          status={m.status}
          nftOffered={m.nftOffered}
          coinRequired={m.coinRequired}
          marketplaceAddress={m.marketplaceAddress}
          handleReset={handleReset}
        />
      </Suspense>
    </Grid>
  ));
  return (
    <>
      <Box mt={2}>
        <Grid
          container
          item
          spacing={2}
          xs={12}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs>
            <IconButton onClick={() => handleReset()}>
              <Refresh />
            </IconButton>
            <Typography display="inline">
              {marketplaceNumber} {t("nftCounter")}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <TextField
              value={searchInput}
              onChange={(event: any) => handleSearch(event)}
              variant="outlined"
              label={t("searchLabel")}
            />
          </Grid>
          <Grid item xs="auto">
            <FormControl variant="outlined">
              <InputLabel id="SortByLabel">{t("sortBy")}</InputLabel>
              <Select
                labelId="SortByLabel"
                label="Ordina per"
                value={sortBy}
                onChange={(event) => handleSort(event)}
              >
                <MenuItem value="alphaAscending">
                  {t("alphaAscending")}
                </MenuItem>
                <MenuItem value="alphaDescending">
                  {t("alphaDescending")}
                </MenuItem>
                <MenuItem value="priceAscending">
                  {t("priceAscending")}
                </MenuItem>
                <MenuItem value="priceDescending">
                  {t("priceDescending")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {!loading ? (
        <Grid
          container
          spacing={1}
          alignContent="center"
          alignItems="center"
          wrap="wrap"
          className={classes.styleForFlexboxWithoutScrollbar}
        >
          {renderMarketplace}
        </Grid>
      ) : (
        <Loading withLoader={true} title={t("waitMarketplaceLoad")} />
      )}
    </>
  );
};

export default Marketplace;
