import React, {useEffect, useState} from "react";
import {logger} from "../../../utilities/logger/logger";

import config from "../../../config";
import Loading from "../../../components/UI/Loading/Loading";
import PiggyCard from "./PiggyCard/PiggyCard";
import PiggiesDetails from "./PiggiesDetails/PiggiesDetails";
import AlertAvatar from "../../../components/UI/AlertAvatar/AlertAvatar";
import SlideModal from "../../../components/UI/Modal/SlideModal/SlideModal";
import Typography from "@material-ui/core/Typography";


//i18n
import {useTranslation} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import {
    Crowdsale,
    crowdsaleJoinReset,
    crowdsalePagingReset,
    crowdsaleRefundReset,
    crowdsaleStopReset,
    getCrowdsaleList,
} from "../../../store/slices/crowdsaleSlice";
import {coinForPiggiesGetListReset, coinGetList, getTokensFM,} from "../../../store/slices/coinSlice";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {getNumberOfCrowdsale} from "../../../api/crowdsaleAPI";
import MiniLoader from "../../../components/UI/Loading/MiniLoader";

const useStyles = makeStyles((theme) => {
    return createStyles({
        container: {
            justifyContent: "center",
        },
        typo: {
            textAlign: "center",
        },
    });
});
const Piggies = () => {
    const {t} = useTranslation(["Piggies"]);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const [crowdSaleModalOpened, setCrowdSaleModalOpened] = useState(false);
    const [selectedCrowdSale, setSelectedCrowdSale] = useState<Crowdsale>();
    //const [mapModalOpened, setMapModalOpened] = useState(false);
    const [refoadAfterEventsOnCrowdsale, setRefoadAfterEventsOnCrowdsale] = useState(false);
    const [index, setIndex] = useState(0);
    const [amount, setAmount] = useState(8);
    const [maxIndex, setMaxIndex] = useState<number>(0);

    const crowdsalesLoading = useAppSelector((state) => state.crowdsale.loading);
    const crowdsales = useAppSelector((state) => state.crowdsale.crowdsales);
    const coinList = useAppSelector((state) => state.coin.coinListForPiggies);
    const coinListLoading = useAppSelector((state) => state.coin.loadingCoinListForPiggies);
    const currentProfile = useAppSelector((state) => state.user.currentProfile);
    const ethersInstance = useAppSelector((state) => state.ethers.ethersInstance);
    const crowdsaleOperation = useAppSelector(state => state.crowdsale.onGoingOperations);
    const unlocked = useAppSelector(state => state.crowdsale.unlocked)
    const unlockLoading = useAppSelector(state => state.crowdsale.unlockLoading)
    const refunded = useAppSelector(state => state.crowdsale.refunded);
    const joined = useAppSelector(state => state.crowdsale.joined);
    const stopped = useAppSelector(state => state.crowdsale.stopped)


    // const mapModalCloseHandler = () => setMapModalOpened(false)
    //
    // const mapModalOpenHandler = () => setMapModalOpened(true)
    //
    // const handleJoin = (crowdsale: Crowdsale, crowdsales: Crowdsale[]) => {
    //     const crowdsaleToJoin: Crowdsale | undefined= crowdsales.find((element) => element.crowdsaleAddress === crowdsale.crowdsaleAddress);
    //     if(crowdsaleToJoin) {
    //         crowdSaleDetailOpen(crowdsaleToJoin);
    //         mapModalCloseHandler();
    //     }
    // };

    useEffect(() => {
        dispatch(crowdsaleRefundReset())
        dispatch(crowdsaleJoinReset())
        dispatch(crowdsaleStopReset())
        dispatch(coinForPiggiesGetListReset());
        dispatch(crowdsalePagingReset());
        dispatch(getCrowdsaleList(index, amount));
        //dispatch(crowdsalePagination(index, amount, setIndex));
        //dispatch(crowdsaleGetAll())
        setIndex(index + amount);
        dispatch(getTokensFM("", false, true, -1));
        //dispatch(coinGetList(null, false, null, true, null));
    }, []);

    useEffect(() => {
        async function numberOfCrowdsale() {
            if (currentProfile !== undefined && currentProfile !== null) {
                const currentProfileWallet =
                    currentProfile.additional_properties?.commonshoodWallet;
                if (currentProfileWallet !== undefined && ethersInstance !== null) {
                    let nOfCrw = await getNumberOfCrowdsale(
                        ethersInstance,
                        currentProfileWallet
                    );
                    logger.info(nOfCrw);
                    setMaxIndex(nOfCrw);
                }
            }
        }
        numberOfCrowdsale();
        console.log("crowdsales", crowdsales);
    }, [crowdsales]);

    useEffect(() => {
        if (refoadAfterEventsOnCrowdsale) {
            dispatch(crowdsalePagingReset());
            dispatch(getCrowdsaleList(0, amount));
            //dispatch(crowdsalePagination(0, amount, setIndex));
            setIndex(amount);
            setRefoadAfterEventsOnCrowdsale(false);
        }
    }, [refoadAfterEventsOnCrowdsale]);
    useEffect(() => {
        if((unlocked || refunded || joined || stopped) && (!crowdsalesLoading || !coinListLoading || !unlockLoading))
            setRefoadAfterEventsOnCrowdsale(true)
    }, [unlocked, refunded, joined, stopped]);

    // Opens CrowdSale Detail
    const crowdSaleDetailOpen = (crowdsale: Crowdsale) => {
        setSelectedCrowdSale(crowdsale);
        setCrowdSaleModalOpened(true);
    };

    //Close CrowdSale Detail
    const crowdSaleDetailClose = () => {
        setSelectedCrowdSale(undefined);
        setCrowdSaleModalOpened(false);
    };

    // const {
    //     coords,
    // }= this.props;

    logger.info("[PIGGIES.js] crowdsales =>", crowdsales);

    //managing case of still loading crowdsales (if any)
    let loadingComponent = null;
    if (crowdsalesLoading && (crowdsales == null || crowdsales.length === 0)) {
        loadingComponent = <Loading title={t("loadingCrowdsales")} withLoader/>;
    } else if (crowdsalesLoading) {
        loadingComponent = <MiniLoader text={true}> </MiniLoader>;
    }

    //managing case of no crowdsales
    if (!crowdsalesLoading && (crowdsales == null || crowdsales.length === 0)) {
        logger.info("No active crowdsales found right now");
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
            >
                <Grid item xs={12}>
                    <AlertAvatar big warning text={t("noCrowdsales")}/>
                </Grid>
            </Grid>
        );
    }

    //standard case:
    // let currentLocation = fromLonLat([
    //     config.defaultGeoCoordinates.longitude,
    //     config.defaultGeoCoordinates.latitude
    // ]); //default on Turin
    // if (coords) {
    //     currentLocation = fromLonLat([coords.longitude, coords.latitude]);
    // }
    const extendedCrowdsales = crowdsales.map((crowdsale) => {
        const extendedCrowdsale = {
            ...crowdsale,
            startDate: new Date(crowdsale.startDate * 1000),
            endDate: new Date(crowdsale.endDate * 1000)
        };
        //images
        if (currentProfile !== null && currentProfile !== undefined) {
            if (coinList != null && coinList.length !== 0 && !coinListLoading) {
                const completeTokenToAccept = coinList.find(
                    (elem) => elem.address === crowdsale.tokenToAcceptAddr
                );
                extendedCrowdsale.tokenToAcceptLogo = completeTokenToAccept
                    ? completeTokenToAccept.logo
                    : null;
                const completeTokenToGive = coinList.find(
                    (elem) => elem.address === crowdsale.tokenToGiveAddr
                );
                extendedCrowdsale.tokenToGiveLogo = completeTokenToGive
                    ? completeTokenToGive.logo
                    : null;
            }
            extendedCrowdsale.isOwnedByCurrentUserWallet =
                crowdsale.ownerAddress ===
                currentProfile.additional_properties?.commonshoodWallet;
        }
        return extendedCrowdsale;
    });
    logger.info(currentProfile)
    console.log("asd", extendedCrowdsales)


    const cards = extendedCrowdsales
        .filter((extendedCrowdsale) => {
            //show only those which are fully loaded with coupons and unlocked
            //or of which I am the owner
            const epsilon = 0.001; //for float comparision
            return (
                (currentProfile !== null &&
                    currentProfile !== undefined &&
                    extendedCrowdsale.ownerAddress ===
                    currentProfile.additional_properties?.commonshoodWallet) ||
                //careful: float comparision
                (Math.abs(
                        extendedCrowdsale.tokenToGiveBalance.balance -
                        extendedCrowdsale.maxCap / extendedCrowdsale.acceptRatio
                    ) <= epsilon && // coupons loaded
                    extendedCrowdsale.status === config.crowdsaleStatus[0]) //running
                //||
                //(extendedCrowdsale.status === config.crowdsaleStatus[1] && (currentProfile !== null && currentProfile !== undefined) && extendedCrowdsale.ownerAddress === currentProfile.additional_properties?.commonshoodWallet)
            );
        })
        .map((extendedCrowdsale) => {
            return (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={extendedCrowdsale.crowdsaleAddress}
                >
                    <PiggyCard
                        crowdsale={extendedCrowdsale}
                        handleOpen={() => {
                            crowdSaleDetailOpen(extendedCrowdsale);
                        }}
                        reload={setRefoadAfterEventsOnCrowdsale}
                        //contract={extendedCrowdsale.TOS}
                    />
                </Grid>
            );
        });

    let piggiesDetails = null;
    if (selectedCrowdSale !== null) {
        piggiesDetails = (
            <PiggiesDetails
                crowdsale={selectedCrowdSale}
                closePiggieDetails={crowdSaleDetailClose}
                reload={setRefoadAfterEventsOnCrowdsale}
            />
        );
    }

    const styleForFlexboxWithoutScrollbar = {
        margin: 0,
        width: "100%",
    };

    // window.onscroll = () => {
    //     if (
    //         window.innerHeight + document.documentElement.scrollTop >=
    //         document.documentElement.offsetHeight - 1
    //     ) {
    //         if (crowdsales.length < maxIndex) {
    //             dispatch(getCrowdsaleList(index, amount));
    //             //dispatch(crowdsalePagination(index, amount, setIndex));
    //             setIndex(index + amount);
    //         }
    //     }
    // };
    
    return (
        <>
            {/*<Grid container>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Typography>{capitalCase(t('openMap'))}*/}
            {/*            <IconButton*/}
            {/*                color="primary"*/}
            {/*                size="small"*/}
            {/*                onClick={mapModalOpenHandler}>*/}
            {/*                <Explore/>*/}
            {/*            </IconButton>*/}
            {/*        </Typography>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid
                container
                spacing={1}
                alignContent="center"
                wrap="wrap"
                style={styleForFlexboxWithoutScrollbar}
            >
                {cards ? cards : null}
            </Grid>
            <SlideModal
                open={crowdSaleModalOpened}
                handleClose={crowdSaleDetailClose}
                title={`Crowdsale: ${
                    selectedCrowdSale ? selectedCrowdSale.title : ""
                } | ${t("modalTitleDetails")}`}
            >
                {piggiesDetails}
            </SlideModal>
            {/*<SlideModal*/}
            {/*    open={mapModalOpened}*/}
            {/*    handleClose={mapModalCloseHandler}*/}
            {/*    title="Mappa"*/}
            {/*    position="absolute"*/}
            {/*>*/}
            {/*    <div style={{marginTop: 60, width: "100%", height: "100%"}}>*/}
            {/*        <MapComponent currentLocation={currentLocation} entities={crowdsales}*/}
            {/*                      pay={(crowdsale) => handleJoin(crowdsale, crowdsales)} type="CC_CROWDFUNDING"/>*/}
            {/*    </div>*/}
            {/*</SlideModal>*/}
            <Grid container className={classes.container}>
                <Typography className={classes.typo} color={"inherit"} variant="body2">
                    {crowdsales.length < maxIndex && !crowdsalesLoading
                        ? "...load more"
                        : null}
                    {crowdsales.length == maxIndex && !crowdsalesLoading
                        ? "no more crowdsale"
                        : null}
                </Typography>
            </Grid>
            {loadingComponent}
        </>
    );
};

export default Piggies;
