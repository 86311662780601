import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFromIpfs, getJsonFromResource } from "../../../api/resourceAPI";
import config from "../../../config";
import { useAppSelector } from "../../../store/hooks";
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../store/slices/nftSlice";
import { Item } from "../../../types/libofthings.type";
import { getOptionValueFromHex } from "../../../utilities/libofthings/libOfThings";
import { imageOptionIndicator, itemImage } from "../Styles/Images.style"
import { getTokenByID } from "../../../api/nftAPI";
import { ConsoleNetworkOutline } from "mdi-material-ui";

export const ImageContainer = ({
  item
}: { item: Item }) => {
  const dispatch = useDispatch();
  const useImageOptionIndicator = imageOptionIndicator();
  const useItemImage = itemImage();
  const [uri, setUri] = useState<any>('');
  const [metadata, setMetadata] = useState<any>(null);
  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);
  const collectionsAndSymbols = useAppSelector(state => state.nft.collectionsBySymbol);

  useEffect(() => {
    const collectionAndSymbol = collectionsAndSymbols.filter((collection) => {
      if (collection.symbol === item.collectionSymbol) {
        return collection;
      }
    })[0];
    if (collectionAndSymbol == null) {
      (async () => { await dispatch(getCollectionFromSymbol(item.collectionSymbol)) })()
    } else {
      (async () => {
        const token = await getTokenByID(collectionAndSymbol.address, item.idInCollection.toString());
        console.log(token.token);
        setUri(token.token.tokenUri);
      })()
    }

  }, [collectionsAndSymbols])

  useEffect(() => {
    (async () => {
      if (uri.includes(config.network.ipfs.default_url))
        setMetadata(await getFromIpfs(uri));
      else setMetadata(await getJsonFromResource(uri));
    })()
  }, [uri])

  useEffect(() => {
    if (metadata !== null) {
      if (metadata.image.includes(config.network.ipfs.default_url)) {
        setMetadata({
          ...metadata,
          image: metadata.image.replace(
            config.network.ipfs.default_url,
            config.network.ipfs.gateway_url
          ),
        });
      }
      if (!metadata.image.includes(config.network.ipfs.default_url)) {
        let tempImage = new Image();
        tempImage.onload = () => {
          setIsImageLoad(true);
        };
        tempImage.src = metadata.image;
      }
    }
  }, [metadata]);

  return (
    <div style={{ position: 'relative', display: 'inline-block', marginBottom: '1em' }}>
      <div className={useImageOptionIndicator.root}>
        <Typography variant={'body1'}>{getOptionValueFromHex(item.option)}</Typography>
      </div>
      {
        isImageLoad ? (
          <img
            className={useItemImage.root}
            src={metadata.image}></img>
        ) : (<></>)
      }

    </div>
  )
}