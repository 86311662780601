import {configureStore} from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import coinReducer from './slices/coinSlice';
import crowdsaleReducer from './slices/crowdsaleSlice';
import daoReducer from './slices/daoSlice';
import fileReducer from './slices/fileSlice';
import notificationReducer from './slices/notificationSlice';
import placeReducer from './slices/placeSlice';
import uiReducer from './slices/uiSlice';
import userReducer from './slices/userSlice';
import exchangeReducer from './slices/exchangeSlice';
import marketplaceReducer from './slices/marketplaceSlice';
import nftReducer from './slices/nftSlice'
import libOfThingsReducer from './slices/libOfThingsSlice';
import calendarReducer from './slices/calendarSlice';
import onGoingTransactionsReducer from './slices/onGoingTransactionsSlice';
import ethersReducer from './slices/ethersSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        coin: coinReducer,
        crowdsale: crowdsaleReducer,
        dao: daoReducer,
        file: fileReducer,
        notification: notificationReducer,
        place: placeReducer,
        ui: uiReducer,
        user: userReducer,
        exchange: exchangeReducer,
        marketplace: marketplaceReducer,
        nft: nftReducer,
        libOfThings: libOfThingsReducer,
        calendar: calendarReducer,
        onGoingTransactions: onGoingTransactionsReducer,
        ethers: ethersReducer
    },
    middleware: (getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: [ 'payload.provider', "payload.ethersInstance"],
            ignoredPaths: ["ethers"]
        }
    }))
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
