import React from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {coinSetPreselected} from "../../../../store/slices/coinSlice";
import {handleBottomMenuIndexChange} from "../../../../store/slices/uiSlice";

import {getProfileId} from "../../../../utilities/utilities";

import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";

//i18n
import {useTranslation} from "react-i18next";

import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  useMediaQuery,
} from "@material-ui/core";
import { logger } from "ethers";

const useStyle = makeStyles(({ spacing }: Theme) => ({
  item: {
    maxWidth: "100%",
  },
  avatar: {
    marginRight: spacing(1),
  },
  balanceText: {
    textAlign: "right",
    marginRight: spacing(5),
  },
  balanceTextWithPay: {
    textAlign: "right",
    marginRight: spacing(10),
  },
  withRadioBalanceText: {
    textAlign: "right",
    marginRight: spacing(6),
  },
}));

type CoinListDetailsProps = {
  coin: {
    address: string;
    id: string;
    name: string;
    symbol: string;
    type: "token" | "good";
    userId: string;
  };
  withRadio: boolean;
  selected: boolean;
  handleSelect: () => void;
  iconData: any; //fixme
  balance: any; //fixme
  showPayButton: boolean;
};

const CoinListDetails = (props: CoinListDetailsProps) => {
  const {
    coin,
    withRadio,
    selected,
    handleSelect,
    iconData,
    balance,
    showPayButton,
  } = props;
  const { address, id, name, symbol, type } = coin;

  const theme = useTheme();
  const matchesBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyle(theme);

  const dispatch = useAppDispatch();
  const currentProfile = useAppSelector((state) => state.user.currentProfile);
  const currentProfileId = getProfileId(currentProfile?.realm, currentProfile);

  const { t } = useTranslation("CoinListDetails");

  const handlePay = () => {
    dispatch(coinSetPreselected({ coin: { symbol, iconData, type, address, balance } }));
    dispatch(handleBottomMenuIndexChange({ index: 0 })); //bring user back to coinSend
  };

  let fullName = name + " | " + symbol;
  if (matchesBelowSm) {
    fullName = symbol;
  }

  const coinDisabled = checkCoinDisabled(coin, balance);
  const ownerString = currentProfileId === coin.userId ? t("owned") : null;

  // let primaryListText = (
  //     <Grid container direction="column">
  //         <Grid item>
  //             <Typography>
  //                 {fullName}
  //             </Typography>
  //         </Grid>
  //         <Grid item>
  //             <Typography color="gray" variant="caption">
  //                 {ownerString}
  //             </Typography>
  //         </Grid>
  //     </Grid>
  // );

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <ListItem
          dense
          className={classes.item}
          button
          onClick={handleSelect}
          disabled={coinDisabled}
          selected={selected}
        >
          {iconData && iconData !== "data:application/octet-stream;base64," ? (
            <Avatar alt={symbol} src={iconData} className={classes.avatar} />
          ) : (
            <Avatar alt={symbol} className={classes.avatar}>
              {symbol}
            </Avatar>
          )}
          <ListItemText primary={fullName} secondary={ownerString} />
          {balance >= 0 ? (
            <ListItemText
              primary={balance}
              className={
                showPayButton ? classes.balanceTextWithPay : classes.balanceText
              }
            />
          ) : (
            <CircularProgress
              color="secondary"
              disableShrink
              size={20}
              className={
                withRadio
                  ? classes.withRadioBalanceText
                  : classes.balanceTextWithPay
              }
            />
          )}

          {/*<ListItemIcon style={{padding: '0 0.5em'}}>*/}

          {/*<AppIcon icon={{font: "material", name: "bar_chart"}}/>*/}

          {withRadio ? (
            <ListItemSecondaryAction>
              <Radio
                id={id}
                name={id}
                value={symbol}
                onChange={handleSelect}
                checked={selected}
              />
            </ListItemSecondaryAction>
          ) : (
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color="primary"
                disabled={coinDisabled || balance <= 0}
                onClick={handlePay}
              >
                {t("Common:pay")}
              </Button>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </Grid>
      {/*{payButton}*/}
    </Grid>
  );
};

const checkCoinDisabled = (coin: any, balance: number | null) => {
  //fixme
  if (balance == null || balance == -1) {
    return true;
  }
  return false;
};

export default CoinListDetails;
