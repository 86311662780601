import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { basicCommonsHoodButtonStyle } from "../../Styles/Buttons.style";
const titleStyle = makeStyles({
    root: {
        paddingTop: '1em',
        fontSize: '4em',
        '@media (max-width: 600px)': {
            fontSize: '2.5em'
        }
    }
})

const subtitleStyle = makeStyles({
    root: {
        fontSize: '2em',
        '@media (max-width: 600px)': {
            fontSize: '1.5em'
        }
    }
})

export const HeaderHomeRegisterNewItem = ({ handleClickOnCreateNewNft }: {
    handleClickOnCreateNewNft: () => void
}) => {

    //languages
    const { t } = useTranslation(["LibOfThings", "Common"]);

    //styles
    const useTitleStyle = titleStyle();
    const commonsHoodStyleButton = basicCommonsHoodButtonStyle();
    const useSubtitleStyle = subtitleStyle();

    return (
        <Grid container style={{ textAlign: 'center', padding: '1em' }}>
            <Grid item xs={12}>
                <Typography className={useTitleStyle.root} variant="h2">{t('createItem')}</Typography>
                <Typography className={useSubtitleStyle.root} variant="h4">{t('inTheLibrary')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    className={commonsHoodStyleButton.root}
                    variant="contained"
                    color="primary"
                    onClick={handleClickOnCreateNewNft}
                    style={{ marginTop: '3em', marginBottom: '3em' }}
                >{t('createANewNft')}</Button>
            </Grid>
            <Grid item xs={12}>
                <Typography className={useSubtitleStyle.root} variant="h5">...{t('orUploadIt')}</Typography>
            </Grid>
        </Grid>
    )
}