import { Button, CircularProgress, Grid } from "@material-ui/core";
import { greenButton } from "../Styles/Buttons.style";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { QrCodeContent, QrContentForEndLendingPeriod, QrContentForStartLendingPeriod } from "../../../types/calendar.type";
import { Item } from "../../../types/libofthings.type";
import { createPreorderSlice, endLendingPeriod, getActivePreorders, startPreorder } from "../../../store/slices/calendarSlice";
import { duration } from "../../../utilities/costants.notifications";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { generateHashForPreorder } from "../../../api/libOfThingsAPI";


function isQrCodeForOrderCreation(content: QrCodeContent | QrContentForEndLendingPeriod | QrContentForStartLendingPeriod): content is QrCodeContent {
    return content.handle === 'preorderItem';
}

export const QrComponentButtons = ({ showQrCode, qrContentReceived, handleButtonAskLending, closeConfirmationDialog, handleButtonStartLending }: {
    showQrCode: boolean,
    qrContentReceived: QrCodeContent | QrContentForEndLendingPeriod | QrContentForStartLendingPeriod | null,
    handleButtonAskLending: () => void,
    closeConfirmationDialog: () => void,
    handleButtonStartLending: () => void
}) => {
    const loading = useAppSelector(state => state.libOfThings.loading);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(['LibOfThings']);
    const profile = useAppSelector(state => state.user.currentProfile);

    const handleConfirmButton = async () => {
        if (qrContentReceived) {
            if (qrContentReceived.handle === 'preorderItem') {
                await handleConfirmLending();
            } else if (qrContentReceived.handle === 'endLendingPeriod') {
                await handleConfirmEndLendingPeriod(qrContentReceived.preorder.preorderID, qrContentReceived.item);
            } else {
                await handleStartPreorder(qrContentReceived.preorder.preorderID);
            }
        }
    }

    const handleConfirmLending = async () => {
        if (qrContentReceived == null || !isQrCodeForOrderCreation(qrContentReceived)) return;

        const { item } = qrContentReceived;
        const address = profile?.additional_properties?.commonshoodWallet;
        if (address) {
            const startDate = new Date(qrContentReceived.startDate);
            const endDate = new Date(qrContentReceived.endDate);

            const { hash, signature } = await generateHashForPreorder(item.itemID, startDate, endDate, address);
            const errors = await dispatch(
                createPreorderSlice(
                    item.itemID,
                    startDate,
                    endDate,
                    hash,
                    signature,
                    item.paymentToken,
                    item.price,
                    item.caution
                )
            );
            if (errors != null && errors.length > 0) {
                toast.error(errors[0]);
            } else {
                toast.success(t('preorderSuccess'));
            }
        }
        closeConfirmationDialog();
    }

    const handleConfirmEndLendingPeriod = async (preorderID: string, item: Item) => {
        if (item != null) {
            const errors = await dispatch(endLendingPeriod(preorderID, item));

            if (errors != null && errors.length > 0) {
                toast.error(errors[0], {
                    duration: duration
                });
            } else {
                toast.success(t('endLendingPeriodToast'), {
                    duration: duration
                });
                dispatch(getActivePreorders(false, 0));
            }
        }
    }

    const handleStartPreorder = async (preorderID: string) => {
        const errors = await dispatch(startPreorder(preorderID));
        handleResultOfRequest('preorderStartedSuccess', errors);
    }

    const handleResultOfRequest = (messageKey: string, errors: string[] | undefined) => {
        if (errors != null && errors.length > 0) {
            toast.error(errors[0], {
                duration: duration
            });
        } else {
            toast.success(t(messageKey), {
                duration: duration
            });
            ((): any => dispatch(getActivePreorders(true, 0)))()
        }
    }

    return (
        <>
            {
                (loading) && <div style={{ textAlign: 'center', marginTop: '20px' }}>< CircularProgress color={'primary'} /></div>
            }
            {
                !loading && <>
                    {
                        (!showQrCode && !qrContentReceived) &&
                        <Grid container >
                            <Grid item xs={12}>
                                <QrComponentButton message={'Richiedi in prestito'} onClick={() => handleButtonAskLending()} />
                            </Grid>
                            <Grid item xs={12}>
                                <QrComponentButton message={'Termina prestito'} onClick={() => handleButtonAskLending()} />
                            </Grid>
                            <Grid item xs={12}>
                                <QrComponentButton message={'Inizia prestito'} onClick={() => handleButtonStartLending()} />
                            </Grid>
                        </Grid>
                    }
                    {
                        (!showQrCode && qrContentReceived) && <QrComponentButton message={'Conferma'} onClick={handleConfirmButton} />
                    }
                </>
            }
        </>

    )
}

const QrComponentButton = ({ message, onClick }: { message: string, onClick: () => void | Promise<void> }) => {
    const useGreenButtonStyle = greenButton();

    return (
        <Button
            onClick={onClick}
            className={useGreenButtonStyle.root}
            style={{ width: '50%', color: 'white', marginTop: '10px' }}
        >{message}</Button>

    );
}