import React, {useEffect, useState} from "react";

import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Divider, Grid, Link, Typography} from '@material-ui/core';

import {useTranslation} from "react-i18next";
import getNotificationText from "../../../utilities/notification/notification-messages";
import {getDate} from "../../../utilities/utilities";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    coinTransactions,
    transactionsPaginationEnable,
    transactionsPaginationReset
} from "../../../store/slices/coinSlice";

import config from "../../../config";

const useStyles = makeStyles( (theme: Theme) => ({
    flowTransactionIn: {
        color: theme.palette.success.main
    },
    flowTransactionOut: {
        color: theme.palette.secondary.main
    },
    transaction: {
        padding: '1vw'
    },
    divider: {
        color: "black",
        width: "100%"
    }
}));

const NOTIFICATION_SEPARATOR_SYMBOL = "+";

type TransactionListProps = {
    selectedCoinAddress: string
    currentProfileWallet: string | undefined
};

const TransactionList = (props: TransactionListProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch= useAppDispatch()
    const { t } = useTranslation(["TransactionList", "NotificationMessages"]);
    const transactions = useAppSelector(state => state.coin.transactions);
    const transactionLoading = useAppSelector(state => state.coin.loadingTransactions);
    const paginationHandler = useAppSelector(state => state.coin.paginationHandler);
    const [page, setPage]= useState(1)
    const [amount, setAmount]= useState(50)

    useEffect(() => {
        dispatch(transactionsPaginationReset())
        dispatch(transactionsPaginationEnable())
        dispatch(coinTransactions(props.selectedCoinAddress, page, amount))
        setPage(page + 1)
    }, []);

    if(transactions.length === 0) return (<></>);
    else return (
        <>
        {
            transactions.map((transaction: any, index: number) => {
                const transactionHash = transaction.id.split(NOTIFICATION_SEPARATOR_SYMBOL)[0]

                return (
                <Grid container key={index}>
                    <Grid container className={classes.transaction}>
                        <Grid item container xs={4} lg={2} justifyContent="flex-start">
                            <Typography>{getDate(transaction.timestamp)}</Typography>
                        </Grid>
                        <Grid item container xs={6} lg={8} justifyContent="flex-start">
                            <Typography>{getNotificationText(transaction.body.message, transaction.body.params, t, props.currentProfileWallet!)}</Typography>
                        </Grid>
                        <Grid item container xs={2} lg={2} justifyContent="flex-end">
                            <Typography>
                                <Link href={`${config.blockchain.explorer}/transactions/${transactionHash}`} target="_blank" rel="noopener">
                                    {t('NotificationMessages:MoreInfo')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Divider orientation="horizontal" className={classes.divider}/>
                    </Grid>
                </Grid>
                )}
            )
        }
        </>
    );
};

export default TransactionList;
