import { makeStyles } from "@material-ui/styles"
import { Toaster } from "react-hot-toast"

const toasterStyleDesktop = makeStyles({
    root: {
        fontFamily: 'Roboto',
    }
})


export const MyToaster = () => {
    const useToasterStyleDesktop = toasterStyleDesktop();

    const optionsForToaster = {
        style: {
            background: '#0B6258',
            color: 'white'
        }
    }

    return (
        <Toaster
            position="bottom-center"
            containerClassName={useToasterStyleDesktop.root}
            toastOptions={optionsForToaster}
        />
    )
}