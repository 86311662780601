import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useAppSelector } from "../../../../store/hooks"
import { Item } from "../../../../types/libofthings.type"
import { ItemCard } from "../../Cards/ItemCard/ItemCard"

export const ListOfItems = ({ items, collections }: {
    items: Item[],
    collections: any[]
}) => {
    const loading = useAppSelector(state => state.libOfThings.loading);
    const getCollectionInfoFromSymbol = (symbol: string) => {
        let collection = collections
            .filter((collection) => {
                if (collection.symbol === symbol) {
                    return collection;
                }
            })[0];
        if (collection == null) {
            collection = { contractAddress: '' };
        }
        return collection.contractAddress;
    }

    return (
        <Grid container style={{ position: 'relative', top: '3em' }}>
            {
                (!loading) ? (
                    items.map((i) => {
                        return <Grid style={{marginBottom: '50px'}}  key={i.itemID} item xs={12} md={6} lg={4}>
                            <ItemCard
                                item={i}
                                address={getCollectionInfoFromSymbol(i.collectionSymbol)}
                            />
                        </Grid>
                    })
                ) : (
                    <>
                    <Skeleton
                        style={{
                            position: 'relative',
                            top: '1em',
                        }}
                        variant="rect"
                        width={'100%'}
                        height={'400px'}
                    />
                    </>
                )
            }
        </Grid>
    )
}