import React from 'react';
import {withRouter} from "react-router-dom";

import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import QrFab from '../../components/UI/QrFab/QrFab';

import {useTranslation} from 'react-i18next';

import {getMenuElements} from './menuConfig';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {handleBottomMenuIndexChange} from "../../store/slices/uiSlice";

const useStyles =  makeStyles((theme: Theme) => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        margin: "auto",
        bottom: 0,
        right: 0,
        [theme.breakpoints.up("sm")]: {
            marginTop: -theme.spacing(1),
            width: `calc(100% - 240px)`
        },
        //borderTop: '2px rgba(0, 0, 0, 0.20) solid',
        boxShadow: '0px -2px 5px 0px rgba(0,0,0,0.18)'
    },
    buttonStyle: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

type BottomMenuBarProps = {
    location: {
        pathname: string;
    }
};

const BottomMenuBar = (props: BottomMenuBarProps) => {
    const { t } = useTranslation('Common');
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const tabIndex = useAppSelector( state => state.ui.bottomMenuActiveIndex );

    let bottomNavigationActions = Object.values(getMenuElements(t)).map( (elem, index) => {
        return (
            <BottomNavigationAction
                className={classes.buttonStyle}
                label={elem.text}
                icon={elem.icon}
                key={elem.text}
            />
        )
    });

    //this menu should be showed ONLY in home route (= '/', see Layout.js )
    if(props.location.pathname !== '/'){
        return null;
    }else {
        return (
            <>
                <QrFab/>
                <BottomNavigation
                    value={tabIndex}
                    onChange={(event, value) => {
                        dispatch(handleBottomMenuIndexChange({index: value}))
                    }}
                    showLabels
                    className={classes.stickToBottom}
                >
                    {bottomNavigationActions}
                </BottomNavigation>
            </>
        );
    }
};

export default withRouter(BottomMenuBar);
