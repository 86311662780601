export type DaoData = {
    additional_properties: //note this subobject is made this just to match the structure of the UserData type
    {
        commonshoodWallet: string;
    }
    name: string;
    flPlaceId: string;
    realm: "dao";
    type: string;
};

export const DaoTypes = ['Merchant', 'Association']
