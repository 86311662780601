import messageKeys from './messageKeys';
import { assetIntegerToDecimalRepresentation } from '../decimalsHandler/decimalsHandler';
import { logger } from '../logger/logger';
import { TFunction } from 'i18next';
import { assetsType } from "../../config/assets";
import { CoinList, NotificationParameters } from "../../api/notificationAPI";
import { getUserNameByAddress } from '../../api/userAPI';


const stringListGenerator = (coinList: CoinList[]) => {
    let actualCoinList: string = ''
    if (coinList.length === 1) {
        actualCoinList += assetIntegerToDecimalRepresentation(coinList[0].amount, coinList[0].decimals) + " " + coinList[0].symbol
    } else {
        for (let i = 0; i < coinList.length; i++) {
            if (i === coinList.length - 1) {
                actualCoinList += assetIntegerToDecimalRepresentation(coinList[i].amount, coinList[i].decimals) + " " + coinList[i].symbol
            }
            else {
                actualCoinList += assetIntegerToDecimalRepresentation(coinList[i].amount, coinList[i].decimals) + " " + coinList[i].symbol + ", "
            }
        }
    }
    return actualCoinList;
}


const getNotificationText = (
    messageKey: string | Promise<string> | void,
    params: NotificationParameters, t: TFunction,
    commonshoodWallet: string
) => {

    let notificationText = '';
    let amountConverted = '';
    let actualCoinAccepted = '';
    let actualCoinOffered = '';
    if (params == null) {
        throw new Error('This notification received no params, check the caller');
    }
    logger.debug('in getNotificationText, for key ===>', messageKey, ' params ===> ', params);

    switch (messageKey) {
        case messageKeys.COIN_CREATED:
            if (!params.symbol || !params.from || params.decimals == null) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:coinCreated', { params });
            break;

        case messageKeys.COIN_SENT:
            if (!params.symbol || params.amount == null || !params.sender || !params.receiver || params.decimals == null || !params.to_name || !params.to_realm || !params.from_realm || !params.from_name) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);

            if (params.to_realm === "exchange") { //sending to exchange
                notificationText = t('NotificationMessages:coinSentToExchange', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    }
                });
            } else if (params.to_realm === "dao") {
                notificationText = t('NotificationMessages:coinSentToDao', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    }
                });
            }
            else if (params.to_realm === "crowdsale") {
                notificationText = t('NotificationMessages:coinSentToCrowdsale', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    }
                });
            }
            else if (params.to_realm === "user") {
                notificationText = t('NotificationMessages:coinSent', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    }
                });
            }
            else if (params.to_realm === "unknown") {
                notificationText = t('NotificationMessages:coinSentToUnknown', {
                    params: {
                        ...params,
                        amount: amountConverted,
                    }
                });
            }
            else if (params.to_realm === 'ItemMarketplace') {
                notificationText = t('NotificationMessages:coinSentToItemMarketplace', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                })
            }
            else {
                throw new Error('no realm matching in: ' + messageKey + ' ' + params.to_realm);
            }

            break;
        case messageKeys.COIN_RECEIVED:
            if (!params.symbol || params.amount == null || !params.sender || !params.receiver || params.decimals == null || !params.to_name || !params.to_realm || !params.from_realm || !params.from_name) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            if (params.from_realm === "unknown") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:coinReceivedFromUnknownByDao', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceivedFromUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromUnknownToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                } else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    throw new Error('no realm matching in: ' + messageKey + ' ' + params.to_realm);
                }
            }
            else if (params.from_realm === "exchange") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceivedByExchange', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceivedByExchange', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromExchangeToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            } else if (params.from_realm === "dao") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceivedByDao', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceivedByDao', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromDaoToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            }
            else if (params.from_realm === "crowdsale") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceivedByCrowdsale', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceivedByCrowdsale', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromCrowdsaleToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            }
            else if (params.from_realm === "user") {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromUserToUnknown', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            }
            else if (params.from_realm === 'ItemMarketplace') {
                if (params.to_realm === "dao") {
                    notificationText = t('NotificationMessages:daoCoinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "user") {
                    notificationText = t('NotificationMessages:coinReceived', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === "unknown") {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted,
                        }
                    });
                }
                else if (params.to_realm === 'ItemMarketplace') {
                    notificationText = t('NotificationMessages:coinReceivedFromItemMarketplace', {
                        params: {
                            ...params,
                            amount: amountConverted
                        }
                    })
                }
                else {
                    logger.info(params)
                    throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
                }
            }
            else {
                throw new Error('no realm matching for sender: ' + messageKey + ' ' + params.from_realm);
            }
            break;
        case messageKeys.COIN_MINTED:
            if (!params.symbol || params.amount == null || params.decimals == null) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            notificationText = t('NotificationMessages:coinMinted', {
                params: {
                    ...params,
                    amount: amountConverted
                }
            });

            break;
        case messageKeys.CROWDSALE_CREATED:
            if (!params.title) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:crowdsaleCreated', { params });

            break;
        case messageKeys.CROWDSALE_UNLOCKED:
            if (!params.title) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:crowdsaleUnlocked', { params });

            break;
        case messageKeys.CROWDSALE_JOINED:
            if (params.amount == null || !params.title || params.decimals == null || !params.from_realm || !params.from_name) {
                throw new Error('missing a required parameter for ' + messageKey + params);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            if (params.from_realm === "dao") {
                notificationText = t('NotificationMessages:crowdsaleJoined', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                });
            }
            else if (params.from_realm === "user") {
                notificationText = t('NotificationMessages:crowdsaleJoined', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                });
            }
            else if (params.from_realm === "unknown") {
                notificationText = t('NotificationMessages:crowdsaleJoinedFromUnknown', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                });
            }
            else {
                throw new Error('no realm matching for receiver: ' + messageKey + ' ' + params.to_realm);
            }


            break;
        case messageKeys.CROWDSALE_REFUNDTO:
            if (params.amount == null || !params.title || params.decimals == null || !params.owner || !params.to_name || !params.to_realm) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            amountConverted = assetIntegerToDecimalRepresentation(params.amount, params.decimals);
            if (commonshoodWallet === params.owner) {
                notificationText = t('NotificationMessages:crowdsaleRefundToOwner', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                });
            } else {
                notificationText = t('NotificationMessages:crowdsaleRefundTo', {
                    params: {
                        ...params,
                        amount: amountConverted
                    }
                });
            }
            break;
        case messageKeys.CROWDSALE_STOPPED:
            if (!params.title) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:crowdsaleStopped', { params });
            break;

        case messageKeys.CROWDSALE_CAPREACHED:
            if (!params.title) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:crowdsaleCapReached', { params });
            break;

        case messageKeys.EXCHANGE_ADDED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)

            notificationText = t('NotificationMessages:exchangeAdded', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered,
                    amount: amountConverted
                }
            });
            break;

        case messageKeys.EXCHANGE_CANCELLED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeCancelled', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;

        case messageKeys.EXCHANGE_COMPLETED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeCompleted', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;

        case messageKeys.EXCHANGE_RENEWED:
            if (!params.coinOffered || !params.coinAccepted) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            actualCoinAccepted = stringListGenerator(params.coinAccepted)
            actualCoinOffered = stringListGenerator(params.coinOffered)
            notificationText = t('NotificationMessages:exchangeRenewed', {
                params: {
                    ...params,
                    coinAccepted: actualCoinAccepted,
                    coinOffered: actualCoinOffered
                }
            });
            break;

        case messageKeys.DAO_CREATED:
            if (!params.name) {
                logger.info(params)
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:daoCreated', { params });

            break;
        case messageKeys.DAO_MEMBER_PROMOTED:
            if (!params.member || params.newRole != null || !params.title || !params.from_name || !params.member_name || !params.newRole) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:daoMemberPromoted', { params });
            break;
        case messageKeys.DAO_MEMBER_DEMOTED:
            if (!params.member || params.newRole != null || !params.title || !params.from_name || !params.member_name || !params.newRole) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:daoMemberDemoted', { params });

            break;
        case messageKeys.DAO_MEMBER_KICKED:
            if (!params.member || !params.title || !params.from_name || !params.member_name) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:daoMemberKicked', { params });
            break;
        case messageKeys.DAO_MEMBER_JOINED:
            if (!params.member || !params.title || !params.member_name) {
                throw new Error('missing a required parameter for ' + messageKey);
            }
            notificationText = t('NotificationMessages:daoMemberJoined', { params });
            break;
        case messageKeys.COLLECTION_CREATED:
            notificationText = t('NotificationMessages:collectionCreated', { params });
            console.log("COLLECTION_CREATED", params)
            break;
        case messageKeys.NFT_ADDED:
            if (params.from_realm == "null") {
                notificationText = t('NotificationMessages:nftMinted', { params });
            } else if (params.from_realm == "marketplace") {
                notificationText = t('NotificationMessages:nftReceivedFromMarketplace', { params });
            } else {
                notificationText = t('NotificationMessages:nftAdded', { params });
            }
            break;
        case messageKeys.NFT_REMOVED:
            if (params.to_realm == "marketplace") {
                notificationText = t('NotificationMessages:nftSendedToAMarketplace', { params });
            } else {
                notificationText = t('NotificationMessages:nftRemoved', { params });
            }
            break;
        case messageKeys.MARKETPLACE_ADDED:
            notificationText = t('NotificationMessages:marketplaceAdded', { params });
            break;
        case messageKeys.MARKETPLACE_CANCELLED:
            notificationText = t('NotificationMessages:marketplaceRemoved', { params });
            break;
        case messageKeys.MARKETPLACE_COMPLETED:
            notificationText = t('NotificationMessages:marketplaceCompleted', { params });
            break;
        case messageKeys.PREORDER_CREATED:
            console.log(params);
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('NotificationMessages:preorderCreatedLender', { params });
                } else if (commonshoodWallet === params.borrower) {
                    notificationText = t('NotificationMessages:preorderCreatedBorrower', { params });
                }
            }
            break;
        case messageKeys.PREORDER_ACCEPTED:
            if (params.borrowerFSName != null && params.itemName != null) {
                if (commonshoodWallet === params.lender) {
                    notificationText = t('NotificationMessages:preorderAcceptedLender', { params });
                } else if (commonshoodWallet === params.borrower) {
                    notificationText = t('NotificationMessages:preorderAcceptedBorrower', { params });
                }
            }
            break;
        case messageKeys.PREORDER_DENIED:
            if (commonshoodWallet === params.lender) {
                notificationText = t('NotificationMessages:preorderDeniedLender', { params });
            } else {
                notificationText = t('NotificationMessages:preorderDeniedBorrower', { params });
            }
            break;
        case messageKeys.PREORDER_STARTED:
            notificationText = t('NotificationMessages:preorderStartedGenericMessage', { params });
            break;
        case messageKeys.PREORDER_CANCELLED:
            notificationText = t(`NotificationMessages:preorderCancelled${params.lender === commonshoodWallet ? 'Lender' : 'Borrower'}`, { params });
            break;
        case messageKeys.PREORDER_ENDED:
            notificationText = t(`NotificationMessages:preorderEnded${params.lender === commonshoodWallet ? 'Lender' : 'Borrower'}`, { params });
            break;
        case messageKeys.PREORDER_REVIEW_ENDED:
            notificationText = t(`NotificationMessages:preorderReviewEnded${params.lender === commonshoodWallet ? 'Lender' : 'Borrower'}`, { params });
            break;
        default:
            notificationText = 'Something went wrong - unrecognized messageKey for notification';
            throw new Error('Something went wrong - unrecognized messageKey for notification. Given: ' + messageKey);
    }

    return notificationText;
};

export default getNotificationText;
