import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '33px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '-0.02em',
        color: 'black',
    },
    styleForFlexboxWithoutScrollbar: { margin: 0, width: "100%" },
});

export const Title = ({ title }: { title: string }) => {
    const classes = useStyles();

    return (
        <b>
            <h1 className={classes.root}>{title}</h1>
        </b>
    )
}