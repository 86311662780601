import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { FormikProps, FormikTouched } from "formik";
import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from "react";

export type NftCreateDataSlice = {
  name: string;
  image: any;
  description: string;
  getFromCollection: boolean;
  numbersNft: number;
  attributes: any[];
  collectionAddress: string;
  isTransferable: boolean;
  type: string;
};

export type CollectionCreateDataSlice = {
  name: string;
  symbol: string;
  attributes: any[];
  description: string;
  image: any;
  file: File | String;
};

export type NftCreateDataApi = {
  tokenUri: string;
  collectionAddress: string;
  numbersNft: number;
  isTransferable: boolean;
  type: string;
};

export type CollectionCreateDataApi = {
  name: string;
  symbol: string;
  collectionURI: string;
};

export type NftData = {
  name: string;
  symbol: string;
  tokenUri: string;
  owner: string;
  contractAddress: string;
  tokenId: string;
  isTransferable: boolean;
};

export type CollectionData = {
  name: string;
  symbol: string;
  contractAddress: string;
  numberNfts: string;
  isCreatedByMe: boolean;
  contractURI: string;
};
export const typesAllowed = {
  artistic: "ARTISTIC",
  item: "ITEM",
  newType: "NEW_TYPE",
};

export type collectionProps = {
  collectionURI: string;
  contractAddress: string;
  name: string;
  nftOwned: number;
  nftType: string;
  owner: boolean;
  symbol: string;
  __v: number;
  _id: string;
};
export type collectionList = {
  collectionList: collectionProps[];
};

export type nftProps = {
  _id: string;
  contractAddress: string;
  owner: boolean;
  tokenUri: string;
  tokenId: number;
  name: string;
  symbol: string;
  isTransferable: boolean;
  isCreatedByMe?: boolean;
  timestamp: number;
  nftType: string;
  __v: number;
};

export type nftList = {
  nftList: nftProps[];
};

export type NftSendProps = {
  to: string;
  nftContract: string;
  tokenId: string;
};

export type FormInitialValuesForNfts = {
  nftName: string,
  nftDescription: string,
  imgFile: any,
  getFromCollection: boolean,
  numbersNft: string,
  attributes: {
    trait_type: string,
    value: string,
    required?: boolean
  }[],
  collection: {
    contractAddress: string,
    collectionURI: string,
    name: string,
    numberNfts: string,
    symbol: string
  },
  isTransferableOnlyOnce: boolean
}
export type FormInitialValuesForItems = {
  usingExistingNft: boolean,
  caution: string,
  dailyPrice: string,
  tokenAcceptedAsPayment: string,
  category: string
} & FormInitialValuesForNfts

export type NftCreationFormInput = {
    classes: ClassNameMap<"fieldContainer" | "buttons" | "next" | "collection" | "media" | "mediaConfirm">,
    formRef: MutableRefObject<any>,
    modalOpened: boolean,
    isForItems: boolean,
    formikProps: FormikProps<FormInitialValuesForNfts | FormInitialValuesForItems>,
    existingNftToUse?: NftData,
    handleConfirmModalOpen: () => void,
    setModalOpened: Dispatch<SetStateAction<boolean>>,
}
