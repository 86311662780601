import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fab } from "@material-ui/core"
import { useState } from "react"
import { QrActionsList } from "./QrActionsList";

export const QrFabForItems = () => {
    const [qrActionListDialogOpen, setQrActionListDialogOpen] = useState(false);

    const handleFableClick = () => {
        setQrActionListDialogOpen(true);
    }
    const toggleDialog = (newValue: boolean) => {
        setQrActionListDialogOpen(newValue);
    }
    
    return (
        <>
            <QrActionsList dialogOpen={qrActionListDialogOpen} toggleDialog={toggleDialog} />
            <Fab size="medium" color="primary" style={{ position: 'fixed', right: '10px', bottom: '10px' }} >
                <FontAwesomeIcon icon="qrcode" size="2x" onClick={handleFableClick} />
            </Fab>
        </>

    )
}