import { makeStyles, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFromIpfs, getJsonFromResource } from "../../../../api/resourceAPI";
import config from "../../../../config";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCollectionFromSymbol, getURIByIDSlice } from "../../../../store/slices/nftSlice";
import { getOptionValueFromHex } from "../../../../utilities/libofthings/libOfThings";

const optionIndicatorStyle = makeStyles({
    root: {
        color: '#FFFFFF',
        width: '100px',
        height: '23px',
        borderRadius: '5px 0px 0px 0px',
        background: '#FF8A00',
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1000,
        top: '0px',
    }
})

const imageStyle = makeStyles({
    root: {
        borderRadius: '5px',
        width: '100%',
        position: 'relative',
        maxWidth: '400px',
        height: '100%',
    }
})

export const ItemImage = ({ collectionSymbol, id, option }: {
    collectionSymbol: string,
    id: number,
    option: string | undefined,
}) => {
    const useOptionIndicatorStyle = optionIndicatorStyle();
    const [uri, setUri] = useState<string>('');
    const [metadata, setMetadata] = useState<any>(null);
    const [isImageLoad, setIsImageLoad] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const useImageStyle = imageStyle();
    const collectionsAndSymbols = useAppSelector((state) => state.nft.collectionsBySymbol);
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        const collectionAndSymbol = collectionsAndSymbols.filter((collection) => {
            if (collection.symbol === collectionSymbol) {
                return collection;
            }
        })[0];
        if (collectionAndSymbol == null) {
            (async () => { await dispatch(getCollectionFromSymbol(collectionSymbol)) })()
        } else {
            (async () => {
                setUri(await dispatch(getURIByIDSlice(id, collectionAndSymbol.address)));
            })()
        }

    }, [collectionsAndSymbols])

    useEffect(() => {
        (async () => {
            if (uri != null && uri.includes(config.network.ipfs.default_url))
                setMetadata(await getFromIpfs(uri));
            else setMetadata(await getJsonFromResource(uri));
        })()
    }, [uri])

    useEffect(() => {
        if (metadata !== null) {
            if (metadata.image.includes(config.network.ipfs.default_url)) {
                setMetadata({
                    ...metadata,
                    image: metadata.image.replace(
                        config.network.ipfs.default_url,
                        config.network.ipfs.gateway_url
                    ),
                });
            }
            if (!metadata.image.includes(config.network.ipfs.default_url)) {
                let tempImage = new Image();
                tempImage.onload = () => {
                    setIsImageLoad(true);
                };
                tempImage.src = metadata.image;
            }
        }

    }, [metadata]);

    return (
        <>
            <div style={{ position: 'relative', width: '100%', height: '150px' }}>
                {
                    (option != null) && (
                        <div className={useOptionIndicatorStyle.root}>
                            <Typography variant={'body1'}>{t(getOptionValueFromHex(option))}</Typography>
                        </div>
                    )
                }
                {
                    (isImageLoad) ? (
                        <img
                            style={{ maxHeight: '250px' }}
                            className={useImageStyle.root}
                            src={metadata.image}></img>
                    ) : (
                        <Skeleton
                            variant="rect" width={'100%'} height={'100%'}
                        />
                    )
                }
            </div>

        </>
    )
}