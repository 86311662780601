import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../types/nft.types";

/**
 * This file contains the initial values for the form in the NFT creation page.
 * It is here and not in the component for the sake of clarity and cleanliness.
 */
export let initialFormValues: FormInitialValuesForNfts = {
    nftName: "",
    nftDescription: "",
    imgFile: undefined,
    getFromCollection: false,
    numbersNft: "1",
    attributes: [
        {
            trait_type: "",
            value: "",
            required: false,
        },
    ],
    collection: {
        contractAddress: "",
        collectionURI: "",
        name: "",
        numberNfts: "",
        symbol: "",
    },
    isTransferableOnlyOnce: false,
}

export let initialFormValuesForItems: FormInitialValuesForItems = {
    ...initialFormValues,
    caution: "0",
    usingExistingNft: false,
    dailyPrice: "0",
    tokenAcceptedAsPayment: "COS",
    category: ""
}