import config from "../config";
import { Item, ItemStats, ItemStatsResponse, UploadItemInformation } from "../types/libofthings.type";
import * as axiosSOT from "../utilities/backend/axios-metadata";
import { AxiosResponse } from "axios";
import { logger } from "../utilities/logger/logger";
import {ethers, utils, Wallet} from "ethers";


type Preorder = {
    data: {
        signature: string,
        start: number,
        end: number,
        itemID: number,
        borrower: string,
        hash: string
    }
}

type User = {
    id: string,
    fullname: string
}

type PreorderResponseData = {
    ok: boolean,
    message: string,
    preorder: Preorder,
    user: User,
    timestamp: number,
}

const getLibOfThingsInstance = (ethersInstance: Wallet) => {
    const abi = new utils.Interface(config.smartContracts.ITM_MKP_ABI)
    return new ethers.Contract(
        config.smartContracts.ITM_MKP_ADDRESS,
        abi,
        ethersInstance
    )
}

const getNftTemplateInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.NFT_TMPLT_ABI)

    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}

export const getAvailableCategories = async (
    ethersInstance: Wallet,
): Promise<string[]> => {
    let categories: string[] = [];
    try {
        const libOfThingsInstance = getLibOfThingsInstance(ethersInstance);
        let eventFilter = libOfThingsInstance.filters.CategoryAdded()
        let categoriesFetched = await libOfThingsInstance.queryFilter(eventFilter)
        // categoriesFetched.map(
        //     (cat) => {
        //         if (cat.args) categories.push(cat.args._name);
        //     });
    } catch (error) {
        console.error(error)
    }
    return categories
}

export const getItemInfoByID = async (
    itemID: number,
): Promise<Item | undefined> => {
    let item;
    try {
        const q = `${config.network.metadata.url}lot/items/${itemID}`;
        const res = await axiosSOT.default.get(q);

        if (res.status === 200) {
            item = res.data.item;
        } else {
            throw new Error(`Error while fetching item with ID: ${itemID} from the backend. Status code: ${res.status})`);
        }

    } catch (error) {
        console.error(error);
    }
    return item;
}

export const uploadItem = async (
    ethersInstance: Wallet,
    itemData: UploadItemInformation,
    addressNftCollection: string,
    from: string
) => {
    try {
        logger.log(`Address of NftTemplate to use: ${addressNftCollection}`);
        const libOfThingsInstance = getLibOfThingsInstance(ethersInstance);
        const nftTemplateInstance = getNftTemplateInstance(ethersInstance, addressNftCollection);
        //Approve the library of things
        logger.log(`Approving the library of things at address: ${config.smartContracts.ITM_MKP_ADDRESS} for item with ID: ${itemData.idInCollection}`)
        logger.log(`Item data: ${JSON.stringify(itemData)}`);

        let trx = await nftTemplateInstance
            .approve(
                config.smartContracts.ITM_MKP_ADDRESS,
                itemData.idInCollection
            )
        trx = await trx.wait();

        console.log(`Transaction of approve: ${JSON.stringify(trx)}`);
        //Upload the item
        let trx2 = await libOfThingsInstance
            .registerItem(itemData)
        trx2 = await trx2.wait();
        console.log(`Transaction of upload`);
        console.log(trx2);
        return trx2;
    } catch (error) {
        console.error(error);
    }
}

export const getItemsOfLoggedUser = async (
    ethersInstance: Wallet,
    address: string
) => {
    console.log('Get items of logged user')
    const items: Item[] = [];
    const q = `${config.network.metadata.url}lot/${address}/myItems?offset=0&limit=100`
    const res = await axiosSOT.default.get(q);
    console.log(res.data);
    if (res.data != null && res.data.items != null) {
        res.data.items.map((item: any) => {
            items.push({
                itemID: item.itemID,
                idInCollection: item.idInCollection,
                price: item.price,
                caution: item.caution,
                owner: item.owner,
                name: item.name,
                collectionSymbol: item.collectionSymbol,
                paymentToken: item.paymentToken,
                option: item.option,
                uploadTime: item.dateOfUpload,
                category: item.category
            })
        })
    }

    return items;
}

export const getNewArrivalsAPI = async (offset: number, limit: number) => {
    console.log('Get new arrivals')
    const items: Item[] = [];
    const q = `${config.network.metadata.url}lot/items/newArrivals?offset=${offset}&limit=${limit}`;
    
    const res = await axiosSOT.default.get(q);
    if (res.data != null && res.data.items != null) {
        res.data.items.map((item: any) => {
            items.push({
                itemID: item.itemID,
                idInCollection: item.idInCollection,
                price: item.price,
                caution: item.caution,
                owner: item.owner,
                name: item.name,
                collectionSymbol: item.collectionSymbol,
                paymentToken: item.paymentToken,
                option: item.option,
                uploadTime: item.dateOfUpload,
                category: item.category
            })
        })
    }
    return items;
}

export const generateHashForPreorder = async (
    itemID: number,
    startDate: Date,
    endDate: Date,
    address: string
): Promise<{ hash: string, signature: string }> => {
    let hash = '', signature = '';
    const startDateUnix = Math.floor(startDate.getTime() / 1000);
    const endDateUnix = Math.floor(endDate.getTime() / 1000);
    const q = `${config.network.metadata.url}lot/preorders/generateHash?id=${itemID}`;
    const res: AxiosResponse<PreorderResponseData> = await axiosSOT.default.post(q, {
        startDate: startDateUnix,
        endDate: endDateUnix,
        address: address
    }, {
        headers: {
            "Authorization": localStorage.getItem("token")
        }
    });
    if (res.status === 200) {
        signature = res.data.preorder.data.signature;
        hash = res.data.preorder.data.hash;
    } else {
        throw new Error(res.data.message);
    }

    return { hash, signature };
}

export const getItemStats = async (itemID: string) => {
    let itemStats: ItemStats = { history: null, numberOfPreorders: 0 };

    console.log(`Getting item stats for item with ID: ${itemID}`);
    const q = `${config.network.metadata.url}lot/stats/${itemID}`;
    const res: AxiosResponse<ItemStatsResponse> = await axiosSOT.default.get(q, {
        headers: {
            "Authorization": localStorage.getItem("token")
        }
    });
    if (res != null && res.data != null) {
        if (res.status === 200) {
            itemStats.history = res.data.history;
            if (res.data.history != null)
                itemStats.numberOfPreorders = res.data.history.length;
        } else {
            throw new Error(res.data.message);
        }
    } else {
        if (res.status != null) {
            throw new Error(`Response is null: status: ${res.status}`);
        } else {
            throw new Error(`Response is null, status is null too`);
        }
    }

    return itemStats;
}
