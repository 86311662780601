import { Box, FormControl, Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import { Field, FormikTouched } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { FormInitialValuesForNfts, FormInitialValuesForItems } from "../../../../types/nft.types";

type NftCollectionFormNumber = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    isForItems: boolean,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const NftCollectionFormPrice = ({
    handleBlur,
    values,
    touched,
    isForItems,
    setFieldValue
}: NftCollectionFormNumber) => {
    const { t } = useTranslation(['LibOfThings']);
    const defaultCost = 1;  

    return (
        <Grid item xs={12}>
            <Box clone textAlign={'left'}>
                <Field
                    name="dailyPrice"
                    fullWidth
                    label={
                        <>
                            <Typography display="inline">
                                {`${t('price')} ${t('perDay')}`}
                            </Typography>
                            <Typography
                                display="inline"
                                color="secondary"
                            >
                                {" *"}
                            </Typography>
                        </>
                    }
                    onChange={(v: React.ChangeEvent<any>) => {
                        if (v.target.value >= 1)
                            setFieldValue(
                                "dailyPrice",
                                Math.floor(v.target.value),
                                true
                            );
                        else setFieldValue("dailyPrice", "1");
                    }}
                    disabled={isForItems}
                    onBlur={handleBlur}
                    touched={(touched as FormikTouched<FormInitialValuesForItems>)["dailyPrice"]}
                    component={TextField}
                    type="number"
                    value={defaultCost}
                />
            </Box>

        </Grid>
    )
}