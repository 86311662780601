import React from 'react';

import FlagButton from './FlagButton/FlagButton';
import Grid from '@material-ui/core/Grid';

type LanguageFlagsProps = {
  i18n: any;
};
const LanguageFlags = (props: LanguageFlagsProps) =>{
    const supportedLanguagesISO = [
        ['it', 'it-IT'],
        ['gb', 'en-US']
    ];

    let languageButtons = supportedLanguagesISO.map( (lng) => {
        return (
            <Grid item key={lng[0]} xs={4} style={{textAlign: "center"}}>
                <FlagButton countryAcronym={lng[0]}  lngISO={lng[1]} i18n={props.i18n}/>
            </Grid>
        );
    });

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" style={{margin: "1em 0"}}>
            {languageButtons}
        </Grid>
    );


};

export default LanguageFlags;
