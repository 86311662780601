import { Grid, TextField, Typography } from "@material-ui/core";
import { Field, FormikTouched } from "formik";
import { TFunction } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormInitialValuesForNfts, FormInitialValuesForItems } from "../../../../types/nft.types";

type NftCollectionFormNumber = {
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    handleBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
    },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const NftCollectionFormCaution = ({
    handleBlur,
    values,
    touched,
    setFieldValue
}: NftCollectionFormNumber) => {
    const { t } = useTranslation(['LibOfThings']);

    return (
        <Grid item xs={12}>
            <Field
                name="caution"
                fullWidth
                label={<>
                    <Typography display="inline">
                        {t("caution")}
                    </Typography>
                    <Typography
                        display="inline"
                        color="secondary"
                    >
                        {" *"}
                    </Typography>
                </>}
                onChange={(v: React.ChangeEvent<any>) => {
                    if (v.target.value >= 0)
                        setFieldValue(
                            "caution",
                            Math.floor(v.target.value)
                        );
                    else setFieldValue("caution", "0");
                }}
                onBlur={handleBlur}
                touched={(touched as FormikTouched<FormInitialValuesForItems>)["caution"]}
                component={TextField}
                type="number"
                value={(values as FormInitialValuesForItems).caution}
            />
        </Grid>
    )
}