import axios from "../utilities/backend/axios-resources";
import instance, {ipfsAPILoadToken} from "../utilities/backend/axios-ipfs";
import {logger} from "../utilities/logger/logger";
import config from "../config";

type PostResponseType = {
  data: {
    result: {
      ok: number;
      n: number;
      opTime: {
        ts: string;
        t: number;
      };
    };
    ops: [
      {
        fieldname: string;
        originalname: string;
        encoding: string;
        mimetype: string;
        destination: string;
        filename: string;
        path: string;
        size: number;
        userId: string;
        auth_server: "FIRSTLIFE";
        status: string;
        _id: string;
        permalink: string;
      }
    ];
  };
};

export const uploadResource = async (
  file: File
): Promise<{ fileHash: string; fileUrl: string }> => {
  const configFileHeader = {
    headers: {
      authentication_server: "FIRSTLIFE",
      "Content-Type": "multipart/form-data",
    },
  };
  console.log("FILE:", file);
  const url = "";
  let formData = new FormData();
  formData.append("files", file);

  const response: PostResponseType = await axios.post(
    url,
    formData,
    configFileHeader
  );
  logger.log("upload resource response:", response);
  const fileHash = response.data.ops[0]._id;
  const fileUrl = config.network.resources.url + fileHash;

  return {
    fileHash,
    fileUrl,
  };
};

export const uploadJsonResource = async (
  json: any,
  filename: string
): Promise<{ fileHash: string; fileUrl: string }> => {
  const configFileHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const url = "";

  const stringifiedJson = JSON.stringify(json);
  const blob = new Blob([stringifiedJson], {
    type: "application/json",
  });

  let formData = new FormData();
  formData.append("files", blob, `${filename}.json`);

  const response: PostResponseType = await axios.post(
    url,
    formData,
    configFileHeader
  );
  logger.log("upload resource response:", response);
  const fileHash = response.data.ops[0]._id;
  const fileUrl = config.network.resources.url + fileHash;

  return {
    fileHash,
    fileUrl,
  };
};

export const uploadFileIpfs = async (file: File): Promise<string> => {
  ipfsAPILoadToken();
  const configFileHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const url = "add";
  let formData = new FormData();
  formData.append("file", file);
  const response = await instance.post(url, formData, configFileHeader);
  console.log("cid: ", response.data);
  return response.data;
};

export const uploadJsonIpfs = async (json: any): Promise<string> => {
  ipfsAPILoadToken();
  const configFileHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const url = "add";

  const stringifiedJson = JSON.stringify(json);
  const blob = new Blob([stringifiedJson], {
    type: "application/json",
  });

  let formData = new FormData();
  formData.append("file", blob);
  const response = await instance.post(url, formData, configFileHeader);
  return response.data;
};

//tokenUri should contains an url like ipfs://
export const getFromIpfs = async (tokenUri: string) => {
  tokenUri = tokenUri.replace(config.network.ipfs.default_url, "/ipfs/");
  try {
    const result = await instance(tokenUri);
    return result.data;
  } catch (e) {
    logger.info("An error occurred during download from ipfs", e);
  }
};

export const getJsonFromResource = async (tokenUri: string) => {
  const result = await fetch(tokenUri);
  const data = await result.json();
  return data;
};

export const getImg= async (cid: string) => {

}