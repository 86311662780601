import { Card, CardMedia } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Skeleton } from "@material-ui/lab"
import { Field, FormikTouched } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CustomImageInput from "../../../../components/UI/Form/Upload/CustomImageInput/CustomImageInput"
import { FormInitialValuesForItems, FormInitialValuesForNfts, NftData } from "../../../../types/nft.types"

export const NftImage = ({
    touched,
    values,
    classes,
    isImageLoad,
    existingNft,
    setFieldValue,
    setFieldTouched
}: {
    isImageLoad: boolean,
    existingNft: NftData | undefined,
    touched: FormikTouched<FormInitialValuesForNfts | FormInitialValuesForItems>,
    values: FormInitialValuesForNfts | FormInitialValuesForItems,
    classes: ClassNameMap<"fieldContainer" | "buttons" | "next" | "collection" | "media" | "mediaConfirm">,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}) => {
    const [imageUri, setImageUri] = useState<string>('')

    const { t } = useTranslation(['NftCreateForm']);

    useEffect(() => {
        setImageUri(values.imgFile);
    }, [values.imgFile])

    return (
        <>
            {
                values.getFromCollection || existingNft ? (
                    <Card
                        className={classes.media}
                        elevation={10}
                    >
                        {
                            isImageLoad ? (
                                <CardMedia
                                    component="img"
                                    image={imageUri}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                />
                            ) : (
                                <Skeleton
                                    variant="rect"
                                    height={529}
                                    width={528}
                                />
                            )
                        }
                    </Card>
                ) : (
                    <Field
                        name="imgFile"
                        component={CustomImageInput}
                        title={t("nftSelectImage")}
                        setFieldValue={setFieldValue}
                        touched={touched["imgFile"]}
                        setFieldTouched={setFieldTouched}
                        className={classes.fieldContainer}
                        value={values.imgFile || undefined}
                        type="nftImage"
                    />
                )
            }
        </>
    )
}