import React, {useEffect, useState} from "react";
//Redux connector
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {coinMintReset, coinSetPreselected,} from "../../../store/slices/coinSlice";
import {handleBottomMenuIndexChange} from "../../../store/slices/uiSlice";

import {coinGetBalance} from "../../../api/coinAPI";
import {getProfileId} from "../../../utilities/utilities";

//i18n
import {useTranslation} from "react-i18next";

//Style injections
import {makeStyles, useTheme} from "@material-ui/core/styles";

import CoinMinter from "./CoinMinter/CoinMinter";
import {Avatar, Button, Divider, Grid, Typography} from "@material-ui/core";
import TransactionList from "../../Transactions/TransactionListItem/TransactionList";
import Loading from "../../../components/UI/Loading/Loading";

import type {CoinBalance} from "../../../types/coin.type";
import {getUserNameByAddress, UserNameType} from "../../../api/userAPI";
import AlertAvatar from "../../UI/AlertAvatar/AlertAvatar";
import MiniLoader from "../../UI/Loading/MiniLoader";
import {logger} from "../../../utilities/logger/logger";
import {DAOFactoryIsAddressDAO, DAOgetName} from "../../../api/daoAPI";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: "120px !important",
        height: "120px !important",
    },
    margintop: {marginTop: 15},
    mainpadding: {padding: "1em 3em"},
}));

type CoinDetailsProps = {
    selectedCoin: {
        symbol: string;
        decimals: number;
        type: "token" | "goods";
        balance: string;
        description: string;
        address: string;
        addressOfOwner: string;
        contractHash: string;
    };
    icon: any; //fixme, check type, probably it is just the string url
    handleClose: () => {};
};

const CoinDetails = (props: CoinDetailsProps) => {
    const {t} = useTranslation(["CoinDetails", "Common"]);
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [showCoinMinter, setShowCoinMinter] = useState(false);
    //Note we use a local balance state to update it instantly as soon as we complete a mint:
    const [localBalance, setLocalBalance] = useState<number>(0);
    const [ownerName, setOwnerName] = useState<UserNameType>();
    const [showLoadingComponent, setShowLoadingComponent] = useState(false);

    const currentProfileWallet = useAppSelector(
        (state) =>
            state.user.currentProfile?.additional_properties?.commonshoodWallet
    );
    const ethersInstance = useAppSelector((state) => state.ethers.ethersInstance);
    const transactionLoading = useAppSelector(
        (state) => state.coin.loadingTransactions
    );
    const transactionsList = useAppSelector((state) => state.coin.transactions);
    const currentProfile = useAppSelector((state) => state.user.currentProfile);
    const profileId = getProfileId(currentProfile?.realm, currentProfile);
    const mintLoading = useAppSelector((state) => state.coin.mintLoading);
    const mintDone = useAppSelector((state) => state.coin.coinMinted);
    const mintError = useAppSelector((state) => state.coin.mintError);

    const {selectedCoin, icon, handleClose} = props;

    const loadBalance = async () => {
        let rawBalance: CoinBalance;
        if (currentProfileWallet != null && ethersInstance != null) {
            rawBalance = await coinGetBalance(
                ethersInstance,
                currentProfileWallet,
                selectedCoin.address
            );
            setLocalBalance(rawBalance.balance);
        }
    };

    useEffect(() => {
        dispatch(coinMintReset())

        async function getUserName() {
            if (currentProfileWallet != null && ethersInstance != null) {
                if (await DAOFactoryIsAddressDAO(ethersInstance, currentProfileWallet, selectedCoin.addressOfOwner)) {
                    let userName = {
                        first_name: await DAOgetName(ethersInstance, selectedCoin.addressOfOwner),
                        last_name: '',
                        email: ''
                    }
                    setOwnerName(userName);
                } else {
                    let userName = await getUserNameByAddress(selectedCoin.addressOfOwner)
                    setOwnerName(userName);
                }
            }
        }
        console.log("zCoin", selectedCoin)

        loadBalance();
        getUserName()
    }, []);

    useEffect(() => {
        if (!mintLoading && !mintError && mintDone) {
            //load new balance on successful mint
            loadBalance();
        }
    }, [mintLoading, mintError, mintDone]);

    window.onscroll = () => {
        logger.info("SCROLLING");
        // if(!transactionLoading) {
        //     if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1) {
        //         if (paginationHandler) {
        //             dispatch(coinTransactions(props.selectedCoinAddress, page, amount))
        //             setPage(page + 1)
        //         }
        //     }
        // }
    };
    const handlePay = () => {
        const iconData = icon;
        const type = selectedCoin.type; //token or goods
        const address = selectedCoin.address;
        const symbol= selectedCoin.symbol
        const balance = selectedCoin.balance
        dispatch(coinSetPreselected({coin: {symbol, iconData, type, address, balance }}));
        handleClose(); //close this modal
        dispatch(handleBottomMenuIndexChange({index: 0}));
    };

    const handleShowCoinMinter = () => {
        setShowCoinMinter(!showCoinMinter);
    };

    const handleSubmission = () => {
        setShowCoinMinter(false);
        setShowLoadingComponent(true);
    };

    const isOwned = currentProfileWallet === selectedCoin.addressOfOwner;

    const ownerString = isOwned
        ? t("owned")
        : ownerName
            ? t("createdBy") + " " + ownerName.first_name + " " + ownerName.last_name
            : null;
    const balance = selectedCoin.balance;

    const coinMinterButton = isOwned ? (
        <Grid item container xs={12} alignItems="center">
            <Button
                variant="contained"
                color="primary"
                onClick={(event) => setShowCoinMinter(!showCoinMinter)}
                disabled={mintLoading}
            >
                {showCoinMinter ? t("coinMintUndo") : t("coinMint")}
            </Button>
        </Grid>
    ) : null;

    const coinPayButton = (
        <Grid item container xs={12} alignItems="center">
            <Button
                variant="contained"
                color="primary"
                onClick={handlePay}
                disabled={parseFloat(balance) == 0}
            >
                {t("Common:pay")}
            </Button>
        </Grid>
    );
    const url = selectedCoin.contractHash;
    const downloadManifestButton = (
        <Grid item container xs={12} alignItems="center">
            <Button
                variant="contained"
                color="primary"
                onClick={() => window.open(url, "_blank")}
            >
                {t("manifestDownload")}
            </Button>
        </Grid>
    );

    const getMintStatusMessage = (
        color: "primary" | "secondary" | "error",
        messageText: string,
        withLoader: boolean = false
    ) => {
        const loader = (
            <Grid item xs={12}>
                <Loading withLoader={true}/>
            </Grid>
        );
        return (
            <>
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        color={color}
                        display="block"
                        style={{
                            marginTop: "3em",
                            paddingLeft: "2em",
                            paddingRight: "2em",
                        }}
                    >
                        {messageText}
                    </Typography>
                </Grid>
                {withLoader ? loader : null}
            </>
        );
    };
    const handleModalClose = () => {
        setShowLoadingComponent(false);
    };

    const loadingTransactionComponent = (
        <MiniLoader/>
    );
    const handleSuccess = () => {
        setTimeout(() => {
            return <AlertAvatar big success text={t('Success')}/>
        }, 10000);
    }

    return (
        <Grid container style={{marginTop: "1em"}}>
            <Grid item xs={12}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                    xs={12}
                >
                    <Grid item xs={12}>
                        {icon ? (
                            <Avatar
                                alt={selectedCoin.symbol}
                                src={icon}
                                className={classes.avatar}
                            />
                        ) : (
                            <Avatar className={classes.avatar} alt={selectedCoin.symbol}>
                                {selectedCoin.symbol}
                            </Avatar>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            color={parseFloat(balance) == 0 ? "error" : "initial"}
                        >
                            {t("balance")} {localBalance}
                        </Typography>
                        <Typography variant="subtitle2">{ownerString}</Typography>
                        {/* {cap ?
                                <Typography variant="subtitle2">
                                    {cap}
                                </Typography>
                                : null
                            } */}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography paragraph={true} align="center">
                            {selectedCoin.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            {coinMinterButton}
                            {showCoinMinter ? (
                                <Grid item xs={12}>
                                    <CoinMinter
                                        tokenAddress={selectedCoin.address}
                                        decimals={selectedCoin.decimals}
                                        coinType={selectedCoin.type}
                                        onMintSubmit={handleSubmission}
                                        symbol={selectedCoin.symbol}
                                    />
                                </Grid>
                            ) : null}
                            {mintLoading ? <MiniLoader text={true}/> : null}
                            {!mintDone && mintError ? <AlertAvatar big fail text={t('Fail')}/> : null}

                            {showCoinMinter ? null : coinPayButton}
                            {showCoinMinter ? null : downloadManifestButton}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={{marginTop: 15}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{marginTop: 15}}>
                            {t("transactionsHistory")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TransactionList
                        selectedCoinAddress={selectedCoin.address}

                        currentProfileWallet={currentProfileWallet}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CoinDetails;
