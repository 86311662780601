// Main Routes
//import React from 'react';

//import Auth from "../containers/Auth/Auth";

import NewCoinCreateForm from "../containers/Coin/CoinCreateForm/NewCoinCreateForm";
import Qr from "../components/Qr/Qr";
import About from "../views/About/About";
import Logout from "../containers/Auth/Logout/Logout";
import Main from "../views/Main/Main";
import CrowdSaleCreateForm from "../containers/CrowdSale/CrowdSaleCreateForm/CrowdSaleCreateForm";
import CreateExchangeForm from "../containers/Exchange/ExchangeCreateForm/CreateExchangeForm";
import DaoCreationForm from "../containers/Dao/DaoCreateForm/DaoCreationForm";
import { FormContainer } from "../containers/Nft/NftCreateForm/FormContainer";
import CollectionCreateForm from "../containers/Nft/CollectionCreateForm/CollectionCreateForm";
import Marketplace from "../containers/Marketplace/Marketplace"
import Messages from "../components/NotificationsHistory/Messages";
import { LibOfThings } from "../containers/LibOfThings/LibOfThings";
import { HubItemsUpload } from "../containers/LibOfThings/RegistrationPage/HubItemsUpload";
import { ItemPage } from "../containers/LibOfThings/ItemPage/ItemPage";
import { MyItems } from "../containers/LibOfThings/MyInventory/MyItems";
import { MyPreordersContainer } from "../containers/LibOfThings/MyRequests/MyPreordersContainer";

const indexRoutes = [
    // { path: "/login", name: "Login", component: Auth, private: false, exact: false },
    { path: "/coinCreate", name: "Create Your Coin", component: NewCoinCreateForm, private: true, exact: false },
    { path: "/crowdSaleCreate", name: "Create Crowdsale", component: CrowdSaleCreateForm, private: true, exact: false },
    { path: "/messages", name: "Messages", component: Messages, private: true, exact: false },
    { path: "/daoCreate", name: "Create your Dao", component: DaoCreationForm, private: true, exact: false },
    { path: "/qrcode", name: "QrCode", component: Qr, private: true, exact: false },
    { path: "/about", name: "About", component: About, private: true, exact: false },
    { path: "/exchangeCreate", name: "Create a Sale", component: CreateExchangeForm, private: true, exact: false },
    { path: "/logout", name: "Logout", component: Logout, private: true, exact: false },
    { path: "/nftCreate", name: "Create Your Nft", component: FormContainer, private: true, exact: false },
    { path: "/collectionCreate", name: "Create Your Collection", component: CollectionCreateForm, private: true, exact: false },
    { path: "/marketplace", name: "Marketplace", component: Marketplace, private: true, exact: false },
    { path: "/libraryOfThings", name: "Library of things", component: LibOfThings, private: true, exact: false },
    { path: "/uploadItemInMarketplace", name: "Upload assets library of things", component: HubItemsUpload, private: true, exact: false },
    { path: "/items/:collection/:itemID", name: "Item page for library of things", component: ItemPage, private: true, exact: false },
    { path: "/myitems", name: "List of owned items", component: MyItems, private: true, exact: false },
    { path: "/mypreorders", name: "List of preorders", component: MyPreordersContainer, private: true, exact: false },
    { path: "/messages", name: "Messages", component: Messages, private: true, exact: false },
    //Main Root
    { path: "/", name: "Home", component: Main, private: true, exact: true },
];

export default indexRoutes;
