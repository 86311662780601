import { Typography } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { possibleStatusItemInCalendar } from "../../../../utilities/costants.calendar"

export const Remember = ({ status, lender }: {
    status: string,
    lender: boolean
}) => {
    const [message, setMessage] = useState<string>('');
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        switch (status) {
            case possibleStatusItemInCalendar.accepted:
                if (!lender)
                    setMessage(`${t('remember')} ${t('rememberAccepted')}`);
                break;
            case possibleStatusItemInCalendar.started:
                if (lender)
                    setMessage(`${t('remember')} ${t('rememberStartedLender')}`);
                break;
        }
    }, [status]);

    return (
        <>
            {
                <Typography variant="body1">
                    {message}
                </Typography>
            }
        </>
    )
}