import { Box, Grid, IconButton, List, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";

export const NftCollectionFormAttributes = ({
    attributes,
    t,
    setAttributesModalOpened,
    attributeItem
}: {
    attributes: {
        trait_type: string;
        value: string;
        required?: boolean;
    }[],
    t: TFunction,
    attributeItem: any,
    setAttributesModalOpened: Dispatch<SetStateAction<boolean>>
}) => {

    const handleAttributesModalOpen = () => {
        setAttributesModalOpened(true);
    };

    return (
        <>
            {attributes && attributes.length > 0 && (
                <Grid item xs={12}>
                    <Box clone borderBottom={1} pb={1}>
                        <Grid container spacing={1}>
                            <Grid item xs="auto">
                                <List color="primary" />
                            </Grid>
                            <Grid item xs>
                                <Box display="flex" justifyContent="flex-start">
                                    <Typography
                                        align="left"
                                        color="primary"
                                        display="inline"
                                    >
                                        {t("nftAttributesLabel")}
                                    </Typography>
                                    {attributes &&
                                        attributes.length > 0 &&
                                        attributes[0].required && (
                                            <Typography
                                                display="inline"
                                                color="secondary"
                                                align="left"
                                            >
                                                {" "}
                                                *
                                            </Typography>
                                        )}
                                </Box>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    align="left"
                                >
                                    {t("nftAttributeCaption")}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton
                                    onClick={() => handleAttributesModalOpen()}
                                >
                                    <Add color="primary"></Add>
                                </IconButton>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                spacing={1}
                                alignContent="center"
                                wrap="wrap"
                            >
                                {attributeItem}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )}
        </>

    )
}