import React from 'react';

//import AppIcon from '../../AppIcon/AppIcon'
//Case changer
import {listIntoArray} from "../../../../utilities/utilities";

//Material-UI
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import {useAppSelector} from "../../../../store/hooks";

type ListMenuPropsType= {
    navHandler: (path:string)=>void,
    itemList: any
}

const ListMenu = (props: ListMenuPropsType) => {

    const arr = listIntoArray(props.itemList);
    const currentProfile= useAppSelector(state => state.user.currentProfile)

    return (
        <List component="nav">
            {
                arr.map((item, key) => {
                    return (
                        <ListItem
                            disabled = {currentProfile!==null && currentProfile!.realm==="dao" && (item.path==='/daoCreate' || item.path==='/collectionCreate')}
                            button
                            key={key}
                            onClick={()=>props.navHandler(item.path)} >
                            <ListItemIcon>
                                {/*<AppIcon icon={item.icon}/>*/}
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>{item.text}</Typography>
                            </ListItemText>

                            {/*<Link to={item.path}/>*/}
                        </ListItem>
                    )
                })
            }
        </List>
    )
};

export default ListMenu;
