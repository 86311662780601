import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SlideModal from '../../../../components/UI/Modal/SlideModal/SlideModal'
import ZoomModal from '../../../../components/UI/Modal/ZoomModal/ZoomModal'
import PlaceCreationForm, { AddressPlace } from './PlaceCreationForm'
import { formFieldsNames } from '../configForm'
import { logger } from '../../../../utilities/logger/logger'
import CreateNewPlaceMap from '../../../../components/Map/CrowdsalesMap'
import { PlainPlaceType } from '../../../../components/Map/CrowdsaleMapUtils'
import logo from '../../../../assets/img/logo/240x240.png'
import config from '../../../../config'

const useStyles = makeStyles((theme) => {
    return createStyles({
        contractLabel: {
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            padding: '13px',
        },
        dateField: {
            width: '150px',
        },
        dateRow: {
            [theme.breakpoints.up('xs')]: {
                marginLeft: theme.spacing(5),
                marginRight: theme.spacing(5),
            },
            [theme.breakpoints.only('xs')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        textFields: {
            margin: '10px 5px 10px 5px',
        },
        select: {
            minWidth: '200px',
            margin: '0 5px 0 5px',
            [theme.breakpoints.only('xs')]: {
                margin: '20px 5px 20px 5px',
            },
        },
        placeCard: {
            minWidth: 275,
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: 20,
            boxShadow: '1px 5px 5px 2px #888888',
        },
        card: {
            minWidth: 275,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: 20,
            boxShadow: '1px 5px 5px 2px #888888',
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 22,
            fontWeight: 'bold',
        },
        pos: {
            marginBottom: 12,
        },
        root: {
            display: 'flex',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            flex: '1 0 auto',
        },
        cover: {
            width: 151,
        },
        controls: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        playIcon: {
            height: 38,
            width: 38,
        },
    })
})

type FormStep4Props = {
    formik: any
    setStep: (step: number) => void
    openModal: any
}
const FormStep4 = (props: FormStep4Props) => {
    const { formik, setStep, openModal } = props

    const {
        errors,
        touched,
        values,
        handleBlur, //this is passed to onBlur of field to let formik manage touched event
        setFieldValue,
    } = formik

    const [selectedPlace, setSelectedPlace] = useState<PlainPlaceType | null>(
        null
    )

    useEffect(() => {
        if (selectedPlace !== null && selectedPlace.firstLifePlaceID === '') {
            setCreateNewPlace(true)
        }
    }, [selectedPlace])

    const classes = useStyles()
    const { t } = useTranslation('CrowdSaleCreateForm')

    const [chooseAddressByMap, setChooseAddressByMap] = useState(false)
    const [createNewPlace, setCreateNewPlace] = useState(false)

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-start'
                item
                xs={12}
                style={{ marginTop: '20px' }}
                spacing={2}
            >
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    item
                    xs={12}
                    spacing={1}
                >
                    {values.firstlifePlace !== null ? (
                        <>
                            <Grid item xs={12} md={5} className={classes.placeCard}>
                                <Card className={classes.root}>
                                    <div className={classes.details}>
                                        <CardContent
                                            className={classes.content}
                                        >
                                            <Typography
                                                component='h5'
                                                variant='h5'
                                            >
                                                {values.firstlifePlace.name}
                                            </Typography>
                                            <Typography
                                                variant='subtitle1'
                                                color='textSecondary'
                                            >
                                                {values.firstlifePlace.address}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                    <CardMedia
                                        className={classes.cover}
                                        image={values.firstlifePlace.icon &&
                                            values.firstlifePlace.icon.length >0
                                            
                                                ? config.network.resources.url + values.firstlifePlace.icon[0].filename
                                                : logo
                                        }
                                        title='crowdsale logo'
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{ marginTop: '30px' }}
                                    onClick={() => {
                                        setFieldValue(
                                            formFieldsNames.firstlifePlace,
                                            null
                                        )
                                        setSelectedPlace(null)
                                    }}
                                >
                                    Cambia
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ padding: '10px' }}
                            >
                                <Card
                                    className={classes.card}
                                    onClick={() => setCreateNewPlace(true)}
                                >
                                    <CardContent>
                                        <Typography
                                            className={classes.title}
                                            gutterBottom
                                        >
                                            Crea un luogo
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ padding: '10px' }}
                            >
                                <Card
                                    className={classes.card}
                                    onClick={() => setChooseAddressByMap(true)}
                                >
                                    <CardContent>
                                        <Typography
                                            className={classes.title}
                                            gutterBottom
                                        >
                                            Scegli dalla mappa
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
                <Button
                    variant='contained'
                    color='primary'
                    style={{ marginTop: '50px' }}
                    onClick={() => setStep(3)}
                >
                    {t('back')}
                </Button>
            </Grid>
            <Grid item md={6} xs={12}>
                <Button
                    variant='contained'
                    color='secondary'
                    style={{ marginTop: '50px' }}
                    //type="submit"
                    onClick={openModal}
                >
                    FINE
                </Button>
            </Grid>
            <SlideModal
                open={chooseAddressByMap && !createNewPlace}
                handleClose={() => setChooseAddressByMap(false)}
            >
                    <CreateNewPlaceMap
                        setPlace={(flPlace: PlainPlaceType) => {
                            logger.info(
                                'CreateNewPlaceMap.setPlace',
                                flPlace,
                                formFieldsNames.firstlifePlace
                            )
                            setFieldValue(
                                formFieldsNames.firstlifePlace,
                                flPlace
                            )
                            setSelectedPlace(flPlace)
                            setChooseAddressByMap(false)
                        }}
                        handleClose={() => {
                            setChooseAddressByMap(false)
                        }}
                    />
            </SlideModal>
            <ZoomModal
                title={'Crea il tuo luogo'}
                open={!chooseAddressByMap && createNewPlace}
                buttons={
                    <>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginTop: '10px', alignItems: 'end' }}
                            onClick={() => setCreateNewPlace(false)}
                        >
                            {t('back')}
                        </Button>
                    </>
                }
            >
                <Grid item xs={12}>
                    <PlaceCreationForm
                        handleClose={() => {
                            setCreateNewPlace(false)
                        }}
                        setPlace={(place: PlainPlaceType) => {
                            setFieldValue(formFieldsNames.firstlifePlace, place)
                        }}
                        partialPlace={selectedPlace}
                    />
                </Grid>
            </ZoomModal>
        </>
    )
}
export default FormStep4
