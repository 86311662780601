import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../store/hooks"
import { cancelPreorder, endLendingPeriod, getActivePreorders } from "../../../../store/slices/calendarSlice"
import { getItemByID } from "../../../../store/slices/libOfThingsSlice"
import { Preorder } from "../../../../types/calendar.type"
import { Item } from "../../../../types/libofthings.type"
import { possibleStatusItemInCalendar } from "../../../../utilities/costants.calendar"
import { duration } from "../../../../utilities/costants.notifications"
import { DialogWithInfo } from "../../BasicComponents/DialogWithInfo"
import { PreorderInformationList } from "../../BasicComponents/PreorderInformationList"
import { PreorderStepper } from "../../MyInventory/LendedItems/PreorderStepper"
import { ItemImage } from "../../MyInventory/MyItems/ItemImage"
import { actionButtonStyle } from "../../Styles/Buttons.style"
import { contentStyle, contentWrapperStyle, itemNameStyle } from "../../Styles/Preorders.style"
import { QrCodeStartLendingPeriod } from "./Dialogs/QrCodeStartLendingPeriod"

export const PreorderBody = ({ preorder, removeFromList, goToItemPage }: {
    preorder: Preorder,
    removeFromList?: (id: string) => void,
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const dispatch = useAppDispatch();
    const useContentStyle = contentStyle();
    const useContentWrapperStyle = contentWrapperStyle();
    const useItemNameStyle = itemNameStyle();
    const useActionButtonStyle = actionButtonStyle();
    const [openDialogToStartReviewWithQrCode, setOpenDialogToStartReviewWithQrCode] = useState<boolean>(false);
    const [item, setItem] = useState<Item>();
    const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false);
    const { t } = useTranslation(['LibOfThings']);

    useEffect(() => {
        (async () => {
            const itemFound = await dispatch(getItemByID(preorder.itemID));
            setItem(itemFound);
        })()
    }, [])

    const handleCancelPreorder = () => {
        dispatch(cancelPreorder(preorder.preorderID, false));
        setAskForConfirmation(false);
        toast.loading(t('denyPreorderToast'), {
            duration: duration
        });
    }

    const handleEndLendingPeriod = async () => {
        if (item != null) {
            const errors = await dispatch(endLendingPeriod(preorder.preorderID, item));

            if (errors != null && errors.length > 0) {
                toast.error(errors[0], {
                    duration: duration
                });
            } else {
                toast.success(t('endLendingPeriodToast'), {
                    duration: duration
                });
                dispatch(getActivePreorders(false, 0));
            }
        }
    }

    return (<>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h1" className={useItemNameStyle.root}>
                    {
                        item != null ? (
                            <b>{item.name}</b>
                        ) : (
                            <Skeleton />
                        )
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                {
                    <>
                        {
                            item != null ? (
                                <ItemImage
                                    id={item.idInCollection}
                                    collectionSymbol={item.collectionSymbol}
                                    option={undefined}
                                />
                            ) : (
                                <Skeleton />
                            )
                        }
                    </>
                }
            </Grid>
            <Grid item xs={12} md={8} className={useContentStyle.root}>
                <div className={useContentWrapperStyle.root}>
                    <Typography variant={'body1'}>
                        {
                            preorder.status === possibleStatusItemInCalendar.started && (
                                <Button className={useActionButtonStyle.root} onClick={() => handleEndLendingPeriod()}>
                                    {t('endLendingPeriod')}
                                </Button>
                            )
                        }
                        {
                            preorder.status === possibleStatusItemInCalendar.accepted && (
                                <>
                                    <Button className={useActionButtonStyle.root} onClick={() => setAskForConfirmation(true)}>
                                        {t('cancel')}
                                    </Button>
                                    <Button className={useActionButtonStyle.root} onClick={() => setOpenDialogToStartReviewWithQrCode(true)}>{t('showQR')}</Button>
                                </>

                            )
                        }

                        <Button className={useActionButtonStyle.root} onClick={() => {
                            if (item != null) {
                                console.log(item);
                                goToItemPage(item.collectionSymbol, item.itemID);
                            } else {
                                toast.loading(t('itemNotReady'), {
                                    duration: duration
                                });
                            }
                        }}>
                            {t('itemPage')}
                        </Button>

                    </Typography>
                    <PreorderInformationList preorder={preorder} lender={false} />
                    {
                        item != null ? (
                            <DialogWithInfo
                                item={item}
                            />
                        ) : (
                            <Skeleton />
                        )
                    }
                </div>
                <PreorderStepper status={preorder.status} />
            </Grid>
        </Grid>

        <Dialog
            style={{ textAlign: 'center' }}
            open={askForConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('areYouSure?')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('pendingPreorderDialogContent')}
                    <br />
                    <b>{t('pendingPreorderDialogContentSubtitle')}</b>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setAskForConfirmation(false)} color="primary">
                    Deny
                </Button>
                <Button onClick={() => handleCancelPreorder()} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        {
            item != null && <QrCodeStartLendingPeriod item={item} preorder={preorder} handleClose={() => setOpenDialogToStartReviewWithQrCode(false)} open={openDialogToStartReviewWithQrCode} />
        }

    </>)
}