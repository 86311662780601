//Form controls
import {Field, FieldArray, Formik} from "formik";
//Material-UI components
import {Box, Button, Grid, IconButton, Switch, Typography,} from "@material-ui/core";
//Custom Components
import TextInput from "../../../../components/UI/Form/Input/TextInput";

// i18n
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

type AttributersProp = {
  create: any;
  attributes: {
    trait_type: string;
    required: boolean;
  }[];
  handleAttributesModalClose: any;
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
}));
const AttributesSelection = (prop: AttributersProp) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation(["NftCreateForm", "Common"]);

  const handleCreateAttributes = (
    attributes: { trait_type: string; required: boolean }[]
  ) => {
    const formattedAttributes: any = attributes.filter((attribute) => {
      return attribute.trait_type !== "";
    });
    formattedAttributes.sort(function (a: any, b: any) {
      if (a.required && b.required) {
        return a.trait_type > b.trait_type ? 1 : -1;
      }
      return a.required ? -1 : 1;
    });
    prop.create(formattedAttributes);
    prop.handleAttributesModalClose();
  };

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          attributes: prop.attributes,
        }}
        //validationSchema={finalValidationSchema}
        onSubmit={(values) => {
          handleCreateAttributes(values.attributes);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          submitForm,
          setFieldValue,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box clone mb={3}>
                  <Typography variant="h6" align="left">
                    {t("attributeDescription")}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Typography variant="body1" align="left">
                      {t("attributeTraitTypeLabel")}
                    </Typography>
                  </Grid>
                </Grid>
                <FieldArray name="attributes">
                  {({ insert, remove }) => (
                    <div>
                      {values.attributes.length > 0 &&
                        values.attributes.map((attribute, index) => (
                          <Grid container spacing={1} key={index}>
                            <Grid item xs>
                              <Field
                                fullWidth
                                name={"attributes[" + index + "].trait_type"}
                                placeholder={t("attributeTraitTypePlaceholder")}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched["attributes"]}
                                component={TextInput}
                                errMessage={errors["attributes"]}
                                value={attribute.trait_type}
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              xs
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs>
                                <Typography>Obbligatorio</Typography>
                              </Grid>
                              <Grid item xs>
                                <Switch
                                  name={"attributes[" + index + "].required"}
                                  checked={attribute.required}
                                  onChange={handleChange}
                                  value={attribute.required || false}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs="auto">
                              <IconButton
                                aria-label="add"
                                onClick={() =>
                                  insert(index + 1, {
                                    trait_type: "",
                                    required: false,
                                  })
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs="auto">
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  if (index !== 0) remove(index);
                                  else {
                                    setFieldValue(
                                      "attributes[" + index + "].required",
                                      false
                                    );
                                    setFieldValue(
                                      "attributes[" + index + "].trait_type",
                                      ""
                                    );
                                  }
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                    </div>
                  )}
                </FieldArray>
                <Button
                  type="submit"
                  className={classes.buttons}
                  onClick={submitForm}
                  variant="contained"
                >
                  {t("attributeSaveButton")}
                </Button>
                <Button
                  className={classes.buttons}
                  onClick={() => prop.handleAttributesModalClose()}
                  variant="contained"
                >
                  {t("Common:close")}
                </Button>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AttributesSelection;
