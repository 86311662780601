import { Button } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import ZoomModal from "../../../../components/UI/Modal/ZoomModal/ZoomModal"
import { nftCreateReset } from "../../../../store/slices/nftSlice"
import { FormInitialValuesForItems, FormInitialValuesForNfts } from "../../../../types/nft.types"
import { ConfirmTitleModalContent } from "./ConfirmTitleModalContent"

export const ConfirmTitleModal = ({
    modalOpened,
    values,
    nftCreated,
    loading,
    submitForm,
    setModalOpened,
    classes,
    attributes,
    isForItems
}: {
    attributes: any
    values: FormInitialValuesForItems | FormInitialValuesForNfts
    isForItems: boolean,
    modalOpened: boolean,
    nftCreated: boolean,
    loading: boolean,
    setModalOpened: Dispatch<SetStateAction<boolean>>,
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    classes: ClassNameMap<"fieldContainer" | "buttons" | "next" | "collection" | "media" | "mediaConfirm">,
}) => {
    const dispatch = useDispatch();

    //language
    const { t } = useTranslation(['NftCreateForm', 'Common', 'LibOfThings']);

    const handleModalClose = () => {
        setModalOpened(false);
        if (nftCreated) {
            dispatch(nftCreateReset());
        }
    };

    return (
        <ZoomModal
            title={t("nftConfirmTitle")}
            open={modalOpened}
            buttons={
                !nftCreated ? (
                    <>
                        <Button
                            type="submit"
                            className={classes.buttons}
                            onClick={submitForm}
                            variant="contained"
                            disabled={loading}
                        >
                            {t("nftCreateButton")}
                        </Button>
                        <Button
                            className={classes.buttons}
                            onClick={() => handleModalClose()}
                            variant="contained"
                        >
                            {t("Common:close")}
                        </Button>
                    </>
                ) : null
            }
        >
            <ConfirmTitleModalContent 
                isForItems={isForItems}
                values={values}
                attributes={attributes}
                classes={classes}
            />
        </ZoomModal>
    )
}