/* Responsive Drawer */
import React from "react";

// Style injections
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";

import {Direction, Divider, Drawer, Hidden} from "@material-ui/core";

const useStyles =  makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: 240,
    height: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      //position: "relative"
    }
  },

  toolbar: theme.mixins.toolbar,
  cursor: {cursor: "pointer"}
}))

type ResponsiveDrawerProps = {
  mobileOpen: boolean,
  direction: Direction,
  handleDrawerToggle: () => void,
  userDataComponent: JSX.Element,
  children: any
}

const ResponsiveDrawer = (props: ResponsiveDrawerProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const {
    mobileOpen,
    direction,
    handleDrawerToggle,
    userDataComponent,
    children
  } = props

  const drawer = (
      <div className={classes.cursor}>
          <div className={classes.toolbar} >{userDataComponent}</div>
          <Divider />
          {children}
      </div>
  );

  return (
    <>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          anchor={direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default ResponsiveDrawer;
