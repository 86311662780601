import React, { useEffect, useState } from "react";
import { logger } from "../../../../../utilities/logger/logger";

//Inject styles
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Field } from "formik";

import { Avatar, Button, Typography } from "@material-ui/core";

import { TextField } from "formik-material-ui";
import CoinList from "../../../../../components/Coin/CoinList/CoinList";
import Loading from "../../../../../components/UI/Loading/Loading";

import { capitalCase } from "change-case";

//i18n
import { useTranslation } from "react-i18next";

import { arrayComparison } from "../../../../../utilities/utilities";

// redux
import { useAppSelector } from "../../../../../store/hooks";
import PreselectedCoinComponent from "../../../../SearchBar/PreselectedCoin";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    padding: theme.spacing(1),
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
  },
  symbolClass: {
    padding: theme.spacing(1),
  },
}));

type SelectFormType = {
  //Note all these are passed by formik, the types are from its documentation
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
  submitForm: () => Promise<any>;
  values: { [field: string]: any };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  //==========================================================================
  preselectedCoin: any
};

const SelectForm = (props: SelectFormType) => {
  const { t } = useTranslation(["CoinSendForm_Step1", "Common"]);
  const theme = useTheme();
  const classes = useStyles(theme);

  const [coinSelectionList, setCoinSelectionList] = useState<any>(undefined); //fixme type
  const [selectedCoin, setSelectedCoin] = useState<any| null>(); //fixme type
  const [selectedCoinIndex, setSelectedCoinIndex] = useState<number>(0);

  const loading = useAppSelector((state) => state.file.loading);
  const coinList = useAppSelector((state) => state.coin.coinList);
  const coinLoading = useAppSelector((state) => state.coin.getListloading);
  const profile = useAppSelector((state) => state.user.currentProfile);

  const { handleChange, handleBlur, submitForm, values, setFieldValue, preselectedCoin } = props;

  useEffect(() => {
    if (!coinLoading && coinList && !coinSelectionList) {
      setCoinSelectionList(
        [...coinList].sort((a, b) => a.symbol.localeCompare(b.symbol))
      );
      if(preselectedCoin!== undefined){
        const filteredList =[...coinList].filter((coin:any)=> coin.address===preselectedCoin.address)
        setCoinSelectionList(filteredList)
      }
      setSelectedCoin({ ...coinList[0] });

    }
  }, []);

  useEffect(() => {
    if (coinSelectionList && coinSelectionList.length>0) {
      updateSelectedCoin(coinSelectionList[0].symbol)
    }
  }, [coinSelectionList]);

  useEffect(() => {
    if (
      coinList &&
      coinList.length !== 0 &&
      coinSelectionList &&
      coinSelectionList.length !== 0
    ) {
      let newCoinList = coinList
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      logger.debug("SelectForm - [UPDATING COIN LIST]");
      setCoinSelectionList(newCoinList);

      if(preselectedCoin!== undefined){
        newCoinList =[...coinList].filter((coin:any)=> coin.address===preselectedCoin.address)
        setCoinSelectionList(newCoinList)
      }
      
      if (selectedCoinIndex !== null) {
        setSelectedCoin({ ...newCoinList[selectedCoinIndex] });
        setFieldValue("selectedCoin", newCoinList[selectedCoinIndex].symbol);
        setFieldValue(
          "selectedBalance",
          newCoinList[selectedCoinIndex].balance
        );
        setFieldValue("decimals", newCoinList[selectedCoinIndex].decimals);
      }
    }
  }, [coinList]);

  //Handlers
  const updateSelectedCoin = (symbol: string) => {
    if (coinList && coinList.length !== 0) {
      //Update local ID
      const selectedCoinIndex = coinSelectionList.findIndex(
        (item: { symbol: string }) => item.symbol === symbol
      );
      logger.debug(
        "SelectForm - [UPDATING SELECTED COIN]",
        coinSelectionList[selectedCoinIndex],
        "with index ",
        selectedCoinIndex
      );
      setSelectedCoin({ ...coinSelectionList[selectedCoinIndex] });
      setSelectedCoinIndex(selectedCoinIndex);
      setFieldValue("selectedCoin", coinSelectionList[selectedCoinIndex].symbol);
      setFieldValue("selectedBalance", coinSelectionList[selectedCoinIndex].balance);
      setFieldValue("decimals", coinSelectionList[selectedCoinIndex].decimals);
    }
  };

  let form = <Loading withLoader title={t("Common:loadingForm") + "..."} />;

  if (coinSelectionList && coinSelectionList.length !== 0) {
    form = (
      <>
        <CoinList
          withRadio={true} //this is too remove the pay button and put a radio button instead
          selected={selectedCoinIndex}
          coinLoading={coinLoading}
          coinList={coinSelectionList}
          handleSelect={(symbol) => updateSelectedCoin(symbol)}
          showPayButton={false}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedCoin.logo ? (
            <Avatar
              style={{
                width: "100px",
                height: "100px",
                margin: "8px",
              }}
              src={selectedCoin.logo}
            />
          ) : (
            <Avatar
              style={{
                width: "100px",
                height: "100px",
                margin: "8px",
              }}
            >
              {selectedCoin.symbol}
            </Avatar>
          )}

          <Field
            name="amount"
            placeholder={t("Common:amount")}
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            component={TextField}
            value={values.amount || ""}
          />
          <Field
            type="hidden"
            name="selectedBalance"
            value={selectedCoin.balance || ""}
          />
          <Typography className={classes.symbolClass} variant="h6">
            {selectedCoin.symbol}
          </Typography>
        </div>
        <div
          style={{ padding: "8px", marginLeft: "auto", marginRight: "auto" }}
        >
          <Button
            fullWidth
            type="submit"
            className={classes.buttons}
            variant="contained"
            onClick={submitForm}
            disabled={coinLoading || !values.amount}
          >
            {capitalCase(t("Common:send"))}
          </Button>
        </div>
      </>
    );
  }
  return form;
};

export default SelectForm;
