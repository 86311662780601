import { Fragment, useEffect, useState} from 'react';
import format from 'date-fns/format';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';

import { Card, CardContent, CardActions, Grid, Typography, Button, Tooltip, Backdrop, CircularProgress, IconButton, Avatar, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BuildIcon from '@material-ui/icons/Build';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ConditionalAvatar from '../../../components/UI/ConditionalAvatar/ConditionalAvatar';

import {useTranslation} from 'react-i18next';

import { useAppSelector } from '../../../store/hooks';

import config from '../../../config';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        position: "relative"
    },
    onGoing: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '30px',
        padding: '10px'
    },
    backdrop: {
        position: 'absolute',
        zIndex: 5
    },

    manageButton: {
        width: "40px",
        height: "40px",
        border: "2px solid" + theme.palette.primary.main,
        background: "white",
        "&:hover": {
            background: "white",
        }
    },

    deleteButton: {
        width: "40px",
        height: "40px",
        border: "2px solid" + theme.palette.error.main,
        color: theme.palette.error.main,
        background: "white",
        "&:hover": {
            background: "white",
        }
    },
    acceptButton: {
        borderRadius: "10px"
    },
    coinImage: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    }
}));


type ExchangeCardProps = {
    key: string,
    exchange: any,
    onOperation: (exchange: any, action: string) => void,
    expanded: string,
    onChangeExpanded: (exchange: string) => void,
}

const ExchangeCard = (props: ExchangeCardProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation('ExchangeCard');

    const accountAddress = useAppSelector(state => state.user.currentProfile?.additional_properties?.commonshoodWallet);
    const exchangeOperation = useAppSelector(state => state.exchange.onGoingOperations);

    const exchange = props.exchange;

    const [hasEnoughBalance, setHasEnoughBalance] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    useEffect(() => {
        if(accountAddress && accountAddress === exchange.owner.address) setIsOwner(true);
        else setIsOwner(false);
    }, [accountAddress, exchange])

    useEffect(() => {
        const checkBalance = async () =>  {
            if(exchange.status === config.exchangeStatus.RUNNING) {
                exchange.coinsRequiredData.forEach((coin: any) => {
                    if(coin.balance.balance < coin.amount) {
                        setHasEnoughBalance(false);
                    }
                });
            }
        }

        checkBalance()
    }, [exchange.coinsRequiredData, exchange.status]);

    const coinsList = (textLabel: string, coins: any[]) => {
        return (
            <Grid container item xs={12} justifyContent="center" spacing={2}>
                <Grid container item xs={12} justifyContent="center">
                    <Typography>{textLabel}</Typography>
                </Grid>
                {
                coins.map((coin, index) => (
                    <Fragment key={coin.symbol}>
                    <Grid container item xs={4} justifyContent="center" spacing={1}>
                        <Grid container item xs={12} justifyContent="center" alignItems='center'>
                            <ConditionalAvatar alt={coin.symbol} src={coin.logoUrl} className={classes.coinImage}/>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            <Typography style={{color: "black"}} variant="caption">{`${coin.amount} ${coin.symbol}`}</Typography>
                            
                        </Grid>
                    </Grid>
                    {index + 1 !== coins.length && <Divider orientation="vertical" flexItem />}
                    </Fragment>
                ))
                }
            </Grid>
        )
    }

    const getExpirationAsReadable = () => {
        if(exchange.expiration === 0) return t('noExpiration');

        return format(exchange.expiration*1000, "d/M/yyyy - HH:mm")
    }

    const acceptButton = () => {
        let disabledReason: string | undefined;

        if(isOwner) disabledReason = t("disabledReasonOwner");
        else if(!hasEnoughBalance) disabledReason = t("disabledReasonBalance");

        const button = () => {
            return (
                <Button
                    color='primary'
                    variant='contained'
                    size='large'
                    fullWidth
                    className={classes.acceptButton}
                    onClick={() => props.onOperation(props.exchange, "accept")}
                    disabled={disabledReason ? true : false}
                    >
                    
                    {t('accept')}
                </Button>
            )
        }

        if(disabledReason) {
            return (
                <Tooltip title={disabledReason} arrow open={isTooltipOpen} onOpen={() => setIsTooltipOpen(true)} onClose={() => setIsTooltipOpen(false)}>
                    <span style={{width: "100%"}} onClick={() => {setIsTooltipOpen(true)}}>
                        {button()}
                    </span>
                </Tooltip>
            )
        }

        return (
            <>
                {button()}
            </>
        )
    }

    return (
        <Grid item key={exchange.address} xs={12} sm={11} md={6} lg={3} xl={2}>
            <Card className={classes.card} elevation={3}>
                <Grid container justifyContent='space-between' alignItems='center' style={{padding: "10px", flexDirection: "row"}}>
                    {isOwner &&
                        <IconButton aria-label="Delete" onClick={() => props.onOperation(props.exchange, "cancel")} className={classes.deleteButton}>
                            <DeleteIcon />
                        </IconButton>
                    }

                    <Grid container xs item style={{display: "flex", gap: "10px"}} alignItems="center" justifyContent='center'>
                        <Avatar src={exchange.owner.avatar}/>
                        <Typography variant="h5">{exchange.owner.firstName}</Typography>
                    </Grid>

                    {isOwner &&
                        <IconButton aria-label="Manage" color="primary" onClick={() => props.onOperation(props.exchange, "manage")} className={classes.manageButton}>
                            <BuildIcon />
                        </IconButton>
                    }
                </Grid>
                <Backdrop className={classes.backdrop} open={exchangeOperation.includes(exchange.address)}>
                    <CircularProgress color="primary" />
                </Backdrop>

                <CardContent>
                    <Grid container justifyContent='center' spacing={2}>
                        {coinsList(t('offer'), exchange.coinsOfferedData)}

                        <Grid container item xs={12} justifyContent='center'>
                            <ImportExportIcon />
                        </Grid>

                        {coinsList(t('receive'), exchange.coinsRequiredData)}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container item xs={12}>
                        <Grid container item xs={8} justifyContent="flex-start" alignItems="center">
                            <Grid item xs={12}>
                                <Typography color="textSecondary" variant='subtitle2'>
                                    {t('expiration', {
                                        params: {
                                            expiration: getExpirationAsReadable()
                                        }
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" variant='subtitle2'>
                                    {t('remainingExchanges', {
                                        params: {
                                            repeatings: exchange.repeatings
                                        }
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={4}>
                            {acceptButton()}
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default ExchangeCard;