import React from "react";

import {useTranslation} from "react-i18next";
//Custom components
import CoinDetails from "../../../../components/Coin/CoinDetails/CoinDetails";

const MyWalletDetails = (props: any) => {
  const { t } = useTranslation("Common");
  const { selectedCoin, handleClose } = props;
  return (
    <CoinDetails
      icon={selectedCoin.logo}
      handleClose={handleClose}
      selectedCoin={selectedCoin}
    />
  );
};

export default MyWalletDetails;
