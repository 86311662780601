import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {logger} from "../../utilities/logger/logger";


enum TransactionTypes{
    'coinCreation',
    'coinMint',
    'coinTranfer',

    'daoCreation',

    'crowdsaleCreation',
    'crowdsaleUnlock',
    'crowdsaleJoin',
    'crowdsaleRefund',
    'crowdsaleStop',

    'exchangeCreation',
    'exchangeAccept',
    'exchangeCancel',
    'exchangeRenew',
    'exchangeRefill',

    'nftCreation',
    'collectionCreation',
    'nftTransfer',

    'marketplaceCreation',
    'marketplaceAccept',
    'marketplaceCancel',


}


type onGoingTransactionsInitialState = {
    onGoingTransactions: string[]
}

const initialState: onGoingTransactionsInitialState = {
    onGoingTransactions: [],
};

export const onGoingTransactionsSlice = createSlice({
    name: 'onGoingTransactions',
    initialState,
    reducers: {
        onGoingTransactionPush(state, action: PayloadAction<{ transactionCode: number }>){
            state.onGoingTransactions.push(TransactionTypes[action.payload.transactionCode])
        },
        onGoingTransactionPop(state, action: PayloadAction<{ transactionCode: number }>){
            const idx= state.onGoingTransactions.indexOf(TransactionTypes[action.payload.transactionCode])
            state.onGoingTransactions= state.onGoingTransactions.filter((el:string, index:number)=>{return index!=idx});
        }
    }
})

export const {onGoingTransactionPush, onGoingTransactionPop} = onGoingTransactionsSlice.actions;
export default onGoingTransactionsSlice.reducer;