import React from 'react';

//i18n
import {useTranslation} from "react-i18next";

//Redux connector
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {checkAuthOnClient, checkAuthOnServer} from "../../store/slices/authSlice";

//Main configuration
import config from '../../config';

import {CircularProgress, Grid, Typography} from '@material-ui/core';
//Material UI Styling
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {logger} from "../../utilities/logger/logger";

const useStyles =  makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    title: {
        margin: 'auto',
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2),
        justifyContent: 'center'
    },
    spinner: {
        margin: 'auto',
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2),
    }
}));

const Auth = () => {
    const { t } = useTranslation(['Auth']);
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.auth.loading);
    const isAuthenticated = useAppSelector(state => state.auth.idToken);

    // Redirect to authentication
    const authRedirecthandler = () => {
        const netConfig = config.network.authserver['firstlife_auth'];
        let urlString = netConfig.base_url + "v1/auth/login?";
        urlString += 'responseType=code';
        urlString += '&clientId=' + netConfig.client_id;
        urlString += '&redirectUri=' + netConfig.redirect_uri + 'login';
        //Redirect to Firstlife auth
        window.location.replace(encodeURI(urlString));
    }

    let redirectText = t('redirectAuth');
    let authRender = (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.mainContainer}>
                <Typography variant="subtitle2" className={classes.title}>{redirectText}</Typography>
                <CircularProgress/>
            </Grid>
        </Grid>
    );

    // if (isAuthenticated) {
    //     authRender = (<Redirect to='/'/>)
    // }

    // Not in redux auth flow
    if (!loading) {
        if (isAuthenticated) {
            redirectText = t('enteringApp');
        } else {
            const queryUrlParams = new URLSearchParams(new URL(document.location.href).searchParams);
            // Check if there is an Authorization code from the query params
            let type = null;

            if (queryUrlParams.has('code')) {
                type = 'code';
            }
            if (queryUrlParams.has('token')) {
                type = 'token';
            }
            if (type) {
                const data = queryUrlParams.get(type);
                dispatch(checkAuthOnServer(type, data))
            } else {
                //I could have a valid token already, but not in Store
                const localToken = localStorage.getItem('token');
                if (localToken) { //Check if token is valid
                    dispatch(checkAuthOnClient())
                } else { // no token, call oauth server
                    redirectText = t('redirectLogin');
                    // User is not authenticated and has to be redirected to login
                    authRedirecthandler();
                }
            }

            // const redirectText = loading ? 'Waiting for Authentication...' : 'Redirect to Login...';
            //
            // // User is not authenticated and has to be redirected to login
            // authRender = (
            //     <Grid container spacing={16}>
            //         <Grid item xs={12} className={classes.mainContainer}>
            //             <Typography variant="subtitle1" className={classes.title}>{redirectText}</Typography>
            //             <CircularProgress/>
            //             { !loading ? this.authRedirecthandler('firstlife') : null }
            //         </Grid>
            //     </Grid>
            // );
        }
    }
    return (authRender);
}

export default (Auth);
