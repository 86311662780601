import { Chip, Grid, makeStyles, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { Item, ItemStats } from "../../../types/libofthings.type";


const informationContainerStyle = makeStyles({
    root: {
        position: 'relative',
        '@media (max-width: 960px)': {
            marginBottom: '1em',
            left: '1em'
        }
    }
})

export const InformationList = ({ item, itemStats }: {
    item: Item,
    itemStats: ItemStats
}) => {
    const useInformationContainerStyle = informationContainerStyle();
    const { t } = useTranslation(["LibOfThings"]);

    return (
        <Grid container spacing={1} className={useInformationContainerStyle.root}>
            <Grid item md={2} >
                <InformationListElement label={`${t('dailyPrice')}: ${item.price / (10 ** 2)}`} />
            </Grid>
            <Grid item md={2}>
                <InformationListElement label={`${t('caution')}: ${item.caution / (10 ** 2)}`} />
            </Grid>
            <Grid item md={2}>
                <InformationListElement label={`${t('totalLendings')}: ${itemStats.numberOfPreorders}`}/>
            </Grid>
            <Grid item md={3}>
                <InformationListElement label={`${t('nftCollection')}: ${item.collectionSymbol}`} />
            </Grid>
            <Grid item md={3}>
                <InformationListElement label={`${t('category')}: ${item.category}`} />
            </Grid>
        </Grid>

    )
}

const InformationListElement = ({ label }: { label: string }) => {
    return (
        <Chip color="primary" style={{ width: '100%' }} label={label} />
    )
}