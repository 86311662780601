import { Box, Grid, Paper, Typography } from "@material-ui/core"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"
import ZoomModal from "../../../../components/UI/Modal/ZoomModal/ZoomModal"
import { FormInitialValuesForNfts } from "../../../../types/nft.types"
import { logger } from "../../../../utilities/logger/logger"
import AttributesSelection from "../AttributeSelection/AttributeSelection"

export const AttributesTitleModal = ({
    t,
    modalAttributesOpened,
    attributes,
    setAttributesModalOpened,
    values,
    setAttributeItem,
    setFieldValue
}: {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setAttributeItem: Dispatch<SetStateAction<any>>,
    values: FormInitialValuesForNfts,
    setAttributesModalOpened: Dispatch<SetStateAction<boolean>>,
    attributes: {
        trait_type: string;
        value: string;
        required?: boolean;
    }[],
    modalAttributesOpened: boolean,
    t: TFunction,
}) => {
    const handleAttributesModalClose = () => {
        setAttributesModalOpened(false);
    };

    const attributeSelect = (
        attributesData: {
          trait_type: string;
          value: string;
          required?: boolean;
        }[]
      ) => {
        logger.info(attributesData);

        if (attributesData.length != 0) {
          values.attributes = attributesData;
          setAttributeItem(
            attributesData.map((attribute, i) =>
              attribute.value !== "" ? (
                <Grid item xs="auto" key={i}>
                  <Box clone p={2}>
                    <Paper elevation={3}>
                      <Typography color="primary" variant="overline">
                        {attribute.trait_type || ""}
                      </Typography>
                      <Typography color="primary" variant="body1">
                        {attribute.value}
                      </Typography>
                    </Paper>
                  </Box>
                </Grid>
              ) : null
            )
          );
        } else {
          values.attributes = [
            { trait_type: "", value: "", required: false },
          ];
          setAttributeItem(<></>);
        }
        setFieldValue("nftName", values.nftName);
      };

    return (
        <ZoomModal
            title={t("attributesTitle")}
            open={modalAttributesOpened}
        >
            {attributes && attributes.length > 0 && (
                <AttributesSelection
                    create={attributeSelect}
                    attributes={attributes}
                    handleAttributesModalClose={handleAttributesModalClose}
                />
            )}
        </ZoomModal>
    )
}