import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  InputAdornment,
  Box,
  Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ClearIcon from '@material-ui/icons/Clear';

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useAppDispatch } from "../../../store/hooks";
import {
  exchangeAccept,
  exchangeCancel,
  exchangeRenew,
  exchangeRefill,
  exchangeChangeExpirationAndRefill
} from "../../../store/slices/exchangeSlice";

import DateFnsUtils from "@date-io/date-fns";
import addDays from "date-fns/addDays";
import isPast from "date-fns/isPast";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-US";
import { assetDecimalRepresentationToInteger, assetIntegerToDecimalRepresentation } from "../../../utilities/decimalsHandler/decimalsHandler";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {},
  redButton: {
    color: "#FF0000",
    borderColor: "#FF0000",
  },
  divider: {
    width: "100%",
    marginBottom: "2vh",
  },
  radio: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  option: {
    height: "5vh",
  },
  coinsList: {
    justify: "center",
  },
  greyedText: {
    color: "gray",
  },
  repeatingsSelection: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
}));

interface LocaleMapType {
  [key: string]: Locale;
}

const localeMap: LocaleMapType = {
  en: enLocale,
  it: itLocale,
};

const getDisplayedDate = (expiration: number) => {
  if(expiration === 0) return null
  if(isPast(new Date(expiration*1000))) return addDays(new Date(), 1)
  
  return new Date(expiration * 1000)
}

type ExchangeInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  exchange: any;
  action: string;
};

const ExchangeConfirmDialog = (props: ExchangeInfoProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, i18n } = useTranslation("ExchangeConfirmDialog");

  const dispatch = useAppDispatch();

  const actionType = props.action;
  const isOpen = props.isOpen;
  const exchangeAddress = props.exchange.address;
  const coinsOffered = props.exchange.coinsOfferedData;
  const coinsRequired = props.exchange.coinsRequiredData;
  const expiration = props.exchange.expiration
  const repeatings = props.exchange.repeatings;

  const minDate = addDays(new Date(), 1)
  const minRepeatings = actionType === "manage" ? repeatings : 1;

  const [repeatingsSelection, setRepeatingsSelection] = useState((actionType === "manage") ? minRepeatings : 1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(getDisplayedDate(expiration));

  const [repeatingsHasChanged, setRepeatingsHasChanged] = useState(false);
  const [expirationHasChanged, setExpirationHasChanged] = useState(false);
  const [isMaxRepeatings, setIsMaxRepeatings] = useState(true);

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setRepeatingsSelection(1);
    setSelectedDate(minDate);
    props.onClose();
  };

  const handleAcceptExchange = () => {
    dispatch(
      exchangeAccept(exchangeAddress, coinsRequired, repeatingsSelection)
    );

    handleClose();
  };

  const handleCancelExchange = () => {
    dispatch(exchangeCancel(exchangeAddress));

    handleClose();
  };

  const handleModifyExchange = () => {
    if(repeatingsHasChanged && expirationHasChanged) {
      const convertedSelectedExpiration = selectedDate ? selectedDate.getTime()/1000 : 0;

      dispatch(exchangeChangeExpirationAndRefill(exchangeAddress, coinsOffered, repeatingsSelection - repeatings, convertedSelectedExpiration))
    } else if(repeatingsHasChanged) {
      dispatch(exchangeRefill(exchangeAddress, coinsOffered, repeatingsSelection - repeatings));
    } else if(expirationHasChanged) {
      const convertedSelectedExpiration = selectedDate ? selectedDate.getTime()/1000 : 0;

      dispatch(exchangeRenew(exchangeAddress, convertedSelectedExpiration));
    }

    handleClose();
  }

  const DatePicker = () => {
    return (
      <>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={localeMap[i18n.languages[1]]}
        >
          <DateTimePicker
            autoOk
            ampm={false}
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            label={t("expirationLabel")}
            minDate={minDate}
            minDateMessage="Selected date is not correct"
          />
        </MuiPickersUtilsProvider>
      </>
    );
  };

  const expirationChanger = () => {
      return (
        <Grid container item xs={12} style={{marginTop: isXs ? "5vh" : "1vh"}}>
          <Grid item container xs={12} sm={5} justifyContent="flex-start" alignItems="flex-end">
            <Typography>{t("expirationText")}</Typography>
          </Grid>
          <Grid container xs={12} sm={7} justifyContent="flex-start">
            <Grid item container xs={9} justifyContent="flex-end" alignItems="flex-end">
                <DatePicker />{" "}
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <Button variant="outlined" disabled={selectedDate === null} color="primary" style={{height: "56px"}} onClick={() => setSelectedDate(null)}>
                  <ClearIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
  };
  
  useEffect(() => {

    switch(actionType) {
      case "accept":
        for(const coinRequired of coinsRequired) {
          const convertedAmount = parseInt(assetIntegerToDecimalRepresentation(coinRequired.balance.balance, coinRequired.balance.decimals));
          if((repeatingsSelection + 1) * coinRequired.amount > convertedAmount) {
            setIsMaxRepeatings(true);
            return;
          }
        }
        setIsMaxRepeatings(false);
        break;
      case "manage":
        let maxReached = false;
        let maxRepeatings = Number.POSITIVE_INFINITY;
        for(const coinOffered of coinsOffered) {
          const convertedAmount = parseInt(assetIntegerToDecimalRepresentation(coinOffered.balance.balance, coinOffered.balance.decimals));

          if((repeatingsSelection - repeatings + 1) * coinOffered.amount > convertedAmount) {
            if((repeatingsSelection - repeatings) * coinOffered.amount > convertedAmount) {
              const tempMax = Math.floor(convertedAmount / coinOffered.amount);
              maxRepeatings = Math.min(tempMax, maxRepeatings);
              setRepeatingsSelection(tempMax + repeatings)
            }
            maxReached = true;
          }
        }
        setIsMaxRepeatings(maxReached);
      }
  }, [actionType, repeatingsSelection, repeatings, coinsOffered, coinsRequired]);

  useEffect(() => {
    const convertedExpiration = expiration !== 0 ? expiration * 1000 : null;
    const convertedSelectedDate = selectedDate ? selectedDate.getTime() : null;
    
    setExpirationHasChanged(convertedExpiration !== convertedSelectedDate)
  }, [selectedDate, expiration])

  const dialogText = () => {
    if (actionType === "accept") return t("acceptText");
    if (actionType === "cancel") return t("cancelText");
    if (actionType === "manage") return t("manageText");
  };

  const actionButton = () => {
      if(actionType === 'accept') return <Button variant="contained" color="primary" onClick={handleAcceptExchange}> {t('acceptButton')} </Button>;
      if(actionType === 'cancel') return <Button variant="outlined" className={classes.redButton} onClick={handleCancelExchange}> {t('deleteButton')} </Button>;
      if(actionType === 'manage') return <Button variant="contained" color="primary" onClick={handleModifyExchange}> {t('manageButton')} </Button>;
  }

  const coinsList = (coins: any, title: string) => {
    const correctAmount = (amount: number) => {
      if (actionType === "accept")
        return amount * repeatingsSelection;
      if (actionType === "cancel") return amount * repeatings;
      if(actionType === "manage") return `${amount * repeatings} + ${amount * (repeatingsSelection - repeatings)}`

      return amount;
    };

    return (
      <List>
        <ListItem key={title}>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ style: { whiteSpace: "nowrap" } }}
          />
        </ListItem>
        <Divider />
        {coins.map((coin: any) => (
          <ListItem key={coin.symbol}>
            <ListItemAvatar>
              <Avatar src={coin.logoUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={coin.symbol}
              secondary={correctAmount(coin.amount)}
              className={classes.coinsList}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const repeatingsComponent = () => {
    const changeRepeatings = (newRepeatings: number) => {
      if (isNaN(newRepeatings) || newRepeatings < 1) setRepeatingsSelection(minRepeatings);
      else if (actionType === "accept" && newRepeatings > repeatings)
        setRepeatingsSelection(repeatings);
      else if(actionType === "manage" && newRepeatings < minRepeatings) setRepeatingsSelection(minRepeatings);
      else {
        setRepeatingsSelection(newRepeatings);
        setRepeatingsHasChanged(newRepeatings !== repeatings)
      }
    };

    const repeatingsButtonsDisabled = (button: string): boolean => {
      switch (button) {
        case "subtract": {
          if(actionType === "manage" && repeatingsSelection === minRepeatings) return true
          if(repeatingsSelection <= 1) return true;
          break;
        }

        case "add": {
          if (actionType === "accept" && repeatingsSelection >= repeatings)
            return true;
          return false;
        }
      }

      return false;
    };

    return (
      <>
        <Grid item container xs={12} sm={5} justifyContent="flex-start" alignItems="center">
          <Typography>{actionType === "manage" ? t("refillText") : t("acceptRepeatings")}</Typography>
        </Grid>
        <Grid item container xs={3} sm={2} justifyContent="flex-start" alignItems="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => changeRepeatings(repeatingsSelection - 1)}
            disabled={repeatingsButtonsDisabled("subtract")}
            style={{height: "100%"}}
          >
            <RemoveIcon />
          </Button>
        </Grid>

        <Grid item container xs={6} sm={3} justifyContent="center" alignItems="flex-end">
          <TextField
            value={repeatingsSelection}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              changeRepeatings(Number(event.target.value))
            }
            variant="outlined"
            fullWidth
            inputProps={{ style: { textAlign: "end" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.greyedText}>
                  /{repeatings}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item container xs={3} sm={2} justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => changeRepeatings(repeatingsSelection + 1)}
            disabled={repeatingsButtonsDisabled("add") || isMaxRepeatings}
            style={{height: "100%"}}
          >
            <AddIcon />
          </Button>
        </Grid>
      </>
    );
  };

  const dialogContent = () => {
    if (actionType === "accept") {
      return (
        <>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              container
              xs={12}
              className={classes.repeatingsSelection}
            >
              {repeatingsComponent()}
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Divider style={{width: "100%", marginTop: "6vh"}}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            {coinsList(coinsOffered, t("receiving"))}
          </Grid>
          <Grid item xs={12} sm={6}>
            {coinsList(coinsRequired, t("giving"))}
          </Grid>
        </>
      );
    }

    if (actionType === "cancel") {
      return (
        <Grid item xs={6} justifyContent="center">
          {coinsList(coinsOffered, t("refund"))}
        </Grid>
      );
    }

    if (actionType === "manage") {
      return (
        <>
          <Grid item container xs={12} justifyContent="center">
            {repeatingsComponent()}
          </Grid>
          {expirationChanger()}
          <Grid item container xs={12} justifyContent="center">
            <Divider style={{width: "100%", marginTop: "6vh"}}/>
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent="center">
            {coinsList(coinsOffered, t("onOffer"))}
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent="center">
            {coinsList(coinsRequired, t("toAccept"))}
          </Grid>
        </>
      );
    }
  };

  const errorMessage = () => {
    if(actionType === "accept") 
      return t("maxAccept");
    if(actionType === "manage") return t("maxRepeatingsRefill")
  }

  if (props.exchange === null) return <></>;
  else
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          className={classes.backdrop}
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="center" xs={12}>
              {dialogText()}
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box justifyContent="center" display="flex">
              <Grid
                container
                xs={12}
                justifyContent="center"
                spacing={isXs ? 0 : 3}
              >
                {dialogContent()}
              </Grid>
            </Box>
            <Grid item container style={{visibility: isMaxRepeatings ? "visible" : "hidden"}} justifyContent="center">
              <Typography color="error">{errorMessage()}</Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              {actionButton()}
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
};

export default ExchangeConfirmDialog;