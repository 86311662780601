import config from "../../config";

export const getIpfsUrl = (url: string) => {
  return url.includes(config.network.ipfs.default_url)
    ? url.replace(
        config.network.ipfs.default_url,
        config.network.ipfs.gateway_url
      )
    : url.includes(config.network.resources.url)
    ? url
    : config.network.resources.url + url;
};
