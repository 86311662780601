import React, {MouseEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getSuggestedUsers, userGetListData, userRemovePreselectedContact} from "../../store/slices/userSlice";
import {daoGetList} from "../../store/slices/daoSlice";
import {
    Avatar,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import ContactList from "../../components/Contacts/ContactList/ContactList";
import {capitalCase} from "change-case";
import {useTranslation} from "react-i18next";
import {ExtendedUserData} from "../../types/user.type";
import SearchField from "../../components/UI/Input/Text/SearchField/SearchField";
import {alphabeticComparisonObj} from "../../utilities/utilities";
import {DaoData, DaoTypes} from "../../types/dao.types";
import place_placeholder from "../../assets/img/mapImgUtils/home-green.png";
import {Place} from "../../types/place.type";
import {logger} from "../../utilities/logger/logger";
import SlideModal from "../../components/UI/Modal/SlideModal/SlideModal";
import CoinSendForm from "../Coin/Send/CoinSendForm/CoinSendForm";
import PreselectedCoinComponent from "./PreselectedCoin";
import {coinSendReset, coinUnsetPreselected} from "../../store/slices/coinSlice";
import {Explore} from "@material-ui/icons";
import TSMap from "../../components/Map/Map";
import {getMapPlacesByCoordinatesReset, getNearPlaces} from "../../store/slices/placeSlice";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StoreIcon from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/Group";
import HomeWorkIconOutlined from "@material-ui/icons/HomeWorkOutlined";


const alphabetic_users = (a: any, b: any) => {
    return alphabeticComparisonObj(a, b, 'name');
};

const placeBuild = (dao: DaoData): Place=> {

    return {
        realm: "dao",
        firstLifePlaceID: dao.flPlaceId,
        name: dao.name,
        icon: place_placeholder,
        additional_properties: {
            commonshoodWallet: dao.additional_properties.commonshoodWallet,
        },
        daoDetails:dao.type
    };
};

const SearchBar = () => {

    const dispatch = useAppDispatch();
    const {t} = useTranslation(['DaoCreateForm','Common','Map'])

    const [contactModalOpened, setContactModalOpened] = useState(false);
    const [sendFormOpened, setSendFormOpened] = useState(false);
    const [searchInput, setSearchInput] = useState<string | null>(null);
    const [sortKey, setSortKey] = useState(0);
    const [filterKey, setFilterKey] = useState('*');
    const [searchStartTimeout, setSearchStartTimeout] = useState<any>()
    const [selectedContact, setSelectedContact] = useState<ExtendedUserData | null>(null)
    const [mapModalOpened, setMapModalOpened] = useState(false);
    const [places, setPlaces] = useState<any>(null);
    const coinLoading = useAppSelector(state => state.coin.getListloading);
    const userLoading = useAppSelector(state => state.user.loading)
    const userList = useAppSelector(state => state.user.userList)
    const profile = useAppSelector(state => state.user.currentProfile)
    const preselectedCoin = useAppSelector(state => state.coin.preselectedCoin)
    const daoList = useAppSelector(state => state.dao.daoList)
    const loadingDaoList = useAppSelector(state => state.dao.loadingDaoList)
    const preselectedContact = useAppSelector(state => state.user.preselectedContact)
    const suggestedUsers = useAppSelector(state => state.user.suggestedUsers)
    const getSuggestedUsersLoading = useAppSelector(state => state.user.suggestedUsersLoading)
    const getNearPlacesLoading = useAppSelector(state => state.place.nearPlacesContactLoading)
    const nearPlaces = useAppSelector(state => state.place.nearPlacesContact)

    const mapModalOpenHandler = () => setMapModalOpened(true)

    const mapModalCloseHandler = () => {
        setMapModalOpened(false)
        dispatch(getMapPlacesByCoordinatesReset())
    };

    const updateSearchInput = (inputField: string) => {
        if ((inputField !== searchInput)) {
            if (inputField.length > 0) {
                if (!userLoading) {
                    dispatch(userGetListData(inputField))
                }
                if (!loadingDaoList) {
                    dispatch(daoGetList(null, inputField))
                }
            }
            setSearchInput(inputField)
        }
    };

    useEffect(() => {
        if(preselectedContact !==null && !sendFormOpened){
            setSendFormOpened(true)
        }
    }, [preselectedContact])

    useEffect(() => {
        if (!getSuggestedUsersLoading && suggestedUsers===null){
            dispatch(getSuggestedUsers(1))
        }
        if (!getNearPlacesLoading) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(location => {
                    const latitude = location.coords.latitude;
                    const longitude = location.coords.longitude;
                    dispatch(getNearPlaces(latitude, longitude))
                });
            }
        }
    }, [profile])

    const sendFormOpen = (contact: any) => {
        logger.info(contact)
        setSelectedContact(contact)
        setSendFormOpened(true)
    };

    const sendFormClose = () => {
        setSelectedContact(null);
        setSendFormOpened(false);
        updateSearchInput('');
        dispatch(coinSendReset());
        dispatch(coinUnsetPreselected());
        dispatch(userRemovePreselectedContact());
    };

    const IconComponent= (profile:any)=> {
        return(
            <Grid item container xs={2} md={1} alignItems="center">
                {
                    profile.realm === "dao" ?
                        profile.type === DaoTypes[1] ?
                            <Avatar
                                style={{"backgroundColor": "transparent"}}><GroupIcon
                                color="primary"/></Avatar> :
                            profile.type === DaoTypes[0] ?
                                <Avatar style={{"backgroundColor": "transparent"}}><StoreIcon
                                    color="primary"/></Avatar> :
                                <Avatar
                                    style={{"backgroundColor": "transparent"}}><HomeWorkIconOutlined
                                    color="primary"/></Avatar> :
                        <Avatar style={{"backgroundColor": "transparent"}} src={profile.icon}/>
                }
            </Grid>
        )
    }

    let listRender: JSX.Element = <Typography variant="subtitle1">{t('Common:searchSubtitle') + "..."}</Typography>
    if (searchInput && searchInput.length > 0 && userList) {
        listRender = (
            <>
                <ContactList
                    searchInput={searchInput}
                    placesList={daoList.map(dao => placeBuild(dao))}
                    contactsList={userList}
                    currentProfile={profile!}
                    hasSearched={searchInput.length > 0}
                    locatePLace={/*(location: any) => handleLocatePlace(location)*/ null}
                    handleSelect={(contact) => sendFormOpen(contact)}
                />
            </>
        );
    }

    let sendFormModal = null;
    if (preselectedContact != null) {
        sendFormModal = (
            <SlideModal
                open={sendFormOpened}
                handleClose={sendFormClose}
                title={capitalCase((t('Common:pay'))) + " " + preselectedContact.name}
                icon={preselectedContact.icon}
            >
                <CoinSendForm
                    contact={preselectedContact}
                    handleClose={() => sendFormClose()}
                    preselectedCoin={null}/>
            </SlideModal>
        )
    }
    if (selectedContact != null) { //contact selected by clicking over a contact element
        sendFormModal = (
            <SlideModal
                open={sendFormOpened}
                handleClose={sendFormClose}
                title={capitalCase((t('Common:pay'))) + " " + selectedContact.name}
                icon={selectedContact.icon}
            >

                <CoinSendForm
                    contact={selectedContact}
                    preselectedCoin={preselectedCoin}
                    handleClose={() => sendFormClose()}/>
            </SlideModal>
        );
    }

    return (
        <>
            <PreselectedCoinComponent/>
            <Grid container>
                <Grid item xs={11}>
                    <SearchField
                        placeholder={capitalCase(t('Common:searchContacts'))}
                        searchHandler={(event: { target: { value: string; }; }) => updateSearchInput(event.target.value)}
                        value={searchInput}
                    />
                </Grid>
                <Grid item xs={1} container alignItems="center" style={{textAlign: "center"}}>
                    <IconButton
                        color="primary"
                        onClick={mapModalOpenHandler}
                        style={{padding: 0, margin: "24px 0px 0px 12px"}}>
                        <Explore/>
                    </IconButton>
                </Grid>
            </Grid>
            {listRender}
            <Divider style={{marginBottom: '10px'}}/>
            {getSuggestedUsersLoading || getNearPlacesLoading ?
                <Grid container style={{marginTop: '3vh'}}>
                    <CircularProgress color="primary"/>
                </Grid> : null}
            {suggestedUsers && !getSuggestedUsersLoading && suggestedUsers.length > 0 ?
                <Typography>{t('Common:suggestedUsers')}</Typography> : null}
            {
                suggestedUsers && !getSuggestedUsersLoading ? suggestedUsers.map((profile, index) => {
                    if (profile) {
                        return <React.Fragment key={index}>
                            <ListItem button onClick={() => sendFormOpen(profile)}
                                    style={{paddingLeft: 0, paddingRight: 0}}>
                                <Grid container alignContent="space-between" direction="row" alignItems="center">
                                    {IconComponent(profile)}
                                    <Grid item xs={8} md={10}>
                                        <ListItemText primary={profile.name}
                                                      secondary={profile.realm === 'user' && profile.email ? `${profile.email}` : profile.realm === 'dao' ? `${t(profile.type)}` : null}/>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </React.Fragment>
                    }
                }) : null
            }
            {nearPlaces && !getNearPlacesLoading && nearPlaces.length > 0 ?
                <Typography style={{marginTop: '15px'}}>{t('Common:nearDaos')}</Typography> : null}
            {
                nearPlaces && !getNearPlacesLoading ? nearPlaces.map((profile, index) => {
                    if (profile) {
                        return <React.Fragment key={index}>
                            <ListItem button onClick={() => sendFormOpen(profile)}
                                      style={{paddingLeft: 0, paddingRight: 0}}>
                                <Grid container alignContent="space-between" direction="row" alignItems="center">
                                    {IconComponent(profile)}
                                    <Grid item xs={8} md={10}>
                                        <ListItemText primary={profile.name} secondary={`${t(profile.type)}`}/>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </React.Fragment>
                    }
                }) : null
            }
            {sendFormModal}
            <SlideModal
                open={mapModalOpened}
                handleClose={mapModalCloseHandler}
                title={(t('Map:MapTitle'))}
            >
                <TSMap preselectedCoin={preselectedCoin} handleClose={mapModalCloseHandler}/>
            </SlideModal>
            </>
    );
}
export default SearchBar