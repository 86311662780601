const formSchema = [
  {
    name: "imgFile",
    text: "File",
    type: "file",
    // component: 'components/UI/Form/Upload/CustomImageInput/CustomImageInput',
    // props: {}
  },
  {
    name: "collectionName",
    text: "Collection Name",
    type: "text",
    // component: 'components/UI/Form/Input/TextInput',
    // props: {}
  },
  {
    name: "collectionSymbol",
    text: "Collection Symbol",
    type: "text",
    // component: 'components/UI/Form/Input/TextInput',
    // props: {}
  },
  {
    name: "collectionDescription",
    text: "Collection Description",
    type: "multiline",
    // component: 'components/UI/Form/Input/TextInput',
    // props: {}
  },
  {
    name: "attributes",
    text: "Attributes",
    type: "object[]",
    // component: 'components/UI/Form/Upload/CustomFileInput/CustomFileInput',
  },
  {
    name: "contractFile",
    text: "Contract File",
    type: "file",
    // component: 'components/UI/Form/Upload/CustomFileInput/CustomFileInput',
  },
];

export default formSchema;
