import { Step, StepLabel, Stepper, useMediaQuery } from "@material-ui/core"
import { useEffect, useState } from "react";
import { possibleStatusItemInCalendar, steps } from "../../../../utilities/costants.calendar";

export const PreorderStepper = ({ status }: {
    status: string
}) => {
    const valueOfSteps = new Map();
    const isMobile = useMediaQuery('(max-width:768px)');
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        valueOfSteps.set(possibleStatusItemInCalendar.accepted, 0);
        valueOfSteps.set(possibleStatusItemInCalendar.started, 1);
        valueOfSteps.set(possibleStatusItemInCalendar.ended, 2);
        valueOfSteps.set(possibleStatusItemInCalendar.review, 3);

        setActiveStep(valueOfSteps.get(status));
    }, [])

    if(status === 'Denied') {
        return <></>
    }

    return (
        <Stepper
            style={{ textAlign: 'center' }}
            activeStep={activeStep}
            orientation={isMobile ? 'vertical' : 'horizontal'}
            >
            {
                steps.map((label, index) => {
                    return (
                        <Step key={label} completed={activeStep==3}>
                            {
                                (label !== 'Review' && label !== 'Ended') && (
                                    <StepLabel>{label}</StepLabel>
                                )
                            }
                            {
                                label === 'Review' && (
                                    <StepLabel>Ended</StepLabel>
                                )
                            }
                            {
                                label === 'Ended' && (
                                    <StepLabel>Under review</StepLabel>
                                )
                            }   
                        </Step>
                    )
                })
            }
        </Stepper>
    )
}