import {Circle, Fill, Icon, Stroke, Style} from "ol/style";
import icon from "../../assets/img/mapImgUtils/home-green.png";
import Text from "ol/style/Text";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Cluster from "ol/source/Cluster";
import {Vector as VectorLayer} from "ol/layer";
import {Feature} from "ol";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from 'ol/source/VectorTile';
import map_marker from "../../assets/img/mapImgUtils/map-marker.webp";
import CircleStyle from "ol/style/Circle";
import FL_PLACES from "../../assets/img/mapImgUtils/FL_PLACES.png";
import users from "../../assets/img/mapImgUtils/users.png";
import store from "../../assets/img/mapImgUtils/store.png";
import {DaoTypes} from "../../types/dao.types";

export type DaoContact = {
    firstLifePlaceID: string,
    realm: "dao",
    icon: any,
    name: string,
    additional_properties: {
        commonshoodWallet: string,
    },
    placeOwner: string,
    daoPlaceAddress: string,
    daoDetails:any,
    resources: any,
};

export type CrwdslCardType = {
    owner: string
    title: string
    description: string
    logoHash: string
    start: number
    end: number
    tokenToGiveAddr:string
    tokenToAcceptAddr: string
    status: string

    crwdslAddress: string
    realm: 'crowdsale'
    firstLifePlaceID: string
    props:any,
    ownerData: any
}

export const placesVectorSource = new VectorSource({
    format: new GeoJSON()
});

export const clusterSource = new Cluster({
    distance: 60,
    source: placesVectorSource
});

const outerCircleFill = new Fill({
    color: '#8d8d8d',
});

const innerCircleFill = new Fill({
    color: 'white',
});

const textFill = new Fill({
    color: '#f36944',
});

const innerCircle = new CircleStyle({
    radius: 17,
    fill: innerCircleFill,
});

const outerCircle = new CircleStyle({
    radius: 20,
    fill: outerCircleFill,
});


export const layer = new VectorLayer({
    source: clusterSource,
    zIndex: 2,
    style: (element) => {
        const size = element.get("features").length;
        if (size === 1) {
            const daoType= element.get("features")[0].get('daoDetails')
            // return iconStyle(element.get("features")[0].get("name"));
            let eType = element.get('features')[0].get("entity_type");
            let type = daoType && daoType.type? daoType.type : eType;
            return [
                new Style({
                    image: new Circle({
                        radius: 17,
                        fill: new Fill({
                            color: (eType !== undefined) ? type===DaoTypes[1]? '#E44E3C' : '#0b6258' : '#0b6258',
                        })
                    })}),
                new Style({
                    image: new Circle({
                        radius: 15,
                        fill: new Fill({
                            color: 'white'
                        })
                    })}),
                new Style({
                    image: new Icon({
                        color: '#fff',
                        src: (eType !== undefined) ? type===DaoTypes[1]? users : type===DaoTypes[0]? store: FL_PLACES : icon,
                        scale: 0.25
                    })
                })];

        } else {
            return [
                new Style({
                    image: outerCircle,
                }),
                new Style({
                    image: innerCircle,
                    text: new Text({
                        text: size.toString(),
                        fill: textFill
                    }),
                })
            ];

        }
    }
});

export const vtLayer = new VectorTileLayer({
    source: new VectorTileSource({
        format: new GeoJSON(),
        attributionsCollapsible: false,
        attributions: '© CommonsHood'
    })
})

export const positionFeature = new Feature();
positionFeature.setStyle(
    new Style({
        // add a background color to the icon
        image: new Icon({
            anchor: [0.5, 1],
            scale: 0.1,
            src: map_marker,
        })
    })
)

export const positionLayer= new VectorLayer({
    source: new VectorSource({
        features: [positionFeature],
    }),
});

export const contactSample : DaoContact = {
    firstLifePlaceID: "",
    realm: "dao",
    icon: null,
    name: "",
    additional_properties: {
        commonshoodWallet: ""
    },
    placeOwner: "",
    daoPlaceAddress: '',
    daoDetails: {},
    resources: null
}

export const crwdslCardSample: CrwdslCardType = {
    owner: '',
    ownerData: null,
    title: '',
    description: '',
    logoHash: '',
    start: 0,
    end: 0,
    tokenToGiveAddr:'',
    tokenToAcceptAddr: '',
    status: '',
    crwdslAddress: '',
    realm: 'crowdsale',
    firstLifePlaceID: '',
    props: null,
}