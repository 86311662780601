import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select, TextField } from "@material-ui/core"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { endRevisionButtonStyle, selectStyle, endRevisioneButtonCancelStyle } from "../../Styles/Buttons.style";

export const EndReviewDialogBox = ({ openDialog, setOpenDialog, handleEndReviewPeriod }: {
    openDialog: boolean,
    setOpenDialog: (openDialog: boolean) => void,
    handleEndReviewPeriod: (isItemFine: boolean, comment: string) => void
}) => {
    const [comment, setComment] = useState<string>('');
    const [isItemFine, setIsItemFine] = useState<boolean>(true);
    const useConfirmButton = endRevisionButtonStyle();
    const useSelectStyle = selectStyle();
    const useCancelButton = endRevisioneButtonCancelStyle();
    const { t } = useTranslation(['LibOfThings']);

    const handleChangeIsItemFine = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        setIsItemFine((event.target.value as string) === "true" ? true : false);
    }

    return (
        <Dialog
            style={{ textAlign: 'center' }}
            open={openDialog}
            maxWidth={'xl'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('endThisRevision')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container>
                        <Grid item xs={8}>
                            {t('wasTheItemOk')}
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                className={useSelectStyle.root}
                                value={isItemFine}
                                onChange={(e) => handleChangeIsItemFine(e)}
                                inputProps={{
                                    name: 'is item fine',
                                    id: 'item-fine',
                                }}
                            >
                                <MenuItem value={"true"}>
                                    {t('yes')}
                                </MenuItem>
                                <MenuItem value={"false"}>No</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="standard-full-width"
                                label={t('comment')}
                                style={{ margin: 8 }}
                                placeholder={t('placeHolderExample')}
                                helperText={t('insertAComment')}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <br />
                </DialogContentText>
            </DialogContent>
            <div style={{ textAlign: 'center', marginBottom: '1em' }}>
                <Button className={useConfirmButton.root} onClick={() => handleEndReviewPeriod(isItemFine, comment)} color="primary" autoFocus>
                    {t('confirm')}
                </Button>
                <Button className={useCancelButton.root} onClick={() => setOpenDialog(false)} color="primary">
                    {t('deny')}
                </Button>
            </div>


        </Dialog>
    )
}