import React, { useEffect, useState } from "react";

//Assets types
import { assetsType } from "../../../config/assets";

//Routing HOC
import { useHistory } from "react-router-dom";

// Action creators
import {
  coinCreate,
  coinGetList,
  getTokensFM,
} from "../../../store/slices/coinSlice";

//form field schema
import formSchema from "./config";

//Form controls
import * as Yup from "yup";
// import this in the related component
import { Field, Formik, FormikErrors } from "formik";
import { Select } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import getValidationSchema from "./getValidationSchema";

//Material-UI components
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";

//Custom Components
import TextInput from "../../../components/UI/Form/Input/TextInput";
import FeaturedCard from "../../../components/UI/Card/FeaturedCard/FeaturedCard";
import ZoomModal from "../../../components/UI/Modal/ZoomModal/ZoomModal";
import Loading from "../../../components/UI/Loading/Loading";
import help1 from "../../../assets/img/help/how-to-01.png";
import help2 from "../../../assets/img/help/how-to-02.png";

//derived from Material Kit pro component
import CustomImageInput from "../../../components/UI/Form/Upload/CustomImageInput/CustomImageInput";
import PdfFileInput from "../../../components/UI/Form/Upload/PdfFileInput/PdfFileInput";

// i18n
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    justifyContent: "center",
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
  bigAvatar: {
    backgroundColor: "white",
    margin: "auto",
    width: 80,
    height: 80,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderSize: "1px",
  },
  avatarThumb: {
    maxWidth: 80,
    maxHeight: 80,
  },
  loading: {
    marginTop: "3vh",
  },
  typographyCreationModal: {
    display: "inline-block",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const CoinCreateForm = () => {
  const { t } = useTranslation([
    "NewCoinCreateForm",
    "Common",
    "NewCoinCreate_validationSchema",
  ]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector((state) => state.coin.coinCreationLoading);
  const coinListLoading = useAppSelector((state) => state.coin.getListloading);
  const coinCreated = useAppSelector((state) => state.coin.coinCreated);
  const coinMinting = useAppSelector(
    (state) => state.coin.coinMintingAfterCreation
  );
  const coinError = useAppSelector((state) => state.coin.error);
  const coinList = useAppSelector((state) => state.coin.coinList);
  const fileData = useAppSelector((state) => state.file.fileData);
  const fileError = useAppSelector((state) => state.file.error);

  const [checkCap, setCheckCap] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [instructionOpened, setInstructionOpened] = useState(false);
  const [iconFileURL, setIconFileURL] = useState<string>("");
  const [contractFileURL, setContractFileURL] = useState<string>("");
  const [contractFile, setContractFile] = useState<File>();
  const [waitForCreation, setWaitForCreation] = useState(false);
  const [coinSymbolsList, setCoinSymbolsList] = useState<string[]>([]);

  const handleCreateCoin = (values: {
    coinName: any;
    coinSymbol: any;
    coinDescription: any;
    initialSupply: any;
    cap: any;
    isCapped: any;
    iconFile: any;
    contractFile: any;
    type: any;
  }) => {
    const {
      coinName,
      coinSymbol,
      coinDescription,
      initialSupply,
      isCapped,
      cap,
      iconFile,
      contractFile,
      type,
    } = values;
    const coinData = {
      name: coinName,
      symbol: coinSymbol,
      description: coinDescription,
      initialSupply: initialSupply,
      decimals:
        type === "token"
          ? assetsType.token.decimals
          : assetsType.goods.decimals, //tokens have 2 decimals, coupons have 0
      cap: isCapped ? cap : "",
      iconFile: iconFile,
      contractFile: contractFile,
      type: type === "token" ? assetsType.token.name : assetsType.goods.name,
    };
    setWaitForCreation(true);
    dispatch(coinCreate(coinData));
  };

  const handleInstructionOpen = () => setInstructionOpened(true);

  const handleInstructionClose = () => setInstructionOpened(false);

  const handleConfirmModalOpen = (
    values: {
      [x: string]: any;
      coinName?: string;
      coinSymbol?: string;
      coinDescription?: string;
      initialSupply?: string;
      cap?: string;
      isCapped?: boolean;
      iconFile?: undefined;
      contractFile?: undefined;
      type?: "token";
    },
    isValid: boolean,
    validateForm: {
      (values?: any): Promise<
        FormikErrors<{
          coinName: string;
          coinSymbol: string;
          coinDescription: string;
          initialSupply: string;
          cap: string;
          isCapped: boolean;
          iconFile: undefined;
          contractFile: undefined;
          type: "token";
        }>
      >;
      (): Promise<any>;
    },
    setFieldTouched: {
      (
        field: string,
        isTouched?: boolean | undefined,
        shouldValidate?: boolean | undefined
      ): void;
      (arg0: string): any;
    }
  ) => {
    formSchema.map((field) => setFieldTouched(field.name));
    validateForm().then(() => {
      if (isValid && values != undefined && values.iconFile != undefined && values.contractFile != undefined) {
        setModalOpened(true);
        setIconFileURL(URL.createObjectURL(values.iconFile));
        setContractFileURL(URL.createObjectURL(values.contractFile));
        setContractFile(values.contractFile);
      }
    });
  };

  const handleModalClose = () => {
    setModalOpened(false);
  };

  useEffect(() => {
    const queryUrlParams = new URLSearchParams(
      new URL(document.location.href).searchParams
    );

    if (queryUrlParams.has("firstTime")) {
      setInstructionOpened(true);
    }
    dispatch(getTokensFM("", false, false, -1));
    //dispatch(coinGetList(null, false, false, false, null))
  }, []);

  useEffect(() => {
    if (coinList.length > 0) {
      let coinSymbols: string[] = [];
      coinList.forEach((coin) => {
        coinSymbols.push(coin.symbol);
      });
      setCoinSymbolsList(coinSymbols);
    }

    if (waitForCreation) {
      if (coinCreated || coinError) {
        setWaitForCreation(false);
      }
    }
  }, [coinList, coinCreated]);

  let form = <Loading withLoader title={t("Common:loadingGeneric") + "..."} />;

  const tutorialSteps = [
    {
      label: "",
      imgPath: help1,
    },
    {
      label: "",
      imgPath: help2,
    },
  ];
  const handleCreate = (submitForm: any) => {
    submitForm();
  };

  if (!coinListLoading) {
    const staticValidationSchema = getValidationSchema(t);

    const symbolValidationSchema = Yup.object().shape({
      coinSymbol: Yup.string().notOneOf(coinSymbolsList, t("symbolPresent")),
    });

    const finalValidationSchema = staticValidationSchema.concat(
      symbolValidationSchema
    );

    form = (
      <Formik
        validateOnMount
        initialValues={{
          coinName: "",
          coinSymbol: "",
          coinDescription: "",
          initialSupply: "",
          cap: "",
          isCapped: false,
          iconFile: undefined,
          contractFile: undefined,
          type: assetsType.token.name,
        }}
        validationSchema={finalValidationSchema}
        onSubmit={(values, actions) => {
          handleCreateCoin(values);
          actions.setSubmitting(false);
          actions.resetForm({});
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          validateForm,
          submitForm,
        }) => {
          let modalConfirm: JSX.Element | JSX.Element[] | null = null;
          //  =coinMinting ?
          // (
          //     <>
          //         <Typography variant='body2'>{t('coinMintingAfterCreationMessage')}</Typography>
          //         <CircularProgress/>
          //     </>
          // ) :
          // (
          //     <>
          //         <Typography variant='body2'>{t('blockchainMining')}</Typography>
          //         <CircularProgress/>
          //     </>
          // );
          if (modalOpened) {
            // if (!waitForCreation) {
            //
            //     if (!coinCreated && !coinError) {

            modalConfirm = formSchema.map((field, key) => {
              const { name } = field;
              let valueElement = null;
              if (name === "iconFile") {
                if (values.coinName) {
                  valueElement = (
                    <Avatar className={classes.bigAvatar} style={{ margin: 0 }}>
                      <img
                        className={classes.avatarThumb}
                        alt={values.coinName}
                        src={iconFileURL}
                      />
                    </Avatar>
                  );
                }
              } else if (name === "contractFile") {
                if (values.contractFile) {
                  valueElement = (
                    <Typography variant="body2">
                      {contractFile?.name}
                    </Typography>
                  );
                }
              } else if (name === "type") {
                valueElement = (
                  <div>
                    {assetsType[values[name]].icon}&nbsp;{" "}
                    <Typography variant="body2">
                      {t(`Common:${values[name]}`)}
                    </Typography>
                  </div>
                );
              } else if (name === "coinDescription") {
                valueElement = (
                  <Typography variant="body2">
                    {values.coinDescription}
                  </Typography>
                );
              } else if (name === "coinName") {
                valueElement = (
                  <Typography variant="body2">{values.coinName}</Typography>
                );
              } else if (name === "coinSymbol") {
                valueElement = (
                  <Typography variant="body2">{values.coinSymbol}</Typography>
                );
              } else if (name === "initialSupply") {
                valueElement = (
                  <Typography variant="body2">
                    {values.initialSupply}
                  </Typography>
                );
              }

              return (
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  key={key}
                >
                  <Grid item xs={6} sm={3}>
                    <Typography variant="button">{t(field.name)}</Typography>:
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <strong>{valueElement}</strong>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </Grid>
              );
            });

            // } else if (coinCreated) {
            //
            //     modalConfirm = (
            //         <AlertAvatar big success/>
            //     )
            // } else if (coinError) {
            //     modalConfirm = (
            //         <>
            //             <AlertAvatar big fail/>
            //             <Typography variant="body1">{coinError}</Typography>
            //         </>
            //     );
            // } else if (fileError) {
            //     modalConfirm = (
            //         <>
            //             <AlertAvatar big fail/>
            //             <Typography variant="body1">{fileError}</Typography>
            //         </>
            //     );
            // }
            // }
          }

          return (
            <form onSubmit={handleSubmit}>
              <FeaturedCard title={t("formTitle")}>
                <FormControl>
                  <Field
                    //className={classes.fieldContainer}
                    type="text"
                    name="type"
                    component={Select}
                    inputProps={{ name: "type", id: "type" }}
                    //formControlProps={field.formControlProps ? field.formControlProps : null}
                    renderValue={(assetValue: string) => {
                      if (assetValue === assetsType.token.name) {
                        return (
                          <MenuItem value={assetsType.token.name}>
                            {assetsType.token.icon}&nbsp;
                            {t(`Common:${assetsType.token.name}`)}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem value={assetsType.goods.name}>
                            {assetsType.goods.icon}&nbsp;
                            {t(`Common:${assetsType.goods.name}`)}
                          </MenuItem>
                        );
                      }
                    }}
                  >
                    <MenuItem
                      value={assetsType.token.name}
                      key={assetsType.token.id}
                    >
                      {assetsType.token.icon}&nbsp;
                      {t(`Common:${assetsType.token.name}`)}
                    </MenuItem>
                    <MenuItem
                      value={assetsType.goods.name}
                      key={assetsType.goods.id}
                    >
                      {assetsType.goods.icon}&nbsp;
                      {t(`Common:${assetsType.goods.name}`)}
                    </MenuItem>
                  </Field>
                  <FormHelperText>{t("selectType")}</FormHelperText>
                </FormControl>
                <Field
                  name="iconFile"
                  component={CustomImageInput}
                  title={t("selectIcon")}
                  setFieldValue={setFieldValue}
                  errMessage={errors["iconFile"]}
                  touched={touched["iconFile"]}
                  setFieldTouched={setFieldTouched}
                  className={classes.fieldContainer}
                  value={values.iconFile || undefined}
                  isIcon //This is coin Icon
                />
                <Field
                  fullWidth
                  name="coinName"
                  placeholder={t("coinNamePlaceholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched["coinName"]}
                  component={TextInput}
                  errMessage={errors["coinName"]}
                  value={values.coinName || ""}
                />
                <Field
                  fullWidth
                  name="coinSymbol"
                  placeholder={t("coinSymbolPlaceholder")}
                  onChange={(e: { target: { value: string } }) =>
                    setFieldValue("coinSymbol", e.target.value.toUpperCase())
                  }
                  onBlur={handleBlur}
                  touched={touched["coinSymbol"]}
                  component={TextInput}
                  errMessage={errors["coinSymbol"]}
                  value={values.coinSymbol || ""}
                />
                <Field
                  fullWidth
                  name="initialSupply"
                  placeholder={t("supplyPlaceholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched["initialSupply"]}
                  component={TextInput}
                  errMessage={errors["initialSupply"]}
                  type="text"
                  value={values.initialSupply || ""}
                />

                <Field
                  multiline //TextInput prop
                  fullWidth
                  name="coinDescription"
                  placeholder={t("shortDescriptionPlaceholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched["coinDescription"]}
                  component={TextInput}
                  errMessage={errors["coinDescription"]}
                  value={values.coinDescription || ""}
                />

                <Field
                  name="contractFile"
                  placeholder={t("uploadCoinContractPlaceholder")}
                  component={PdfFileInput}
                  className={classes.fieldContainer}
                  errMessage={errors["contractFile"]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  touched={touched["contractFile"]}
                  value={values.contractFile || undefined}
                />
                {/*<Button*/}
                {/*    color='secondary'*/}
                {/*    onClick={() => handleInstructionOpen()}*/}
                {/*>*/}
                {/*    {t('help')}*/}
                {/*</Button>*/}
              </FeaturedCard>
              <div className={classes.fieldContainer}>
                <Button
                  fullWidth
                  className={classes.buttons}
                  variant="contained"
                  onClick={() => {
                    handleConfirmModalOpen(
                      values,
                      isValid,
                      validateForm,
                      setFieldTouched
                    );
                  }}
                >
                  {t("buttonCreate")}
                </Button>
              </div>
              {loading || coinMinting ? (
                <Grid
                  container
                  item
                  justifyContent="center"
                  className={classes.loading}
                >
                  <CircularProgress color="primary" />
                </Grid>
              ) : null}

              <ZoomModal
                title={t("coinConfirmCreationMessage")}
                open={modalOpened}
                buttons={
                  <>
                    {!waitForCreation ? (
                      <Button
                        className={classes.buttons}
                        onClick={() => {
                          handleCreate(submitForm);
                          handleModalClose();
                        }}
                        type="submit"
                        disabled={waitForCreation}
                        variant="contained"
                      >
                        {t("goCreateButton")}
                      </Button>
                    ) : null}

                    <Button
                      className={classes.buttons}
                      onClick={() => handleModalClose()}
                      variant="contained"
                    >
                      {!waitForCreation
                        ? t("Common:cancel")
                        : t("Common:close")}
                    </Button>
                  </>
                }
              >
                {modalConfirm}
                <Typography
                  className={classes.typographyCreationModal}
                  color="secondary"
                >
                  Confermando, riceverai una notifica al termine dell'operazione
                </Typography>
              </ZoomModal>
            </form>
          );
        }}
      </Formik>
    );
  }

  return form;
};

export default CoinCreateForm;
