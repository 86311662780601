import axiosFirstLifeToken from "../utilities/backend/axios-firstlife-token";
import axiosFirstLifeAuth from "../utilities/backend/axios-firstlife-oauth"

import config from "../config";
import {logger} from "../utilities/logger/logger";
import {UserProfile} from "../types/user.type";


export const getAuth = async (type: 'code' | string, data: any) : Promise<UserProfile> => {
    logger.log('type: ', type);
    logger.log('code: ', data);
    logger.log('client_id', config.network.authserver.firstlife_token.client_id );

    //TODO here if type is != CODE we should manage it in a different way
    const tokenResponse = await axiosFirstLifeToken.post('tokens/fl-auth', {
        "code": data,
        "clientId": config.network.authserver.firstlife_token.client_id,
        "redirectUri": config.network.authserver.firstlife_auth.redirect_uri,
    });

    return tokenResponse.data.token;
}

export const logout = async (token: string): Promise<any> => {
    return await axiosFirstLifeAuth.post('v1/auth/logout', {
        "token": token,
        "clientId": config.network.authserver.firstlife_token.client_id,
    });
}