import { makeStyles } from "@material-ui/core";

export const contentWrapperStyle = makeStyles({
    root: {
        marginLeft: '1em',
        '@media (max-width: 400px)': {
            textAlign: 'center'
        }
    }
})

export const itemNameStyle = makeStyles({
    root: {
        margin: '1em',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeigth: 700,
        fontSize: '19px',
        lineHeight: '22px',
        letterSpacing: '-0.02em',
        color: 'black'
    }
})

export const contentStyle = makeStyles({
    root: {
        '@media (max-width: 400px)': {
            marginBottom: '1em',
            textAlign: 'center'
        }
    }
})

