//Form controls
import {Field, FieldArray, Formik} from "formik";
//Material-UI components
import {Box, Button, Grid, Typography} from "@material-ui/core";
//Custom Components
import TextInput from "../../../../components/UI/Form/Input/TextInput";

// i18n
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core/styles";

type AttributersProp = {
  create: any;
  attributes: {
    trait_type: string;
    value: string;
    required?: boolean;
  }[];
  handleAttributesModalClose: any;
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
}));
const AttributesSelection = (prop: AttributersProp) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation(["NftCreateForm", "Common"]);

  const handleCreateAttributes = (
    attributes: { trait_type: string; value: string; required?: boolean }[]
  ) => {
    prop.create(attributes);
    prop.handleAttributesModalClose();
  };

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{
          attributes: prop.attributes,
        }}
        //validationSchema={finalValidationSchema}
        onSubmit={(values) => {
          handleCreateAttributes(values.attributes);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          submitForm,
          setFieldValue,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box clone mb={3}>
                  <Typography variant="h6" align="left">
                    {t("attributeDescription")}
                  </Typography>
                </Box>

                <FieldArray name="attributes">
                  {({ insert, remove }) => (
                    <div>
                      {values.attributes.length > 0 &&
                        values.attributes.map((attribute, index) => (
                          <Grid container spacing={1} key={index}>
                            <Grid item xs>
                              <Field
                                fullWidth
                                name={"attributes[" + index + "].value"}
                                placeholder={t("attributeValuePlaceholder")}
                                label={
                                  attribute.required ? (
                                    <>
                                      <Typography display="inline">
                                        {attribute.trait_type}
                                      </Typography>
                                      <Typography
                                        display="inline"
                                        color="secondary"
                                      >
                                        {" *"}
                                      </Typography>
                                    </>
                                  ) : (
                                    <Typography>
                                      {attribute.trait_type}
                                    </Typography>
                                  )
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched["attributes"]}
                                component={TextInput}
                                errMessage={errors["attributes"]}
                                value={attribute.value || ""}
                              />
                            </Grid>
                          </Grid>
                        ))}
                    </div>
                  )}
                </FieldArray>
                <Button
                  type="submit"
                  className={classes.buttons}
                  onClick={submitForm}
                  variant="contained"
                >
                  {t("attributeSaveButton")}
                </Button>
                <Button
                  className={classes.buttons}
                  onClick={() => prop.handleAttributesModalClose()}
                  variant="contained"
                >
                  {t("Common:close")}
                </Button>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AttributesSelection;
