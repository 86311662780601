import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { Preorder } from "../../../../types/calendar.type";
import { PreorderCancelledInList } from "./PreorderCancelledInList";

export const ListOfPreordersCancelled = ({ cancelledPreorders, goToItemPage }: {
    cancelledPreorders: Preorder[],
    goToItemPage: (symbol: string, itemID: number) => void
}) => {
    const [preordersToShow, setPreordersToShow] = useState<Preorder[]>([]);
    const loading = useAppSelector(state => state.calendar.loading);

    useEffect(() => {
        setPreordersToShow(cancelledPreorders);
    }, [cancelledPreorders])

    const removePendingPreorderFromList = (preorderID: string) => {
        const preordersFilterered = preordersToShow.filter((p) => {
            if (p.preorderID !== preorderID) {
                return p;
            }
        })
        setPreordersToShow(preordersFilterered);
    }

    return (
        <Grid container style={{ position: 'relative', top: '3em' }} spacing={4}>
            {
                (!loading) ? (
                    preordersToShow.map((p) => {
                        return <Grid style={{ marginBottom: '100px' }} key={p.preorderID} item xs={12}>
                            <PreorderCancelledInList
                                preorder={p}
                                removeFromList={() => removePendingPreorderFromList}
                                goToItemPage={goToItemPage}
                            />
                        </Grid>
                    })
                ) : (
                    <Skeleton
                        style={{
                            position: 'relative',
                            top: '1em',
                        }}
                        variant="rect"
                        width={'100%'}
                        height={'400px'}
                    />
                )
            }
        </Grid>
    )
}