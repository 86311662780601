import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { getAuth, logout } from "../../api/authAPI";
import { logger } from "../../utilities/logger/logger";

import { FLAuthLoadToken } from "../../utilities/backend/axios-firstlife-oauth";
import { FLTokenLoadToken } from "../../utilities/backend/axios-firstlife-token";
import { walletAPILoadToken } from "../../utilities/backend/axios-walletapi";
import { resourceLoadToken } from "../../utilities/backend/axios-resources";
import { firstlifeAPILoadToken } from "../../utilities/backend/axios-firstlife-api";
import { notificationsAPILoadToken } from "../../utilities/backend/axios-notifications";
import { ipfsAPILoadToken } from "../../utilities/backend/axios-ipfs";
import { sotAPILoadToken } from "../../utilities/backend/axios-metadata";

type AuthInitialState = {
  error: string | null;
  loading: boolean;
  authCode: any;
  idToken: any;
  authRedirectPath: string;
};

const initialState: AuthInitialState = {
  error: null,
  loading: false,
  authCode: null,
  idToken: null,
  authRedirectPath: "/",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStart(state) {
      state.error = null;
      state.loading = true;
    },

    authCodeGet(state, action: PayloadAction<{ authCode: any }>) {
      state.authCode = action.payload.authCode;
    },

    authSuccess(state, action: PayloadAction<{ idToken: any }>) {
      state.authCode = null;
      state.error = null;
      state.loading = false;
      state.idToken = action.payload.idToken;
    },

    authFail(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
    },

    setAuthRedirectPath(state, action: PayloadAction<{ path: any }>) {
      state.authRedirectPath = action.payload.path;
    },

    authLogout(state) {
      state.error = null;
      state.loading = false;
      state.authCode = null;
      state.idToken = null;
      state.authRedirectPath = "/";
    },
  },
});

export const {
  authStart,
  authCodeGet,
  authSuccess,
  authFail,
  setAuthRedirectPath,
  authLogout,
} = authSlice.actions;

export const logoutFromBackend = () => {
  return (dispatch: Dispatch) => {
    dispatch(authStart());
    const token = localStorage.getItem("token");
    if (!token) {
      logger.error("token not found in local storage");
      localStorage.clear();
      dispatch(authLogout());
    }
    else{
      logout(token)
          .then(() => {
            localStorage.clear();
            dispatch(authLogout());
          })
          .catch((error) => {
            logger.error(error);
            localStorage.clear();
            dispatch(authFail({ error: error.message }));
            dispatch(authLogout());
          });
    }
  };
};

export const checkAuthOnClient = () => {
  return (dispatch: Dispatch) => {
    dispatch(authStart());
    const idToken = localStorage.getItem("token");
    if (!idToken) {
      logger.error("token not found in local storage");
      dispatch(authLogout());
    } else {
      const localExpirationDate = localStorage.getItem("expiresIn");
      if (localExpirationDate) {
        const expirationDate = new Date(localExpirationDate);
        const now = new Date();
        if (expirationDate <= now) {
          logoutFromBackend()(dispatch);
        } else {
          //loading the token received in the various axios instances that need it
          FLTokenLoadToken();
          FLAuthLoadToken();
          walletAPILoadToken();
          resourceLoadToken();
          firstlifeAPILoadToken();
          notificationsAPILoadToken();
          ipfsAPILoadToken();
          sotAPILoadToken();
          dispatch(authSuccess({ idToken }));
        }
      } else {
        logoutFromBackend()(dispatch);
      }
    }
  };
};

export const checkAuthOnServer = (type: any, data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(authStart());

    logger.info("TYPE: " + type + " DATA: " + data);
    getAuth(type, data)
      .then((response) => {
        const expiresIn = new Date(response.expiration);
        const token = response.access_token;
        const userId = response.member.id;
        localStorage.setItem("expiresIn", new Date(expiresIn).toDateString());
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);

        //loading the token received in the various axios instances that need it
        FLTokenLoadToken();
        FLAuthLoadToken();
        walletAPILoadToken();
        resourceLoadToken();
        firstlifeAPILoadToken();
        notificationsAPILoadToken();
        ipfsAPILoadToken();
        sotAPILoadToken();
        dispatch(authSuccess({ idToken: token }));
      })
      .catch((error) => {
        dispatch(authFail({ error }));
        logger.error(error);
      });
  };
};

export default authSlice.reducer;
