import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export const MyPreordersDashboardMenu = ({ activeMenu, handleMenuChange }: {
    activeMenu: number,
    handleMenuChange: (newMenu: number) => void
}) => {
    const { t } = useTranslation(['LibOfThings']);

    const handleChange = (event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        handleMenuChange(event.target.value as unknown as number)
    }

    return (
        <FormControl fullWidth style={{
            marginTop: '2em'
        }}>
            <InputLabel id="preorders-to-show-label">{t('filterRequests')}</InputLabel>
            <Select
                labelId="selectPreordersToShow"
                id="preorders-to-show"
                value={activeMenu}
                label="tab"
                onChange={handleChange}
            >
                <MenuItem value={0}>{t('pending')}</MenuItem>
                <MenuItem value={1}>{t('accepted')}</MenuItem>
                <MenuItem value={2}>{t('started')}</MenuItem>
                <MenuItem value={3}>{t('underReview')}</MenuItem>
                <MenuItem value={4}>{t('ended')}</MenuItem>
                <MenuItem value={5}>{t('cancelled')}</MenuItem>
                <MenuItem value={6}>{t('denied')}</MenuItem>
            </Select>
        </FormControl>
    )
}