import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

export const PaginationContainer = ({ pageCount, onChange }: {
    pageCount: number,
    onChange: (selectedPage: number) => Promise<void>
}) => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleChange = (selectedItem: { selected: number }) => {
        // the selected field of clickEvent does not work as expected, it outputs random numbers. 
        // it is correct only when the user clicks on the next or previous button
        onChange(selectedItem.selected);
    }

    return (
        <div className="react-paginate-container">
            <ReactPaginate
                breakLabel={'...'}
                nextLabel={isMobile ? '>' : 'next'}
                marginPagesDisplayed={1}
                pageRangeDisplayed={0}
                pageCount={pageCount > 0 ? pageCount : 1}
                previousLabel={isMobile ? '<' : 'previous'}
                className="react-paginate"
                onPageChange={handleChange}
            ></ReactPaginate>
        </div>

    )
}