import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { greenButton } from "../Styles/Buttons.style";
import { useState } from "react";
import { QrReaderForItems } from "./QrReaderForItems";
import { QrConfirmationInfo } from "./Dialogs/QrConfirmationInfo";
import { QrComponentButtons } from "./QrComponentButtons";
import { EndLendingPeriodDialog } from "./Dialogs/QrEndLendingPeriodDialog";
import { QrCodeContent, QrContentForEndLendingPeriod, QrContentForStartLendingPeriod } from "../../../types/calendar.type";
import { QrStartLendingPeriodDialog } from "./Dialogs/QrStartLendingPeriodDialog";


export const QrActionsList = (
    { toggleDialog, dialogOpen }: { toggleDialog: (newValue: boolean) => void, dialogOpen: boolean }
) => {
    const [showQrCode, setShowQrCode] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [qrContentReceived, setQrContentReceived] = useState<QrCodeContent | QrContentForEndLendingPeriod | QrContentForStartLendingPeriod | null>(null);
    const button = greenButton();

    const handleShowQrCode = () => {
        setShowQrCode(true);
    }

    const handleCloseDialog = () => {
        toggleDialog(false);
        setShowQrCode(false);
    }

    const openConfirmatonDialog = (qrContent: QrCodeContent | QrContentForEndLendingPeriod | QrContentForStartLendingPeriod) => {
        setShowQrCode(false);
        setShowConfirmation(true);
        setQrContentReceived(qrContent);
    }

    const closeConfirmationDialog = () => {
        setQrContentReceived(null);
        setShowConfirmation(false);
        setShowQrCode(false);
        toggleDialog(false);
    }

    return (
        <Dialog fullWidth open={dialogOpen} onClose={() => handleCloseDialog()}>
            <DialogTitle style={{ textAlign: 'center' }}>
                {qrContentReceived == null && "Scegli l'opzione e scannerizza il codice QR"}
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                {(qrContentReceived != null && qrContentReceived.handle === 'preorderItem') && <QrConfirmationInfo preorder={qrContentReceived} />}
                {(qrContentReceived != null && qrContentReceived.handle === 'endLendingPeriod') && < EndLendingPeriodDialog />}
                {(qrContentReceived != null && qrContentReceived.handle === 'startLendingPeriod') && <QrStartLendingPeriodDialog preorder={qrContentReceived.preorder} item={qrContentReceived.item} />}
                <QrComponentButtons
                    showQrCode={showQrCode}
                    qrContentReceived={qrContentReceived}
                    handleButtonAskLending={handleShowQrCode}
                    closeConfirmationDialog={closeConfirmationDialog}
                    handleButtonStartLending={handleShowQrCode}
                />
                <QrReaderForItems show={showQrCode} openConfirmation={openConfirmatonDialog} />
                {
                    showQrCode && <Button className={button.root} onClick={() => { setShowQrCode(false) }} style={{ width: '50%', color: 'white', marginTop: '16px', marginBottom: '16px' }}>Chiudi scanner</Button>
                }
            </DialogContent>
        </Dialog>
    );
}