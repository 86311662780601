import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {Chip, Fab, IconButton} from "@material-ui/core";
import PendingTransactionList from "./PendingTransactionList";
import {makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {onGoingTransactionPush} from "../../../store/slices/onGoingTransactionsSlice";

type CustomCircularProgressType = {
    handleMenu: (event: any) => void,
    anchorEl: EventTarget & Element | null,
    onClose: () => void,
}

const useStyle = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        marginTop: '10px',
    },
    bottom: {
        color: 'whitesmoke',
    },
    top: {
        color: theme.palette.secondary.main,
        animationDuration: '700ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },

}));


const CustomCircularProgress = (CustomCircluarProgressProps: CustomCircularProgressType) => {
    const theme = useTheme();
    const classes = useStyle(theme);
    const open = Boolean(CustomCircluarProgressProps.anchorEl);
    const dispatch = useAppDispatch();

    const onGoingTransactions = useAppSelector(state => state.onGoingTransactions.onGoingTransactions);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        function reportWindowSize() {setWindowWidth(window.innerWidth)}
        window.addEventListener('resize', reportWindowSize)
        return () => window.removeEventListener('resize', reportWindowSize)
    }, [])

    return (
        <>
            <Fab size="medium" color='primary' style={{
                margin: 0,
                top: 'auto',
                left: windowWidth<=600 ? '20px' : '260px',
                bottom: '80px',
                position: 'fixed',
            }}
                onClick={CustomCircluarProgressProps.handleMenu}
            >
                <div className={classes.root}>
                    <CircularProgress
                        variant="determinate"
                        className={classes.bottom}
                        size={40}
                        thickness={6}
                        value={100}

                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.top}
                        classes={{
                            circle: classes.circle,
                        }}
                        size={40}
                        thickness={6}

                    />
                </div>

                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    margin={'auto'}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Chip
                        label={<Typography variant="caption" component="div"
                                           style={{
                                               fontWeight: 'bold',
                                               fontSize: '1rem',
                                           }}>{onGoingTransactions.length}</Typography>}
                        color='primary'
                        size="small"
                    />
                </Box>
            </Fab>

            <PendingTransactionList
                id={'pending-transaction-list'}
                anchorEl={CustomCircluarProgressProps.anchorEl}
                open={open}
                onClose={() => CustomCircluarProgressProps.onClose()}
            />
        </>
    );
}

export default CustomCircularProgress;