import React from 'react';


//Custom components
//import AppIcon from '../../../AppIcon/AppIcon'
//Style injections
import {withStyles} from "@material-ui/core/styles";
import searchFieldStyle from "./SearchFieldStyle";

//Material-UI Components
import {Input, InputAdornment, InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
//Material Icons
import {Search} from "@material-ui/icons";

const searchField = props => {

    const {
        classes,
        placeholder,
        value,
        searchHandler} = props;
    return (

        <FormControl
            fullWidth
            className={classes.textField}
        >
            <InputLabel htmlFor="searchField">{placeholder}</InputLabel>
            <Input
                id="searchField"
                value={value}
                onChange={searchHandler}
                endAdornment={<InputAdornment position="end">
                    {/*<AppIcon icon={{font: 'material', name: 'search'}}/>*/}
                    <Search />
                </InputAdornment>}
            />
        </FormControl>

    );
};

export default withStyles(searchFieldStyle)(searchField);
