import axios from 'axios';
import config from '../../config';
import {logger} from "../logger/logger";


const instance = axios.create (
    {baseURL:  config.network.firstLifeApi.url}
);

export const firstlifeAPILoadToken = () => {
    const token = localStorage.getItem('token');
    if (token == null) {
        logger.debug('token still null ', token);
        setTimeout(firstlifeAPILoadToken, 250);
    } else {
        logger.debug('updating axios firstlife API with token: ', token);
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ token;
    }
};

instance.interceptors.response.use(undefined, err => {
    const error = err.response;

    // if error is 401
    if (error && error.status===401 &&
        error.config &&
        !error.config.__isRetryRequest
    ) {
        window.location.assign("/logout");
    }
});

export default instance
