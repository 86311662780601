import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit';
import {uploadResource} from "../../api/resourceAPI";

type FileInitialState = {
    error: string | null,
    loading: boolean,
    fileData: string | null,
    fileDataURL: string | null,
    fileList: string[] | null,
}

const fileInitialState: FileInitialState = {
    error: null,
    loading: false,
    fileData: null,
    fileDataURL: null,
    fileList: null,
};

export const fileSlice = createSlice({
    name: 'File',
    initialState: fileInitialState,
    reducers: {
        fileUploadStart(state){
            state.error = null;
            state.fileData = null;
            state.loading = true;
        },
        fileUploadSuccess(state, action: PayloadAction<{fileData: any}> ){
            state.fileData = action.payload.fileData;
            state.loading = false;
        },
        fileUploadFail(state, action: PayloadAction<{error: string}>){
            state.error = action.payload.error;
            state.loading = false;
        },
        fileGetStart(state){
            state.error = null;
            state.fileData = null;
            state.loading = true;
        },
        fileGetSuccess(state, action: PayloadAction<{fileData: any}>){
            state.error = null;
            state.fileData = action.payload.fileData;
            state.loading = false;
        },
        fileGetFail(state, action: PayloadAction<{error: string}>){
            state.error = action.payload.error;
            state.loading = false;
            state.fileData = null;
        },
        fileGetReset(state){
            state.error = null;
            state.loading = false;
            state.fileData = null;
        },
        fileGetListStart(state){
            state.error = null;
            state.fileList = null;
            state.loading = true;
        },
        fileGetListSuccess(state, action: PayloadAction<{fileList: any}>){
            state.error = null;
            state.fileList = action.payload.fileList;
            state.loading = false;
        },
        fileGetListFail(state, action: PayloadAction<{error: string}>){
            state.error = action.payload.error;
            state.loading = false;
            state.fileList = null;
        },
        fileGetListReset(state){
            state.error = null;
            state.loading = false;
            state.fileList = null;
        },
    }
});

export const {
    fileUploadStart, fileUploadSuccess, fileUploadFail,
    fileGetFail, fileGetReset, fileGetStart, fileGetSuccess,
    fileGetListReset, fileGetListStart, fileGetListSuccess, fileGetListFail
} = fileSlice.actions;

export const fileUpload = (file: File) => {
    return async (dispatch: Dispatch) => {
        dispatch(fileUploadStart());
        try{
            const response = await uploadResource(file);
            dispatch(fileUploadSuccess({fileData: response.fileUrl}));
        }catch(error: any){
            dispatch(fileUploadFail({error}));
        }
    }
};

export default fileSlice.reducer;
