import { makeStyles } from "@material-ui/core";

export const calendarTileStyle = makeStyles({
    root: {
        border:'none',
        backgroundColor: 'Transparent',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        marginTop: '1em',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0B6258',
            borderRadius: '10px',
            color: 'white'
        }
    }
})