import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {  useAppSelector } from "../../../store/hooks";
import { MyToaster } from "../BasicComponents/MyToaster";
import { searchButton } from "../Styles/Buttons.style";
import { ListOfItems } from "./MyItems/ListOfItems";
import { ListOfPreordersPending } from "./Lendings/Pending/ListOfPreordersPending";
import { MyItemsDashboardMenu } from "./MyItemsDashboardMenu";
import { SearchBar } from "./SearchBar";
import { ListOfAcceptedPreorders } from "./Lendings/Accepted/ListOfAcceptedPreorders";
import { ListOfStartedPreorders } from "./Lendings/Started/ListOfStartedPreorders";
import { ListOfPreordersUnderReview } from "./Lendings/UnderReview/ListOfPreordersUnderReview";
import { ListOfEndedPreorders } from "./Lendings/Ended/ListOfEndedPreorders";

const titleStyle = makeStyles({
    root: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '32.81px',
        letter: '-2%',
        whiteSpace: 'nowrap',
        '@media (max-width: 837px)': {
            fontSize: '17px'
        }
    }
})

const mainContainerStyle = makeStyles({
    root: {
        position: 'relative',
        top: '2em',
        '@media (min-width: 837px)': {
            left: '0.2em',
        }
    }
})

export const MyItems = () => {
    const useTitleStyle = titleStyle();
    const useSearchButtonStyle = searchButton();
    const useMainContainerStyle = mainContainerStyle();
    const [activeMenu, setActiveMenu] = useState(0);
    const { t } = useTranslation(['LibOfThings']);
    const items = useAppSelector((state) => state.libOfThings.items);
    const collections = useAppSelector((state) => state.nft.collectionList);

    const handleMenuChange = (menuToActivate: number) => {
        setActiveMenu(menuToActivate);
    }

    return (
        <>
            <MyToaster />
            <Grid container className={useMainContainerStyle.root}>
                <Grid item xs={6} md={6}>
                    <Typography variant={'h4'} className={useTitleStyle.root}>
                        Dashboard - {t('items')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <SearchBar />
                        </Grid>
                        <Grid item xs={3}>
                            <Button className={useSearchButtonStyle.root}>{t('search')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9} md={3}>
                    <MyItemsDashboardMenu
                        activeMenu={activeMenu}
                        handleMenuChange={handleMenuChange}
                    />
                </Grid>

                {
                    (activeMenu === 0) && (
                        <ListOfItems
                            items={items}
                            collections={collections}
                        />
                    )
                }
                {
                    (activeMenu === 1) && (
                        <ListOfPreordersPending />
                    )
                }

                {
                    (activeMenu === 2) && (
                        <ListOfAcceptedPreorders />
                    )
                }

                {
                    (activeMenu === 3) && (
                        <ListOfStartedPreorders
                        />
                    )
                }

                {
                    (activeMenu === 4) && (
                        <ListOfPreordersUnderReview />
                    )
                }

                {
                    (activeMenu === 5) && (
                        <ListOfEndedPreorders />
                    )
                }
                

            </Grid>
        </>
    )
}