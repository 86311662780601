import { Dialog, DialogContentText, DialogTitle, Typography, makeStyles } from "@material-ui/core";
import { Item } from "../../../types/libofthings.type";
import { useState } from "react";
import QRCode from "qrcode.react";
import { useAppSelector } from "../../../store/hooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { qrContainerTitleStyle } from "../Styles/Qr.style";
import { QrCodeContent } from "../../../types/calendar.type";


export const ItemQRCode = ({ item, showQR, handleShowQR }: {
    item: Item,
    showQR: boolean
    handleShowQR: (value: boolean) => void,
}) => {
    const { t } = useTranslation(['LibOfThings']);
    const useQrContainerTitleStyle = qrContainerTitleStyle();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const dates = useAppSelector(state => state.calendar.rangeOfDatesForItem);

    const handleDateChange = (date: Date | null) => {
        if (date == null)
            return;

        setSelectedDate(date);
    }

    return (
        <Dialog
            onClose={() => handleShowQR(false)}
            open={showQR}
        >
            <DialogTitle id="qr-dialog-title">
                <Typography className={useQrContainerTitleStyle.root} variant={'h5'} style={{ paddingTop: '1em' }}>
                    {t('qrCodeContainerTitle')}
                </Typography>
            </DialogTitle>
            <DialogContentText style={{ textAlign: 'center' }}>
                <DatePicker
                    showIcon
                    selected={selectedDate}
                    excludeDateIntervals={dates.map(date => ({ start: new Date(date.startDate * 1000), end: new Date(date.endDate * 1000) }))}
                    onChange={handleDateChange}
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                    {t('qrCodeContainerMessage')}
                </Typography>
                {
                    selectedDate != null && (
                        <QRCode
                            size={300}
                            style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '2em', maxWidth: '350px', maxHeight: '500px' }}
                            value={JSON.stringify({
                                handle: 'preorderItem',
                                item: item,
                                startDate: Math.floor(Date.now() / 1000),
                                endDate: Math.floor(selectedDate.getTime() / 1000)
                            } as QrCodeContent)} renderAs="canvas" />
                    )
                }

            </DialogContentText>
        </Dialog>
    )
};